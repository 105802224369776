import { Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { paths } from '@/routing';

import { ScheduleMeeting } from '../schedule-meeting/ScheduleMeeting';

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={8}
      className='max-w-5xl rounded-3xl bg-white-100 p-6 lg:p-10'
      data-testid='portfolio-hero'
      data-cy='portfolio-hero'
    >
      <Stack spacing={4}>
        <span className='typography-display2 text-primary-100' data-cy='portfolio-hero-title'>
          {t('landSteward.portfolio.hero.title')}
        </span>
        <span className='typography-h4 whitespace-pre-wrap text-text-secondary' data-cy='portfolio-hero-subtitle'>
          <Trans
            i18nKey='landSteward.portfolio.hero.subtitle'
            components={{
              Link: <Link className='underline' to={paths.landSteward.investments} data-cy='investments-link' />,
            }}
          />
        </span>
      </Stack>
      <ScheduleMeeting className='bg-neutral-black-4' />
    </Stack>
  );
};
