/* eslint-disable no-redeclare */
export function getMapPadding(
  /**
   * Percent of the smallest edge of the measured element to be considered as
   * padding. By default, the smallest edge of the document body is measured.
   * Value should lie in the range 0 <= value <= 50.
   *
   * @default 10
   */
  percentPadding?: number,
): number;

export function getMapPadding(
  /**
   * The HTML element to be measured to calculate the padding.
   *
   * @default document.body
   */
  element: HTMLElement,
  /**
   * Percent of the smallest edge of the measured element to be considered as
   * padding. By default, the smallest edge of the document body is measured.
   * Value should lie in the range 0 <= value <= 50.
   *
   * @default 10
   */
  percentPadding?: number,
): number;

export function getMapPadding(percentPaddingOrElement: unknown, maybePercentPadding = 10) {
  let percentPadding = maybePercentPadding;
  let element = document.body;

  if (typeof percentPaddingOrElement === 'number') {
    percentPadding = percentPaddingOrElement;
  } else {
    element = (percentPaddingOrElement as HTMLElement) ?? document.body;
  }

  if (percentPadding < 0 || percentPadding > 50) {
    throw Error('Percent padding must be in range 0 <= percentPadding <= 50');
  }

  const smallestEdgeSize = Math.min(element.clientHeight, element.clientWidth);
  const padding =
    smallestEdgeSize * (percentPadding / 100) - 1; /* Subtract 1 to prevent crash on android if padding = edge size */

  return Math.round(padding);
}
