import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { queryClient } from '@/config/queryClient';

import { getRequest } from './requests/get';
import { RequestData } from './types';

export const useApiGetRequest = <PathVariables, QueryParams, SuccessResponse, ErrorResponse = Error>(
  queryKey: QueryKey,
  path: string,
  params?: RequestData<PathVariables, QueryParams, undefined>,
  options?: Omit<UseQueryOptions<SuccessResponse, ErrorResponse>, 'queryKey' | 'queryFn'>,
) => {
  const abort = () => {
    queryClient.cancelQueries({ queryKey });
  };

  const query = useQuery<SuccessResponse, ErrorResponse>({
    queryKey,
    queryFn: ({ signal }) => getRequest<SuccessResponse>(path, params?.pathVariables, params?.queryParams, signal),
    ...options,
  });

  return {
    abort,
    ...query,
  };
};
