import { Trans, useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const CarbonUpliftPotentialExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='carbon-explainer'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.projects.project.impactProjections.carbon.explainers.howDoMeasureThis.headline')}
        </ExplainerTitle>
        <ExplainerBody>
          <ExplainerCopy
            body={
              <Trans
                i18nKey='shared.projects.project.impactProjections.carbon.explainers.howDoMeasureThis.body'
                components={{
                  br: <br />,
                  ol: <ol className='ml-6 list-outside list-decimal space-y-4 pt-4' />,
                  li: <li />,
                }}
              />
            }
          />
          <ExplainerCopy body={t('shared.ncaDetail.details.explainers.howDoMeasureThis.confidenceInterval.body')} />
        </ExplainerBody>
      </ExplainerContent>
    </Explainer>
  );
};
