import MapPinAreaImageSrc from '@assets/images/map-pin-area.svg';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { useState } from 'react';
import { LngLatLike, Marker, useMap } from 'react-map-gl';

import { LocationSearchDialog } from './components/LocationSearchDialog';

export type LocationSearchProps = {
  open: boolean;
  onOpenChange?: (open: boolean) => void;
};

export const LocationSearch = ({ open, onOpenChange }: LocationSearchProps) => {
  const { current: map } = useMap();

  const [searchedLocation, setSearchedLocation] = useState<GeocodeFeature | null>(null);

  const handleLocationSearch = (location: GeocodeFeature) => {
    setSearchedLocation(location);
    map?.flyTo({ center: location.center as LngLatLike, duration: 0, zoom: 18 });
  };

  return (
    <>
      <LocationSearchDialog onLocationSelect={handleLocationSearch} open={open} onOpenChange={onOpenChange} />
      {searchedLocation?.center && (
        <Marker longitude={searchedLocation.center[0]} latitude={searchedLocation.center[1]}>
          <img width='100' height='100' alt='map-pin' src={MapPinAreaImageSrc as string} />
        </Marker>
      )}
    </>
  );
};
