import React from 'react';
import { useLocation } from 'react-router-dom';

import { ConfirmMailChangeState } from '@/pages/confirm-mail-change/hooks/useConfirmMailChange';

import { AccountEditEmailConfirmMailChangeDialog } from './components/confirm-mail-change-dialog/ConfirmMailChangeDialog';
import { AccountEditEmailForm } from './components/form/Form';

export const AccountEditEmailPage: React.FC = () => {
  const { state } = useLocation();

  return (
    <>
      <AccountEditEmailForm />
      {state?.confirmMailChangeState && state.confirmMailChangeState !== ConfirmMailChangeState.NO_TOKEN_PROVIDED && (
        <AccountEditEmailConfirmMailChangeDialog state={state.confirmMailChangeState} />
      )}
    </>
  );
};
