import { PasswordInput } from '@landler/component-library';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePatchUserMeChangepassword } from '@/api/rest/resources/user';
import { ControlledTextInput } from '@/components/Form/ControlledTextInput';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';

import { EditContainerLayout, EditContainerLayoutBox } from '../../components';
import { SuccessDialog } from './components/SuccessDialog';

interface Inputs {
  passwordOld: string;
  password: string;
  passwordRetype: string;
}

// NOTE: The form field names must match the api param names to make useRestApiServerErrorHandling work
const formFields: (keyof Inputs)[] = ['passwordOld', 'password', 'passwordRetype'];
const TRANSLATION_KEY_REQUIRED = 'global.ui.form.input.required';

export const AccountEditPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const { control, formState, handleSubmit, setError, watch, reset } = useForm<Inputs>();
  const newPassword = watch('password');

  const areAllInputsFilled = Object.keys(formState.dirtyFields).length === formFields.length;
  const { mutate, isPending } = usePatchUserMeChangepassword();

  const handleServerError = useRestApiServerErrorHandling(setError, formFields);

  const handleSave: SubmitHandler<Inputs> = (data) => {
    mutate(
      { bodyData: { password_old: data.passwordOld, password: data.password } },
      {
        onSuccess: () => {
          reset();
          setShowSuccessDialog(true);
        },
        onError: handleServerError,
      },
    );
  };

  const handleSuccessDialogOpenChange = (open: boolean) => {
    if (open === false) {
      setShowSuccessDialog(false);
    }
  };

  return (
    <EditContainerLayout
      isSubmitting={isPending}
      isSaveButtonDisabled={!areAllInputsFilled}
      onSave={handleSubmit(handleSave)}
      title={t('shared.account.editPassword.title')}
      dataCy='edit-password'
    >
      <EditContainerLayoutBox>
        <Stack spacing={4} maxWidth='480px'>
          <ControlledTextInput
            id='passwordOld'
            name='passwordOld'
            label={t('shared.account.editPassword.labels.currentPassword')}
            control={control}
            component={PasswordInput}
            rules={{
              required: t(TRANSLATION_KEY_REQUIRED),
            }}
            data-cy='edit-password-current-password-container'
          />
          <ControlledTextInput
            id='password'
            name='password'
            label={t('shared.account.editPassword.labels.newPassword')}
            control={control}
            component={PasswordInput}
            rules={{
              required: t(TRANSLATION_KEY_REQUIRED),
              minLength: { value: 8, message: t('global.ui.form.input.passwordMinLength') },
            }}
            data-cy='edit-password-new-password-container'
          />
          <ControlledTextInput
            id='passwordRetype'
            name='passwordRetype'
            label={t('shared.account.editPassword.labels.newPasswordRetype')}
            control={control}
            component={PasswordInput}
            rules={{
              required: t(TRANSLATION_KEY_REQUIRED),
              validate: (value) => value === newPassword || t('global.ui.form.input.passwordMismatch'),
            }}
            data-cy='edit-password-confirm-new-password-container'
          />
        </Stack>
      </EditContainerLayoutBox>
      {showSuccessDialog && <SuccessDialog open onOpenChange={handleSuccessDialogOpenChange} />}
    </EditContainerLayout>
  );
};
