// TODO: (MVP-1132): these types should be generated automatically based on the API's Swagger documentation

export enum OrganizationTypeEnum {
  DEMO = 'DEMO',
  MANAGED = 'MANAGED',
  USER = 'USER',
}

export type Organization = {
  id: string;
  name: string;
  type: OrganizationTypeEnum;
};
