import {
  ButtonProps,
  cn,
  Divider,
  IconButton,
  Pill,
  RiArrowRightSLine,
  RiCheckboxMultipleLine,
  Stack,
  StackProps,
} from '@landler/tw-component-library';
import React, { FC, HTMLAttributes, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, To } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { PlotType } from '@/api/rest/resources/types/plot';
import { ProjectBuyerDetail, ProjectDetail, ProjectStatus } from '@/api/rest/resources/types/project';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { KycEnum } from '@/api/rest/resources/types/user';
import { PlotTypeIcon, ProjectStatusBadge } from '@/components';
import { KycDialog } from '@/components/KycDialog/kyc-dialog';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useUser } from '@/lib/auth';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { exhaustivenessCheck } from '@/utils/exhaustivenessCheck';
import { formatUnit } from '@/utils/formatting';
import { squareMetersToHectares } from '@/utils/plot';

const maxDisplayCropCount = 10;

export const ProjectOverview: React.FC = () => {
  const { t } = useTranslation();

  const membershipType = useMembershipType();

  const projectDetail = useProjectDetailById().data;
  const plots = usePlotsForProject().data.results;

  const isDemoProject = projectDetail.organization.type === OrganizationTypeEnum.DEMO;
  const plotTypes = projectDetail.landtypes_allowed;
  const numPlots = plots.length;
  const numCroplandPlots = plots.filter((plot) => plot.type === PlotType.CROPLAND).length;

  const crops =
    numCroplandPlots && numCroplandPlots > 0 && projectDetail.crops && projectDetail.crops.length > 0
      ? projectDetail.crops.map((crop) => t(`global.crops.${crop}`)).sort()
      : undefined;

  const sponsorName = (projectDetail as ProjectDetail).buyer?.name;
  const sellerName = (projectDetail as ProjectBuyerDetail).organization.name;
  const areaDisplay = useDisplayNumber(squareMetersToHectares(projectDetail.area));

  return (
    <div
      className='grid-row grid auto-rows-min items-start overflow-hidden rounded-3xl bg-white-100'
      data-testid='project-overview'
    >
      <DataGroup className='items-start'>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.details.labels.projectName')}</DataStackLabel>
        </DataStack>
        <DataStackContent>{projectDetail.name}</DataStackContent>
      </DataGroup>

      <Divider />

      <DataGroup className={cn('grid grid-cols-1 gap-6 md:grid-cols-2')}>
        {membershipType === MembershipWithOrganizationTypeEnum.buyer ? (
          <DataStack>
            <DataStackLabel>{t('shared.ncaDetail.details.labels.landSteward')}</DataStackLabel>
            <DataStackContent>{sellerName}</DataStackContent>
          </DataStack>
        ) : (
          <DataStack>
            <DataStackLabel>{t('shared.projects.overviewCard.labels.sponsor')}</DataStackLabel>
            <DataStackContent>{sponsorName}</DataStackContent>
          </DataStack>
        )}

        <DataStack>
          <DataStackLabel>{t('shared.projects.overviewCard.labels.projectSize')}</DataStackLabel>
          <DataStackContent>{`${areaDisplay} ${formatUnit(UnitEnum.ha)}`}</DataStackContent>
        </DataStack>

        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.details.labels.location')}</DataStackLabel>
          <DataStackContent>
            {projectDetail.location_description || t('global.plot.mapPopup.unknownLocation')}
          </DataStackContent>
        </DataStack>

        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.details.labels.projectStatus')}</DataStackLabel>
          <DataStackContent>
            <ProjectStatusBadge project={projectDetail} data-testid='project-status-badge' />
          </DataStackContent>
        </DataStack>
      </DataGroup>

      <Divider className='mx-6' />

      <DataGroup className={cn('grid grid-cols-1 gap-6 md:grid-cols-2')}>
        {plotTypes && (
          <DataStack>
            <DataStackLabel>{t('shared.projects.overviewCard.labels.projectLandType')}</DataStackLabel>
            <DataStackContent>
              {plotTypes.length > 1 ? (
                t('shared.projects.overviewCard.mixedLandtypes')
              ) : (
                <Stack direction='row' spacing={1}>
                  <PlotTypeIcon type={plotTypes[0] as PlotType} size={20} />
                  <span>{t(`global.plotTypes.${plotTypes[0] as PlotType}`)}</span>
                </Stack>
              )}
            </DataStackContent>
          </DataStack>
        )}

        {crops && (
          <DataStack className='md:row-[span_2]'>
            <DataStackLabel>{t('shared.ncaDetail.details.labels.crops')}</DataStackLabel>
            <Stack className='flex flex-row flex-wrap gap-2'>
              {crops.slice(0, maxDisplayCropCount).map((crop, index) => (
                <Pill key={index} size='small' className='whitespace-nowrap bg-warning-light'>
                  {crop}
                </Pill>
              ))}
              {crops.length > maxDisplayCropCount && (
                <Pill key='...' size='small' className='bg-white whitespace-nowrap'>
                  {t(`shared.ncaDetail.itemsMoreCount`, {
                    count: crops.length - maxDisplayCropCount,
                  })}
                </Pill>
              )}
            </Stack>
          </DataStack>
        )}

        {numPlots > 0 && (
          <DataStack>
            <DataStackLabel>{t('shared.ncaDetail.details.labels.numberOfPlots')}</DataStackLabel>
            <DataStackContent>
              {t('shared.ncaDetail.details.labels.plotWithCount', { count: numPlots })}
            </DataStackContent>
          </DataStack>
        )}
      </DataGroup>

      {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
        (isDemoProject ? null : <LandstewardFooter />)}

      {membershipType === MembershipWithOrganizationTypeEnum.buyer && (isDemoProject ? null : <BuyerFooter />)}
    </div>
  );
};

type LandstewardFooterVariant = 'initial' | 'kyc' | 'proposeInvestment' | 'invested';

const LandstewardFooter = () => {
  const { t } = useTranslation();

  // NOTE: Using the kyc status from the user and not from the project detail response because the user gets updated after
  // NOTE: the verification process is completed, so the status automatically switches to in_progress.
  // NOTE: We could also refetch the project detail but this would cause an unnecessary request and loading state.
  const { kyc: kycStatus } = useUser();

  const [isKycDialogOpen, setIsKycDialogOpen] = useState(false);
  const projectId = useProjectId();
  const projectDetail = useProjectDetailById().data;

  const variant = useMemo((): LandstewardFooterVariant => {
    const projectStatus = projectDetail.status;
    const projectIsLocked = projectDetail.is_locked;

    const showKycPrompt =
      [KycEnum.not_verified, KycEnum.failed, KycEnum.in_progress].includes(kycStatus) && projectIsLocked;

    if (showKycPrompt) return 'kyc';

    if (projectIsLocked) return 'proposeInvestment';

    if (projectStatus === ProjectStatus.invested) return 'invested';

    return 'initial';
  }, [projectDetail, kycStatus]);

  if (variant === 'kyc') {
    return (
      <>
        <Footer
          overline={t('shared.ncaDetail.footer_v1_2.kyc.overline')}
          description={t('shared.ncaDetail.footer_v1_2.kyc.description')}
          icon={<RiCheckboxMultipleLine size={24} />}
          cta={
            <FooterCta
              disabled={kycStatus === KycEnum.in_progress}
              onClick={() => {
                setIsKycDialogOpen(true);
              }}
            />
          }
        />
        <KycDialog open={isKycDialogOpen} onChange={setIsKycDialogOpen} />
      </>
    );
  }

  if (variant === 'proposeInvestment') {
    return (
      <Footer
        overline={t('shared.ncaDetail.footer_v1_2.proposeInvestmentLandsteward.overline')}
        description={t('shared.ncaDetail.footer_v1_2.proposeInvestmentLandsteward.description')}
        icon={<RiCheckboxMultipleLine size={24} />}
        cta={<FooterCta to={paths.landSteward.investments} />}
      />
    );
  }

  if (variant === 'invested') {
    return (
      <Footer
        overline={t('shared.ncaDetail.footer_v1_2.investedLandsteward.overline')}
        description={t('shared.ncaDetail.footer_v1_2.investedLandsteward.description')}
        icon={<RiCheckboxMultipleLine size={24} />}
        cta={<FooterCta to={paths.landSteward.investments} />}
      />
    );
  }

  if (variant === 'initial') {
    return (
      <Footer
        overline={t('shared.ncaDetail.footer_v1_2.initial.overline')}
        description={t('shared.ncaDetail.footer_v1_2.initial.description')}
        icon={<RiCheckboxMultipleLine size={24} />}
        cta={<FooterCta to={buildPath(paths.landSteward.plots, { pathParams: { projectId } })} />}
      />
    );
  }

  return exhaustivenessCheck(variant);
};

type BuyerFooterVariant = 'proposeInvestment' | 'invested';

const BuyerFooter = () => {
  const { t } = useTranslation();
  const projectDetail = useProjectDetailById().data;

  const variant = useMemo((): BuyerFooterVariant => {
    if (projectDetail.status === ProjectStatus.invested) return 'proposeInvestment';

    return 'invested';
  }, [projectDetail]);

  if (variant === 'proposeInvestment') {
    return (
      <Footer
        overline={t('shared.ncaDetail.footer_v1_2.proposeInvestmentBuyer.overline')}
        description={t('shared.ncaDetail.footer_v1_2.proposeInvestmentBuyer.description')}
        icon={<RiCheckboxMultipleLine size={24} />}
        cta={<FooterCta to={paths.buyer.investments} />}
      />
    );
  }

  return (
    <Footer
      overline={t('shared.ncaDetail.footer_v1_2.investedLandsteward.overline')}
      description={t('shared.ncaDetail.footer_v1_2.investedLandsteward.description')}
      icon={<RiCheckboxMultipleLine size={24} />}
      cta={<FooterCta to={paths.buyer.investments} />}
    />
  );
};

const Footer: FC<{ overline: string; description: string; icon: React.ReactNode; cta: React.ReactNode }> = ({
  overline,
  description,
  icon,
  cta,
}) => {
  return (
    <Stack
      direction='row'
      className='items-center justify-between gap-5 bg-neutral-black-8 p-6 full-bleed-x'
      data-test-id='card-footer'
      data-cy='cta-container'
    >
      <Stack direction='row' className='items-center gap-4'>
        <div className='items-center justify-center rounded-full bg-neutral-black-30 p-3 text-white-100'>{icon}</div>
        <div className='flex flex-col gap-2'>
          <span className='typography-overline text-text-secondary' data-cy='cta-title'>
            {overline}
          </span>
          <span className='typography-h4 text-text-primary' data-cy='cta-text'>
            {description}
          </span>
        </div>
      </Stack>
      {cta}
    </Stack>
  );
};

const FooterCta = <T,>({
  to,
  ...delegated
}: { to?: T } & (T extends To ? Omit<LinkProps, 'children' | 'to'> : Omit<ButtonProps, 'children'>)) => {
  const style = 'h-fit border border-primary-100 text-primary-100';

  if (typeof to === 'string')
    return (
      <IconButton className={style} asChild>
        <Link to={to}>
          <RiArrowRightSLine size={24} />
        </Link>
      </IconButton>
    );

  return (
    <IconButton className={style} {...(delegated as ButtonProps)}>
      <RiArrowRightSLine size={24} />
    </IconButton>
  );
};

const DataGroup: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => (
  <div className={cn('min-w-0 p-6', className)} {...delegated} />
);

const DataStack = ({ className, ...delegated }: StackProps) => {
  return <Stack className={cn('min-w-0', className)} {...delegated} />;
};

const DataStackLabel: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-overline mb-2', className)} {...delegated} />
);
const DataStackContent: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h3 min-w-0 break-words', className)} {...delegated} />
);
