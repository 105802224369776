import { Stack } from '@landler/tw-component-library';
import { SVGProps } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { useDisplayNumber } from '@/hooks/useDisplayNumber';

export const ChartTooltip = (
  content: TooltipProps<ValueType, NameType>,
  xUnit?: string,
  yUnit?: string,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  formatter?: (...args: any[]) => any,
) => {
  const { active, payload, label } = content;

  const yValue = payload ? payload[0]?.value : 0;
  const formattedYValue = useDisplayNumber(yValue?.toString() ? yValue?.toString() : 0);
  const formattedXValue = formatter ? formatter(label) : label;
  if (active && payload && payload.length) {
    return (
      <Stack className='rounded-md bg-graph-tooltip px-2 py-1' direction='col' spacing={1.5}>
        <p className='typography-body2Semibold text-primary-100'>{`${formattedYValue} ${yUnit}`}</p>
        <p className='typography-body2 text-primary-100'>{formattedXValue}</p>
      </Stack>
    );
  }

  return null;
};

export const ChartTooltipDot = (props: SVGProps<SVGElement>) => {
  const { cx, cy, onMouseEnter, onMouseLeave } = props;
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={6}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className='fill-none stroke-white-100 stroke-[3px]'
      />
      <circle cx={cx} cy={cy} r={4} className='fill-primary-100' />
    </g>
  );
};
