import { VariantProps } from 'class-variance-authority';
import _merge from 'lodash/merge';
import React, { HTMLAttributes } from 'react';

import { cn } from '../utils';
import { containedGrayIndicatorVariants } from './containedGrayIndicator';
import { containedIndicatorVariants } from './containedIndicator';
import { textIndicatorVariants } from './textIndicator';

type ContainedIndicatorProps = VariantProps<typeof containedIndicatorVariants>;
type ContainedGrayIndicatorProps = VariantProps<typeof containedGrayIndicatorVariants>;
type TextIndicatorProps = VariantProps<typeof textIndicatorVariants>;

interface IndicatorVariants extends ContainedIndicatorProps, ContainedGrayIndicatorProps, TextIndicatorProps {
  variant?: 'contained' | 'contained-gray' | 'text';
}

const indicatorStyles = (variants: IndicatorVariants = {}) => {
  const defaultVariants: IndicatorVariants = { variant: 'contained', size: 'medium', color: 'success' };
  const $variants = _merge(defaultVariants, variants);

  const backgroundStyles = cn(
    'relative',
    {
      contained: containedIndicatorVariants($variants),
      'contained-gray': containedGrayIndicatorVariants($variants),
      text: textIndicatorVariants($variants),
    }[$variants.variant ?? 'contained'],
  );

  const labelStyles = cn('leading-none text-[.875rem] text-center uppercase', {
    'font-medium': $variants.size === 'medium',
    'font-semibold tracking-[0.1px]': $variants.size === 'small',
  });

  return cn(backgroundStyles, labelStyles);
};

export type IndicatorProps = HTMLAttributes<HTMLSpanElement> & IndicatorVariants;

/**
 * Use the `color`, `size` and `variant` props to control the apppearance of the Indicator.
 */
export const Indicator = React.forwardRef<HTMLSpanElement, IndicatorProps>(
  ({ className, variant, color, size, ...delegated }, ref) => {
    const styles = cn(indicatorStyles({ variant, color, size }), className);

    return <span ref={ref} {...delegated} className={styles} />;
  },
);
Indicator.displayName = 'Indicator';
