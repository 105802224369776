import { Loader } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActivateAccountPageContent } from '../page-content/PageContent';

export const ActivateAccountLoading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ActivateAccountPageContent headlineText={t('activateAccount.title')}>
      <Loader className='min-h-fit' />
    </ActivateAccountPageContent>
  );
};
