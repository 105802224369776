import {
  Alert,
  AlertAction,
  AlertBody,
  AlertIcon,
  AlertTitle,
  ButtonLink,
  RiStackLine,
  Stack,
} from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';

import { useProjectId } from '../../../hooks/useProjectId';
import { NCBreakdown } from './components/nc-breakdown/NCBreakdown';
import { ProjectOverview } from './components/ProjectOverview';

export const ProjectPage = () => {
  return (
    <>
      <ProjectOverview />
      <PlotListNotice />
      <NCBreakdown />
    </>
  );
};

const PlotListNotice = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';
  const membershipType = useMembershipType();
  const projectId = useProjectId();
  const plotListPath =
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? buildPath(paths.landSteward.plots, { pathParams: { projectId } })
      : buildPath(paths.buyer.plots, { pathParams: { projectId } });
  return (
    <Alert className='gap-y-2 bg-secondary-12 p-6' data-testid='plot-list-notice'>
      <AlertIcon>
        <Stack center className='rounded-full bg-neutral-black-8 p-3'>
          <RiStackLine size={24} />
        </Stack>
      </AlertIcon>
      <AlertTitle data-cy='plot-list-alert-title' className='typography-overline text-text-secondary'>
        {t('shared.ncaDetail.plotListNotice.title')}
      </AlertTitle>
      {isSmallScreen ? (
        <AlertBody className='flex flex-col gap-4'>
          <span>{t('shared.ncaDetail.plotListNotice.body')}</span>
          <ButtonLink to={plotListPath} preventScrollReset>
            {t('shared.ncaDetail.plotListNotice.button')}
          </ButtonLink>
        </AlertBody>
      ) : (
        <>
          <AlertBody data-cy='plot-list-alert-desc' className='flex flex-col gap-4'>
            {t('shared.ncaDetail.plotListNotice.body')}
          </AlertBody>
          <AlertAction>
            <ButtonLink data-cy='plot-list-btn' to={plotListPath} preventScrollReset>
              {t('shared.ncaDetail.plotListNotice.button')}
            </ButtonLink>
          </AlertAction>
        </>
      )}
    </Alert>
  );
};
