import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RestApiServerError, RestApiServerErrorStatusCode } from '@/api/rest/resources/errors/RestApiServerError';
import { usePostUserActivateAccount } from '@/api/rest/resources/user';
import { useAuth, useUpdateUserPartially } from '@/lib/auth';
import { delay, IDelay } from '@/utils/delay';

export const enum ErrorActivateAccountPageState {
  ALREADY_ACTIVATED = 'ALREADY_ACTIVATED',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  NO_TOKEN_SUPPLIED = 'NO_TOKEN_SUPPLIED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const enum BaseActivateAccountPageState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}

export type ActivateAccountPageState = BaseActivateAccountPageState | ErrorActivateAccountPageState;

export const BAD_REQUEST_STATUS_CODE = RestApiServerErrorStatusCode.BAD_REQUEST;
export const EXPIRED_STATUS_CODE = RestApiServerErrorStatusCode.FORBIDDEN;
export const ALREADY_ACTIVATED_STATUS_CODE = RestApiServerErrorStatusCode.CONFLICT;

export const useActivateAccount = () => {
  const [searchParams] = useSearchParams();
  const queryParameterToken = searchParams.get('token');

  const { isSignedIn } = useAuth();
  const updateUserPartially = useUpdateUserPartially();

  const { mutate: activateAccount } = usePostUserActivateAccount();

  const [pageState, setPageState] = useState<ActivateAccountPageState>(BaseActivateAccountPageState.LOADING);
  const handleUnexpectedError = () => {
    setPageState(ErrorActivateAccountPageState.UNKNOWN_ERROR);
  };

  const onMutationSuccess = () => {
    // TODO: MVP-1158: refetch user at this point instead of just set emailActivated.
    // TODO: MVP-1158: we also need to ensure, that the signed in user we are updating is actually the user the link was for.
    if (isSignedIn && updateUserPartially) {
      updateUserPartially({ email_activated: true });
    }
    setPageState(BaseActivateAccountPageState.SUCCESS);
  };

  const onMutationError = (error: RestApiServerError | Error) => {
    if (!(error instanceof RestApiServerError) || !error.statusCode) {
      handleUnexpectedError();
      return;
    }

    if (error.statusCode === BAD_REQUEST_STATUS_CODE) {
      setPageState(ErrorActivateAccountPageState.INVALID);
      return;
    }

    if (error.statusCode === EXPIRED_STATUS_CODE) {
      setPageState(ErrorActivateAccountPageState.EXPIRED);
      return;
    }

    if (error.statusCode === ALREADY_ACTIVATED_STATUS_CODE) {
      setPageState(ErrorActivateAccountPageState.ALREADY_ACTIVATED);
      return;
    }

    handleUnexpectedError();
  };

  const fireActivateAccountRequest = (token: string) => {
    activateAccount(
      { bodyData: { token } },
      {
        onError: onMutationError,
        onSuccess: onMutationSuccess,
      },
    );
  };

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function checkForTokenAndCallMutationAfterDelay() {
      let timer: IDelay | undefined;

      if (!queryParameterToken) {
        setPageState(ErrorActivateAccountPageState.NO_TOKEN_SUPPLIED);
      } else {
        // NOTE: Delaying the request has two purposes.
        // NOTE: 1. The loading spinner is displayed a little longer which makes the user experience better.
        // NOTE: 2. React strict mode mounts/unmounts each component twice. Without delaying the post request it would get fired twice.
        timer = delay(() => fireActivateAccountRequest(queryParameterToken));
      }

      return () => {
        if (timer) {
          timer.cancel();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParameterToken],
  );

  return {
    pageState,
  };
};
