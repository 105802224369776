import { useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const CarbonExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='carbon-explainer'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>{t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.headline')}</ExplainerTitle>
        <ExplainerBody>
          <ExplainerCopy
            data-cy='carbonBelowGroundData'
            title={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.carbonBelowGroundTitle')}
            body={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.carbonBelowGroundBody')}
          />
          <ExplainerCopy
            title={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.emissionsTitle')}
            body={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.emissionsBody')}
          />
          <ExplainerCopy body={t('shared.ncaDetail.details.explainers.howDoMeasureThis.confidenceInterval.body')} />
        </ExplainerBody>
      </ExplainerContent>
    </Explainer>
  );
};
