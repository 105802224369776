/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_DELAY_TIMER } from '@/config/constants';

export interface IDelay {
  cancel: () => void;
}

/**
 * Wraps a function with a delaying timeout.
 * Typically used to ensure the user is able to see an intermediate state to enhance UX.
 *
 * @param delayedFunc The function to be delayed.
 * @param ms The ms to delay. Defaults to DEFAULT_SLEEP_TIMER constant.
 *
 * @returns The timeouts cancel function.
 */
export const delay = (delayedFunc: (...args: any[]) => any, ms: number = DEFAULT_DELAY_TIMER): IDelay => {
  let timeout: any = setTimeout(delayedFunc, ms);
  return {
    cancel() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    },
  };
};
