/**
 * Converts rgba to hex. Optionally keep the alpha channel (default false).
 * Reference: https://stackoverflow.com/a/73401564
 */
export const rgbaToHex = (rgbaInput: string, options: { withAlpha: boolean } = { withAlpha: false }) => {
  return `#${rgbaInput
    .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
    .split(',') // splits them at ","
    .filter((_, index) => options.withAlpha || index !== 3)
    .map((string) => parseFloat(string)) // Converts them to numbers
    .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
    .map((number) => number.toString(16)) // Converts numbers to hex
    .map((string) => (string.length === 1 ? `0${string}` : string)) // Adds 0 when length of one number is 1
    .join('')}`; // Puts the array together to a string
};
