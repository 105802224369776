import React from 'react';

import { ContractList } from '@/api/rest/resources/types/contract';

import { BuyerPageLayout } from '../../components';
import { WithContracts } from './components/with-contracts/WithContracts';
import { WithoutContracts } from './components/without-contracts/WithoutContracts';
import { useBuyerContract } from './hooks/useBuyerContract';

export const BuyerInvestmentsPage = () => {
  const { data } = useBuyerContract();

  return (
    <BuyerPageLayout withCustomContentContainer>
      <Body contracts={data.results} />
    </BuyerPageLayout>
  );
};

export const Body: React.FC<{ contracts: ContractList[] }> = ({ contracts }) => {
  if (contracts.length === 0) {
    return <WithoutContracts />;
  }

  return <WithContracts />;
};
