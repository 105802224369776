import {
  Button,
  ButtonProps,
  Checkbox,
  cn,
  Dropdown,
  DropdownContent,
  DropdownContentProps,
  DropdownProps,
  DropdownTrigger,
  RadioGroup,
  RadioGroupItem,
  RadioGroupItemProps,
  RadioGroupProps,
  RiArrowDownSLine,
  RiFilter3Line,
  RiMap2Line,
  Stack,
  StackProps,
} from '@landler/tw-component-library';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { allThreats, Threat } from '@/api/rest/resources/conservation';
import { FormControl, FormField, FormFieldProps, FormItem, FormLabel, withSuspenseBoundary } from '@/components';
import { range } from '@/utils/range';

import { useFact } from '../../../components/conservation/hooks/useFact';
import { useProject } from '../../../components/conservation/hooks/useProject';
import { useTilesetsByLayer } from '../../../components/conservation/hooks/useTilesets';
import { useControlsContext } from '../hooks/useControlsForm';
import { ControlsFormFieldValues, FilterField, SpeciesFilter } from '../types';

export const Controls = (props: StackProps) => {
  const controlsForm = useControlsContext();
  const [layer] = controlsForm.watch(['indicatorLayer']);

  return (
    <Stack direction='row' spacing={2} data-testid='interactive-map-controls' {...props}>
      <YearPicker />
      <LayerPicker />
      {layer === 'habitatIntactness' && <ThreatFilters />}
      {layer === 'floraFauna' && <SpeciesFilters />}
    </Stack>
  );
};

const YearPicker = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;
  const currentYear = useFact<number>('r1_conservation_current_year').value;

  const years = range(baselineYear, currentYear)
    .reverse()
    .map((n) => n.toString());

  const selectedYear = controlsForm.watch('year');

  return (
    <Picker>
      <PickerTrigger data-testid='year-picker-trigger'>
        {selectedYear ?? t('shared.projects.project.conservation.interactiveMap.labels.year')}
      </PickerTrigger>
      <PickerContent data-testid='year-picker-content'>
        <FormRadioGroup name='year'>
          {years.map((item) => (
            <FormRadioItem key={item} value={item} label={item} />
          ))}
        </FormRadioGroup>
      </PickerContent>
    </Picker>
  );
};

const filterFields: FilterField[] = ['speciesFilter', 'threatFilters'];

const LayerPicker = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();
  const layer = controlsForm.watch('indicatorLayer');

  return (
    <Picker>
      <PickerTrigger leftAdornment={<RiMap2Line />} data-testid='layer-picker-trigger'>
        {layer
          ? t(`shared.projects.project.conservation.interactiveMap.labels.${layer}`)
          : t('shared.projects.project.conservation.interactiveMap.labels.indicatorLayers')}
      </PickerTrigger>

      <PickerContent data-testid='layer-picker-content'>
        <FormRadioGroup
          name='indicatorLayer'
          onValueChange={() => {
            filterFields.forEach((field) => controlsForm.setValue(field, null));
          }}
        >
          <FormRadioItem
            value='ecologicalIntegrity'
            label={t('shared.projects.project.conservation.interactiveMap.labels.ecologicalIntegrity')}
          />
          <FormRadioItem
            value='habitatIntactness'
            label={t('shared.projects.project.conservation.interactiveMap.labels.habitatIntactness')}
          />
          <FormRadioItem
            value='floraFauna'
            label={t('shared.projects.project.conservation.interactiveMap.labels.floraFauna')}
          />
        </FormRadioGroup>
      </PickerContent>
    </Picker>
  );
};

const ThreatFilters = withSuspenseBoundary(() => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();

  const [threatFilters] = controlsForm.watch(['threatFilters']);

  const [selected, setSelected] = useState<typeof threatFilters>([]);
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const resetForm = () => {
    setSelected(threatFilters);
  };

  const handleCancelClick = () => {
    resetForm();
    closeDropdown();
  };

  const handleSubmitClick = () => {
    controlsForm.setValue('threatFilters', selected);
    closeDropdown();
  };

  const tilesetsByLayer = useTilesetsByLayer().data;

  const availableThreats = Object.entries(tilesetsByLayer)
    .filter(([tilesetId, value]) => {
      const isThreat = (maybeThreat: unknown): maybeThreat is Threat => {
        return allThreats.includes(maybeThreat as Threat);
      };

      if (!isThreat(tilesetId)) {
        return false;
      }

      if (!value) {
        return false;
      }

      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (!Object.keys(value).length) {
        return false;
      }

      return true;
    })
    .map(([tilesetId]) => tilesetId as Threat);

  return (
    <Picker
      open={isOpen}
      onOpenChange={(open) => {
        resetForm();
        setIsOpen(open);
      }}
    >
      <PickerTrigger leftAdornment={<RiFilter3Line />} data-testid='threat-picker-trigger'>
        {threatFilters?.length
          ? t('shared.projects.project.conservation.interactiveMap.labels.filtersApplied', {
              count: threatFilters.length,
            })
          : t('shared.projects.project.conservation.interactiveMap.labels.filterByThreats')}
      </PickerTrigger>

      <PickerContent data-testid='threat-picker-content'>
        <Stack spacing={4}>
          <Stack spacing={2}>
            {availableThreats.map((threat) => {
              return (
                <Checkbox
                  key={threat}
                  id={threat}
                  checked={selected?.includes(threat)}
                  onCheckedChange={() => {
                    const set = new Set(selected);
                    if (set.has(threat)) {
                      set.delete(threat);
                    } else {
                      set.add(threat);
                    }
                    setSelected(Array.from(set));
                  }}
                >
                  <FormLabel htmlFor={threat}>{t(`shared.projects.project.conservation.labels.${threat}`)}</FormLabel>
                </Checkbox>
              );
            })}
          </Stack>
          <Stack direction='row-reverse' spacing={2}>
            <Button variant='contained' size='small' onClick={handleSubmitClick}>
              {t('shared.projects.project.conservation.interactiveMap.labels.applyFilter')}
            </Button>
            <Button variant='text' size='small' onClick={handleCancelClick}>
              {t('global.ui.buttons.cancel')}
            </Button>
          </Stack>
        </Stack>
      </PickerContent>
    </Picker>
  );
}, null);

const SpeciesFilters = withSuspenseBoundary(() => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();

  const [speciesFilter] = controlsForm.watch(['speciesFilter']);

  const project = useProject().data;

  return (
    <Picker>
      <PickerTrigger leftAdornment={<RiFilter3Line />} data-testid='species-picker-trigger'>
        {project.speciesGroups[speciesFilter as SpeciesFilter] ??
          t('shared.projects.project.conservation.interactiveMap.labels.filterBySpecies')}
      </PickerTrigger>

      <PickerContent data-testid='species-picker-content'>
        <FormRadioGroup
          name='speciesFilter'
          onValueChange={() => {
            controlsForm.setValue('speciesFilter', null);
          }}
        >
          {(Object.keys(project.speciesGroups) as SpeciesFilter[]).map((group) => (
            <FormRadioItem key={group} value={group} label={project.speciesGroups[group as SpeciesFilter]} />
          ))}
        </FormRadioGroup>
      </PickerContent>
    </Picker>
  );
}, null);

const PickerContext = createContext<{ open: boolean; setOpen: (open: boolean) => void }>({
  open: false,
  setOpen: () => undefined,
});

const usePicker = () => useContext(PickerContext);

const Picker = ({ open: openProp, onOpenChange, ...delegated }: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <PickerContext.Provider value={{ open, setOpen }}>
      <Dropdown
        modal
        open={openProp ?? open}
        onOpenChange={(isOpen) => {
          onOpenChange?.(isOpen);

          if (openProp == null) {
            setOpen(isOpen);
          }
        }}
        {...delegated}
      />
    </PickerContext.Provider>
  );
};

const PickerTrigger = ({ className, ...delegated }: ButtonProps) => {
  return (
    <DropdownTrigger asChild>
      <Button
        variant='contained'
        color='white'
        size='small'
        rightAdornment={<RiArrowDownSLine className='group-data-[state=open]/trigger:rotate-180' />}
        className={cn('group/trigger tabular-nums', className)}
        {...delegated}
      />
    </DropdownTrigger>
  );
};

const PickerContent = ({ className, ...delegated }: DropdownContentProps) => {
  return (
    <DropdownContent className={cn('rounded-[20px] p-4', className)} sideOffset={8} align='start' {...delegated} />
  );
};

type FormRadioGroupProps = RadioGroupProps &
  Pick<FormFieldProps<Pick<ControlsFormFieldValues, 'year' | 'indicatorLayer' | 'speciesFilter'>>, 'name'>;

const FormRadioGroup = ({ name, className, onValueChange, ...delegated }: FormRadioGroupProps) => {
  const controlsForm = useControlsContext();
  const { setOpen } = usePicker();

  return (
    <FormField
      name={name}
      control={controlsForm.control}
      render={({ field: { onChange, ...field } }) => (
        <FormItem>
          <FormControl>
            <RadioGroup
              className={cn('flex flex-col gap-2', className)}
              {...field}
              onValueChange={(...args) => {
                onValueChange?.(...args);
                onChange(...args);
                setOpen(false);
              }}
              data-testid={`radiogroup-${field.name}`}
              {...delegated}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );
};

const FormRadioItem = ({ value, label, ...delegated }: RadioGroupItemProps & { label: React.ReactNode }) => {
  return (
    <FormItem className='flex items-center gap-2'>
      <FormControl>
        <RadioGroupItem value={value} {...delegated} />
      </FormControl>
      <FormLabel className='typography-body2 text-text-primary'>{label}</FormLabel>
    </FormItem>
  );
};
