import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useFormatCoordinates = (coordinates: Array<number> | null | undefined): string => {
  const { t } = useTranslation();
  return useMemo(() => {
    if (!coordinates || coordinates.length !== 2) {
      return '';
    }
    const roundedCoordinates = coordinates?.map(
      (num: number) => Math.round((num + Number.EPSILON) * 1000000) / 1000000,
    );

    return `${t('global.plot.latitude')} ${roundedCoordinates[0]}, ${t('global.plot.longitude')} ${
      roundedCoordinates[1]
    }`;
  }, [coordinates, t]);
};
