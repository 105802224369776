import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

import { RiCircleFill } from '../Icon/Icon';
import { cn } from '../utils';

export type RadioGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>,
  'value' | 'onValueChange'
> & {
  value?: string | boolean | null;
  onValueChange?: (value: RadioGroupProps['value']) => void;
};

export const RadioGroup = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, RadioGroupProps>(
  ({ className, value, onValueChange, ...delegated }, ref) => {
    return (
      <RadioGroupPrimitive.Root
        className={cn('grid gap-2', className)}
        value={`${value}`}
        onValueChange={(v) => {
          if (!onValueChange) return null;
          if (v.toLowerCase() === 'true') return onValueChange(true);
          if (v.toLowerCase() === 'false') return onValueChange(false);
          return onValueChange(v);
        }}
        {...delegated}
        ref={ref}
      />
    );
  },
);
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export type RadioGroupItemProps = Omit<
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
  'value' | 'children'
> & {
  value: RadioGroupProps['value'];
};

export const RadioGroupItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, RadioGroupItemProps>(
  ({ value, className, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        value={`${value}`}
        className={cn(
          'peer aspect-square h-5 w-5 rounded-full',
          'border-2 border-primary-100 bg-transparent text-primary-100',
          'hover:bg-primary-12 data-[state=checked]:hover:bg-transparent',
          'disabled:cursor-not-allowed disabled:border-text-disabled disabled:text-text-disabled disabled:hover:bg-transparent',
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className='flex items-center justify-center'>
          <RiCircleFill className='h-2.5 w-2.5 fill-current text-current' />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    );
  },
);
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
