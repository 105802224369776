import { useParams } from 'react-router-dom';

import { ProjectStatus } from '@/api/rest/resources/types/project';

import { useProjectDetailById } from './useProjectDetailById';

export const useIsProjectCalculating = (projectIdArg?: string) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const { data: projectDetail } = useProjectDetailById(projectId);

  return projectDetail.status === ProjectStatus.scheduled_for_analysis;
};
