import { Stack } from '@landler/tw-component-library';
import React, { HTMLProps, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'tw-component-library/utils';
import { useResizeObserver } from 'usehooks-ts';

import { useCookiebot } from '@/hooks/useCookiebot';
import { useLegalUrls } from '@/hooks/useLegalUrls';

type FooterVariant = 'dark' | 'white';

type FooterProps = HTMLProps<HTMLDivElement> & {
  variant?: FooterVariant;
};

export const Footer: React.FC<FooterProps> = ({ variant = 'dark', className }: FooterProps) => {
  const { t } = useTranslation();
  const { imprintUrl, privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();

  const { renew: renewCookiePreferences } = useCookiebot();

  const footerRef = useRef<HTMLElement | null>(null);

  useResizeObserver({
    ref: footerRef,
    box: 'border-box',
    onResize: ({ height }) => {
      document.documentElement.style.setProperty('--footer-height', `${height}px`);
    },
  });

  return (
    <footer
      ref={footerRef}
      className={cn(
        'grid w-full border-t border-t-divider',
        'grid-cols-[1fr_calc(100%_-_64px)_1fr] gap-x-8',
        'pb-6 pt-5 md:grid-cols-[1fr_min(var(--bp-lg),_calc(100%_-_96px))_1fr] md:gap-x-12',
        variant === 'dark' ? 'text-text-secondary backdrop-blur-sm' : 'border-t-0 text-white-100',
        className,
      )}
      role='contentinfo'
      data-cy='footer'
    >
      <Stack direction='row' spacing={10} className='typography-body1 col-start-2 max-w-screen-lg flex-col md:flex-row'>
        <span data-cy='copyright-text'>&copy; Landler {new Date().getFullYear()}</span>
        <a
          className='text-[inherit] hover:underline'
          target='_blank'
          href={imprintUrl}
          data-cy='imprint-link'
          rel='noreferrer'
        >
          {t('global.footer.nav.imprint')}
        </a>
        <a
          className='text-[inherit] hover:underline'
          target='_blank'
          href={termsAndConditionsUrl}
          data-cy='terms-and-conditions-link'
          rel='noreferrer'
        >
          {t('global.footer.nav.termsAndConditions')}
        </a>
        <a
          className='text-[inherit] hover:underline'
          target='_blank'
          href={privacyPolicyUrl}
          data-cy='privacy-link'
          rel='noreferrer'
        >
          {t('global.footer.nav.privacy')}
        </a>
        <button className='text-start hover:underline' onClick={renewCookiePreferences}>
          {t('global.footer.nav.updateCookiePreferences')}
        </button>
      </Stack>
    </footer>
  );
};
