import { forwardRef, HTMLAttributes } from 'react';

import { Stack } from '../Stack/Stack';
import { cn } from '../utils';

export type IconHaloProps = HTMLAttributes<HTMLDivElement>;

export const IconHalo = forwardRef<HTMLDivElement, IconHaloProps>(({ className, ...delegated }, ref) => {
  return (
    <Stack
      ref={ref}
      center
      className={cn('aspect-square w-12 shrink-0 rounded-full bg-neutral-black-4 text-2xl', className)}
      {...delegated}
    />
  );
});
IconHalo.displayName = 'IconHalo';
