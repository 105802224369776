import { FC, PropsWithChildren } from 'react';

import { SignedInPageLayout } from '@/layout/signed-in-page';

import { useMenuItems } from './hooks/useMenuItems';

type LandStewardPageLayoutProps = {
  withCustomContentContainer?: boolean;
};

export const LandStewardPageLayout: FC<PropsWithChildren<LandStewardPageLayoutProps>> = (props) => {
  const menuItems = useMenuItems();

  return <SignedInPageLayout data-testid='landsteward-page-layout' items={menuItems} {...props} />;
};
