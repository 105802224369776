import { tokenRefresher } from '@/lib/auth/TokenRefresher';

import { restApi } from '../../client';
import { replacePathVariables } from './utils/replacePathVariables';

export const patchRequest = async <TResponse>(
  path: string,
  pathVariables?: unknown,
  queryParams?: unknown,
  bodyData?: unknown,
  signal?: AbortSignal,
): Promise<TResponse> => {
  await tokenRefresher.checkToken();

  const pathWithVariables = replacePathVariables(path, pathVariables);

  return new Promise((resolve, reject) => {
    restApi
      .patch(pathWithVariables, bodyData, { params: queryParams, signal })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
