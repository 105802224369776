import { ProjectType } from '@/api/rest/resources/types/project';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

import { projectNameMap } from '../mockData/projectNameMap';

export const useProjectKey = (id?: string) => {
  const projectDetail = useProjectDetailById(id).data;

  if (projectDetail.type !== ProjectType.conservation) {
    throw Error(`Expected project of type '${ProjectType.conservation}', received ${projectDetail.type}.`);
  }

  const projectKey = projectNameMap[projectDetail.name as keyof typeof projectNameMap];

  if (!projectKey) {
    throw Error(`No project key found for project name ${projectDetail.name}`);
  }

  return projectKey;
};
