/* eslint-disable sonarjs/no-duplicate-string */
import { PaletteOptions } from '@mui/material/styles';

export const palette: PaletteOptions = {
  mode: 'light',
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    main: '#004C3D',
    light: '#278668',
    dark: '#002921',
    p08: 'rgba(0, 76, 61, 0.08)',
    p12: 'rgba(0, 76, 61, 0.12)',
    p30: 'rgba(0, 76, 61, 0.3)',
    p50: 'rgba(0, 76, 61, 0.5)',
  },
  secondary: {
    main: '#338E5A',
    light: '#6DBF90',
    dark: '#1D5E39',
    p08: 'rgba(51, 142, 90, 0.08)',
    p12: 'rgba(51, 142, 90, 0.12)',
    p30: 'rgba(51, 142, 90, 0.30)',
    p50: 'rgba(51, 142, 90, 0.50)',
  },
  info: {
    main: '#004C3D',
    light: '#D2E3D8',
    dark: '#002921',
  },
  success: {
    main: '#44BB4A',
    light: '#CFFFBF',
    dark: '#285E2B',
  },
  warning: {
    main: '#FFAA1E',
    light: '#FFF2D2',
    dark: '#774200',
    contrastText: '#1A1A1A',
  },
  error: {
    main: '#D83B09',
    light: '#F7D4C1',
    dark: '#7B290F',
    p12: 'rgba(216, 59, 9, .12)',
  },
  special: {
    biodiversity: {
      main: '#C2F96D',
      light: '#E1FFB2',
      dark: '#456E06',
      contrastText: '#000',
    },
    built_up_environment: {
      main: '#A68100',
      light: '#E9E5DD',
      dark: '#666460',
      contrastText: '#000',
    },
    co2: {
      main: '#FFDD5F',
      light: '#FFEFB2',
      dark: '#806E33',
      contrastText: '#000',
    },
    cropland: {
      main: '#F2B030',
      light: '#FFCB66',
      dark: '#CC8700',
      contrastText: '#000',
    },
    grassland: {
      main: '#8BD279',
      light: '#D5E9BC',
      dark: '#4CB232',
      contrastText: '#000',
    },
    forest: {
      main: '#40BF94',
      light: '#8CD9BF',
      dark: '#19805B',
      contrastText: '#000',
    },
    mangroves: {
      main: '#9BA808',
      light: '#D1E304',
      dark: '#666200',
      contrastText: '#000',
    },
    misc: {
      main: '#BDBDBD',
      light: '#F5F5F5',
      dark: '#757575',
      contrastText: '#000',
    },
    moss_and_lichens: {
      main: '#AFACA5',
      light: '#EDC804',
      dark: '#665603',
      contrastText: '#000',
    },
    peatland: {
      main: '#BA91BB',
      light: '#CDB0CE',
      dark: '#8B578C',
      contrastText: '#000',
    },
    permanent_water_bodies: {
      main: '#34A4E2',
      light: '#85DAFF',
      dark: '#1E5778',
      contrastText: '#000',
    },
    shrubland: {
      main: '#A9BD89',
      light: '#CDFE7C',
      dark: '#5B664A',
      contrastText: '#000',
    },
    snow_and_ice: {
      main: '#9CE0F0',
      light: '#CCF5FF',
      dark: '#00A5CC',
      contrastText: '#000',
    },
    soil: {
      main: '#F7974F',
      light: '#FFD0AC',
      dark: '#8C4007',
      contrastText: '#000',
    },
    sparse_vegetation: {
      main: '#AB8A72',
      light: '#FFE5D3',
      dark: '#665244',
      contrastText: '#000',
    },
    water: {
      main: '#6ACDE1',
      light: '#CCF6FF',
      dark: '#1B6E80',
      contrastText: '#000',
    },
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    default: '#F5F5F3',
    paper: '#FFFFFF',
    sand: '#F3EDD3',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};
