// eslint-disable-next-line import/no-extraneous-dependencies
import plugin from 'tailwindcss/plugin';

/**
 * We use the `@apply` directive instead of using plain CSS, so that
 * it picks up the breakpoints defined in the tailwind config.
 */
export const typographyPlugin = plugin(({ addComponents }) => {
  addComponents({
    '.typography-h1': { '@apply text-[2rem] leading-[1.3] md:text-[2.875rem] md:leading-[1.2]': '' },

    '.typography-h2': { '@apply text-[1.5rem] leading-[1.3] md:text-[2rem] md:leading-[1.2]': '' },

    '.typography-h3': { '@apply text-[1.125rem] leading-[1.3] md:text-[1.5rem] md:leading-[1.2]': '' },

    '.typography-h4': { '@apply text-[1rem] leading-[1.3] md:text-[1.125rem] md:leading-[1.3]': '' },

    '.typography-body1': { '@apply text-[.9375rem] leading-[1.4] md:text-[1rem] md:leading-[1.4]': '' },

    '.typography-body1Semibold': {
      '@apply text-[.9375rem] leading-[1.4] md:text-[1rem] md:leading-[1.4] font-semibold': '',
    },

    '.typography-body2': { '@apply text-[.8125rem] leading-[1.4] md:text-[.875rem] md:leading-[1.4]': '' },

    '.typography-body2Semibold': {
      '@apply text-[.8125rem] leading-[1.4] md:text-[.875rem] md:leading-[1.4] font-semibold': '',
    },

    '.typography-link1': { '@apply text-[.9375rem] leading-[1.4] md:text-[1rem] md:leading-[1.4] underline': '' },

    '.typography-link2': {
      '@apply text-[.8125rem] leading-[1.4] md:text-[.875rem] md:leading-[1.4] underline': '',
    },

    '.typography-overline': {
      '@apply text-[.6875rem] leading-none md:text-[.75rem] md:leading-none tracking-[.0625rem] font-normal uppercase':
        '',
    },

    '.typography-caption': { '@apply text-[.6875rem] leading-[1.3] md:text-[.75rem] md:leading-[1.3]': '' },

    '.typography-display1': {
      '@apply text-[2.5rem] leading-[1.3] md:text-[3.75rem] md:leading-[1.2] font-display': '',
    },

    '.typography-display2': {
      '@apply text-[2rem] leading-[1.3] md:text-[2.875rem] md:leading-[1.2] font-display': '',
    },

    '.typography-display3': { '@apply text-[1.5rem] leading-[1.3] md:text-[2rem] md:leading-[1.2] font-display': '' },

    '.typography-display4': {
      '@apply text-[1.125rem] leading-[1.3] md:text-[1.5rem] md:leading-[1.2] font-display': '',
    },

    '.typography-display5': {
      '@apply text-[1rem] leading-[1.3] md:text-[1.25rem] md:leading-[1.2] font-display': '',
    },

    '.typography-button1': { '@apply text-[.9375rem] leading-none md:text-[1rem] md:leading-none font-medium': '' },

    '.typography-button2': {
      '@apply text-[.8125rem] leading-none md:text-[.875rem] md:leading-none font-medium': '',
    },

    '.typography-inputLabel': { '@apply text-[.6875rem] leading-[1.2] md:text-[.75rem] md:leading-[1.2]': '' },
  });
});
