import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import bbox from '@turf/bbox';
import { area } from '@turf/turf';
import { FeatureCollection, Geometry } from 'geojson';
import { LngLatBoundsLike } from 'mapbox-gl';

import { CellProperties } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectKey } from './useProjectKey';

export const getPolygonByProjectKey = async (projectKey: string) => {
  const module = await import(`../mockData/${projectKey}/polygon.json`);
  return module.default as FeatureCollection;
};

export const useProjectBoundary = (projectId?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(projectId);

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'boundary'],
    queryFn: () => getPolygonByProjectKey(projectKey),
  });
};

export const useProjectArea = (projectId?: string) => {
  const projectPerimeterPolygon = useProjectBoundary(projectId).data;
  return area(projectPerimeterPolygon);
};

export const useProjectBounds = (projectId?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(projectId);

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'boundary', 'bounds'],
    queryFn: () => getPolygonByProjectKey(projectKey).then((polygon) => bbox(polygon) as LngLatBoundsLike),
  });
};

export const getProjectCells = async ({ projectKey, year }: { projectKey: string; year?: string }) => {
  const suffix = year ? `_${year}` : '';

  const module = await import(`../mockData/${projectKey}/cells${suffix}.json`);
  return module.default as FeatureCollection<Geometry, CellProperties>;
};

export const useProjectCells = (projectId?: string, year?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(projectId);

  return useQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'cells', year],
    queryFn: async () => {
      const cells = await getProjectCells({ projectKey, year });
      return {
        ...cells,
        features: cells.features.map((feature, index) => ({
          ...feature,
          id: index + 1,
        })),
      } as FeatureCollection<Geometry, CellProperties>;
    },
  });
};
