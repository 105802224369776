import { Avatar, AvatarProps, cn, Stack } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';

type AvatarGroupProps = HTMLAttributes<HTMLDivElement> & {
  items: AvatarProps[];
};

export const AvatarGroup: FC<AvatarGroupProps> = ({ items, ...delegated }) => (
  <Stack direction='row' {...delegated}>
    {items.map((item, index) => (
      <Avatar
        key={item.name}
        image={item.image}
        name={item.name}
        className={cn('h-12 w-12', {
          'ml--4': index !== 0,
        })}
      />
    ))}
  </Stack>
);
