import { Button, ButtonProps, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

export type MapButtonProps = PropsWithChildren<ButtonProps>;

const StyledMapButton = styled(Button, { shouldForwardProp: () => true })<MapButtonProps>(({ theme, children }) => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  borderRadius: 8,
  padding: children ? '8px 16px' : '4px',
  minWidth: 40,
  boxShadow: theme.shadows[3],
  fontSize: 16,
  fontWeight: '500!important',
  '& svg': { color: theme.palette.primary.main },
  '& .MuiButton-startIcon': {
    marginRight: children ? 8 : 0,
    marginLeft: 0,
  },
  '&:hover, &.Mui-focusVisible': {
    color: theme.palette.primary.main,
    '& svg': { color: theme.palette.primary.main },
    '&:not(:disabled)': {
      background: 'linear-gradient(0deg, rgba(0, 77, 45, 0.08), rgba(0, 77, 45, 0.08)), #FAFDFA',
    },
  },
  '&:disabled': {
    '& svg': { color: theme.palette.grey[500] },
    color: theme.palette.grey[500],
    background: theme.palette.grey[300],
    boxShadow: 'none',
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },
}));

export const MapButton = (props: MapButtonProps) => <StyledMapButton {...props} />;
