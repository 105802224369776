import { PlotType } from '@/api/rest/resources/types/plot';

export const supportedPlotTypes = [PlotType.GRASSLAND, PlotType.CROPLAND] as const;

export type SupportedPlotType = (typeof supportedPlotTypes)[number];

export function isSupportedPlotType(plotType: PlotType): plotType is SupportedPlotType {
  return supportedPlotTypes.some((l) => l === plotType);
}

export const unsupportedPlotTypes: UnsupportedPlotType[] = Object.values(PlotType).filter(isUnsupportedPlotType);

export type UnsupportedPlotType = Exclude<PlotType, SupportedPlotType>;

export function isUnsupportedPlotType(plotType: PlotType): plotType is UnsupportedPlotType {
  return !isSupportedPlotType(plotType);
}

export const containsUnsupportedPlotTypes = (plotTypes: PlotType[]) =>
  plotTypes.some((type) => isUnsupportedPlotType(type));
