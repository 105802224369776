import React, { HTMLAttributes, ReactNode } from 'react';

import { cn } from '../utils';

export type PillProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Defines the size of the pill.
   *
   * @default large
   */
  size?: 'small' | 'large';
  /**
   * Allows to add a left adornment.
   */
  leftAdornment?: ReactNode;
  /**
   * Allows to add a right adornment.
   */
  rightAdornment?: ReactNode;
};

export const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  ({ size = 'large', leftAdornment, rightAdornment, className, children, ...delegated }, ref) => {
    const styles = cn(
      'flex flex-row gap-2 items-center rounded-[32px] text-text-primary w-fit shrink-0 whitespace-nowrap',
      {
        'px-4 py-2.5 typography-body1': size === 'large',
        'px-3 py-1.5 typography-body2': size === 'small',
      },
      className,
    );

    return (
      <div ref={ref} className={styles} {...delegated}>
        {leftAdornment}
        {children}
        {rightAdornment}
      </div>
    );
  },
);
Pill.displayName = 'Pill';
