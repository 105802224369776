import { Sidebar, SidebarItem } from '@landler/component-library';
import { RiAuctionFill, RiGroupFill } from '@landler/tw-component-library';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useAuth } from '@/lib/auth';

export const AccountSidebar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { signOutAndInvalidateSession } = useAuth();
  const { accountPath, accountLegalPath, accountTeamPath } = useAccountPageRoutes();

  const isItemSelected = (to: string) => {
    return pathname === to || (pathname.startsWith(to) && to !== accountPath);
  };

  return (
    <Sidebar title={t('shared.account.titles.myAccount')}>
      {/* using a link instead of useNavigate has the advantage that a user get's not redirected to the page if the user is already
      on the respective page. This keeps the history clean. */}
      <Link
        to={accountPath}
        style={{ textDecoration: 'none', color: theme.palette.text.primary }}
        data-cy='sidebar-personal-data-link'
      >
        <SidebarItem active={isItemSelected(accountPath)} iconKey='person-filled'>
          {t('shared.account.sidebar.personalInformation')}
        </SidebarItem>
      </Link>
      <Link to={accountTeamPath} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
        <SidebarItem
          active={isItemSelected(accountTeamPath)}
          icon={<RiGroupFill size={20} opacity={0.54} className='mr-5' />}
        >
          {t('shared.account.sidebar.team')}
        </SidebarItem>
      </Link>
      <Link to={accountLegalPath} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
        <SidebarItem
          active={isItemSelected(accountLegalPath)}
          icon={<RiAuctionFill size={20} opacity={0.54} className='mr-5' />}
        >
          {t('shared.account.sidebar.legal')}
        </SidebarItem>
      </Link>
      <SidebarItem
        active={false}
        onClick={signOutAndInvalidateSession}
        iconKey='logoutFilled'
        dataCy='sidebar-logout-item'
      >
        {t('shared.account.sidebar.logout')}
      </SidebarItem>
    </Sidebar>
  );
};
