import { cn, Stack } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';

export const ComplianceFrameworkCard: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => (
  <div className={cn('rounded-3xl bg-white-100 p-6', className)} {...delegated} />
);

type ComplianceFrameworkCardHeaderProps = HTMLAttributes<HTMLDivElement> & {
  logoSrc: string;
  logoAlt?: string;
  title: string;
};

export const ComplianceFrameworkCardHeader: FC<ComplianceFrameworkCardHeaderProps> = ({
  className,
  logoSrc,
  logoAlt,
  title,
  ...delegated
}) => (
  <Stack
    direction='row'
    spacing={3}
    className={cn('mb-6 items-center border-b border-divider pb-6', className)}
    {...delegated}
  >
    <Stack center className='aspect-square w-12 rounded-lg border-[0.5px] border-neutral-black-12 p-1.5'>
      <img src={logoSrc} alt={logoAlt} className='w-full' data-testid={`compliance-framework-logo-${logoAlt}`} />
    </Stack>
    <span data-cy={`${logoAlt}-header`} className='typography-h3 text-text-primary'>
      {title}
    </span>
  </Stack>
);

export const ComplianceFrameworkCardContent: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => (
  <div className={cn('typography-body1 max-w-5xl whitespace-pre-line text-text-primary', className)} {...delegated} />
);

export const ComplianceFrameworkCardUL: FC<HTMLAttributes<HTMLUListElement>> = ({ className, ...delegated }) => (
  <ul className={cn('ml-5 list-disc', className)} {...delegated} />
);

export const ComplianceFrameworkCardLI: FC<HTMLAttributes<HTMLLIElement>> = ({ className, ...delegated }) => (
  <li className={cn('typography-body1 max-w-5xl text-text-primary', className)} {...delegated} />
);
