import { Box, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type ContentContainerVariant = 'regular' | 'full-white';

interface ContentContainerProps {
  variant?: ContentContainerVariant;
}

export const SignedInPageLayoutContentContainer: React.FC<PropsWithChildren<ContentContainerProps>> = ({
  variant = 'regular',
  children,
}) => {
  if (variant === 'regular') {
    return (
      <Stack
        sx={{
          flexGrow: 1,
          mx: 'auto',
          pb: { xs: 5, md: 8 },
          pt: { xs: 5, md: 6 },
          px: { xs: 2, md: 5 },
          maxWidth: 'lg',
          width: '100%',
        }}
        data-testid='regular-signed-in-page-layout-content-container'
      >
        {children}
      </Stack>
    );
  }

  return (
    <Box sx={{ bgcolor: 'common.white', display: 'flex', flexGrow: 1, width: '100%' }}>
      <Stack
        sx={{
          flexGrow: 1,
          mx: 'auto',
          pb: { xs: 5, md: 4 },
          pt: 3,
          px: { xs: 2, md: 5 },
          maxWidth: 'lg',
          bgcolor: 'common.white',
        }}
        data-testid='full-white-signed-in-page-layout-content-container'
      >
        {children}
      </Stack>
    </Box>
  );
};
