export enum UnitEnum {
  none = 'none',
  unknown = 'unknown',
  '%' = '%',
  g = 'g',
  kg = 'kg',
  t = 't',
  mm = 'mm',
  m = 'm',
  km = 'km',
  'm^2' = 'm^2',
  'km^2' = 'km^2',
  ha = 'ha',
  'm^3' = 'm^3',
  l = 'l',
  pH = 'pH',
  'year^-1' = 'year^-1',
  'g/kg' = 'g/kg',
  'kg/ha' = 'kg/ha',
  't/ha' = 't/ha',
  'm^3/ha' = 'm^3/ha',
  '100*m^3/m^3' = '100*m^3/m^3',
  '°C' = '°C',
  'm^3/m^3/a' = 'm^3/m^3/a',
  habitat_score = 'habitat_score',
  bool = 'bool',
  enum = 'enum',
  decade = 'decade',
  geojson = 'geojson',
  timeseries = 'timeseries',
}
