export const PROJECT_BOUNDARY_SOURCE_ID = 'project-boundary';

export const PROJECT_CELLS_SOURCE_ID = 'project-cells';
export const CELLS_OUTLINE_LAYER_ID = 'cells-outline';
export const CELLS_FILL_LAYER_ID = 'cells-fill';

export const OBSERVATION_MARKERS_SOURCE_ID = 'observation-markers';
export const CLUSTER_LAYER_ID = 'cluster';
export const CLUSTER_COUNT_LAYER_ID = 'cluster-count';
export const UNCLUSTERED_POINT_LAYER_ID = 'unclustered-point';
export const UNCLUSTERED_POINT_ICON_LAYER_ID = 'unclustered-point-icon';

export const cellLayers = [CELLS_OUTLINE_LAYER_ID, CELLS_FILL_LAYER_ID];

export const observationMarkerLayers = [
  CLUSTER_LAYER_ID,
  CLUSTER_COUNT_LAYER_ID,
  UNCLUSTERED_POINT_ICON_LAYER_ID,
  UNCLUSTERED_POINT_LAYER_ID,
];

type Color = {
  binary?: string;
  index?: [string, string];
};

export const COLORS = {
  ecological_integrity: {
    index: ['#FFFACA', '#0C5326'],
  },
  combined: {
    index: ['#F8EDB7', '#8B391E'],
  },
  built: {
    binary: '#4B2880',
  },
  crop: {
    binary: '#BCA013',
  },
  treeloss: {
    binary: '#A4BE20',
  },
  mine: {
    binary: '#9A4317',
  },
  overgrazing: {
    binary: '#E1CA52',
  },
  road: {
    binary: '#C47FCF',
  },
  other: {
    binary: 'transparent',
  },
  g1: {
    binary: '#CA623C',
  },
  g2: {
    binary: '#769DC7',
  },
  g3: {
    binary: '#728A42',
  },
  g4: {
    binary: '#5CB689',
  },
  g5: {
    binary: '#D2A034',
  },
  g6: {
    binary: '#6FADC8',
  },
  g7: {
    binary: '#955454',
  },
  g8: {
    binary: '#B18B65',
  },
  g9: {
    binary: '#8D5495',
  },
  g10: {
    binary: '#549569',
  },
} satisfies Record<string, Color>;
