import { Icon, MenuExpandableItem } from '@landler/component-library';
import { IconButton, RiAuctionFill, RiCloseLine, RiGroupFill, RiLogoutBoxRLine } from '@landler/tw-component-library';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useAuth, useUser } from '@/lib/auth';

import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { SignedInNavbarMenuAccountItem } from './AccountItem';

export type MenuItemProps = {
  active: boolean;
  // if a link is external, we need to use an anchor tag instead of a react-router-dom Link
  isExternalLink?: boolean;
  name: string;
  to: string;
  rel?: string;
  target?: string;
  text: string;
  icon?: JSX.Element;
  'data-cy'?: string;
};

export type SignedInNavbarMenuProps = {
  closeDrawer: () => void;
  items: MenuItemProps[];
};

export const SignedInNavbarMenu: React.FC<PropsWithChildren<SignedInNavbarMenuProps>> = ({ closeDrawer, items }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useUser();
  const { signOutAndInvalidateSession } = useAuth();
  const { accountPath, accountLegalPath, accountTeamPath } = useAccountPageRoutes();

  const isItemSelected = (to: string) => {
    return pathname === to || pathname.startsWith(`${to}/`);
  };

  /** If route paths are nested, isItemSelected always returns true on the second condition and fails to switch correctly,
   * using this instead in such cases */
  const isPageSelected = (to: string) => {
    return pathname === to || (pathname.startsWith(to) && to !== accountPath);
  };

  return (
    <Stack sx={{ justifyContent: 'space-between', minHeight: '100%' }}>
      <List data-testid='navbar-menu' sx={{ position: 'relative', pt: 15, pb: 0 }}>
        <IconButton onClick={closeDrawer} className='absolute right-1.5 top-[60px]'>
          <RiCloseLine className='h-10 w-10' data-testid='close-drawer' />
        </IconButton>
        <Divider />
        <MenuExpandableItem dividers='bottom' header={<SignedInNavbarMenuAccountItem user={user} />}>
          <ListItemButton
            component={Link}
            key='personal-information'
            to={accountPath}
            selected={isPageSelected(accountPath)}
            onClick={closeDrawer}
            sx={{ height: 64 }}
          >
            <ListItemIcon>
              <Icon icon='person-filled' />
            </ListItemIcon>
            <ListItemText>{t('shared.account.sidebar.personalInformation')}</ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='manage-team'
            to={accountTeamPath}
            selected={isPageSelected(accountTeamPath)}
            onClick={closeDrawer}
            sx={{ height: 64 }}
          >
            <ListItemIcon>
              <RiGroupFill size={20} className='mr-5' />
            </ListItemIcon>
            <ListItemText>{t('shared.account.sidebar.team')}</ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='legal-info'
            to={accountLegalPath}
            selected={isPageSelected(accountLegalPath)}
            onClick={closeDrawer}
            sx={{ height: 64 }}
          >
            <ListItemIcon>
              <RiAuctionFill size={20} className='mr-5' />
            </ListItemIcon>
            <ListItemText>{t('shared.account.sidebar.legal')}</ListItemText>
          </ListItemButton>
        </MenuExpandableItem>
        <Divider />
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {item.isExternalLink ? (
              <ListItemButton
                component='a'
                href={item.to}
                onClick={closeDrawer}
                rel={item.rel}
                target={item.target}
                sx={{ height: 64 }}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.text}</ListItemText>
              </ListItemButton>
            ) : (
              <ListItemButton
                component={Link}
                to={item.to}
                onClick={closeDrawer}
                rel={item.rel}
                target={item.target}
                selected={isItemSelected(item.to)}
                sx={{ height: 64 }}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.text}</ListItemText>
              </ListItemButton>
            )}
            <Divider />
          </React.Fragment>
        ))}
        <ListItemButton onClick={signOutAndInvalidateSession} sx={{ height: 64 }}>
          <ListItemIcon>
            <RiLogoutBoxRLine className='h-6 w-6' />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error.main' }}>{t('logout.title')}</ListItemText>
        </ListItemButton>
        <Divider />
      </List>

      <DrawerFooter data-testid='drawer-footer' />
    </Stack>
  );
};
