export const useDisplayCurrency = (input: number | string, currency?: string) => {
  return getDisplayCurrency(input, window.navigator.language, currency);
};

export const getDisplayCurrency = (input: number | string, language: string, currency?: string) => {
  const number = Number(input);

  if (Number.isNaN(number)) throw Error(`Invalid number received: ${input}`);

  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency ?? 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .formatToParts(number)
    .map(({ type, value }) => {
      // if a group delimeter is detected, replace with thinspace
      if (type === 'group') {
        return `\u2009`;
      }
      return value;
    })
    .reduce((string, part) => string + part);
};
