import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_PROJECT_AGGREGATE_PATH,
  GetProjectAggregateErrorDto,
  GetProjectAggregateSuccessDto,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';

type SuccessDto = GetProjectAggregateSuccessDto;
type ErrorDto = GetProjectAggregateErrorDto;

export const useProjectAggregate = () => {
  const membershipType = useMembershipType();
  const queryParams = { demo_if_none_analysed_or_later: true };

  const queryFn = () => {
    return getRequest<SuccessDto>(GET_PROJECT_AGGREGATE_PATH, undefined, queryParams);
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'project', 'aggregate', queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
