import { useQueryClient } from '@tanstack/react-query';

import { RoleEnum } from '@/api/rest/resources/types/membership';
import { User } from '@/api/rest/resources/types/user';
import { usePostUserByIdModifyUserAndRole } from '@/api/rest/resources/user';

export type EditMemberInputs = {
  user: {
    firstname: string;
    lastname: string;
    email: string;
  };
  role: RoleEnum;
};

export const useEditTeamMember = () => {
  const queryClient = useQueryClient();
  const postUserByIdModifyUserAndRoleMutation = usePostUserByIdModifyUserAndRole();

  const submit = async (member: User, data: EditMemberInputs) => {
    await postUserByIdModifyUserAndRoleMutation.mutateAsync({
      pathVariables: {
        id: member.id,
      },
      bodyData: {
        org_id: member.membership.organization.id,
        ...data,
      },
    });

    queryClient.resetQueries({ queryKey: ['user'] });
  };

  const isSubmitting = postUserByIdModifyUserAndRoleMutation.isPending;

  return { submit, isSubmitting };
};
