import { useQueryClient } from '@tanstack/react-query';

import { usePostUserByIdSoftDelete } from '@/api/rest/resources/user';

export const useRemoveTeamMember = () => {
  const queryClient = useQueryClient();
  const postUserByIdSoftDeleteMutation = usePostUserByIdSoftDelete();

  const submit = async (id: string) => {
    await postUserByIdSoftDeleteMutation.mutateAsync({
      pathVariables: { id },
    });

    queryClient.resetQueries({ queryKey: ['user'] });
  };

  const isSubmitting = postUserByIdSoftDeleteMutation.isPending;

  return { submit, isSubmitting };
};
