import { Button, PasswordInput } from '@landler/component-library';
import { Stack } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextInput } from '@/components/Form/ControlledTextInput';
import { OnboardingPageHelperLink } from '@/layout/OnboardingPage';
import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';
import { validatePassword } from '@/utils/validators';

import { NewPasswordPageContent } from '../page-content/PageContent';

interface Inputs {
  password: string;
  repeatedPassword: string;
}

const defaultValues: Inputs = {
  password: '',
  repeatedPassword: '',
};

interface NewPasswordFormProps {
  token: string;
  onSuccessfulSubmit: () => void;
}

const formFields: never[] = [];

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ token, onSuccessfulSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, formState, setError } = useForm<Inputs>({
    defaultValues,
  });

  const { resetPassword, isLoading } = useAuth();

  const password = watch('password');
  const repeatedPassword = watch('repeatedPassword');
  const handleServerError = useRestApiServerErrorHandling(setError, formFields);

  const handleSetNewPassword: SubmitHandler<Inputs> = async (data) => {
    try {
      await resetPassword({ token, password: data.password });
      onSuccessfulSubmit();
    } catch (e) {
      handleServerError(e);
    }
  };

  const isSubmitDisabled = !formState.isDirty || password !== repeatedPassword;

  return (
    <>
      <NewPasswordPageContent dataCy='new-password' headlineText={t('newPassword.title')}>
        <Stack component='form' spacing={3} onSubmit={handleSubmit(handleSetNewPassword)}>
          <ControlledTextInput
            id='password'
            label={t('newPassword.password.label')}
            name='password'
            type='password'
            control={control}
            inputProps={{
              autoComplete: 'new-password',
            }}
            component={PasswordInput}
            rules={{
              required: t('newPassword.password.errors.required'),
              validate: (value) => validatePassword(value) || t('newPassword.password.errors.invalid'),
            }}
            data-cy='password-input'
          />
          <ControlledTextInput
            id='repeatPassword'
            label={t('newPassword.repeatPassword.label')}
            name='repeatedPassword'
            type='password'
            control={control}
            inputProps={{
              autoComplete: 'new-password',
            }}
            component={PasswordInput}
            rules={{
              required: t('newPassword.repeatPassword.errors.required'),
              validate: (value) => value === password || t('newPassword.repeatPassword.errors.mismatch'),
            }}
            data-cy='repeat-password-input'
          />
          <Button
            type='submit'
            size='large'
            sx={{ alignSelf: 'end' }}
            disabled={isSubmitDisabled}
            isLoading={isLoading}
            data-cy='submit-button'
          >
            {t('newPassword.submit')}
          </Button>
        </Stack>
      </NewPasswordPageContent>
      <OnboardingPageHelperLink i18nKey='newPassword.back' to={paths.login} data-cy='back-link' />
    </>
  );
};
