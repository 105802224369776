import { useApiPostRequest } from '../hooks/useApiPostRequest';
import {
  POST_MEMBERSHIP_BY_ID_SEND_INVITATION_MAIL_PATH,
  PostMembershipByIdSendInvitationMailErrorDto,
  PostMembershipByIdSendInvitationMailRequestBodyData,
  PostMembershipByIdSendInvitationMailRequestPathVariables,
  PostMembershipByIdSendInvitationMailRequestQueryParams,
  PostMembershipByIdSendInvitationMailSuccessDto,
} from './types/membership';

export const usePostMembershipByIdSendInvitationMail = () => {
  return useApiPostRequest<
    PostMembershipByIdSendInvitationMailRequestPathVariables,
    PostMembershipByIdSendInvitationMailRequestQueryParams,
    PostMembershipByIdSendInvitationMailRequestBodyData,
    PostMembershipByIdSendInvitationMailSuccessDto,
    PostMembershipByIdSendInvitationMailErrorDto
  >(POST_MEMBERSHIP_BY_ID_SEND_INVITATION_MAIL_PATH);
};
