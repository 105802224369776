import { Box } from '@mui/material';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { SignInWithTokenState, useSignInWithToken } from '@/hooks/useSignInWithToken';
import { NotFoundPage } from '@/pages/not-found';
import { paths } from '@/routing';

export const ImpersonatePage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { signInState } = useSignInWithToken(searchParams.get('token'));

  if (signInState === SignInWithTokenState.SUCCESS) {
    return <Navigate to={paths.landSteward.projects} />;
  }

  if ([SignInWithTokenState.IN_PROGRESS, SignInWithTokenState.SUCCESS].includes(signInState)) {
    /* an empty box is returned because if null is returned hubspot throws an error */
    return <Box data-testid='empty-page' />;
  }

  return <NotFoundPage disableLogging />;
};
