import { BenchmarkType } from '@/api/rest/resources/types/fact';
import { Logger } from '@/lib/logs/logger';

import { resolveThemeColor } from './resolveThemeColor';

export type BenchmarkStyle = {
  primary: string;
  text: string;
};

const exhaustivenessCheck = (benchmark: never): BenchmarkStyle => {
  Logger.error(`No color defined for benchmark type "${benchmark}"`);
  return { primary: resolveThemeColor('transparent'), text: resolveThemeColor('transparent') };
};

export const getStyleForBenchmark = (benchmark: BenchmarkType): BenchmarkStyle => {
  switch (benchmark) {
    case BenchmarkType.low:
      return { primary: resolveThemeColor('error-light'), text: resolveThemeColor('error-dark') };
    case BenchmarkType.moderate:
      return { primary: resolveThemeColor('warning-light'), text: resolveThemeColor('warning-dark') };
    case BenchmarkType.high:
      return { primary: resolveThemeColor('success-light'), text: resolveThemeColor('success-dark') };
    default:
      return exhaustivenessCheck(benchmark);
  }
};
