// TODO: (MVP-1132): these types should be generated automatically based on the API's Swagger documentation

import { MembershipWithOrganization, RoleEnum } from './membership';

export enum LanguageEnum {
  af = 'af',
  ar = 'ar',
  'ar-dz' = 'ar-dz',
  ast = 'ast',
  az = 'az',
  bg = 'bg',
  be = 'be',
  bn = 'bn',
  br = 'br',
  bs = 'bs',
  ca = 'ca',
  cs = 'cs',
  cy = 'cy',
  da = 'da',
  de = 'de',
  dsb = 'dsb',
  el = 'el',
  en = 'en',
  'en-au' = 'en-au',
  'en-gb' = 'en-gb',
  eo = 'eo',
  es = 'es',
  'es-ar' = 'es-ar',
  'es-co' = 'es-co',
  'es-mx' = 'es-mx',
  'es-ni' = 'es-ni',
  'es-ve' = 'es-ve',
  et = 'et',
  eu = 'eu',
  fa = 'fa',
  fi = 'fi',
  fr = 'fr',
  fy = 'fy',
  ga = 'ga',
  gd = 'gd',
  gl = 'gl',
  he = 'he',
  hi = 'hi',
  hr = 'hr',
  hsb = 'hsb',
  hu = 'hu',
  hy = 'hy',
  ia = 'ia',
  id = 'id',
  ig = 'ig',
  io = 'io',
  is = 'is',
  it = 'it',
  ja = 'ja',
  ka = 'ka',
  kab = 'kab',
  kk = 'kk',
  km = 'km',
  kn = 'kn',
  ko = 'ko',
  ky = 'ky',
  lb = 'lb',
  lt = 'lt',
  lv = 'lv',
  mk = 'mk',
  ml = 'ml',
  mn = 'mn',
  mr = 'mr',
  ms = 'ms',
  my = 'my',
  nb = 'nb',
  ne = 'ne',
  nl = 'nl',
  nn = 'nn',
  os = 'os',
  pa = 'pa',
  pl = 'pl',
  pt = 'pt',
  'pt-br' = 'pt-br',
  ro = 'ro',
  ru = 'ru',
  sk = 'sk',
  sl = 'sl',
  sq = 'sq',
  sr = 'sr',
  'sr-latn' = 'sr-latn',
  sv = 'sv',
  sw = 'sw',
  ta = 'ta',
  te = 'te',
  tg = 'tg',
  th = 'th',
  tk = 'tk',
  tr = 'tr',
  tt = 'tt',
  udm = 'udm',
  uk = 'uk',
  ur = 'ur',
  uz = 'uz',
  vi = 'vi',
  'zh-hans' = 'zh-hans',
  'zh-hant' = 'zh-hant',
  unknown = 'unknown',
}

export enum KycEnum {
  not_verified = 'not_verified',
  in_progress = 'in_progress',
  failed = 'failed',
  success = 'success',
}

export type User = {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  language: LanguageEnum;
  email_activated: boolean;
  membership: MembershipWithOrganization;
  accepted_terms: string; // yes, it's awkward but it's a string in the API for whatever reason and it will be revisited once we actually need to ask users to re-accept updated terms
  accepted_privacy_policy: string;
  kyc: KycEnum;
  accepted_biometric_data_process: boolean;
};

export type OrganizationRequest = {
  name: string;
};

export type BasicUserRequest = {
  email: string;
  firstname?: string;
  lastname?: string;
};

/**
 * GET /api/v1/user
 */
export const GET_USERS_PATH = 'user';
export type GetUsersRequestPathVariables = {
  organization: string;
};
export type GetUsersQueryParams = undefined;
export type GetUsersSuccessDto = {
  count: number;
  next: string | null;
  previous: string | null;
  results: User[];
};
export type GetUsersErrorDto = Error;

/**
 * GET /api/v1/user/me
 */
export const GET_USER_ME_PATH = 'user/me';
export type GetUserMeRequestPathVariables = undefined;
export type GetUserMeRequestQueryParams = undefined;
export type GetUserMeSuccessDto = User;
export type GetUserMeErrorDto = Error;

/**
 * GET /api/v1/user/{id}
 */
export const GET_USER_BY_ID_PATH = 'user/{id}';
export type GetUserByIdRequestPathVariables = {
  id: string;
};
export type GetUserByIdRequestQueryParams = undefined;
export type GetUserByIdSuccessDto = User;
export type GetUserByIdErrorDto = Error;

/**
 * PATCH /api/v1/user/me
 */
export const PATCH_USER_ME_PATH = 'user/me';
export type PatchUserMeRequestPathVariables = undefined;
export type PatchUserMeRequestQueryParams = undefined;
export type PatchUserMeRequestBodyData = {
  firstname?: string;
  lastname?: string;
  language?: string;
  accepted_biometric_data_process?: boolean;
};
export type PatchUserMeSuccessDto = User;
export type PatchUserMeErrorDto = Error;

/**
 * PATCH /api/v1/user/me/changepassword
 */
export const PATCH_USER_ME_CHANGEPASSWORD_PATH = 'user/me/changepassword';
export type PatchUserMeChangepasswordRequestPathVariables = undefined;
export type PatchUserMeChangepasswordRequestQueryParams = undefined;
export type PatchUserMeChangepasswordRequestBodyData = {
  password: string;
  password_old: string;
};
export type PatchUserMeChangepasswordSuccessDto = {
  /* empty body */
};
export type PatchUserMeChangepasswordErrorDto = Error;

/**
 * POST /api/v1/user/me/change_mail_request
 */
export const POST_USER_ME_CHANGE_MAIL_REQUEST_PATH = 'user/me/change_mail_request';
export type PostUserMeChangeMailRequestRequestPathVariables = undefined;
export type PostUserMeChangeMailRequestRequestQueryParams = undefined;
export type PostUserMeChangeMailRequestRequestBodyData = {
  email: string;
  password: string;
};
export type PostUserMeChangeMailRequestSuccessDto = {
  /* empty body */
};
export type PostUserMeChangeMailRequestErrorDto = Error;

/**
 * POST /api/v1/user/me/confirm_change_mail_request
 */
export const POST_USER_ME_CONFIRM_CHANGE_MAIL_REQUEST_PATH = 'user/me/confirm_change_mail_request';
export type PostUserMeConfirmChangeMailRequestRequestPathVariables = undefined;
export type PostUserMeConfirmChangeMailRequestRequestQueryParams = undefined;
export type PostUserMeConfirmChangeMailRequestRequestBodyData = { token: string };
export type PostUserMeConfirmChangeMailRequestSuccessDto = { email: string };
export type PostUserMeConfirmChangeMailRequestErrorDto = Error;

/**
 * POST /api/v1/user/{id}/modify_user_and_role
 */
export const POST_USER_BY_ID_MODIFY_USER_AND_ROLE_REQUEST_PATH = 'user/{id}/modify_user_and_role';
export type PostUserByIdModifyUserAndRoleRequestPathVariables = {
  id: string;
};
export type PostUserByIdModifyUserAndRoleRequestRequestQueryParams = undefined;
export type PostUserByIdModifyUserAndRoleRequestRequestBodyData = {
  org_id: string;
  user: BasicUserRequest;
  role?: RoleEnum;
};
export type PostUserByIdModifyUserAndRoleSuccessDto = User;
export type PostUserByIdModifyUserAndRoleErrorDto = Error;

/**
 * POST /api/v1/user/me/request_activation_mail
 */
export const POST_USER_ME_REQUEST_ACTIVATION_MAIL_PATH = 'user/me/request_activation_mail';
export type PostUserMeRequestActivationMailRequestPathVariables = undefined;
export type PostUserMeRequestActivationMailRequestQueryParams = undefined;
export type PostUserMeRequestActivationMailRequestBodyData = {
  org_id: string;
};
export type PostUserMeRequestActivationMailSuccessDto = {
  /* empty body */
};
export type PostUserMeRequestActivationMailErrorDto = Error;

/**
 * POST /api/v1/user/{id}/soft_delete
 */
export const POST_USER_BY_ID_SOFT_DELETE_PATH = 'user/{id}/soft_delete';
export type PostUserByIdSoftDeleteRequestPathVariables = {
  id: string;
};
export type PostUserByIdSoftDeleteRequestQueryParams = undefined;
export type PostUserByIdSoftDeleteRequestBodyData = undefined;
export type PostUserByIdSoftDeleteSuccessDto = undefined;
export type PostUserByIdSoftDeleteErrorDto = Error;

/**
 * POST /api/v1/user/activate_account
 */
export const POST_USER_ACTIVATE_ACCOUNT_PATH = 'user/activate_account';
export type PostUserActivateAccountRequestPathVariables = undefined;
export type PostUserActivateAccountRequestQueryParams = undefined;
export type PostUserActivateAccountRequestBodyData = {
  token: string;
};
export type PostUserActivateAccountSuccessDto = {
  /* empty body */
};
export type PostUserActivateAccountErrorDto = Error;

/**
 * POST /api/v1/user/reset_password_request
 */
export const POST_USER_RESET_PASSWORD_REQUEST_PATH = 'user/reset_password_request';
export type PostUserResetPasswordRequestRequestPathVariables = undefined;
export type PostUserResetPasswordRequestRequestQueryParams = undefined;
export type PostUserResetPasswordRequestRequestBodyData = { email: string };
export type PostUserResetPasswordRequestSuccessDto = {
  /* empty body */
};
export type PostUserResetPasswordRequestErrorDto = Error;

/**
 * POST /api/v1/user/reset_password
 */
export const POST_USER_RESET_PASSWORD_PATH = 'user/reset_password';
export type PostUserResetPasswordRequestPathVariables = undefined;
export type PostUserResetPasswordRequestQueryParams = undefined;
export type PostUserResetPasswordRequestBodyData = {
  token: string;
  password: string;
};
export type PostUserResetPasswordSuccessDto = {
  token: {
    refresh: string;
    access: string;
  };
  user: User;
};
export type PostUserResetPasswordErrorDto = Error;

/**
 * POST /api/v1/user/sign_up
 */
export const POST_USER_SIGN_UP_PATH = 'user/sign_up';
export type PostUserSignUpRequestPathVariables = undefined;
export type PostUserSignUpRequestQueryParams = undefined;
export type PostUserSignUpRequestBodyData = {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  language: LanguageEnum;
  organizations: OrganizationRequest[];
  accepted_terms: string;
  accepted_privacy_policy: string;
};
export type PostUserSignUpSuccessDto = {
  token: {
    refresh: string;
    access: string;
  };
  user: User;
};
export type PostUserSignUpErrorDto = Error;

/**
 * POST /api/v1/user/complete_account_data
 */
export const POST_USER_COMPLETE_ACCOUNT_DATA_PATH = 'user/complete_account_data';
export type PostUserCompleteAccountDataRequestPathVariables = undefined;
export type PostUserCompleteAccountDataRequestQueryParams = undefined;
export type PostUserCompleteAccountDataRequestBodyData = {
  token: string;
};
export type PostUserCompleteAccountDataSuccessDto = {
  email: string;
  firstname: string;
  lastname: string;
};
export type PostUserCompleteAccountDataErrorDto = Error;

/**
 * POST /api/v1/user/complete_account
 */
export const POST_USER_COMPLETE_ACCOUNT_PATH = 'user/complete_account';
export type PostUserCompleteAccountRequestPathVariables = undefined;
export type PostUserCompleteAccountRequestQueryParams = undefined;
export type PostUserCompleteAccountRequestBodyData = {
  token: string;
  firstname: string;
  lastname: string;
  password: string;
  accepted_newsletter: boolean;
  accepted_terms: string;
  accepted_privacy_policy: string;
  accepted_biometric_data_process: boolean;
};
export type PostUserCompleteAccountSuccessDto = User;
export type PostUserCompleteAccountErrorDto = Error;

/**
 * POST /api/v1/user/create_and_invite_user/
 */
export const POST_USER_CREATE_AND_INVITE_USER_PATH = 'user/create_and_invite_user/';
export type PostUserCreateAndInviteUserRequestPathVariables = undefined;
export type PostUserCreateAndInviteUserRequestQueryParams = undefined;
export type PostUserCreateAndInviteUserRequestBodyData = {
  user: BasicUserRequest;
  role: RoleEnum;
  org_id: string;
};
export type PostUserCreateAndInviteUserSuccessDto = undefined;
export type PostUserCreateAndInviteUserErrorDto = Error;

/**
 * POST /api/v1/user/land-steward/complete_account_data
 */
export const POST_USER_LAND_STEWARDCOMPLETE_ACCOUNT_DATA_PATH = 'user/land-steward/complete_account_data';
export type PostUserLandStewardCompleteAccountDataRequestPathVariables = undefined;
export type PostUserLandStewardCompleteAccountDataRequestQueryParams = undefined;
export type PostUserLandStewardCompleteAccountDataRequestBodyData = {
  token: string;
};
export type PostUserLandStewardCompleteAccountDataSuccessDto = {
  email: string;
  organization: string;
  firstname: string;
  lastname: string;
};
export type PostUserLandStewardCompleteAccountDataErrorDto = Error;

/**
 * POST /api/v1/user/land-steward/complete_account
 */
export const POST_USER_LAND_STEWARD_COMPLETE_ACCOUNT_PATH = 'user/land-steward/complete_account';
export type PostUserLandStewardCompleteAccountRequestPathVariables = undefined;
export type PostUserLandStewardCompleteAccountRequestQueryParams = undefined;
export type PostUserLandStewardCompleteAccountRequestBodyData = {
  token: string;
  firstname: string;
  lastname: string;
  password: string;
  accepted_newsletter: boolean;
  accepted_terms: string;
  accepted_privacy_policy: string;
};
export type PostUserLandStewardCompleteAccountSuccessDto = User;
export type PostUserLandStewardCompleteAccountErrorDto = Error;

/**
 * POST /api/v1/user/complycube-kyc/token
 */
export const POST_USER_COMPLYCUBE_KYC_TOKEN_PATH = 'user/complycube-kyc/token';
export type PostUserComplycubeKycTokenRequestPathVariables = undefined;
export type PostUserComplycubeKycTokenRequestQueryParams = undefined;
export type PostUserComplycubeKycTokenRequestBodyData = {
  /* empty body */
};
export type PostUserComplycubeKycTokenSuccessDto = {
  token: string;
};
export type PostUserComplycubeKycTokenErrorDto = Error;

/**
 * POST /api/v1/user/complycube-kyc/check
 */
export const POST_USER_COMPLYCUBE_KYC_CHECK_PATH = 'user/complycube-kyc/check';
export type PostUserComplycubeKycCheckRequestPathVariables = undefined;
export type PostUserComplycubeKycCheckRequestQueryParams = undefined;
export type PostUserComplycubeKycCheckRequestBodyData = {
  document_capture: {
    document_id: string;
    document_type: string;
  };
  face_capture: {
    live_photo_id: string;
  };
  poa_capture: {
    document_id: string;
    document_type: string;
  };
};

export type PostUserComplycubeKycCheckSuccessDto = {
  ckeck_id: string;
  status: string;
  user: User;
};
export type PostUserComplycubeKycCheckErrorDto = Error;
