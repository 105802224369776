import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { usePostAuthenticationValidateOnetimeToken } from '@/api/rest/resources/authentication';
import { RestApiServerError } from '@/api/rest/resources/errors/RestApiServerError';
import { OnboardingPage } from '@/layout/OnboardingPage';
import { Logger } from '@/lib/logs/logger';
import { delay, IDelay } from '@/utils/delay';

import { NewPasswordForm, NewPasswordLoading, NewPasswordSuccess, NewPasswordTokenInvalid } from './components';

type TokenError = 'no-token-provided' | 'token-invalid' | 'unexpected-error';

type PageStateLoadingOrSubmitted = {
  state: 'loading' | 'submitted';
};

type PageStateError = {
  state: 'error';
  error: TokenError;
};

type PageStateReady = {
  state: 'ready';
  token: string;
};

export const NewPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { mutate: validateToken } = usePostAuthenticationValidateOnetimeToken();

  const [pageState, setPageState] = useState<PageStateLoadingOrSubmitted | PageStateError | PageStateReady>({
    state: 'loading',
  });

  const token = searchParams.get('token');

  const handleOnTokenInvalid = () => {
    setPageState({ state: 'error', error: 'token-invalid' });
  };

  const handleOnSuccessfulSubmit = () => {
    setPageState({ state: 'submitted' });
  };

  const handleUnexpectedError = (error: Error) => {
    Logger.error(error);
    setPageState({ state: 'error', error: 'unexpected-error' });
  };

  const fireValidateToken = async (_token: string) => {
    validateToken(
      { bodyData: { token: _token } },
      {
        onSuccess: (response) => {
          if (response.valid) {
            setPageState({ state: 'ready', token: _token });
            return;
          }
          handleOnTokenInvalid();
        },
        onError: (error: Error) => {
          if (!(error instanceof RestApiServerError)) {
            handleUnexpectedError(error);
            return;
          }
          handleOnTokenInvalid();
        },
      },
    );
  };

  const determineErrorMessage = () => {
    if (pageState.state !== 'error') {
      return '';
    }

    switch (pageState.error) {
      case 'no-token-provided':
        return t('passwordResetTokenInvalid.errors.noTokenSupplied');
      case 'token-invalid':
        return t('passwordResetTokenInvalid.errors.unexpected');
      case 'unexpected-error':
      default:
        return t('passwordResetTokenInvalid.errors.unexpected');
    }
  };

  const errorMessage = determineErrorMessage();

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function checkForTokenAndCallMutationAfterDelay() {
      let timer: IDelay | undefined;

      if (!token) {
        setPageState({ state: 'error', error: 'no-token-provided' });
      } else {
        // NOTE: Delaying the request has two purposes.
        // NOTE: 1. The loading spinner is displayed a little longer which makes the user experience better.
        // NOTE: 2. React strict mode mounts/unmounts each component twice. Without delaying the post request it would get fired twice.
        timer = delay(() => fireValidateToken(token));
      }

      return () => {
        if (timer) {
          timer.cancel();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  );

  return (
    <OnboardingPage variant='narrow'>
      {(() => {
        switch (pageState.state) {
          case 'ready':
            return <NewPasswordForm token={pageState.token} onSuccessfulSubmit={handleOnSuccessfulSubmit} />;
          case 'error':
            return <NewPasswordTokenInvalid error={errorMessage} />;
          case 'submitted':
            return <NewPasswordSuccess />;
          default:
            return <NewPasswordLoading />;
        }
      })()}
    </OnboardingPage>
  );
};
