import { cva } from 'class-variance-authority';

export const containedButtonVariants = cva(
  [
    'rounded-full',
    'data-[disabled=true]:bg-disabled-button-bg',
    'data-[disabled=true]:border-none',
    'data-[disabled=true]:text-text-disabled',
    'data-[disabled=true]:cursor-not-allowed',
    'data-[disabled=true]:hover:bg-disabled-button-bg',
  ],
  {
    variants: {
      size: {
        large: ['px-5 py-3 typography-h4 leading-[23.4px]'],
        medium: ['px-5 py-3 typography-button1 tracking-[0.2px]'],
        small: ['px-4 py-2 typography-button2 tracking-[0.3px]'],
      },
      color: {
        error: ['bg-error border-error text-white-100 hover:bg-error-dark hover:border-error-hover'],
        primary: ['bg-primary-100 border-primary-100 text-white-100 hover:bg-primary-hover hover:border-primary-hover'],
        white: ['bg-white-100 border-white-100 text-primary-100 hover:bg-bg-light-grey'],
      },
    },
    defaultVariants: {
      size: 'medium',
      color: 'primary',
    },
  },
);
