import { useApiPostRequest } from '../hooks/useApiPostRequest';
import {
  POST_COMPLIANCE_SEND_DATA_REQUEST_TO_CUSTOMER_SUPPORT_PATH,
  PostComplianceSendDataRequestToCustomerErrorDto,
  PostComplianceSendDataRequestToCustomerRequestBodyData,
  PostComplianceSendDataRequestToCustomerRequestPathVariables,
  PostComplianceSendDataRequestToCustomerRequestQueryParams,
  PostComplianceSendDataRequestToCustomerSuccessDto,
} from './types/compliance';

export const usePostComplianceSendDataRequestToCustomerSupport = () => {
  return useApiPostRequest<
    PostComplianceSendDataRequestToCustomerRequestPathVariables,
    PostComplianceSendDataRequestToCustomerRequestQueryParams,
    PostComplianceSendDataRequestToCustomerRequestBodyData,
    PostComplianceSendDataRequestToCustomerSuccessDto,
    PostComplianceSendDataRequestToCustomerErrorDto
  >(POST_COMPLIANCE_SEND_DATA_REQUEST_TO_CUSTOMER_SUPPORT_PATH);
};
