import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Polygon } from 'geojson';
import { EventData, Map, MapMouseEvent, Popup } from 'mapbox-gl';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum DrawHintState {
  AdditionalOrFinalPoint = 'additionalOrFinalPoint',
  AddMidPoint = 'addMidPoint',
  EditPoint = 'editPoint',
  EditPolygon = 'editPolygon',
  MovePolygon = 'movePolygon',
  MovePoint = 'movePoint',
  SecondPoint = 'secondPoint',
  StartPoint = 'startPoint',
}

export const getTranslationKeyForDrawHintState = (state: DrawHintState) => {
  // NOTE: probably ts rule "switch-exhaustiveness-check" and disable eslint rule "default-case"
  const exhaustiveCheck = (value: never): never => {
    throw new Error(`Unhandled case: ${value}`);
  };

  switch (state) {
    case DrawHintState.AdditionalOrFinalPoint:
      return 'landSteward.plot.tooltips.drawPolygon.additionalOrFinalPoint';
    case DrawHintState.AddMidPoint:
      return 'landSteward.plot.tooltips.drawPolygon.addMidPoint';
    case DrawHintState.EditPoint:
      return 'landSteward.plot.tooltips.drawPolygon.editPoint';
    case DrawHintState.EditPolygon:
      return 'landSteward.plot.tooltips.drawPolygon.editPolygon';
    case DrawHintState.MovePolygon:
      return 'landSteward.plot.tooltips.drawPolygon.movePolygon';
    case DrawHintState.MovePoint:
      return 'landSteward.plot.tooltips.drawPolygon.movePoint';
    case DrawHintState.StartPoint:
      return 'landSteward.plot.tooltips.drawPolygon.startPoint';
    case DrawHintState.SecondPoint:
      return 'landSteward.plot.tooltips.drawPolygon.secondPoint';
    default:
      return exhaustiveCheck(state);
  }
};

export const useDrawHint = (ref: MapboxDraw | null) => {
  const [drawHintState, setDrawHintState] = useState(DrawHintState.StartPoint);
  const tooltipRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();

  const hintTooltip = useMemo(
    () => new Popup({ closeButton: false, closeOnClick: false, offset: 16, maxWidth: '500px' }),
    [],
  );
  const drawHintTranslation = t(getTranslationKeyForDrawHintState(drawHintState));

  const setupDrawTooltip = useCallback(() => {
    if (!ref) {
      return;
    }

    const pointsCount = (ref?.getAll().features[0]?.geometry as Polygon)?.coordinates[0]?.length;

    if (!pointsCount || pointsCount <= 2) {
      setDrawHintState(DrawHintState.StartPoint);
    } else if (pointsCount === 3) {
      setDrawHintState(DrawHintState.SecondPoint);
    } else {
      setDrawHintState(DrawHintState.AdditionalOrFinalPoint);
    }
  }, [ref]);

  const setupDirectSelectTooltip = useCallback(
    (meta: string, active: boolean) => {
      if (meta === 'vertex') {
        setDrawHintState(DrawHintState.MovePoint);
      } else if (meta === 'midpoint') {
        setDrawHintState(DrawHintState.AddMidPoint);
      } else if (meta === 'feature' && active) {
        setDrawHintState(DrawHintState.MovePolygon);
      } else {
        hintTooltip.remove();
      }
    },
    [hintTooltip],
  );

  const setupSimpleSelectTooltip = useCallback(
    (meta: string, active: boolean) => {
      if (meta === 'vertex') {
        setDrawHintState(DrawHintState.EditPoint);
      } else if (meta === 'feature') {
        setDrawHintState(active ? DrawHintState.MovePolygon : DrawHintState.EditPolygon);
      } else {
        hintTooltip.remove();
      }
    },
    [hintTooltip],
  );

  const handleMouseEvents = useCallback(
    (event: MapMouseEvent & EventData, map: Map) => {
      const properties = event.featureTarget?.properties;
      const active = Boolean(properties?.active === 'true');
      hintTooltip.setLngLat(event.lngLat).addTo(map);
      if (tooltipRef?.current) {
        hintTooltip.setDOMContent(tooltipRef.current as unknown as Node);
      }
      const mode = ref?.getMode();
      if (mode === 'draw_polygon') {
        setupDrawTooltip();
      } else if (mode === 'direct_select') {
        setupDirectSelectTooltip(properties?.meta, active);
      } else if (mode === 'simple_select') {
        setupSimpleSelectTooltip(properties?.meta, active);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref],
  );

  return { drawHintTranslation, tooltipRef, drawHintState, handleMouseEvents };
};
