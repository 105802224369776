import { ThemeOptions } from '@mui/material/styles';

export const otherComponentsOverrides: ThemeOptions['components'] = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        minHeight: 64,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: 64,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        letterSpacing: '1px',
        lineHeight: 1.2,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: 1.2,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 10,
        lineHeight: 1.2,
      },
    },
  },
};
