import { ThemeOptions } from '@mui/material';

import { breakpoints } from './breakpoints';

type ResponsiveStylesParams = Partial<Record<keyof typeof breakpoints, Record<string, unknown>>>;

const generateResponsiveStyles = (params: ResponsiveStylesParams) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`@media (max-width:${breakpoints[key as keyof typeof breakpoints]}px)`]: value,
    };
  }, {});
};

const commonStyles = {
  fontFamily: 'Lemur,sans-serif',
  fontWeight: 400,
  lineHeight: 1.2,
};

export const typography: ThemeOptions['typography'] = {
  fontFamily: ['Inter', 'sans-serif'].join(','),
  h1: {
    ...commonStyles,
    fontSize: 84,
    ...generateResponsiveStyles({ md: { fontSize: 52, lineHeight: 1.3 } }),
  },
  h2: {
    ...commonStyles,
    fontSize: 60,
    ...generateResponsiveStyles({ md: { fontSize: 36, lineHeight: 1.3 } }),
  },
  h3: {
    ...commonStyles,
    fontSize: 46,
    ...generateResponsiveStyles({ md: { fontSize: 30, lineHeight: 1.3 } }),
  },
  h4: {
    ...commonStyles,
    fontSize: 32,
    ...generateResponsiveStyles({ md: { fontSize: 24, lineHeight: 1.3 } }),
  },
  h5: {
    ...commonStyles,
    fontSize: 24,
    ...generateResponsiveStyles({ md: { fontSize: 20, lineHeight: 1.3 } }),
  },
  h6: {
    ...commonStyles,
    fontSize: 20,
    ...generateResponsiveStyles({ md: { fontSize: 18, lineHeight: 1.3 } }),
  },
  subtitle1: {
    fontSize: 24,
    lineHeight: 1.2,
    ...generateResponsiveStyles({ md: { fontSize: 18, lineHeight: 1.3 } }),
  },
  subtitle2: {
    fontSize: 18,
    lineHeight: 1.2,
    ...generateResponsiveStyles({ md: { fontSize: 16, lineHeight: 1.3 } }),
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.3,
    ...generateResponsiveStyles({ md: { fontSize: 15, lineHeight: 1.4 } }),
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.4,
    ...generateResponsiveStyles({ md: { fontSize: 13 } }),
  },
  overline: {
    fontSize: 12,
    letterSpacing: 1,
    lineHeight: 1,
    ...generateResponsiveStyles({ md: { fontSize: 11 } }),
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.2,
    ...generateResponsiveStyles({ md: { fontSize: 11 } }),
  },
  helper: {
    fontSize: 12,
    lineHeight: 1.2,
  },
};
