import { ReactComponent as Co2Icon } from '@assets/images/icons/co2.svg';
import { useTranslation } from 'react-i18next';

import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
} from '@/components';
import { CarbonExplainer } from '@/components/NCAExplainers/CarbonExplainer';
import { COMPLIANCE_LOGOS_CARBON } from '@/config/constants';

import { CarbonCardGraphTile } from './GraphTile';
import { CarbonCardStatsTile } from './StatsTile';

export const CarbonCard = () => {
  const { t } = useTranslation();

  return (
    <NCCard data-cy='carbon-card' explainer={<CarbonExplainer />}>
      <NCCardHeader icon={<Co2Icon className='text-primary-100' />} title={t('global.analysis.carbon')}>
        <NCCardAnalysisTypeToggle data-testid='carbon-toggle' data-cy='carbon-toggle' />
      </NCCardHeader>
      <NCCard2SectionLayout>
        <CarbonCardGraphTile />
        <CarbonCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CARBON} />
    </NCCard>
  );
};
