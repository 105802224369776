import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlotType } from '@/api/rest/resources/types/plot';
import { supportedPlotTypes, unsupportedPlotTypes } from '@/utils/plot';

type PlotTypeTranslation = Record<PlotType, string>;

export const useSortedPlotTypes = () => {
  const { t } = useTranslation();

  const plotTypeTranslations = useMemo(() => {
    const translations: PlotTypeTranslation = {} as PlotTypeTranslation;
    Object.values(PlotType).forEach((type) => {
      // eslint-disable-next-line security/detect-object-injection
      translations[type] = type !== PlotType.UNDEFINED ? t(`global.plotTypes.${type}`) : t('global.plotTypes.other');
    });
    return translations;
  }, [t]);

  const sortedSupportedPlotTypes = useMemo(
    // eslint-disable-next-line security/detect-object-injection
    () => [...supportedPlotTypes].sort((a, b) => (plotTypeTranslations[a] > plotTypeTranslations[b] ? 1 : -1)),
    [plotTypeTranslations],
  );

  const sortedUnsupportedPlotTypes = useMemo(() => {
    const unsupportedPlotTypesWithoutUndefined = [...unsupportedPlotTypes].filter(
      (type) => type !== PlotType.UNDEFINED,
    );
    const result = unsupportedPlotTypesWithoutUndefined.sort((a, b) =>
      // eslint-disable-next-line security/detect-object-injection
      plotTypeTranslations[a] > plotTypeTranslations[b] ? 1 : -1,
    );
    result.push(PlotType.UNDEFINED);
    return result;
  }, [plotTypeTranslations]);

  const sortedSupportedPlotTypesWithTranslations = useMemo(
    // eslint-disable-next-line security/detect-object-injection
    () => sortedSupportedPlotTypes.map((type) => ({ type, translation: plotTypeTranslations[type] })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedSupportedPlotTypes],
  );

  const sortedUnsupportedPlotTypesWithTranslations = useMemo(
    // eslint-disable-next-line security/detect-object-injection
    () => sortedUnsupportedPlotTypes.map((type) => ({ type, translation: plotTypeTranslations[type] })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedUnsupportedPlotTypes],
  );

  return {
    supported: sortedSupportedPlotTypesWithTranslations,
    unsupported: sortedUnsupportedPlotTypesWithTranslations,
  };
};
