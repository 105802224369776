import bbox from '@turf/bbox';
import { featureCollection, polygon } from '@turf/helpers';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';

import { Plot } from '@/api/rest/resources/types/plot';

export const getBoundsForPlots = (plots: Plot[]) => {
  if (!plots.length) throw Error(`Empty plot list received`);

  const polygonFeatures = plots.map((plot) => polygon(plot.polygon.coordinates));
  const featureCollectionWithPolygons = featureCollection(polygonFeatures);

  const bounds = bbox(featureCollectionWithPolygons);

  if (bounds.length !== 4) throw Error(`Invalid bounds calculated: ${JSON.stringify(bounds)}`);

  return bounds as BBox2d;
};
