/* eslint-disable no-param-reassign */
/* eslint-disable security/detect-object-injection */
import { Line } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';

import { ChartLabel } from './components/ChartLabel';
import { SHARED_CHART_COLORS } from './constants';
import { FactDataPoint } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChartDataItem = Record<keyof FactDataPoint, any>;

export const extrapolateReferenceLinesChartData = (data: ChartDataItem[], dataKey: keyof FactDataPoint) => {
  /**
   * Science/Design spec -
   * Reference lines should extend the whole range of the graph on both ends (min to max).
   * In cases where the timeseries data for reference lines might have gaps, or do not extend the whole min-max range -
   *    with connectNulls - recharts interpolates over null values and extends the line internally to fill in the gaps in these lines.
   *    For extrapolation, such that the line gets extended externally to the start and ends of the parent graph
   *    we manually fill in the min and max point for reference lines so that interpolation can take over.
   *    We pick the first non-null value as the min, and the last non-null value as the max.
   */
  const firstDataKeyItemValue = data.find((point: FactDataPoint) => point[dataKey] !== null)?.[dataKey] ?? null;
  const lastDataKeyItemValue = data.findLast((point: FactDataPoint) => point[dataKey] !== null)?.[dataKey] ?? null;

  const firstItem = data[0];
  const lastItem = data[data.length - 1];

  if (!firstItem || !lastItem) {
    return data;
  }

  data[0] = {
    ...firstItem,
    [dataKey]: firstItem[dataKey] ?? firstDataKeyItemValue,
  };
  data[data.length - 1] = {
    ...lastItem,
    [dataKey]: lastItem[dataKey] ?? lastDataKeyItemValue,
  };

  return data;
};

export type ReferenceLineProps = {
  dataKey: string;
  type: string;
  // TODO: MVP-3284 remove and pass as child
  label?: string | null;
  chartData: ChartDataItem[];
};

export const RenderReferenceLine = ({ dataKey, type, label, chartData }: ReferenceLineProps) => {
  return (
    <Line
      type={type as CurveType}
      dataKey={dataKey}
      connectNulls
      activeDot={false}
      isAnimationActive={false}
      stroke={SHARED_CHART_COLORS.referenceLines.stroke}
      strokeDasharray='4 4'
      dot={false}
      strokeWidth={1}
      label={(props) =>
        /**
         * Recharts API does not offer a method to add a tag/label to the whole chart
         * Hence, we use the label component as a hack to add a label to the whole chart
         * by rendering a custom label only on the last datapoint
         */
        label && props.index === chartData.length - 1 ? (
          <ChartLabel title={label} stroke={SHARED_CHART_COLORS.referenceLines.label} {...props} />
        ) : (
          <></>
        )
      }
    />
  );
};
