import { Polygon } from 'geojson';

import { CropsEnum, PlotType } from '@/api/rest/resources/types/plot';

export type GrasslandInputs = {
  grassland_biodiversity_livestock_units?: number;
  grassland_converted_to_permanent_in_last_20y?: boolean;
  grassland_grass_type?: string;
  grassland_num_of_mechanical_events_per_year?: number;
  grassland_organic_fertilizer_type?: string;
  grassland_regularly_exposed_to_backwater?: boolean;
  grassland_subject_to_water_shortages?: boolean;
};

export enum PlotCreationType {
  DRAW = 'draw',
  UPLOAD = 'upload',
}

export type CustomInputs = {
  custom?: Record<string, unknown>;
};

export type PlotCreationInputs = {
  type?: PlotCreationType;
};

export type DraftPlotInputs = {
  draftPlots?: string[];
};

export type PlotInputs = CustomInputs &
  PlotCreationInputs &
  DraftPlotInputs &
  GrasslandInputs & {
    name?: string;
    plotType?: PlotType;
    polygon?: Polygon;
    areaSquareMeters?: number;
    crops?: CropsEnum[];
  };
