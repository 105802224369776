import { Icon, MenuExpandableItem } from '@landler/component-library';
import { IconButton, RiAccountCircleFill, RiCloseLine } from '@landler/tw-component-library';
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DrawerFooter } from '@/components';
import { languages, useLanguage } from '@/hooks/useLanguage';
import { paths } from '@/routing';
import { zIndex } from '@/styles/zIndex';

import { OnboardingNavBarMobileContentListItemButton } from './list-item-button/ListItemButton';

export const OnboardingNavBarMobileContent: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { changeLanguage, currentLanguage } = useLanguage();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Stack direction='row' display='flex' flexGrow={1} justifyContent='end' data-testid='mobile-navbar-content'>
      <IconButton className='ml-auto' onClick={openDrawer}>
        <Icon icon='menu-filled' width={40} height={40} />
      </IconButton>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={closeDrawer}
        PaperProps={{ sx: { minWidth: '320px', maxWidth: '90vw' } }}
        sx={{ zIndex: zIndex.drawer }}
      >
        <Stack sx={{ justifyContent: 'space-between', minHeight: '100%' }}>
          <List data-testid='mobile-navbar-menu' sx={{ position: 'relative', pt: 15, pb: 0 }}>
            <IconButton onClick={closeDrawer} className='absolute right-1.5 top-[60px]'>
              <RiCloseLine size={40} data-testid='drawer-close-icon' />
            </IconButton>
            <Divider />
            {pathname !== paths.login && (
              <OnboardingNavBarMobileContentListItemButton
                href={paths.login}
                icon={<RiAccountCircleFill size={24} />}
                label={t('navbar.login')}
                onClick={closeDrawer}
              />
            )}
            <MenuExpandableItem iconKey='world' label={t('navbar.language')}>
              {Object.entries(languages).map(([key, value], index) => (
                <ListItemButton
                  key={index}
                  selected={value === currentLanguage.nativeTranslation}
                  onClick={() => {
                    changeLanguage(key);
                    closeDrawer();
                  }}
                  sx={{ height: 64 }}
                >
                  <ListItemIcon />
                  <ListItemText>{value}</ListItemText>
                </ListItemButton>
              ))}
            </MenuExpandableItem>
          </List>

          <DrawerFooter data-testid='drawer-footer' />
        </Stack>
      </Drawer>
    </Stack>
  );
};
