import { PasswordInput, Text } from '@landler/component-library';
import { Button, RiLockFill, Stack, TextInput } from '@landler/tw-component-library';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ControlledCheckbox } from '@/components/Form';
import { useLegalUrls } from '@/hooks/useLegalUrls';
import { validatePassword } from '@/utils/validators';

import { CompleteLandStewardInputs, PredefinedData } from '../../hooks/useLandStewardCompleteAccountPage';

type CompleteAccountFormProps = {
  predefinedData: PredefinedData;
  handleCompleteAccount: (data: CompleteLandStewardInputs) => void;
  isSubmitting: boolean;
};

const requiredRuleKey = 'global.ui.form.input.required';

export const LandStewardCompleteAccountForm: React.FC<CompleteAccountFormProps> = ({
  predefinedData,
  handleCompleteAccount,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();

  const { control, handleSubmit, register, formState } = useForm<CompleteLandStewardInputs>({
    defaultValues: {
      firstname: predefinedData.firstname,
      lastname: predefinedData.lastname,
      organization: predefinedData.organization,
      password: '',
      termsAndConditions: false,
      privacyPolicy: false,
    },
  });

  return (
    <div className='px-4'>
      <h1 className='typography-h1 text-primary-100'>{t('landSteward.completeAccount.title')}</h1>
      <p className='typography-body-1 mt-4'>{t('landSteward.completeAccount.subline')}</p>
      <div className='mt-8 w-full rounded-2xl bg-white-100 p-6 pt-10 sm:p-12'>
        <form onSubmit={handleSubmit(handleCompleteAccount)}>
          <Stack direction='col' spacing={6}>
            <Stack className='sm:flex-row' spacing={6}>
              <TextInput
                className='flex-grow'
                {...register('firstname', { required: requiredRuleKey })}
                label={`${t('landSteward.completeAccount.form.firstName.label')}*`}
                helperText={formState.errors.firstname?.message}
                error={!!formState.errors.firstname}
              />
              <TextInput
                className='flex-grow'
                {...register('lastname', { required: requiredRuleKey })}
                label={`${t('landSteward.completeAccount.form.lastName.label')}*`}
                helperText={formState.errors.lastname?.message}
                error={!!formState.errors.lastname}
              />
            </Stack>
            <TextInput
              {...register('organization')}
              label={t('landSteward.completeAccount.form.company.label')}
              EndIcon={<RiLockFill size={24} />}
              disabled
            />
            <PasswordInput
              {...register('password', {
                required: t('global.ui.form.input.required'),
                validate: (password) => validatePassword(password) || t('global.ui.form.input.passwordMinLength'),
              })}
              label={`${t('landSteward.completeAccount.form.password.label')}*`}
              error={!!formState.errors.password}
              helperText={formState.errors.password?.message}
            />
          </Stack>
          <Stack spacing={3} className='mb-8 pt-6'>
            <ControlledCheckbox
              id='termsAndConditions'
              name='termsAndConditions'
              control={control}
              data-testid='checkbox-termsAndConditions'
              rules={{
                required: requiredRuleKey,
              }}
            >
              <Trans
                i18nKey='landSteward.completeAccount.form.termsAndConditions.label'
                components={{
                  /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                  Link: <a className='underline' href={termsAndConditionsUrl} target='_blank' rel='noreferrer' />,
                }}
              />
              *
            </ControlledCheckbox>
            <ControlledCheckbox
              id='privacyPolicy'
              name='privacyPolicy'
              control={control}
              data-testid='checkbox-privacyPolicy'
              rules={{
                required: requiredRuleKey,
              }}
            >
              <Trans
                i18nKey='landSteward.completeAccount.form.privacyPolicy.label'
                components={{
                  /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                  Link: <a className='underline' href={privacyPolicyUrl} target='_blank' rel='noreferrer' />,
                }}
              />
              *
            </ControlledCheckbox>
            <Text variant='body2' alignSelf='start' color='text.secondary' data-cy='required-text'>
              {t('global.ui.form.requiredHint')}
            </Text>
          </Stack>
          <div className='flex flex-row-reverse'>
            <Button className='w-full sm:w-auto' type='submit' disabled={isSubmitting} loading={isSubmitting}>
              {t('landSteward.completeAccount.form.submit')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
