import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useUserContext } from '@/lib/auth';
import { paths } from '@/routing';

export const useAccountPageRoutes = () => {
  const { user } = useUserContext();

  if (user.membership.type === MembershipWithOrganizationTypeEnum.buyer) {
    return {
      accountPath: paths.buyer.account,
      accountEditEmailPath: paths.buyer.accountEditEmail,
      accountEditNamePath: paths.buyer.accountEditName,
      accountEditPasswordPath: paths.buyer.accountEditPassword,
      accountLegalPath: paths.buyer.accountLegal,
      accountTeamPath: paths.buyer.accountTeam,
      accountTeamInvitePath: paths.buyer.accountTeamInvite,
      accountTeamEditMemberPath: paths.buyer.accountTeamEditMember,
    };
  }

  return {
    accountPath: paths.landSteward.account,
    accountEditEmailPath: paths.landSteward.accountEditEmail,
    accountEditNamePath: paths.landSteward.accountEditName,
    accountEditPasswordPath: paths.landSteward.accountEditPassword,
    accountLegalPath: paths.landSteward.accountLegal,
    accountTeamPath: paths.landSteward.accountTeam,
    accountTeamInvitePath: paths.landSteward.accountTeamInvite,
    accountTeamEditMemberPath: paths.landSteward.accountTeamEditMember,
  };
};
