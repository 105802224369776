/* eslint-disable camelcase */
import {
  Button,
  Checkbox,
  cn,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Loader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Stack,
  TextInput,
  toast,
  toastifyToast,
} from '@landler/tw-component-library';
import { FC, PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { FrameworksEnum } from '@/api/rest/resources/types/compliance';
import { ProjectBuyerList, ProjectList } from '@/api/rest/resources/types/project';
import {
  Form as FormProvider,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField,
} from '@/components';
import { useUser } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { validateEmail } from '@/utils/validators';

import { useGetProjects } from '../hooks/useGetProjects';
import {
  RequestCompliancaDataInputs,
  RequestCompliancaDataInputsFrameworks,
  useRequestComplianceData,
} from '../hooks/useRequestComplianceData';

export const RequestDialog = () => {
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const projects = useGetProjects().data;

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(isOpen) => {
        setIsDialogOpen(isOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button>{t('shared.complianceFrameworks.buttonLabel')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('shared.complianceFrameworks.dialog.title')}</DialogTitle>
          <DialogDescription>{t('shared.complianceFrameworks.dialog.description')}</DialogDescription>
        </DialogHeader>
        {Array.isArray(projects) ? <Form projects={projects} /> : <Loader className='min-h-0' />}
      </DialogContent>
    </Dialog>
  );
};

const Form = ({ projects }: { projects: ProjectList[] | ProjectBuyerList[] }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const { email: userEmail } = useUser();

  const [requestSent, setRequestSent] = useState(false);

  const defaultValues: RequestCompliancaDataInputs = {
    email: userEmail,
    project_id: projectId || '',
    frameworks: [
      {
        value: FrameworksEnum.eudr,
        label: t('shared.complianceFrameworks.dialog.form.frameworks.eudr'),
        checked: false,
      },
      { value: FrameworksEnum.sai, label: t('shared.complianceFrameworks.dialog.form.frameworks.sai'), checked: false },
      {
        value: FrameworksEnum.sbti,
        label: t('shared.complianceFrameworks.dialog.form.frameworks.sbti'),
        checked: false,
      },
      {
        value: FrameworksEnum.gcp,
        label: t('shared.complianceFrameworks.dialog.form.frameworks.greenhouse'),
        checked: false,
      },
    ],
  };

  const form = useForm<RequestCompliancaDataInputs>({ defaultValues });

  const onFrameworksCheckboxChange = (checked: boolean, value?: FrameworksEnum) => {
    const newFrameworksValue: RequestCompliancaDataInputsFrameworks = [...form.getValues().frameworks];
    for (let i = 0; i < newFrameworksValue.length; i += 1) {
      // eslint-disable-next-line security/detect-object-injection
      const frameworkValue = newFrameworksValue[i];
      if (frameworkValue && frameworkValue.value === value) {
        frameworkValue.checked = checked;
        break;
      }
    }
    form.setValue('frameworks', newFrameworksValue);
  };

  const { submit, isSubmitting } = useRequestComplianceData();

  const onSubmit = async (inputs: RequestCompliancaDataInputs) => {
    try {
      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      await submit(inputs);

      setRequestSent(true);

      toast({
        title: t('shared.complianceFrameworks.toasts.success.title'),
        type: 'success',
        autoClose: 10_000,
      });
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(`Failed to request compliance data with message "${error.message}"`);
      }

      toast({
        title: t('shared.complianceFrameworks.toasts.error.title'),
        description: t('shared.complianceFrameworks.toasts.error.description'),
        type: 'error',
        autoClose: 10_000,
      });
    }
  };

  const { email, project_id, frameworks } = form.watch();

  const requiredMessage = t('global.ui.form.input.required');

  const selectedFrameworksCount = frameworks.filter((framework) => framework.checked).length;

  const isSubmitButtonEnabled = !requestSent && !!email && !!project_id && selectedFrameworksCount > 0;

  const isProjectSelectDisabled = projects.length === 1 && !!project_id;

  return (
    <FormProvider {...form}>
      <DialogBody>
        <form
          id='request-compliance-data-form'
          onSubmit={form.handleSubmit(onSubmit)}
          data-testid='request-compliance-data-form'
          // NOTE: the padding is needed so the MUI label is not cut off
          className='pt-2'
        >
          <fieldset disabled={isSubmitting}>
            <Stack spacing={9}>
              <FormField
                control={form.control}
                name='email'
                rules={{
                  required: requiredMessage,
                  validate: (value) => validateEmail(value) || t('global.ui.form.input.emailInvalid'),
                }}
                render={({ field: { value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <CustomFormLabel>{t('shared.complianceFrameworks.dialog.form.email.label')}</CustomFormLabel>
                    <FormControl>
                      <TextInput
                        type='email'
                        placeholder={t('shared.complianceFrameworks.dialog.form.email.placeholder')}
                        value={value}
                        {...field}
                      />
                    </FormControl>
                    <CustomFormMessage>{form.formState.errors.email?.message}</CustomFormMessage>
                  </FormItem>
                )}
              />
              <FormField
                name='project_id'
                rules={{ required: true }}
                control={form.control}
                render={({ field: { ref, value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <CustomFormLabel>{t('shared.complianceFrameworks.dialog.form.project.label')}</CustomFormLabel>
                    <Select value={value} disabled={isProjectSelectDisabled} {...field}>
                      <FormControl>
                        <SelectTrigger
                          ref={ref}
                          placeholder={t('shared.complianceFrameworks.dialog.form.project.placeholder')}
                        />
                      </FormControl>
                      <SelectContent>
                        {projects.map((project) => (
                          <SelectItem key={project.id} value={project.id}>
                            {project.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <CustomFormMessage>{requiredMessage}</CustomFormMessage>
                  </FormItem>
                )}
              />
              <FormField
                name='frameworks'
                rules={{ validate: (value) => value.some((val) => val.checked) || requiredMessage }}
                control={form.control}
                render={({ field: { value, ...field } }) => (
                  <FormItem className='flex flex-col gap-3'>
                    <CustomFormLabel>{t('shared.complianceFrameworks.dialog.form.frameworks.label')}</CustomFormLabel>
                    {defaultValues.frameworks.map((framework, index) => (
                      <Checkbox
                        key={framework.value}
                        id={`form:frameworks:${framework.value}`}
                        // eslint-disable-next-line security/detect-object-injection
                        {...value[index]}
                        {...field}
                        onCheckedChange={(checked) =>
                          // eslint-disable-next-line security/detect-object-injection
                          onFrameworksCheckboxChange(Boolean(checked), value[index]?.value)
                        }
                      >
                        <FormLabel htmlFor={`form:frameworks:${framework.value}`}>{framework.label}</FormLabel>
                      </Checkbox>
                    ))}
                    <CustomFormMessage>{requiredMessage}</CustomFormMessage>
                  </FormItem>
                )}
              />
            </Stack>
          </fieldset>
        </form>
      </DialogBody>
      <DialogFooter className='mt-9'>
        <DialogClose asChild>
          <Button variant='text' disabled={isSubmitting}>
            {t('global.ui.buttons.cancel')}
          </Button>
        </DialogClose>
        <Button
          form='request-compliance-data-form'
          type='submit'
          disabled={!isSubmitButtonEnabled}
          loading={isSubmitting}
        >
          {requestSent
            ? t('shared.complianceFrameworks.dialog.buttonLabels.submitted')
            : t('shared.complianceFrameworks.dialog.buttonLabels.submit')}
        </Button>
      </DialogFooter>
    </FormProvider>
  );
};

const CustomFormLabel: FC<PropsWithChildren> = ({ children }) => {
  const { error } = useFormField();

  return (
    <FormLabel
      className={cn('text-[.75rem] font-medium leading-4 text-text-secondary md:text-[.8125rem] md:leading-5', {
        'text-error': error,
      })}
    >
      {children}
    </FormLabel>
  );
};

const CustomFormMessage: FC<PropsWithChildren> = ({ children }) => (
  <FormMessage className='typography-inputLabel mt-0 text-error'>{children}</FormMessage>
);
