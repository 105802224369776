type DataLabelsRecord = Record<string, unknown>;

const isDataLabelsRecord = (input: unknown): input is DataLabelsRecord => {
  return typeof input === 'object';
};

export class UnexpectedMissingDataError extends Error {
  constructor(message: string, options?: ErrorOptions);

  constructor(message: { dataLabel: string }, options?: ErrorOptions);

  constructor(message: { dataLabels: DataLabelsRecord }, options?: ErrorOptions);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(maybeMessage: any, options?: ErrorOptions) {
    if (typeof maybeMessage === 'string') {
      super(maybeMessage, options);
    } else if (isDataLabelsRecord(maybeMessage.dataLabels)) {
      const missingData: DataLabelsRecord = {};

      Object.entries(maybeMessage.dataLabels).forEach(([key, value]) => {
        // eslint-disable-next-line security/detect-object-injection
        if (value === 'undefined') missingData[key] = '<undefined>';
        // eslint-disable-next-line security/detect-object-injection
        if (value === 'null') missingData[key] = null;
      });

      super(
        `The following items are missing: ${JSON.stringify(
          missingData,
        )}. This is a bug, since this code should be unreachable.`,
        options,
      );
    } else {
      super(`No ${maybeMessage.dataLabel} received. This is a bug, since this code should be unreachable.`, options);
    }

    this.name = 'UnexpectedMissingDataError';
  }
}
