export type BuildPathOptions = {
  pathParams?: Record<string, string | number>;
  queryParams?: Record<string, string | number | null | undefined>;
};

export const buildPath = (path: string, { pathParams, queryParams }: BuildPathOptions | undefined = {}) => {
  const pathInterpolated = !pathParams
    ? path
    : Object.entries(pathParams).reduce((acc, [key, value]) => {
        return acc.replace(`:${key}`, `${value}`);
      }, path);

  if (queryParams) {
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      searchParams.append(key, `${value ?? ''}`);
    });

    return `${pathInterpolated}?${searchParams.toString()}`;
  }

  return pathInterpolated;
};
