import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { FC, forwardRef, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { cn } from '../utils';

export type DropdownProps = DropdownMenuPrimitive.DropdownMenuProps;

export const Dropdown: FC<DropdownProps> = (props) => <DropdownMenuPrimitive.Root modal={false} {...props} />;

export type DropdownTriggerProps = DropdownMenuPrimitive.DropdownMenuTriggerProps;

export const DropdownTrigger = forwardRef<HTMLButtonElement, DropdownTriggerProps>(
  ({ children, className, onClick, ...delegated }, ref) => {
    const styles = cn('outline-none', className);

    return (
      <DropdownMenuPrimitive.Trigger
        ref={ref}
        className={styles}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
        {...delegated}
      >
        {children}
      </DropdownMenuPrimitive.Trigger>
    );
  },
);
DropdownTrigger.displayName = 'DropdownTrigger';

export type DropdownContentProps = DropdownMenuPrimitive.DropdownMenuContentProps & {
  /**
   * Determines whether to add an arrow to the dropdown menu.
   *
   * @default false
   */
  withArrow?: boolean;
};

export const DropdownContent = forwardRef<HTMLDivElement, DropdownContentProps>(
  ({ children, className, withArrow, side, ...delegated }, ref) => {
    const styles = cn(
      'z-dialog rounded-md bg-white-100 py-2 elevation-1',
      'animate-in fade-in-0',
      {
        'slide-in-from-bottom-1': side === 'top',
        'slide-in-from-right-1': side === 'left',
        'slide-in-from-left-1': side === 'right',
        'slide-in-from-top-1': side === 'bottom' || side === undefined,
      },
      className,
    );

    return (
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content ref={ref} className={styles} side={side} {...delegated}>
          {children}
          {withArrow && <DropdownMenuPrimitive.Arrow className='fill-white-100' />}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    );
  },
);
DropdownContent.displayName = 'DropdownContent';

export type DropdownItemProps = DropdownMenuPrimitive.DropdownMenuItemProps & {
  leftAdornment?: ReactNode;
};

export const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>(
  ({ asChild, leftAdornment, children, className, onClick, disabled, ...delegated }, ref) => {
    const styles = cn(
      'typography-body1 text-text-primary cursor-pointer px-4 py-1.5 outline-none flex flex-row items-center gap-2',
      'data-[highlighted]:bg-neutral-black-4',
      'data-[disabled]:text-text-disabled data-[disabled]:hover:bg-transparent data-[disabled]:cursor-not-allowed',
      className,
    );

    const Comp = asChild ? (Slot as typeof DropdownMenuPrimitive.Item) : DropdownMenuPrimitive.Item;

    return (
      <Comp
        ref={ref}
        className={styles}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();

          if (!disabled) {
            onClick?.(e);
          }
        }}
        {...delegated}
      >
        {leftAdornment && <span className={disabled ? '' : 'text-text-secondary'}>{leftAdornment}</span>}
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);
DropdownItem.displayName = 'DropdownItem';

export type DropdownItemLinkProps = DropdownItemProps & LinkProps;

export const DropdownItemLink = forwardRef<HTMLDivElement, DropdownItemLinkProps>(
  ({ children, className, to, ...delegated }, ref) => {
    const styles = cn('hover:bg-neutral-black-4', className);

    if (delegated.disabled) {
      return (
        <DropdownItem ref={ref} className={styles} {...delegated}>
          {children}
        </DropdownItem>
      );
    }

    return (
      <DropdownItem asChild ref={ref} className={styles} {...delegated}>
        <Link to={to} role='menuitem'>
          {children}
        </Link>
      </DropdownItem>
    );
  },
);
DropdownItemLink.displayName = 'DropdownItemLink';

export type DropdownSeparatorProps = DropdownMenuPrimitive.MenuSeparatorProps;

export const DropdownSeparator = forwardRef<HTMLDivElement, DropdownSeparatorProps>(
  ({ className, ...delegated }, ref) => {
    const styles = cn('h-[1px] bg-divider my-2', className);

    return <DropdownMenuPrimitive.Separator ref={ref} className={styles} {...delegated} />;
  },
);
DropdownSeparator.displayName = 'DropdownSeparator';
