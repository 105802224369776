import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCCardSectionHeader,
  NCFactCapsule,
  printLatestDate,
} from '@/components';
import { useIsProjectCalculating } from '@/pages/shared/hooks/useIsProjectCalculating';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

export const BiodiversityStatsTile = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const biodiversityLatestAnalysis = getFact<number>(R1FactType.r1_biodiversity_latest_analysis);
  const biodiversityIncreaseSincePreviousAnalysis = getFact<number>(
    R1FactType.r1_biodiversity_buffer_zone_pct_previous_1y_uplift_change,
  );

  const isProjectCalculating = useIsProjectCalculating();

  const biodiversityLatestAnalysisLastMeasuredDate = printLatestDate([biodiversityLatestAnalysis?.measured_at]);
  const biodiversityIncreaseSincePreviousAnalysisLastMeasuredDate = printLatestDate([
    biodiversityIncreaseSincePreviousAnalysis?.measured_at,
  ]);

  const lastMeasuredDate = printLatestDate([
    biodiversityLatestAnalysis?.measured_at,
    biodiversityIncreaseSincePreviousAnalysis?.measured_at,
  ]);

  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='biodiversity-stats-tile'>
      <div className='flex flex-col gap-8 lg:gap-4'>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.details.biodiversity.labels.biodiversityZone')}
          supplementaryInfo={!isProjectCalculating && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
          popover={{
            title: t('shared.ncaDetail.details.biodiversity.explainers.biodiversityZone.title'),
            body: t('shared.ncaDetail.details.biodiversity.explainers.biodiversityZone.body'),
          }}
        />
        <div className='flex flex-col gap-8 lg:flex-row lg:justify-between lg:gap-0'>
          <NCFactCapsule
            label={latestAnalysisTranslation}
            capsuleIconProps={{
              icon: getNCChangeIcon(biodiversityLatestAnalysis),
              variant: getNCChangeIconVariant(biodiversityLatestAnalysis),
            }}
            fact={biodiversityLatestAnalysis}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
                date: biodiversityLatestAnalysisLastMeasuredDate,
              }),
            }}
          />
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.sincePreviousAnalysis')}
            capsuleIconProps={{
              icon: getNCChangeIcon(biodiversityIncreaseSincePreviousAnalysis),
              variant: getNCChangeIconVariant(biodiversityIncreaseSincePreviousAnalysis),
            }}
            fact={biodiversityIncreaseSincePreviousAnalysis}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.sincePreviousAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.sincePreviousAnalysis.body', {
                date: biodiversityIncreaseSincePreviousAnalysisLastMeasuredDate,
              }),
            }}
          />
        </div>
      </div>
    </Stack>
  );
};
