import { Link as MuiLink, LinkProps as MuiLinkProps, styled } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import _merge from 'lodash/merge';
import React from 'react';

const StyledTypography = styled(MuiTypography)<TypographyProps>(() => ({ whiteSpace: 'pre-line' }));

const StyledLink = styled(MuiLink)<MuiLinkProps>(() => ({ cursor: 'pointer' }));

export type HeadingVariant =
  | 'h1'
  | 'h1-bold'
  | 'h2'
  | 'h2-bold'
  | 'h3'
  | 'h3-bold'
  | 'h4'
  | 'h4-bold'
  | 'h5'
  | 'h5-bold'
  | 'h6'
  | 'h6-bold';

export interface HeadingProps extends Omit<TypographyProps, 'variant'> {
  variant: HeadingVariant;
}

const defaultHeadingProps: TypographyProps = { color: 'text.primary' };

const getHeadingStyleProps = (
  variant: HeadingVariant,
): { variant: Variant | undefined; fontWeight: string | number | undefined } | Record<string, unknown> => {
  switch (variant) {
    case 'h1':
      return {
        variant: 'h1',
        fontWeight: 400,
      };
    case 'h1-bold':
      return {
        variant: 'h1',
        fontWeight: 700,
      };
    case 'h2':
      return {
        variant: 'h2',
        fontWeight: 400,
      };
    case 'h2-bold':
      return {
        variant: 'h2',
        fontWeight: 700,
      };
    case 'h3':
      return {
        variant: 'h3',
        fontWeight: 400,
      };
    case 'h3-bold':
      return {
        variant: 'h3',
        fontWeight: 700,
      };
    case 'h4':
      return {
        variant: 'h4',
        fontWeight: 400,
      };
    case 'h4-bold':
      return {
        variant: 'h4',
        fontWeight: 700,
      };
    case 'h5':
      return {
        variant: 'h5',
        fontWeight: 400,
      };
    case 'h5-bold':
      return {
        variant: 'h5',
        fontWeight: 700,
      };
    case 'h6':
      return {
        variant: 'h6',
        fontWeight: 400,
      };
    case 'h6-bold':
      return {
        variant: 'h6',
        fontWeight: 700,
      };
    default:
      return {};
  }
};

export const Heading = ({ variant, ...props }: HeadingProps) => (
  <StyledTypography {...getHeadingStyleProps(variant)} {...defaultHeadingProps} {...props} />
);

export type TextVariant = 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'overline' | 'caption' | 'helper';

export interface TextProps extends Omit<TypographyProps, 'variant'> {
  variant: TextVariant;
}

export const Text = ({ variant, ...props }: TextProps) => <StyledTypography variant={variant} {...props} />;

export type LinkVariant = 'link1' | 'link2';

export interface LinkProps extends Omit<MuiLinkProps, 'variant'> {
  variant: LinkVariant;
}

const defaultLinkProps: MuiLinkProps = { color: 'primary.main' };

const getLinkStyle = (variant: LinkVariant): SxProps<Theme> => {
  switch (variant) {
    case 'link1':
      return {
        fontSize: 16,
      };
    case 'link2':
      return {
        fontSize: 14,
      };
    default:
      return {};
  }
};

export const Link = ({ variant, sx = {}, ...props }: LinkProps) => (
  <StyledLink {...defaultLinkProps} sx={_merge(getLinkStyle(variant), sx)} {...props} />
);
