import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Footer } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';

import { OnboardingNavbar } from './OnboardingNavbar/OnboardingNavbar';

type OnboardingPageVariant = 'standard' | 'wide' | 'narrow';

const variantMaxWidths: Record<OnboardingPageVariant, number> = {
  narrow: 526,
  standard: 728,
  wide: 1122,
};

interface OnboardingPageProps extends PropsWithChildren {
  variant?: OnboardingPageVariant;
}

export const OnboardingPage: React.FC<OnboardingPageProps> = ({ variant = 'standard', children }) => {
  const isLargeScreen = useScreenSize() === 'large';

  return (
    <Box
      height='100vh'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      data-testid='onboarding-page-layout'
    >
      <Box display='flex' flexDirection='column' flex={1}>
        <OnboardingNavbar />
        <Box
          component='main'
          sx={{
            // eslint-disable-next-line security/detect-object-injection
            maxWidth: variantMaxWidths[variant],
            mb: { xs: 5, md: 8 },
            mt: { xs: 5, md: 6 },
            mx: 'auto',
            px: { xs: 1, md: 0 },
            textAlign: 'center',
            width: '100%',
            flex: 1,
          }}
        >
          {children}
        </Box>
      </Box>
      {isLargeScreen && <Footer />}
    </Box>
  );
};
