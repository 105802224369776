import { Stack, StackProps } from '@landler/tw-component-library';
import React, { HTMLAttributes, PropsWithChildren } from 'react';

export const Section = Stack;

export const SectionHeading = (props: HTMLAttributes<HTMLDivElement>) => {
  return <div className='typography-overline mb-4 border-b border-divider pb-4' {...props} />;
};

export const SectionContent = (props: StackProps) => <Stack spacing={4} {...props} />;

type SectionItemProps = PropsWithChildren<{
  label: string;
  value: string;
  ActionButton?: React.ReactNode;
}>;

export const SectionItem: React.FC<SectionItemProps> = ({ label, value, ActionButton, children }) => {
  return (
    <Stack spacing={2} data-testid={`section-item-${label}`}>
      <span className='typography-inputLabel text-text-secondary'>{label}</span>
      <Stack>
        <Stack direction='row' className='items-center justify-between'>
          <span>{value}</span>
          {ActionButton}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};
