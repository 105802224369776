/* eslint-disable camelcase */
import { FormError, RestApiServerError } from './RestApiServerError';

export type BadRequestReponseData = {
  [field_errors: string]: string[] | undefined;
} & {
  non_field_errors?: string[];
};

export class RestApiBadRequestServerError extends RestApiServerError {
  constructor(response: { data: BadRequestReponseData }) {
    let errors: string[] = [];
    const formErrors: FormError[] = [];

    if (response.data) {
      const { non_field_errors, ...field_errors } = response.data;

      Object.entries(field_errors).forEach(([field, errorMessages]) => {
        if (errorMessages && errorMessages.length > 0) {
          formErrors.push({ field, messages: errorMessages });
        } else {
          /**
           * NOTE: needed for nested (1-level) formErrors like "user.firstname"
           * MUST BE REFACTORED and cleaned up!
           */
          // eslint-disable-next-line security/detect-object-injection
          Object.entries(field_errors[field] ?? {}).forEach(([innerField, innerErrorMessages]) => {
            if (Array.isArray(innerErrorMessages) && innerErrorMessages.length > 0) {
              formErrors.push({ field: `${field}.${innerField}`, messages: innerErrorMessages });
            }
          });
        }
      });

      errors = non_field_errors ?? [];
    }

    super(errors, formErrors, 400);
  }
}
