import { InfoPopover } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { cn } from 'tw-component-library/utils';

export type ChartLegendBarItem = {
  label: string;
  color: string;
  shape?: 'area' | 'line';
  popoverContent?: {
    title?: string;
    body: string;
  };
};

type ChartLegendBarProps = HTMLAttributes<HTMLDivElement> & {
  items: ChartLegendBarItem[];
};

export const ChartLegendBar: FC<ChartLegendBarProps> = ({ items, className, ...delegated }) => {
  const styles = cn(
    'flex flex-row items-center rounded-md bg-bg-light-grey p-2 gap-2 w-fit divide-x divide-divider',
    className,
  );

  return (
    <div className={styles} {...delegated}>
      {items.map(({ label, color, shape = 'area', popoverContent }) => (
        <div key={label} className='flex flex-row items-center gap-1 pl-2 first-of-type:pl-0 '>
          <div
            className={cn({
              'h-2 w-2 rounded-full': shape === 'area',
              'h-0.5 w-2 rounded': shape === 'line',
            })}
            style={{ backgroundColor: color }}
          />
          <span className='typography-caption text-text-secondary'>{label}</span>
          {popoverContent && <InfoPopover title={popoverContent.title} body={popoverContent.body} />}
        </div>
      ))}
    </div>
  );
};
