import React, { PropsWithChildren } from 'react';

type ErrorBoundaryProps = {
  fallback: React.ReactNode;
} & PropsWithChildren;

type ErrorBoundaryState = {
  hasError: boolean;
};

// NOTE: This must be a class component to be able to use getDerivedStateFromError. There is currently no way to use this with a functional component.
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
