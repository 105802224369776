import { Organization } from './organization';

export enum RoleEnum {
  admin = 'admin',
  editor = 'editor',
}

export enum MembershipStatus {
  invited = 'invited',
  accepted = 'accepted',
  declined = 'declined', // no relevant at all at this point
}

export enum MembershipWithOrganizationTypeEnum {
  buyer = 'buyer',
  land_steward = 'land_steward',
  other = 'other',
}

export type MembershipWithOrganization = {
  id: string;
  type: MembershipWithOrganizationTypeEnum;
  role: RoleEnum;
  status: MembershipStatus;
  organization: Organization;
};

/**
 * POST /api/v1/membership/{id}/send_invitation_mail/
 */
export const POST_MEMBERSHIP_BY_ID_SEND_INVITATION_MAIL_PATH = 'membership/{id}/send_invitation_mail';
export type PostMembershipByIdSendInvitationMailRequestPathVariables = {
  id: string;
};
export type PostMembershipByIdSendInvitationMailRequestQueryParams = undefined;
export type PostMembershipByIdSendInvitationMailRequestBodyData = undefined;
export type PostMembershipByIdSendInvitationMailSuccessDto = undefined;
export type PostMembershipByIdSendInvitationMailErrorDto = Error;
