export type UniqueLogEntries = {
  [key: string]: boolean;
};

export abstract class LoggingStrategy {
  abstract uniqueInfos: UniqueLogEntries;

  abstract uniqueWarns: UniqueLogEntries;

  abstract uniqueErrors: UniqueLogEntries;

  /** Logs an info. */
  abstract info(error: string | Error): void;

  /** Logs a warn only once, no matter how often they occur. */
  abstract infoOnce(error: string | Error): void;

  /** Logs a warn. */
  abstract warn(error: string | Error): void;

  /** Logs a warn only once, no matter how often they occur. */
  abstract warnOnce(error: string | Error): void;

  /** Logs an error. */
  abstract error(error: string | Error): void;

  /** Logs an error only once, no matter how often they occur. */
  abstract errorOnce(error: string | Error): void;
}
