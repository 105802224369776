import { ButtonLinkProps, cn, Stack, toast } from '@landler/tw-component-library';
import { area, centerOfMass } from '@turf/turf';
import { Feature, Polygon } from 'geojson';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker } from 'react-map-gl';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { usePostPlotValidatePolygon } from '@/api/rest/resources/plot';
import { Plot } from '@/api/rest/resources/types/plot';
import { PlotTypeIcon } from '@/components';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { DrawControls } from '@/pages/landsteward/pages/plot/components/DrawControls';
import { usePlotForm } from '@/pages/landsteward/pages/plot/hooks/usePlotForm';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';
import { squareMetersToHectares } from '@/utils/plot';

import { useDrawControl } from '../../../../hooks/useDrawControl';
import { LocationSearch } from './components/LocationSearch/LocationSearch';

export type DrawPolygonProps = {
  ForwardButton: (props: Omit<ButtonLinkProps, 'to'>) => JSX.Element;
  BackButton: (props: Omit<ButtonLinkProps, 'to'>) => JSX.Element;
  existingPlots: Plot[];
};

export const DrawPolygon = ({ BackButton, ForwardButton, existingPlots }: DrawPolygonProps) => {
  const { t } = useTranslation();
  const form = usePlotForm();
  const [plotType, plotName, polygon] = form.watch(['plotType', 'name', 'polygon']);

  if (!plotType) {
    throw new UnexpectedMissingDataError({ dataLabel: 'plotType' });
  }

  const validatePolygonMutation = usePostPlotValidatePolygon();

  const validatePolygon = (newFeature: Feature | null) => {
    if (newFeature) {
      validatePolygonMutation.mutate(
        { bodyData: { polygon: newFeature.geometry as Polygon } },
        {
          onError: (error) => {
            const isFormError = !!(error as RestApiBadRequestServerError)?.formErrors?.length;

            if (isFormError) return;

            toast({
              type: 'error',
              title: t('global.ui.toast.errorToastFallbackTitle'),
              description: t('global.ui.toast.errorToastFallbackDescription'),
            });
          },
        },
      );
    }
  };

  const { deleteCurrentPolygon, drawNewPolygon, currentFeature, renderDrawHint } = useDrawControl({
    initialPolygon: polygon,
    plotType,
    onFeatureUpdate: validatePolygon,
  });

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function validateInitialPolygon() {
      if (!polygon) return;
      validatePolygon({ geometry: polygon, type: 'Feature', properties: {} });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [polygon],
  );

  /**
   * Show the location dialog when adding the first
   * ever plot, ie., no existing plots or polygon.
   */
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(!existingPlots.length && !polygon);

  const featureCentroidCoordinates = currentFeature ? centerOfMass(currentFeature).geometry.coordinates : null;

  const areaSquareMeters = currentFeature ? area(currentFeature) : form.watch('areaSquareMeters') ?? 0;
  const formattedArea = useDisplayNumber(squareMetersToHectares(areaSquareMeters));

  const saveChanges = () => {
    form.setValue('polygon', currentFeature?.geometry as Polygon, { shouldDirty: true });
    form.setValue('areaSquareMeters', areaSquareMeters, { shouldDirty: true });
  };

  const validationErrors = (validatePolygonMutation.error as RestApiBadRequestServerError | null)?.formErrors?.reduce(
    (acc, curr) => {
      const result = [...acc];
      curr.messages.forEach((message) => result.push(message));

      return result;
    },
    [] as string[],
  );

  const hasValidationErrors = validatePolygonMutation.isError;
  const hasNoPolygon = !currentFeature;
  const disableSave = hasValidationErrors || hasNoPolygon;

  return (
    <>
      <DrawControls
        onDrawClick={drawNewPolygon}
        onDeleteClick={() => {
          deleteCurrentPolygon();
          validatePolygonMutation.reset();
        }}
        onLocationSearchClick={() => setIsLocationDialogOpen(true)}
        disableDelete={!currentFeature}
        disableDraw={!!currentFeature}
      />

      <LocationSearch open={isLocationDialogOpen} onOpenChange={(o) => setIsLocationDialogOpen(o)} />

      {renderDrawHint()}

      {featureCentroidCoordinates && (
        <Marker longitude={featureCentroidCoordinates[0]} latitude={featureCentroidCoordinates[1]}>
          <Stack className='items-center'>
            <PlotTypeIcon type={plotType} className='text-white-100' />
            {plotName && <span className='typography-caption text-white-100'>{plotName}</span>}
          </Stack>
        </Marker>
      )}

      <Stack
        data-testid='plot-info'
        direction='row'
        className={cn(
          'absolute bottom-10 left-0 right-0 mx-5 my-0 bg-white-100 p-2 pl-6 sm:mx-auto sm:w-[414px]',
          validationErrors ? 'rounded-3xl' : 'rounded-full',
        )}
      >
        <Stack direction='row' className='flex-1 items-center justify-between'>
          <Stack>
            <Stack direction='row' spacing={2} className='items-center'>
              <PlotTypeIcon type={plotType} />
              <span className={cn('typography-h3', validationErrors?.length ? 'text-error' : '')}>
                {formattedArea} ha
              </span>
            </Stack>
            {validationErrors?.map((error, index) => (
              <span key={index} className='typography-caption text-error'>
                {error}
              </span>
            ))}
          </Stack>
          <Stack direction='row' spacing={4}>
            <BackButton data-testid='back-button' />
            <ForwardButton
              disabled={disableSave}
              loading={validatePolygonMutation.isPending}
              onClick={saveChanges}
              data-testid='forward-button'
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
