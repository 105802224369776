import { useParams } from 'react-router-dom';

import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { usePlotForm } from '../../../hooks/usePlotForm';

export const usePreviousPath = () => {
  const form = usePlotForm();
  const { projectId, plotId } = useParams();

  if (!plotId || !projectId) {
    throw new UnexpectedMissingDataError({ dataLabels: { projectId, plotId } });
  }

  const previousPathFromFormValues = form.getValues().custom?.previousPath;

  return typeof previousPathFromFormValues === 'string'
    ? previousPathFromFormValues
    : buildPath(paths.landSteward.plot, { pathParams: { projectId, plotId } });
};
