import { SelectItemText } from '@radix-ui/react-select';
import { FieldError, FieldValues, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import {
  Alert,
  AlertBody,
  AlertIcon,
  AlertTitle,
  cn,
  ComboBox,
  Divider,
  Item,
  RiFlaskLine,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  Stack,
} from 'tw-component-library';

import { CropsEnum, PlotType } from '@/api/rest/resources/types/plot';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, PlotTypeIcon } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useSortedPlotTypes } from '@/hooks/useSortedPlotTypes';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { usePlotForm } from '@/pages/landsteward/pages/plot/hooks/usePlotForm';
import { paths } from '@/routing';
import { isSupportedPlotType } from '@/utils/plot';

export const PlotUsage = () => {
  const { t } = useTranslation();

  const project = useProject().data;
  const projectLandType = project.landtypes_allowed[0];

  const form = usePlotForm();
  const [plotType] = form.watch(['plotType']);

  const sortedPlotTypes = useSortedPlotTypes();

  if (!plotType) return <Navigate to={paths.landSteward.newPlot} />;

  const isPlotTypeSupported = isSupportedPlotType(plotType);

  const messages: Partial<Record<FieldError['type'], string>> = {
    required: t('landSteward.plot.typeOfLand.messages.shouldMatchProjectLandtype'),
    validate: t('landSteward.plot.typeOfLand.messages.shouldMatchProjectLandtype'),
  };

  const errorType = form.formState.errors?.plotType?.type;
  // eslint-disable-next-line security/detect-object-injection
  const errorMessage = typeof errorType === 'string' ? messages?.[errorType] ?? '' : '';

  return (
    <div className='w-full rounded-2xl bg-white-100 p-8 md:p-12' data-testid='plot-usage'>
      <span className='typography-overline mb-6 block text-text-secondary'>
        {t('landSteward.plot.specifyPlotUsageTitle')}
      </span>
      <span className='block max-w-xl'>{t('landSteward.plot.specifyPlotUsageExplainer')}</span>
      <Divider className='my-6' />
      <div className='flex flex-col gap-8 lg:flex-row'>
        <div className='lg:w-72'>
          <FormField
            name='plotType'
            rules={{ required: true, validate: (value) => value === projectLandType }}
            control={form.control}
            render={({ field: { ref, ...field } }) => (
              <FormItem>
                <FormLabel className='typography-overline mb-6 block text-text-secondary'>
                  {t('landSteward.plot.typeOfLand.title')}
                </FormLabel>
                <Select {...field}>
                  <FormControl>
                    <SelectTrigger
                      ref={ref}
                      leftAdornment={<PlotTypeIcon type={plotType} />}
                      data-testid='plot-usage-select'
                    />
                  </FormControl>
                  <FormMessage>{form.formState.errors.type?.message}</FormMessage>
                  <SelectContent>
                    <SelectGroup>
                      {sortedPlotTypes.supported.map((item) => (
                        <SelectItem key={item.type} value={item.type} leftAdornment={<PlotTypeIcon type={item.type} />}>
                          {item.translation}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectGroup>
                      {sortedPlotTypes.unsupported.map((item) => (
                        <SelectItem
                          disabled={true}
                          key={item.type}
                          value={item.type}
                          leftAdornment={<PlotTypeIcon className='text-[#00000061]' type={item.type} />}
                          className='flex-1 text-text-disabled'
                          asChild
                        >
                          <Stack direction='row' spacing={6} className='flex-1 justify-between'>
                            <SelectItemText>{item.translation}</SelectItemText>
                            <span>{t('global.misc.comingSoon')}</span>
                          </Stack>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <FormMessage>{errorMessage}</FormMessage>
              </FormItem>
            )}
          />
        </div>
        {plotType === PlotType.CROPLAND && <CropQuestion />}
      </div>
      {!isPlotTypeSupported && (
        <Alert className='mt-6'>
          <AlertIcon>
            <RiFlaskLine size={24} />
          </AlertIcon>
          <AlertTitle>{t('landSteward.plot.unsupportedInfoBox.title')}</AlertTitle>
          <AlertBody>{t('landSteward.plot.unsupportedInfoBox.message')}</AlertBody>
        </Alert>
      )}
    </div>
  );
};

const CROPS_MAX_COUNT = 3;

const CropQuestion = () => {
  const { t } = useTranslation();

  const isLargeScreen = useScreenSize() === 'large';
  const form = usePlotForm();

  const [crops] = form.watch(['crops']);
  const initialSelectedCrops = crops?.map((crop) => CropsEnum[crop as keyof typeof CropsEnum]) ?? [];

  const cropTypes: Item[] = (Object.keys(CropsEnum) as CropsEnum[]).map((crop) => ({
    value: crop,
    label: t(`global.crops.${crop}`),
  }));
  const initialCrops: Item[] = initialSelectedCrops.map((crop) => ({
    value: crop.toString(),
    label: t(`global.crops.${crop}`),
  }));

  const rules: Omit<
    RegisterOptions<FieldValues, 'crops'>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  > = {
    validate: (value: string[]) => value.length <= CROPS_MAX_COUNT,
  };

  const messages: Partial<Record<FieldError['type'], string>> = {
    validate: t('landSteward.plot.typeOfCrop.messages.maxOrEqualToNumber', {
      count: CROPS_MAX_COUNT,
    }),
  };

  const errorType = form.formState.errors?.crops?.type;
  // eslint-disable-next-line security/detect-object-injection
  const errorMessage = typeof errorType === 'string' ? messages?.[errorType] ?? '' : '';

  return (
    <div className='lg:w-72'>
      <FormField
        name='crops'
        control={form.control}
        rules={rules}
        render={({ field }) => (
          <FormItem className='flex before:typography-overline before:text-text-secondary'>
            <Stack direction={isLargeScreen ? 'row' : 'col'} className={cn('w-full flex-col items-start')}>
              <FormLabel className='typography-overline mb-6 block'>{t('landSteward.plot.typeOfCrop.title')}</FormLabel>
              <div>
                <FormControl>
                  <ComboBox
                    {...field}
                    data={cropTypes}
                    initialSelectedItems={initialCrops}
                    placeholder={t('landSteward.plot.typeOfCrop.prompt', { count: CROPS_MAX_COUNT })}
                  />
                </FormControl>
                <FormMessage>{errorMessage}</FormMessage>
              </div>
            </Stack>
          </FormItem>
        )}
      />
    </div>
  );
};
