import {
  Alert,
  AlertAction,
  AlertBody,
  AlertIcon,
  Button,
  ButtonLink,
  RiCloseLine,
  RiInformationLine,
  Stack,
} from '@landler/tw-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useCalcAvailableViewportHeight } from '@/hooks/useCalcAvailableViewportHeight';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { PlotBoundaryEditor } from '../../../components/PlotBoundaryEditor';
import { usePlotForm } from '../../../hooks/usePlotForm';
import { PlotCreationType } from '../../../types';
import { TitleBar } from '../components/TitleBar';

export const DrawBoundaryPage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const plots = usePlotsForProject().data.results;

  const { availableHeight } = useCalcAvailableViewportHeight();

  const historyState = useLocation().state as undefined | { entryMethod: PlotCreationType };
  const polygonEntryMethod = historyState?.entryMethod;
  const form = usePlotForm();

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function storePolgyonEntryMethod() {
      form.setValue('type', polygonEntryMethod);
    },
    [form, polygonEntryMethod],
  );

  const plotType = form.watch('plotType');

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  if (!plotType) {
    throw new UnexpectedMissingDataError({ dataLabel: 'plotType' });
  }

  return (
    <Stack className='bg-secondary-30' style={{ height: `${availableHeight}px` }}>
      <TitleBar currentStep={2} />
      <div className='h-full w-full'>
        {!plots ? null : (
          <PlotBoundaryEditor
            existingPlots={plots}
            ForwardButton={(props) => (
              <ButtonLink
                to={buildPath(paths.landSteward.newPlotQuestionnaire, { pathParams: { projectId } })}
                data-testid='save-button'
                {...props}
              >
                {t('global.ui.buttons.continue')}
              </ButtonLink>
            )}
            BackButton={(props) => (
              <Button onClick={() => navigate(-1)} variant='outline' {...props}>
                {t('global.ui.buttons.back')}
              </Button>
            )}
          >
            <DrawSinglePlotNotice />
          </PlotBoundaryEditor>
        )}
      </div>
    </Stack>
  );
};

const DrawSinglePlotNotice = () => {
  const { t } = useTranslation();

  const form = usePlotForm();
  const plotType = form.watch('plotType');

  const [isShowing, setIsShowing] = useState(true);

  const dismissNotice = () => {
    setIsShowing(false);
  };

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function dismissNoticeIn60Seconds() {
    const timeout = setTimeout(dismissNotice, 60 * 1000);
    return () => clearTimeout(timeout);
  }, []);

  if (!isShowing) return null;

  return (
    <div className='pointer-events-none absolute top-8 w-full pl-4 pr-24' data-testid='draw-single-plot-notice'>
      <Alert className='pointer-events-auto mx-auto max-w-2xl'>
        <AlertIcon>
          <RiInformationLine size={24} />
        </AlertIcon>
        <AlertBody>
          {t('landSteward.plot.new.drawBoundary.alert', { landType: t(`global.plotTypes.${plotType}`) })}
        </AlertBody>
        <AlertAction>
          <button className='w-fit' onClick={dismissNotice}>
            <RiCloseLine size={24} />
          </button>
        </AlertAction>
      </Alert>
    </div>
  );
};
