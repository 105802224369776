import { FC, PropsWithChildren } from 'react';

import { SignedInPageLayout } from '@/layout/signed-in-page';

import { useMenuItems } from '../hooks/useMenuItems';

type StackedPageLayoutProps = {
  withCustomContentContainer?: boolean;
};

export const StackedPageLayout: FC<PropsWithChildren<StackedPageLayoutProps>> = (props) => {
  const menuItems = useMenuItems();

  return <SignedInPageLayout data-testid='stacked-page-layout' items={menuItems} {...props} />;
};
