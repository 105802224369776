import React from 'react';
import { useTranslation } from 'react-i18next';

import { KycEnum } from '@/api/rest/resources/types/user';
import { exhaustivenessCheck } from '@/utils/exhaustivenessCheck';

import { AccountStatus } from '../../status/status';

type AccountKycStatusProps = {
  status: KycEnum;
};

export const AccountKycStatus: React.FC<AccountKycStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case KycEnum.success:
      return <AccountStatus variant='success'>{t('global.kyc.status.success')}</AccountStatus>;
    case KycEnum.in_progress:
      return <AccountStatus variant='in-progress'>{t('global.kyc.status.inProgress')}</AccountStatus>;
    case KycEnum.not_verified:
      return <AccountStatus variant='warning'>{t('global.kyc.status.notVerified')}</AccountStatus>;
    case KycEnum.failed:
      return <AccountStatus variant='error'>{t('global.kyc.status.error')}</AccountStatus>;
    default:
      return exhaustivenessCheck(status);
  }
};
