import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@landler/tw-component-library';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { User } from '@/api/rest/resources/types/user';

type ReinviteDialogContentProps = {
  member: User;
};

export const ReinviteDialogContent: FC<ReinviteDialogContentProps> = ({ member }) => {
  const { t } = useTranslation();

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className='text-success'>{t('shared.account.team.reinviteDialog.title')}</DialogTitle>
        <DialogDescription className='whitespace-pre-line'>
          <Trans
            i18nKey='shared.account.team.reinviteDialog.description'
            values={{ email: member.email }}
            components={{
              strong: <span className='typography-body1Semibold' />,
            }}
          />
        </DialogDescription>
      </DialogHeader>
      <DialogFooter className='justify-center md:justify-end'>
        <DialogClose asChild>
          <Button>{t('global.ui.buttons.ok')}</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};
