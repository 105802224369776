import { useTranslation } from 'react-i18next';

import { GrasslandGrassType, GrasslandOrganicFertilizerType, PlotType } from '@/api/rest/resources/types/plot';
import { usePlotForm } from '@/pages/landsteward/pages/plot/hooks/usePlotForm';

import { Question, QuestionList, RadioForm, SelectForm, TextForm } from './QuestionnaireForm';

export const GrasslandQuestionnaire = () => {
  const { t } = useTranslation();

  const form = usePlotForm();
  const plotType = form.watch('plotType');

  if (plotType !== PlotType.GRASSLAND) {
    throw Error(`Invalid plotType received: ${plotType}. Expected: ${PlotType.GRASSLAND}`);
  }

  const grasslandTypes = [
    { value: GrasslandGrassType.PERENNIAL_GRASS, label: t(`question.grassland_grass_type.perennial_grass`) },
    { value: GrasslandGrassType.GRASS_CLOVER_MIXTURE, label: t(`question.grassland_grass_type.grass_clover_mixture`) },
    { value: GrasslandGrassType.ALFALFA, label: t(`question.grassland_grass_type.alfalfa`) },
  ];

  const fertilizerTypes = [
    { value: GrasslandOrganicFertilizerType.NONE, label: t('question.grassland_organic_fertilizer_type.none') },
    { value: GrasslandOrganicFertilizerType.SLURRY, label: t('question.grassland_organic_fertilizer_type.slurry') },
    {
      value: GrasslandOrganicFertilizerType.FORCED_AERIATION_COMPOST_OR_MANURE,
      label: t('question.grassland_organic_fertilizer_type.forced_aeriation_compost_or_manure'),
    },
    {
      value: GrasslandOrganicFertilizerType.NON_FORCED_AERIATION_COMPOST_OR_MANURE,
      label: t('question.grassland_organic_fertilizer_type.non_forced_aeriation_compost_or_manure'),
    },
  ];

  return (
    <div>
      <div data-testid='grassland-questionnaire'>
        <QuestionList>
          <Question
            name='grassland_grass_type'
            label={t('question.grassland_grass_type.label')}
            rules={{ required: true }}
            messages={{
              // eslint-disable-next-line sonarjs/no-duplicate-string
              required: t('global.ui.form.input.required'),
            }}
            render={({ field }) => <SelectForm items={grasslandTypes} field={field} />}
          />

          <Question
            name='grassland_converted_to_permanent_in_last_20y'
            label={t('question.grassland_converted_to_permanent_in_last_20y.label')}
            rules={{ validate: (v) => v != null }}
            messages={{
              validate: t('global.ui.form.input.required'),
            }}
            render={({ field }) => (
              <RadioForm
                field={field}
                items={[
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  { label: t('question.radio.option.yes'), value: true },
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  { label: t('question.radio.option.no'), value: false },
                ]}
              />
            )}
          />

          <Question
            name='grassland_regularly_exposed_to_backwater'
            label={t('question.grassland_regularly_exposed_to_backwater.label')}
            rules={{ validate: (v) => v != null }}
            messages={{
              validate: t('global.ui.form.input.required'),
            }}
            render={({ field }) => (
              <RadioForm
                field={field}
                items={[
                  { label: t('question.radio.option.yes'), value: true },
                  { label: t('question.radio.option.no'), value: false },
                ]}
              />
            )}
          />

          <Question
            label={t('question.grassland_subject_to_water_shortages.label')}
            name='grassland_subject_to_water_shortages'
            rules={{ validate: (v) => v != null }}
            messages={{
              validate: t('global.ui.form.input.required'),
            }}
            render={({ field }) => (
              <RadioForm
                field={field}
                items={[
                  { label: t('question.radio.option.yes'), value: true },
                  { label: t('question.radio.option.no'), value: false },
                ]}
              />
            )}
          />

          <Question
            label={t('question.grassland_organic_fertilizer_type.label')}
            name='grassland_organic_fertilizer_type'
            rules={{ required: true }}
            messages={{
              required: t('global.ui.form.input.required'),
            }}
            render={({ field }) => <SelectForm field={field} items={fertilizerTypes} />}
          />

          <Question
            label={t('question.grassland_num_of_mechanical_events_per_year.label')}
            name='grassland_num_of_mechanical_events_per_year'
            rules={{ required: true, max: 10, min: 0 }}
            messages={{
              required: t('global.ui.form.input.required'),
              min: t('global.ui.form.input.minOrEqualToNumber', {
                min: '0',
              }),
              max: t('global.ui.form.input.maxOrEqualToNumber', {
                max: '10',
              }),
            }}
            render={({ field }) => (
              <TextForm
                field={field}
                type='number'
                className='max-w-[250px]'
                data-testid='grassland_num_of_mechanical_events_per_year'
              />
            )}
          />

          <Question
            label={t('question.grassland_biodiversity_livestock_units.label')}
            name='grassland_biodiversity_livestock_units'
            rules={{ required: true, min: 0 }}
            messages={{
              required: t('global.ui.form.input.required'),
              min: t('global.ui.form.input.minOrEqualToNumber', {
                min: '0',
              }),
            }}
            render={({ field }) => (
              <TextForm
                field={field}
                type='number'
                step={0.1}
                className='max-w-[250px]'
                data-testid='grassland_biodiversity_livestock_units'
              />
            )}
          />
          <span className='text-text-secondary'>{t('global.ui.form.requiredHint')}</span>
        </QuestionList>
      </div>
    </div>
  );
};
