/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/cognitive-complexity */
import { useCombobox, useMultipleSelection } from 'downshift';
import { forwardRef, HTMLAttributes, InputHTMLAttributes, useEffect, useMemo, useState } from 'react';

import { RiArrowDownSFill, RiCheckLine } from '../Icon/Icon';
import { cn } from '../utils';

const isSelectedItem = (items: Item[], checkItem: Item) => {
  return items.some((item: Item) => item.value.toLowerCase() === checkItem.value.toLowerCase());
};

const getFilteredItems = (items: Item[], inputValue: string) => {
  return items.filter((item: Item) => item.value.toLowerCase().includes(inputValue.toLowerCase()));
};

let getPrimitiveInputProps: any;
let getPrimitiveDropdownProps: any;

let getPrimitiveItemProps: any;
let getPrimitiveMenuProps: any;
let getPrimitiveToggleButtonProps: any;
let getPrimitiveLabelProps: any;

export type ComboBoxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  data: Item[];
  label?: string;
  placeholder?: string;
  initialSelectedItems?: Item[];
  intialInputValue?: string;
  onChange?: (items: string[]) => void;
};

export const ComboBox = forwardRef<HTMLInputElement, ComboBoxProps>(
  (
    { data, label = '', initialSelectedItems = [], intialInputValue = '', placeholder = '', onChange, ...props },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(intialInputValue);
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

    const items = useMemo(() => getFilteredItems(data, inputValue), [data, inputValue]);

    useEffect(() => {
      onChange?.(selectedItems.map((item) => item?.value));
    }, [selectedItems, onChange]);

    const { getDropdownProps } = useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            if (newSelectedItems) {
              setSelectedItems(newSelectedItems);
            }
            break;
          default:
            break;
        }
      },
    });
    getPrimitiveDropdownProps = getDropdownProps;

    const { isOpen, getToggleButtonProps, getLabelProps, getInputProps, getMenuProps, getItemProps } = useCombobox({
      items,
      itemToString(item) {
        return item ? item.label : '';
      },
      defaultHighlightedIndex: 0,
      selectedItem: null,
      inputValue,
      stateReducer(state, actionAndChanges) {
        const { changes, type } = actionAndChanges;

        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true,
            };
          default:
            return changes;
        }
      },
      onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (newSelectedItem) {
              if (isSelectedItem(selectedItems, newSelectedItem)) {
                setSelectedItems(selectedItems.filter((item) => item.value !== newSelectedItem.value));
              } else {
                setSelectedItems([...selectedItems, newSelectedItem]);
              }
              setInputValue('');
            }
            break;

          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(newInputValue ?? '');

            break;
          default:
            break;
        }
      },
    });
    getPrimitiveMenuProps = getMenuProps;
    getPrimitiveItemProps = getItemProps;
    getPrimitiveToggleButtonProps = getToggleButtonProps;
    getPrimitiveLabelProps = getLabelProps;
    getPrimitiveInputProps = getInputProps;

    return (
      <div className='flex w-full flex-col'>
        {label && (
          <ComboBoxLabel>
            <span>{label}</span>
          </ComboBoxLabel>
        )}
        <ComboBoxTrigger
          ref={ref}
          {...props}
          isOpen={isOpen}
          value={selectedItems.length > 0 ? selectedItems.map((item: Item) => item?.label).join(', ') : undefined}
          placeholder={placeholder}
        />
        <ComboBoxContent className={!(isOpen && items.length) ? 'hidden' : undefined}>
          {isOpen &&
            items.map((item, index) => (
              <ComboBoxItem
                key={index}
                item={item}
                index={index}
                isSelected={isSelectedItem(selectedItems, item)}
              ></ComboBoxItem>
            ))}
        </ComboBoxContent>
      </div>
    );
  },
);
ComboBox.displayName = 'ComboBox';

export type ComboBoxTriggerProps = HTMLAttributes<HTMLInputElement> & {
  isOpen: boolean;
  value?: string;
  placeholder?: string;
};

export const ComboBoxTrigger = forwardRef<HTMLInputElement, ComboBoxTriggerProps>(
  ({ isOpen, value, placeholder, className, ...delegated }, ref) => {
    return (
      <div
        {...delegated}
        className={cn(
          'group flex w-full flex-row items-center rounded',
          'border border-outlined-border-23p p-4 hover:border-text-primary',
          !value && 'data-[placeholder]:text-text-disabled',
          'disabled:cursor-not-allowed disabled:text-text-disabled disabled:hover:border-outlined-border-23p',
          'data-[invalid=true]:border-error',
          'flex w-full flex-row justify-between sm:max-w-[350px]',
          className,
        )}
      >
        <input
          ref={ref}
          className={cn(
            'typography-body1 flex h-6 w-full overflow-ellipsis rounded text-text-primary',
            'focus:border-none focus:outline-none',
            'placeholder-text-primary focus:placeholder-text-disabled',
            !value && 'placeholder-text-disabled',
            'disabled:cursor-not-allowed disabled:border-text-disabled',
            'data-[invalid=true]:border-error',
            className,
          )}
          placeholder={value ?? placeholder}
          {...getPrimitiveInputProps(getPrimitiveDropdownProps({ preventKeyAction: isOpen }))}
        />
        <div className='self-center' {...getPrimitiveToggleButtonProps()}>
          <RiArrowDownSFill size={16} />
        </div>
      </div>
    );
  },
);
ComboBoxTrigger.displayName = 'ComboBoxTrigger';

export type ComboBoxLabelProps = HTMLAttributes<HTMLLabelElement>;
export const ComboBoxLabel = forwardRef<HTMLLabelElement, ComboBoxLabelProps>(
  ({ children, className, ...delegated }, ref) => {
    return (
      <label
        {...getPrimitiveLabelProps()}
        ref={ref}
        className={cn('typography-overline mb-6 block text-text-secondary', className)}
        {...delegated}
      >
        {children}
      </label>
    );
  },
);
ComboBoxLabel.displayName = 'ComboBoxLabel';

export interface Item {
  value: string;
  label: string;
}

export type ComboBoxItemProps = HTMLAttributes<HTMLLIElement> & {
  index: number;
  item: Item;
  isSelected: boolean;
};
export const ComboBoxItem = forwardRef<HTMLLIElement, ComboBoxItemProps>(
  ({ className, index, item, isSelected, ...delegated }, ref) => {
    const primitiveItemProps = getPrimitiveItemProps({ item, index });
    return (
      <li
        {...primitiveItemProps}
        ref={ref}
        className={cn(
          'typography-body2 relative flex cursor-default items-center py-2 pl-4 pr-10 text-left',
          'data-[highlighted]:bg-primary-12 data-[state=checked]:bg-neutral-black-8',
          'data-[disabled]:cursor-not-allowed data-[disabled]:text-text-disabled',
          className,
        )}
        {...delegated}
      >
        <span>{item.label}</span>
        {isSelected && (
          <span className='absolute right-3 top-0 inline-flex h-full items-center justify-center'>
            <RiCheckLine />
          </span>
        )}
      </li>
    );
  },
);
ComboBoxItem.displayName = 'ComboBoxItem';

export type ComboBoxContentProps = HTMLAttributes<HTMLUListElement>;
export const ComboBoxContent = forwardRef<HTMLUListElement, ComboBoxContentProps>(
  ({ className, children, ...delegated }, ref) => {
    return (
      <ul
        ref={ref}
        {...getPrimitiveMenuProps()}
        className={cn(
          'z-dialog max-h-80 overflow-x-hidden overflow-y-scroll rounded bg-white-100 animate-in fade-in-0 elevation-1',
          className,
        )}
        {...delegated}
      >
        {children}
      </ul>
    );
  },
);
ComboBoxContent.displayName = 'ComboBoxContent';
