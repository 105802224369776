import ReactGA from 'react-ga4';

import { GA_TRACKING_ID } from '@/config/constants';

import { GoogleAnalyticsEvent } from './GoogleAnalyticsEvents';

let isInitialized = false;

export const init = () => {
  if (GA_TRACKING_ID && !isInitialized) {
    ReactGA.initialize(GA_TRACKING_ID);

    // trigger tracking of initial page view
    ReactGA.send({ hitType: 'pageview', path: window.location.pathname + window.location.search });

    isInitialized = true;
  }
};

export const pageview = (path: string) => {
  if (!isInitialized) {
    return;
  }

  ReactGA.send({ hitType: 'pageview', page: path });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const event = ({ name, params }: { name: GoogleAnalyticsEvent; params?: any }) => {
  if (!isInitialized) {
    return;
  }

  ReactGA.event(name, params);
};
