import DrawPlotIllustration from '@assets/images/draw.svg';
import UploadIllustration from '@assets/images/upload.svg';
import { Button, cn, Container, RiArrowRightSLine, Stack, StackProps } from '@landler/tw-component-library';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useParams } from 'react-router-dom';

import { Footer } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { PlotCreationType } from '../../../types';
import { TitleBar } from '../components/TitleBar';

export const PickPolygonEntryMethodPage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  return (
    <Stack className='min-h-screen items-center md:justify-between' data-testid='pick-polygon-entry-method-page'>
      <TitleBar currentStep={1} />
      <Container contentWidth='md' className='items-center py-10 [text-wrap:_balance]'>
        <Stack spacing={4} className='mb-10'>
          <h1 className='typography-display2 text-center text-primary-100' data-cy='new-plot-title'>
            {t('landSteward.plot.new.pickPolygonEntryMethod.title')}
          </h1>
          <span className='text-center' data-cy='new-plot-subtitle'>
            {t('landSteward.plot.new.pickPolygonEntryMethod.subtitle')}
          </span>
        </Stack>

        <Stack className='flex-col items-stretch justify-center gap-6 md:flex-row md:gap-8'>
          <Card
            to={buildPath(paths.landSteward.newPlotDrawBoundary, { pathParams: { projectId } })}
            state={{ entryMethod: PlotCreationType.DRAW }}
            illustrationUrl={DrawPlotIllustration}
            title={t('landSteward.plot.new.pickPolygonEntryMethod.drawYourLand.title')}
            description={t('landSteward.plot.new.pickPolygonEntryMethod.drawYourLand.description')}
            buttonLabel={t('landSteward.plot.new.pickPolygonEntryMethod.drawYourLand.buttonLabel')}
            data-cy='new-plot-card-draw'
          />

          <Card
            to={buildPath(paths.landSteward.newPlotUpload, { pathParams: { projectId } })}
            state={{ entryMethod: PlotCreationType.UPLOAD }}
            illustrationUrl={UploadIllustration}
            title={t('landSteward.plot.new.pickPolygonEntryMethod.uploadFile.title')}
            description={t('landSteward.plot.new.pickPolygonEntryMethod.uploadFile.description')}
            buttonLabel={t('landSteward.plot.new.pickPolygonEntryMethod.uploadFile.buttonLabel')}
            data-cy='new-plot-card-upload'
          />
        </Stack>
      </Container>
      {isLargeScreen && <Footer />}
    </Stack>
  );
};

type CardProps = Omit<
  StackProps &
    LinkProps & {
      illustrationUrl: string;
      title: string;
      description: string;
      buttonLabel: string;
    },
  'children' | 'asChild'
>;

const Card: FC<CardProps> = (props) => {
  const isLargeScreen = useScreenSize() === 'large';

  if (isLargeScreen) return <CardLarge {...props} />;

  return <CardSmall {...props} />;
};

const CardLarge: FC<CardProps> = ({ className, illustrationUrl, title, description, buttonLabel, ...delegated }) => {
  return (
    <Link
      className={cn(
        'flex max-w-[316px] flex-1 flex-col overflow-hidden rounded-3xl bg-white-100',
        'transition-transform hover:scale-[1.02] active:scale-100',
        className,
      )}
      {...delegated}
    >
      <div
        className='h-[175px] bg-cover bg-center'
        style={{ backgroundImage: `url(${illustrationUrl})` }}
        data-cy='card-image'
      />
      <Stack spacing={12} className='flex-1 items-center justify-between px-8 pb-10 pt-6'>
        <Stack spacing={3}>
          <span className='typography-display4 text-center' data-cy='card-title'>
            {title}
          </span>
          <span className='typography-body2 text-center' data-cy='card-description'>
            {description}
          </span>
        </Stack>
        <Button data-cy='card-button'>{buttonLabel}</Button>
      </Stack>
    </Link>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CardSmall: FC<CardProps> = ({ className, illustrationUrl, title, description, buttonLabel: _, ...delegated }) => {
  return (
    <Link
      className={cn(
        'mx-auto flex w-full max-w-[728px] flex-1 items-stretch overflow-hidden rounded-2xl bg-white-100',
        'transition-transform hover:scale-[1.02] active:scale-100',
        className,
      )}
      {...delegated}
    >
      <div
        className='min-h-[118px] w-[114px] bg-cover bg-center'
        style={{ backgroundImage: `url(${illustrationUrl})` }}
      />
      <Stack direction='row' spacing={4} className='flex-1 items-center justify-between py-4 pl-6 pr-4'>
        <Stack spacing={3}>
          <span className='typography-display4'>{title}</span>
          <span className='typography-body2'>{description}</span>
        </Stack>
        <RiArrowRightSLine size={24} className='flex-shrink-0' />
      </Stack>
    </Link>
  );
};
