import { QueryClient } from '@tanstack/react-query';

import { isTestEnv } from '@/utils/isTestEnv';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: isTestEnv ? false : undefined,
    },
  },
});
