import { Button, RiContactsFill } from '@landler/tw-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KycEnum } from '@/api/rest/resources/types/user';
import { KycDialog } from '@/components/KycDialog/kyc-dialog';

import { InformationBox } from '../../information-box';

export type AccountVerificationBoxProps = {
  status: KycEnum;
};

export const AccountKycVerificationBox: React.FC<AccountVerificationBoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <InformationBox
      title={t('shared.account.verifyAccount.title')}
      data-cy='kyc'
      entries={[<Body key='verify-box-body' {...props} />]}
    />
  );
};

type BodyProps = AccountVerificationBoxProps;

const Body: React.FC<BodyProps> = ({ status }) => {
  const { t } = useTranslation();
  const [isKycDialogOpen, setIsKycDialogOpen] = useState(false);

  return (
    <>
      <KycDialog onChange={setIsKycDialogOpen} open={isKycDialogOpen} />
      <div className='flex flex-col items-center gap-5 sm:flex-row sm:gap-0'>
        <div className='flex flex-row items-center'>
          <div className='items-center justify-center rounded-full bg-neutral-black-4 p-3'>
            <RiContactsFill size={24} className='text-primary-100' />
          </div>
          <span className='typography-body2 ml-4 text-text-primary' data-cy='kyc-description'>
            {t('shared.account.verifyAccount.description')}
          </span>
        </div>
        <Button
          type='button'
          className='sm:ml-16'
          disabled={status === KycEnum.in_progress}
          data-cy='start-kyc-button'
          onClick={() => {
            setIsKycDialogOpen(true);
          }}
        >
          {t('shared.account.verifyAccount.button')}
        </Button>
      </div>
    </>
  );
};
