import { Outlet } from 'react-router-dom';

import { LandStewardPageLayout } from '@/pages/landsteward/layouts/LandStewardPage.layout';

export const LandstewardComplianceFrameworksPageWrapper = () => {
  return (
    <LandStewardPageLayout withCustomContentContainer>
      <Outlet />
    </LandStewardPageLayout>
  );
};
