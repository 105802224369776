import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECTS_PATH,
  GET_PROJECT_PATH,
  GetBuyerProjectsErrorDto,
  GetBuyerProjectsSuccessDto,
  GetProjectErrorDto,
  GetProjectSuccessDto,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';

type SuccessDto = GetProjectSuccessDto | GetBuyerProjectsSuccessDto;
type ErrorDto = GetProjectErrorDto | GetBuyerProjectsErrorDto;

export const useProjects = () => {
  const membershipType = useMembershipType();

  const queryParams = { demo_if_none_analysed_or_later: true };

  const queryFn = async () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward ? GET_PROJECT_PATH : GET_BUYER_PROJECTS_PATH,
      undefined,
      queryParams,
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'projects', queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
