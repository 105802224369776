import { useQueryClient } from '@tanstack/react-query';

import { usePostAnalyseProject } from '@/api/rest/resources/project';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

export const useAnalyseProject = () => {
  const projectId = useProjectId();
  const analyseProjectMutation = usePostAnalyseProject();

  const queryClient = useQueryClient();

  const submit = async () => {
    await analyseProjectMutation.mutateAsync({
      pathVariables: { id: projectId },
    });

    queryClient.resetQueries({ queryKey: ['land_steward'] });
  };

  const isSubmitting = analyseProjectMutation.isPending;

  return { submit, isSubmitting };
};
