/**
 * POST /api/v1/token/sign-out
 */
export const POST_TOKEN_SIGN_OUT_PATH = 'token/sign-out';
export type PostTokenSignOutRequestPathVariables = undefined;
export type PostTokenSignOutRequestQueryParams = undefined;
export type PostTokenSignOutRequestBodyData = {
  access: string;
  refresh: string;
};
export type PostTokenSignOutSuccessDto = {
  access: string;
  refresh: string;
};
export type PostTokenSignOutErrorDto = Error;
