import { Loader } from '@landler/tw-component-library';
import { NavigationState } from 'globals';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';

import { ConfirmMailChangeState, useConfirmMailChange } from './hooks/useConfirmMailChange';

export const ConfirmMailChangePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const verficationToken = searchParams.get('token');
  const { accountEditEmailPath } = useAccountPageRoutes();

  const { state: confirmMailChangeState } = useConfirmMailChange(verficationToken);

  if (confirmMailChangeState === ConfirmMailChangeState.LOADING) {
    return <Loader />;
  }

  // NOTE: The prop type of state in <Navigate /> has the type any which is not ideal.
  // NOTE: The state type is overwritten for the useNavigate and useLocation hooks.
  // NOTE: It is not straght forward to overwrite the type for the Navigate component.
  // NOTE: So this is a quick solution so tsc complains if the state type changes and doesn't match anymore.
  const state: NavigationState = { confirmMailChangeState };

  return <Navigate to={accountEditEmailPath} state={state} replace />;
};
