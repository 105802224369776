import { ImgHTMLAttributes, ReactElement, useEffect, useState } from 'react';

import { shimmerStyle } from '../Shimmer/Shimmer';
import { cn } from '../utils';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  fallback?: string | ReactElement;
};

export const Image = ({ className, src, fallback, ...delegated }: ImageProps) => {
  const [image, setImage] = useState<ReactElement | string>(src);
  const [settled, setSettled] = useState(false);

  const handleLoadError = () => {
    if (!fallback) {
      setSettled(true);
      return;
    }

    setImage(fallback);
    setSettled(true);
  };

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function updateImageSrcWhenSrcChanged() {
      if (src !== image) {
        setImage(src);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [src],
  );

  if (typeof image !== 'string') {
    return image;
  }

  return (
    <div className={cn(className, !settled && shimmerStyle)}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        className={cn('opacity-0 transition-opacity duration-200', settled && 'opacity-100', className)}
        src={image}
        onLoad={() => setSettled(true)}
        onError={handleLoadError}
        {...delegated}
      />
    </div>
  );
};
