import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  RiArrowDownSLine,
  RiArrowUpSLine,
} from '@landler/tw-component-library';
import { FC, useState } from 'react';

import { languages, useLanguage } from '@/hooks/useLanguage';
import { ApplicationLanguage } from '@/types';

export interface LanguageSelectorProps {
  changeUserLanguage?: (language: ApplicationLanguage) => Promise<void>;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ changeUserLanguage }) => {
  const { changeLanguage, currentLanguage } = useLanguage({ changeUserLanguage });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown onOpenChange={(e) => setIsDropdownOpen(e)}>
      <DropdownTrigger asChild>
        <Button
          variant='text'
          size='medium'
          data-cy='language-selector-button'
          rightAdornment={isDropdownOpen ? <RiArrowUpSLine size={24} /> : <RiArrowDownSLine size={24} />}
        >
          {currentLanguage.nativeTranslation}
        </Button>
      </DropdownTrigger>
      <DropdownContent>
        {Object.entries(languages).map(([key, label]) => (
          <DropdownItem key={key} onSelect={() => changeLanguage(key)} data-cy={`language-selector-item-${key}`}>
            {label}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};
