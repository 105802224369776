import { useSuspenseQuery } from '@tanstack/react-query';

import { Project } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectKey } from './useProjectKey';

export const getProjectById = async (projectKey: string) => {
  const module = await import(`../mockData/${projectKey}/project.json`);

  return module.default as Project;
};

export const useProject = (id?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(id);

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey],
    queryFn: () => getProjectById(projectKey),
  });
};
