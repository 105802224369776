import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { paths } from '../../../routing';

export const RequireAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { isSignedIn } = useAuth();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  if (!isSignedIn) {
    const searchString = searchParams.toString();
    const queryString = searchString ? `?${searchString}` : '';
    return <Navigate to={paths.login} replace state={{ loginDeeplink: `${pathname}${queryString}` }} />;
  }

  return <>{children}</>;
};
