import { ReactComponent as WaterIcon } from '@assets/images/icons/water.svg';
import { useTranslation } from 'react-i18next';

import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
} from '@/components';
import { WaterExplainer } from '@/components/NCAExplainers/WaterExplainer';
import { COMPLIANCE_LOGOS_WATER } from '@/config/constants';

import { WaterCardGraphTile } from './GraphTile';
import { WaterCardStatsTile } from './StatsTile';

export const WaterCard = () => {
  const { t } = useTranslation();

  return (
    <NCCard data-cy='water-card' explainer={<WaterExplainer />}>
      <NCCardHeader icon={<WaterIcon className='text-natural-capital-water-dark' />} title={t('global.analysis.water')}>
        <NCCardAnalysisTypeToggle data-testid='water-toggle' data-cy='water-toggle' />
      </NCCardHeader>
      <NCCard2SectionLayout>
        <WaterCardGraphTile />
        <WaterCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_WATER} />
    </NCCard>
  );
};
