import { Slot } from '@radix-ui/react-slot';
import React, { forwardRef, HTMLAttributes } from 'react';

import { TAILWIND_SCALE_MULTIPLIER } from '../constants';
import { cn } from '../utils';

export interface StackProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Add a `gap` between items.
   *
   * If spacing is a number, it will use the Tailwind scale
   * (for eg., 5 == `20px`).
   * If spacing is a string, it will be used as-is.
   */
  spacing?: number | string;
  /**
   * Specify the `flex-direction`. Defaults to 'col' (vertical).
   */
  direction?: 'row' | 'col' | 'row-reverse' | 'col-reverse';
  /**
   * Centers the contents across the main and cross axes.
   */
  center?: boolean;
  /**
   * Centers the contents across the main axis (x-axis if direction is row,
   * y-axis if direction is col).
   */
  centerMain?: boolean;
  /**
   * Centers the contents across the cross axis (y-axis if direction is row,
   * x-axis if direction is col).
   */
  centerCross?: boolean;
  /**
   * Applies styling and forwards props to the immediate child without rendering
   * an element.
   */
  asChild?: boolean;
}

/**
 * Flex container for a stack of elements. Vertically stacked by default.
 */
export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ spacing, direction, center, centerMain, centerCross, className, asChild, style, ...delegated }, ref) => {
    const dir = direction ?? 'col';

    const styles = cn(
      'flex justify-start',
      {
        'flex-row': dir === 'row',
        'flex-col': dir === 'col',
        'flex-col-reverse': dir === 'col-reverse',
        'flex-row-reverse': dir === 'row-reverse',
        'items-center justify-center': center,
        'justify-center': centerCross,
        'items-center': centerMain,
      },
      className,
    );

    const Comp = asChild ? Slot : 'div';

    return (
      <Comp
        ref={ref}
        style={{ gap: resolveSpacing(spacing), ...style } as React.CSSProperties}
        {...delegated}
        className={styles}
      />
    );
  },
);
Stack.displayName = 'Stack';

const resolveSpacing = (input: StackProps['spacing']) => {
  if (!input) return undefined;

  if (typeof input === 'string') return input;

  return `${input * TAILWIND_SCALE_MULTIPLIER}px`;
};
