import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { User } from '@/api/rest/resources/types/user';
import { APP_VERSION, SENTRY_DSN } from '@/config/constants';

import { isSentryConfigured } from './isSentryConfigured';

let isInitialized = false;

export const init = () => {
  if (!isSentryConfigured()) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
    ],
    ignoreErrors: ['The server responded with a failure'],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: import.meta.env.SENTRY_ENVIRONMENT,
    release: APP_VERSION,
  });

  isInitialized = true;
};

export const setUser = (user: User | null) => {
  if (!isInitialized) {
    return;
  }

  // we only want to send the user's ID
  // as this information is sufficient to debug
  const sentryUser = user ? { id: user.id } : null;
  Sentry.setUser(sentryUser);
};
