import { useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const BiodiversityExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='biodiversity-explainer' data-cy='biodiversity-explainer-btn'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle data-cy='biodiversity-info-heading'>
          {t('shared.ncaDetail.details.biodiversity.explainers.howDoMeasureThis.headline')}
        </ExplainerTitle>
        <ExplainerBody data-cy='biodiversityZoneBody'>
          <ExplainerCopy
            title={t('shared.ncaDetail.details.biodiversity.explainers.howDoMeasureThis.title')}
            body={t('shared.ncaDetail.details.biodiversity.explainers.howDoMeasureThis.body')}
          />
        </ExplainerBody>
      </ExplainerContent>
    </Explainer>
  );
};
