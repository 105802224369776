import { Stack } from '@landler/tw-component-library';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { BiodiversityZoneChart, BiodiversityZoneChartData, CalculationInProgress, NoChartData } from '@/components';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

export const BiodiversityGraphTile = () => {
  const plot = usePlot().data;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });

  const biodiversityZonePercentHistoricGraph = getFact<BiodiversityZoneChartData[]>(
    R1FactType.r1_biodiversity_zone_percent_historic_graph,
  )?.value;

  /* variable == null checks for both null and undefined */
  const noChartData = biodiversityZonePercentHistoricGraph == null;

  if (noChartData) {
    if ([PlotStatusEnum.calculating, PlotStatusEnum.scheduled_for_analysis].includes(plot.status)) {
      return <CalculationInProgress />;
    }

    if (plot.status === PlotStatusEnum.analysed) {
      return <NoChartData />;
    }

    return <NoChartData variant='analysisPending' />;
  }

  return (
    <Stack
      spacing={2.5}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='biodiversity-graph-tile'
    >
      <BiodiversityZoneChart
        data={biodiversityZonePercentHistoricGraph}
        height={233}
        showTooltip={true}
        className='relative'
        data-testid='biodiversity-zone-chart'
      />
    </Stack>
  );
};
