import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RestApiServerError, RestApiServerErrorStatusCode } from '@/api/rest/resources/errors/RestApiServerError';
import { PostUserCompleteAccountDataSuccessDto } from '@/api/rest/resources/types/user';
import { usePostUserCompleteAccount, usePostUserCompleteAccountData } from '@/api/rest/resources/user';
import { PRIVACY_POLICY_VERSION } from '@/config/constants';
import { useAuth } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { delay, IDelay } from '@/utils/delay';

export enum PageState {
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
}

export enum ErrorPageState {
  ALREADY_ACTIVATED = 'ALREADY_ACTIVATED',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  NO_TOKEN_SUPPLIED = 'NO_TOKEN_SUPPLIED',
  ERROR = 'ERROR',
}

export interface BuyerCompleteAccountInputs {
  firstname: string;
  lastname: string;
  password: string;
  termsAndConditions: boolean;
  privacyPolicy: boolean;
}

export type PredefinedData = PostUserCompleteAccountDataSuccessDto;

type SuccessReturnValue = {
  pageState: PageState.SUCCESS;
  predefinedData: PredefinedData;
  handleCompleteAccount: (data: BuyerCompleteAccountInputs) => void;
  isSubmitting: boolean;
};

type ErrorReturnValue = {
  pageState: keyof typeof ErrorPageState;
  predefinedData?: PredefinedData | undefined;
  handleCompleteAccount?: undefined;
  isSubmitting?: undefined;
};

type LoadingReturnValue = {
  pageState: PageState.LOADING;
  predefinedData?: PredefinedData | undefined;
  handleCompleteAccount?: undefined;
  isSubmitting?: undefined;
};

type ReturnValue = SuccessReturnValue | ErrorReturnValue | LoadingReturnValue;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useBuyerCompleteAccountPage: () => ReturnValue = () => {
  const [searchParams] = useSearchParams();
  const completeAccountToken = searchParams.get('token');
  const [mutationError, setMutationError] = useState<RestApiServerError | Error | undefined>();

  const { signIn } = useAuth();

  const {
    error: predefinedError,
    status: predefinedStatus,
    data: predefinedData,
    mutate: completeAccountData,
  } = usePostUserCompleteAccountData();

  const { status: completeAccountStatus, mutate: completeAccount } = usePostUserCompleteAccount();

  useEffect(() => {
    let timer: IDelay | undefined;

    if (completeAccountToken) {
      // NOTE: Delaying the request has two purposes.
      // NOTE: 1. The loading spinner is displayed a little longer which makes the user experience better.
      // NOTE: 2. React strict mode mounts/unmounts each component twice. Without delaying the post request it would get fired twice.
      timer = delay(() =>
        completeAccountData({ bodyData: { token: completeAccountToken } }, { onError: setMutationError }),
      );
    }

    return () => {
      if (timer) {
        timer.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (error: Error) => {
    return onMutationError(error);
  };

  const onMutationError = (error: RestApiServerError | Error) => {
    if (!(error instanceof RestApiServerError)) {
      return { pageState: ErrorPageState.ERROR };
    }

    if (error.statusCode) {
      switch (error.statusCode) {
        case RestApiServerErrorStatusCode.BAD_REQUEST:
          return { pageState: ErrorPageState.INVALID };
        case RestApiServerErrorStatusCode.FORBIDDEN:
          return { pageState: ErrorPageState.EXPIRED };
        case RestApiServerErrorStatusCode.CONFLICT:
          return { pageState: ErrorPageState.ALREADY_ACTIVATED };
        default:
          return { pageState: ErrorPageState.ERROR };
      }
    }
    return { pageState: ErrorPageState.ERROR };
  };

  if (mutationError) {
    return handleError(mutationError);
  }

  if (predefinedStatus === 'error') {
    return handleError(predefinedError);
  }

  if (!completeAccountToken || completeAccountStatus === 'error') {
    return { pageState: ErrorPageState.ERROR };
  }

  if (predefinedStatus === 'pending' || predefinedStatus === 'idle') {
    return {
      pageState: PageState.LOADING,
      predefinedData,
    };
  }

  const handleCompleteAccount = async (data: BuyerCompleteAccountInputs) => {
    completeAccount(
      {
        bodyData: {
          token: completeAccountToken,
          firstname: data.firstname,
          lastname: data.lastname,
          password: data.password,
          // NOTE: This is supposed to be a string in case there are updated versions of the terms and conditions. E.g. "true v1.1"
          accepted_terms: data.termsAndConditions ? 'true' : 'false',
          accepted_privacy_policy: data.termsAndConditions ? PRIVACY_POLICY_VERSION : '',
          accepted_biometric_data_process: !!data.termsAndConditions,
          accepted_newsletter: false,
        },
      },
      {
        onSuccess: () => {
          signIn({
            email: predefinedData.email,
            password: data.password,
          }).catch((error) => {
            Logger.error(
              `Something unexpected happend while signing in after completing the account. "${error.message}"`,
            );
            setMutationError(error);
          });
        },
        onError: (error) => {
          Logger.error(`Something unexpected happend while completing the account. ${error.message}`);
          setMutationError(error);
        },
      },
    );
  };

  return {
    pageState: PageState.SUCCESS,
    predefinedData,
    handleCompleteAccount,
    // NOTE: This is still submitting when the user is signed in. The user gets redirected to the SignedInPage afterwards.
    isSubmitting: completeAccountStatus === 'pending' || completeAccountStatus === 'success',
  };
};
