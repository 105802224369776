import { Loader } from '@landler/tw-component-library';
import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';

import { useAuth } from '../../../../lib/auth';
import { paths } from '../../../routing';

export const SkipLoggedInUser: React.FC<PropsWithChildren> = ({ children }) => {
  const { isBooted, isSignedIn, authenticatedUser } = useAuth();

  if (!isBooted) return <Loader />;

  if (isSignedIn) {
    // NOTE: It should basically not be possible that the user is undefined when isSignedIn is true.
    // NOTE: Those states should be coupled and typescript should be able to infer that.
    // NOTE: This will be tackled in MVP-1158
    const userType = authenticatedUser.user?.membership.type;
    const initialPath =
      userType === MembershipWithOrganizationTypeEnum.buyer ? paths.buyer.projects : paths.landSteward.projects;

    return <Navigate to={initialPath} replace />;
  }

  return <>{children}</>;
};
