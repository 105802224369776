import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useUser } from '@/lib/auth';
import { paths } from '@/routing';
import { RequireAuth } from '@/routing/components/protections/require-auth/RequireAuth';

export const BuyerSignedInRoute: FC = () => {
  return (
    <RequireAuth>
      <RequireBuyerRole />
    </RequireAuth>
  );
};

const RequireBuyerRole: FC = () => {
  const user = useUser();

  const userType = user.membership.type;

  if (userType === MembershipWithOrganizationTypeEnum.land_steward) return <Navigate to={paths.root} replace />;

  if (userType !== MembershipWithOrganizationTypeEnum.buyer)
    throw new Error(`Expected "${MembershipWithOrganizationTypeEnum.buyer}", but got invalid user type "${userType}"`);

  return <Outlet />;
};
