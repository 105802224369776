import { Project } from '@/api/rest/resources/conservation';

import { useProjectKey } from './useProjectKey';

const getImage = ({ projectKey, name }: { projectKey: Project['id']; name: string }) => {
  return new URL(`../mockData/${projectKey}/${name}.png`, import.meta.url).href;
};

export const useStaticImage = (name: string) => {
  const projectKey = useProjectKey();

  return getImage({ projectKey, name });
};
