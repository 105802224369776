import { StorageStrategy } from './StorageStrategy';

export const LocalStorage: StorageStrategy = {
  setItem: (key: string, value: string): string | undefined => {
    localStorage.setItem(key, value);
    return localStorage.getItem(key) ?? undefined;
  },

  getItem: (key: string): string | undefined => {
    return localStorage.getItem(key) ?? undefined;
  },

  removeItem: (key: string): void => {
    localStorage.removeItem(key);
  },
};
