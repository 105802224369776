import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { GET_PLOT_BY_ID_PATH, GetPlotByIdErrorDto, GetPlotByIdSuccessDto } from '@/api/rest/resources/types/plot';

import { usePlotId } from '../../../../../../shared/hooks/usePlotId';

export const usePlot = () => {
  const plotId = usePlotId();

  return useSuspenseQuery<GetPlotByIdSuccessDto, GetPlotByIdErrorDto>({
    queryKey: ['land_steward', 'plot', plotId],
    queryFn: () => getRequest<GetPlotByIdSuccessDto>(GET_PLOT_BY_ID_PATH, { id: plotId }),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });
};
