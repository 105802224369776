import { isSentryConfigured } from '../isSentryConfigured';
import { ConsoleLogger } from './ConsoleLogger';
import { LoggingStrategy } from './LoggingStrategy';
import { NoopLogger } from './NoopLogger';
import { SentryLogger } from './SentryLogger';

const isDevelopment = ['localhost', 'development'].includes(process.env.NODE_ENV as string);
const isProduction = process.env.NODE_ENV === 'production';

const getLogger = () => {
  /**
   * Rules:
   * When in development, we only log to the console.
   * When in staging, log to sentry if available, fallback to console.
   * When in production, log to sentry if available, fallback to noop.
   */
  if (isDevelopment) return new ConsoleLogger();

  if (isProduction) return isSentryConfigured() ? new SentryLogger() : new NoopLogger();

  return isSentryConfigured() ? new SentryLogger() : new ConsoleLogger();
};

export const Logger: LoggingStrategy = getLogger();
