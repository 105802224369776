import { useForm, useFormContext } from 'react-hook-form';

import { LandQuestionnaireAnswers, Plot } from '@/api/rest/resources/types/plot';

import { GrasslandInputs, PlotCreationInputs, PlotInputs } from '../types';

export type CustomFormArgs = { previousPath?: string };

export type UploadPlotFormArgs = { draftPlots?: string[] };

export type DrawPlotFormArgs = { plot?: Partial<Plot>; questionnaire?: LandQuestionnaireAnswers<PlotInputs> };

export type useCreatePlotFormArgs = CustomFormArgs & PlotCreationInputs & DrawPlotFormArgs & UploadPlotFormArgs;

export const useCreatePlotForm = ({
  type,
  plot,
  questionnaire,
  draftPlots,
  previousPath,
}: useCreatePlotFormArgs | undefined = {}) => {
  return useForm<PlotInputs>({
    values: {
      type,
      plotType: plot?.type,
      name: plot?.name,
      polygon: plot?.polygon,
      areaSquareMeters: plot?.area,
      crops: plot?.crops,
      draftPlots: draftPlots ?? [],
      ...grasslandQuestionnaireForm(questionnaire),
      custom: {
        previousPath,
      },
    },
  });
};

export const grasslandQuestionnaireForm = (questionnaire?: GrasslandInputs) => {
  if (!questionnaire) return {};
  return {
    /** API sends back null as the default value for livestock units, if there has been no user input
     * this is to be sanitized to 0, the predetermined min value, before being used since null is not a valid value
     */
    grassland_biodiversity_livestock_units:
      questionnaire.grassland_biodiversity_livestock_units === null
        ? 0
        : questionnaire.grassland_biodiversity_livestock_units,
    grassland_converted_to_permanent_in_last_20y: questionnaire.grassland_converted_to_permanent_in_last_20y,
    /** API sends back NONE as the default value for grass type, if there has been no user input
     * this is to be sanitized to undefined/null before being used since NONE is not a valid grass type
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    grassland_grass_type: ['NONE'].includes(questionnaire.grassland_grass_type!)
      ? undefined
      : questionnaire.grassland_grass_type,
    grassland_num_of_mechanical_events_per_year: questionnaire.grassland_num_of_mechanical_events_per_year,
    grassland_organic_fertilizer_type: questionnaire.grassland_organic_fertilizer_type,
    grassland_regularly_exposed_to_backwater: questionnaire.grassland_regularly_exposed_to_backwater,
    grassland_subject_to_water_shortages: questionnaire.grassland_subject_to_water_shortages,
  };
};

export const usePlotForm = () => {
  return useFormContext<PlotInputs>();
};
