// This eslint rule can be disabled for the whole file because the type "any" is allowed by react-hook form
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, cn } from '@landler/tw-component-library';
import { PropsWithChildren } from 'react';
import { Control, Path, RegisterOptions, useController } from 'react-hook-form';

type ControlledCheckboxProps<T extends Record<string, any>> = {
  control: Control<T>;
  id: string;
  name: Path<T>;
  disabled?: boolean;
  rules?: Omit<RegisterOptions<T, Path<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  'data-testid'?: string;
  'data-cy'?: string;
} & PropsWithChildren;

export const ControlledCheckbox = <T extends Record<string, any>>({
  children,
  control,
  id,
  name,
  disabled,
  rules,
  'data-testid': dataTestId,
  'data-cy': dataCy,
}: ControlledCheckboxProps<T>) => {
  const { field, fieldState, formState } = useController({
    control,
    name,
    rules,
  });

  const isDisabled = disabled ?? formState.isSubmitting;

  return (
    <Checkbox
      id={id}
      hasError={!!fieldState.error}
      checked={field.value}
      disabled={isDisabled}
      onCheckedChange={(newValue: boolean) => {
        field.onChange(newValue);
      }}
      data-testid={fieldState.error ? `${dataTestId}-error` : dataTestId}
      data-cy={dataCy}
    >
      <label
        className={cn(
          'typography-body2 text-left',
          isDisabled && 'cursor-not-allowed text-text-disabled',
          fieldState.error && 'text-error',
        )}
        htmlFor={id}
      >
        {children}
      </label>
    </Checkbox>
  );
};
