import { Route, Routes, useLocation } from 'react-router-dom';

import { Form, withSuspenseBoundary } from '@/components';
import { NotFoundPage } from '@/pages/not-found';

import { withErrorBoundary } from '../../components/ErrorBoundary';
import { useCreatePlotForm } from '../../hooks/usePlotForm';
import { usePlot, useQuestionnaire } from './hooks';
import { EditPlotBoundaryPage } from './pages/editPlotBoundary/edit-plot-boundary.page';
import { EditPlotQuestionnairePage } from './pages/editPlotQuestionnaire/edit-plot-questionnaire.page';
import { EditPlotSuccess } from './pages/editPlotSuccess/EditPlotSuccess';

export const LandstewardEditPlotPage = withErrorBoundary(
  withSuspenseBoundary(() => {
    const { state } = useLocation();
    const plotQuery = usePlot();
    const plot = plotQuery.data;

    const questionnaireQuery = useQuestionnaire();
    const questionnaire = questionnaireQuery.data?.json;

    const form = useCreatePlotForm({ plot, questionnaire, previousPath: state?.previousPath });

    return (
      <Form {...form}>
        <Routes>
          <Route index element={<EditPlotQuestionnairePage />} />
          <Route path='boundary' element={<EditPlotBoundaryPage />} />
          <Route path='success' element={<EditPlotSuccess />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Form>
    );
  }),
);
