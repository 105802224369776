import { createContext, useContext } from 'react';

import {
  PostAuthenticationOtpRequestBodyData,
  PostAuthenticationSignInRequestBodyData,
} from '@/api/rest/resources/types/authentication';
import {
  PostUserResetPasswordRequestBodyData,
  PostUserSignUpRequestBodyData,
  User,
} from '@/api/rest/resources/types/user';

export interface AuthenticatedUser {
  impersonatedBy?: string;
  user: User | null;
}

export interface Authenticator {
  accessToken?: string;
  isLoading: boolean;
  isSignedIn: boolean;
  isBooted: boolean;
  authenticatedUser: AuthenticatedUser;
  setUser: (user: User) => void;

  signUp(data: PostUserSignUpRequestBodyData): Promise<void>;
  signIn(data: PostAuthenticationSignInRequestBodyData): Promise<void>;
  signInWithToken(data: PostAuthenticationOtpRequestBodyData): Promise<void>;
  resetPassword(data: PostUserResetPasswordRequestBodyData): Promise<void>;
  signOut(): Promise<void>;
  signOutAndInvalidateSession(): Promise<void>;
}

export const AuthenticationContext = createContext<Readonly<Authenticator> | undefined>(undefined);

export const AuthenticationProvider = AuthenticationContext.Provider;

export function useAuth(): Authenticator {
  const auth = useContext(AuthenticationContext);

  if (!auth) {
    throw new Error(
      `The component that calls useAuthentication() needs to be a deep child of <AuthenticationProvider>`,
    );
  }

  return auth;
}
