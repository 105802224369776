import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH,
  GET_PROJECT_BY_ID_DASHBOARD_PATH,
  GetBuyerProjectByIdDashboardErrorDto,
  GetBuyerProjectByIdDashboardSuccessDto,
  GetProjectByIdDashboardErrorDto,
  GetProjectByIdDashboardSuccessDto,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';

type SuccessDto = GetProjectByIdDashboardSuccessDto | GetBuyerProjectByIdDashboardSuccessDto;
type ErrorDto = GetProjectByIdDashboardErrorDto | GetBuyerProjectByIdDashboardErrorDto;

/**
 * @param projectIdArg Falls back to the projectId in the URL if no value is provided.
 */
export const useDashboardByProjectId = (projectIdArg?: string) => {
  const membershipType = useMembershipType();

  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const queryParams = { id: projectId };

  const queryFn = async () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_PROJECT_BY_ID_DASHBOARD_PATH
        : GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH,
      queryParams,
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'project', queryParams, 'dashboard'],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
