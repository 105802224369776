import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { MapOverviewHud } from '@/components';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { formatUnit, unitToTonne, valueToTonne } from '@/utils/formatting';
import { squareMetersToHectares } from '@/utils/plot';

export const ProjectMapHud = () => {
  const { t } = useTranslation();

  const membershipType = useMembershipType();
  const projectDetail = useProjectDetailById().data;

  const biodiversityZonePercent = useNCData<number>(R1FactType.r1_biodiversity_zone_percent);
  const carbonStorageBg = useNCData<number>(R1FactType.r1_carbon_storage_bg_total);
  const waterHoldingCapacity = useNCData<number>(R1FactType.r1_water_holding_capacity_total);

  return (
    <MapOverviewHud
      items={[
        {
          'data-cy': 'area',
          label:
            membershipType === MembershipWithOrganizationTypeEnum.land_steward
              ? t('global.analysis.area')
              : t('global.analysis.totalSponsoredArea'),
          value: squareMetersToHectares(projectDetail.area),
          unit: formatUnit(UnitEnum.ha),
        },
        {
          'data-cy': 'biodiversity',
          label: t('global.analysis.biodiversityZone'),
          value: biodiversityZonePercent?.value,
          unit: formatUnit(biodiversityZonePercent?.unit ?? UnitEnum['%']),
        },
        {
          'data-cy': 'carbon',
          label: t('global.analysis.carbonStorageBg'),
          value: valueToTonne(carbonStorageBg?.value, carbonStorageBg?.unit ?? UnitEnum.t),
          unit: formatUnit(unitToTonne(carbonStorageBg?.unit ?? UnitEnum.t)),
        },
        {
          'data-cy': 'water',
          label: t('global.analysis.waterHoldingCapacity'),
          value: waterHoldingCapacity?.value,
          unit: formatUnit(waterHoldingCapacity?.unit ?? UnitEnum['m^3']),
        },
      ]}
    />
  );
};
