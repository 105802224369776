import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { GET_PLOT_QUESTIONNAIRE_PATH, GetPlotQuestionnaireSuccessDto } from '@/api/rest/resources/types/plot';

import { usePlotId } from '../../../../../../shared/hooks/usePlotId';

export const useQuestionnaire = () => {
  const plotId = usePlotId();

  return useSuspenseQuery({
    queryKey: ['land_steward', 'plot', plotId, 'get_questionnaire'],
    queryFn: () => getRequest<GetPlotQuestionnaireSuccessDto>(GET_PLOT_QUESTIONNAIRE_PATH, { id: plotId }),
    staleTime: 1000 * 60 * 5,
  });
};
