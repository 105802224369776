import { css } from '@emotion/react';
import {
  cn,
  RadioGroup,
  RadioGroupItem,
  RadioGroupProps,
  Select,
  SelectContent,
  SelectItem,
  SelectProps,
  SelectTrigger,
  Stack,
} from '@landler/tw-component-library';
import React, { HTMLAttributes } from 'react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextInput, TextInputProps } from 'tw-component-library/TextInput/TextInput';

import { FormControl, FormField, FormFieldProps, FormItem, FormLabel, FormMessage } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { usePlotForm } from '@/pages/landsteward/pages/plot/hooks/usePlotForm';

import { PlotInputs } from '../types';

export const QuestionList = ({ className, ...delegated }: HTMLAttributes<HTMLOListElement>) => {
  return <ol className={cn('flex flex-col gap-y-8 rounded-2xl bg-white-100 p-8 md:p-12', className)} {...delegated} />;
};

const liStyle = css`
  counter-increment: item;

  &::before {
    content: counter(item, decimal-leading-zero) ' ';
    text-align: right;
    display: inline-block;
  }
`;

export type QuestionProps = Omit<FormFieldProps, 'control' | 'name'> & {
  name: keyof PlotInputs;
  label?: string;
  messages?: Partial<Record<FieldError['type'], string>>;
};

export const Question = ({ name, label, render, rules, messages, ...delegated }: QuestionProps) => {
  const isLargeScreen = useScreenSize() === 'large';
  const form = usePlotForm();

  // eslint-disable-next-line security/detect-object-injection
  const errorType = form.formState.errors?.[name]?.type;
  // eslint-disable-next-line security/detect-object-injection
  const errorMessage = typeof errorType === 'string' ? messages?.[errorType] ?? '' : '';

  return (
    <FormField
      name={name}
      control={form.control}
      rules={rules}
      {...delegated}
      render={(...renderArgs) => (
        <FormItem css={liStyle} className='flex before:typography-overline before:text-text-secondary'>
          {isLargeScreen ? (
            <Stack direction='row' className='w-full items-start'>
              {label && <FormLabel className='w-1/2 pl-2 pr-6'>{label}&nbsp;*</FormLabel>}
              <div className='w-1/2'>
                {render(...renderArgs)}
                <FormMessage>{errorMessage}</FormMessage>
              </div>
            </Stack>
          ) : (
            <Stack className='w-full pl-3'>
              {label && <FormLabel className='mb-4'>{label}&nbsp;*</FormLabel>}
              <div>
                {render(...renderArgs)}
                <FormMessage>{errorMessage}</FormMessage>
              </div>
            </Stack>
          )}
        </FormItem>
      )}
    />
  );
};

export type RadioFormProps = {
  field: ControllerRenderProps<FieldValues, string>;
  items: { label: string; value: RadioGroupProps['value'] }[];
};

export const RadioForm = ({ items, field: { value, onChange, ...field } }: RadioFormProps) => {
  return (
    <FormControl>
      <RadioGroup
        className='flex items-center gap-6'
        onValueChange={onChange}
        {...field}
        value={value}
        data-testid={`radiogroup-${field.name}`}
      >
        {items.map((item) => (
          <FormItem key={`${item.value}`} className='flex items-center gap-2'>
            <FormControl>
              <RadioGroupItem value={item.value} />
            </FormControl>
            <FormLabel>{item.label}</FormLabel>
          </FormItem>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export type SelectFormProps = Omit<SelectProps, 'value' | 'onChange'> & {
  field: ControllerRenderProps<FieldValues, string>;
  items: {
    label: React.ReactNode;
    value: string;
  }[];
};

export const SelectForm = ({ items, field: { value, ref, ...field }, ...delegated }: SelectFormProps) => {
  const { t } = useTranslation();

  return (
    <Select value={value ?? ''} {...field} {...delegated}>
      <FormControl>
        <SelectTrigger
          ref={ref}
          placeholder={t('global.ui.form.input.selectOption')}
          className='w-full sm:max-w-[350px]'
          data-testid={`select-${field.name}`}
        />
      </FormControl>
      <SelectContent>
        {items.map((i) => (
          <SelectItem key={i.value} value={i.value}>
            {i.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export type TextFormProps = Omit<TextInputProps, 'value' | 'onChange'> & {
  field: ControllerRenderProps<FieldValues, string>;
};

export const TextForm = ({ field: { value, ...field }, ...delegated }: TextFormProps) => {
  return (
    <FormControl>
      <TextInput value={value ?? ''} {...field} {...delegated} />
    </FormControl>
  );
};
