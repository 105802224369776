import { Stack } from '@landler/tw-component-library';
import { FC, PropsWithChildren } from 'react';

import { useAuth } from './AuthenticationContext';
import { ImpersonatingBanner } from './components/ImpersonatingBanner';

export const ImpersonatingBannerHandler: FC<PropsWithChildren> = ({ children }) => {
  const { authenticatedUser } = useAuth();

  return (
    <Stack>
      {authenticatedUser.impersonatedBy && <ImpersonatingBanner />}
      {children}
    </Stack>
  );
};
