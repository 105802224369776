import { Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { OnboardingPageHeading, OnboardingPagePaper } from '@/layout/OnboardingPage';

interface NewPasswordPageContentProps extends PropsWithChildren {
  headlineText: string;
  dataCy?: string;
}

export const NewPasswordPageContent: React.FC<NewPasswordPageContentProps> = ({ headlineText, children, dataCy }) => {
  return (
    <>
      <OnboardingPageHeading dataCy={dataCy} headlineText={headlineText} />
      <OnboardingPagePaper>
        <Stack spacing={3}>{children}</Stack>
      </OnboardingPagePaper>
    </>
  );
};
