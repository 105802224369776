import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import {
  CalculationInProgress,
  CarbonStorageBGChart,
  CarbonStorageBGChartData,
  NoChartData,
  useNCCardContext,
} from '@/components';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

export const CarbonGraphTile = () => {
  const { t } = useTranslation();
  const plot = usePlot().data;
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const carbonBgUpliftPotential = getFact<CarbonStorageBGChartData[]>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_uplift_potential_graph
      : R1FactType.r1_carbon_bg_uplift_potential_per_ha_graph,
  );

  const chartData = carbonBgUpliftPotential?.value;
  const noChartData = !chartData?.length;

  if (noChartData) {
    if ([PlotStatusEnum.calculating, PlotStatusEnum.scheduled_for_analysis].includes(plot.status)) {
      return <CalculationInProgress />;
    }

    if (plot.status === PlotStatusEnum.analysed) {
      return <NoChartData />;
    }

    return <NoChartData variant='analysisPending' />;
  }

  return (
    <Stack
      spacing={2.5}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='carbon-graph-tile'
    >
      <Stack direction='row' spacing={2} className='items-center'>
        <span className='typography-body1'>{t('shared.ncaDetail.details.carbon.labels.upliftGraphHeadline')}</span>
      </Stack>
      <CarbonStorageBGChart
        analysisType={analysisType}
        data={chartData}
        showTooltip={true}
        className='relative'
        data-testid='carbon-storage-bg-chart'
      />
    </Stack>
  );
};
