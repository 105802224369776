import { Divider, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCCardSectionHeader,
  NCFactCapsule,
  printLatestDate,
  useNCCardContext,
} from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useIsProjectCalculating } from '@/pages/shared/hooks/useIsProjectCalculating';
import { useNCData } from '@/pages/shared/hooks/useNCData';

export const WaterCardStatsTile = () => {
  const isLargeScreen = useScreenSize() === 'large';
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='water-stats-tile'>
      <WaterHoldingCapacity />

      {isLargeScreen && <Divider className='border-dashed' />}

      <WaterUpliftPotential />
    </Stack>
  );
};

const WaterHoldingCapacity = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const whcLatestAnalysis = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_latest_analysis_total
      : R1FactType.r1_water_holding_capacity_latest_analysis_per_ha,
  );
  const whcSincePreviousAnalysis = useNCData<number>(
    analysisType === 'total' ? R1FactType.r1_whc_previous_1y_uplift_total : R1FactType.r1_whc_previous_1y_uplift_per_ha,
  );

  const isProjectCalculating = useIsProjectCalculating();

  const latestAnalysisDate = printLatestDate([whcLatestAnalysis?.measured_at]);

  const whcSincePreviousAnalysisInitialMeasuredAt = printLatestDate([whcSincePreviousAnalysis?.measured_at]);
  const whcSincePreviousAnalysisPreviousMeasuredAt = printLatestDate([
    whcSincePreviousAnalysis?.previous_1y_measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}
        supplementaryInfo={!isProjectCalculating && `${latestAnalysisTranslation} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={latestAnalysisTranslation}
          fact={whcLatestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(whcLatestAnalysis),
            variant: getNCChangeIconVariant(whcLatestAnalysis),
          }}
          infoPopoverProps={
            whcLatestAnalysis?.measured_at
              ? {
                  title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.title'),
                  body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.body', {
                    date: latestAnalysisDate,
                  }),
                }
              : null
          }
        />
        {!!whcSincePreviousAnalysis?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.sincePreviousAnalysis')}
            fact={whcSincePreviousAnalysis}
            capsuleIconProps={{
              icon: getNCChangeIcon(whcSincePreviousAnalysis),
              variant: getNCChangeIconVariant(whcSincePreviousAnalysis),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.water.explainers.sincePreviousAnalysis.title'),
              body: t('shared.ncaDetail.details.water.explainers.sincePreviousAnalysis.body', {
                date: whcSincePreviousAnalysisInitialMeasuredAt,
                previousDate: whcSincePreviousAnalysisPreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-since-previous-analysis-tooltip',
              },
            }}
          />
        )}
      </Stack>
    </div>
  );
};

const WaterUpliftPotential = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const waterHoldingCapacity1yearUpliftPotential = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_1year_uplift_potential_total
      : R1FactType.r1_water_holding_capacity_1year_uplift_potential_per_ha,
  );
  const waterHoldingCapacity10yearUpliftPotential = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_total
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha,
  );

  return (
    <Stack className='gap-8 lg:mt-4 lg:justify-center lg:gap-4' data-testid='water-uplift-stats'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.upliftPotentialForWaterHoldingCapacity')}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.upliftPotentialForWaterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.upliftPotentialForWaterHoldingCapacity.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.1YearPotential')}
          fact={waterHoldingCapacity1yearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacity1yearUpliftPotential),
            variant: getNCChangeIconVariant(waterHoldingCapacity1yearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.1YearPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.1YearPotential.body'),
          }}
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.10YearPotential')}
          fact={waterHoldingCapacity10yearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacity10yearUpliftPotential),
            variant: getNCChangeIconVariant(waterHoldingCapacity10yearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.10YearPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.10YearPotential.body'),
          }}
        />
      </Stack>
    </Stack>
  );
};
