import { Stack, WaterIcon } from '@landler/tw-component-library';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { Plot } from '@/api/rest/resources/types/plot';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { WaterExplainer } from '@/components/NCAExplainersV1_2/WaterExplainer';
import { useScreenSize } from '@/hooks/useScreenSize';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { FactCell, FactTrendCell } from '../../components/plot-list/components/Cells';
import { PlotListDesktop } from '../../components/plot-list/components/PlotListDesktop';
import { ToggleSortButton } from '../../components/plot-list/components/ToggleSortButton';
import { PlotListTitle } from '../../components/plot-list/PlotList';
import { WaterCard } from './components/nc-breakdown/Card';

export const WaterDetailPage = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const customPlotListColumns: ColumnDef<Plot>[] = [
    {
      id: 'waterHoldingCapacity',
      accessorKey: 'waterHoldingCapacity',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}
        </ToggleSortButton>
      ),
      cell: ({ row }) => {
        return (
          <FactCell
            plot={row.original}
            factType={R1FactType.r1_water_holding_capacity_per_ha}
            unitType={UnitEnum['m^3/ha']}
            icon={WaterIcon}
          />
        );
      },
    },
    {
      id: 'soilMoisture',
      accessorKey: 'soilMoisture',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.ncaDetail.details.water.labels.soilMoisture')}
        </ToggleSortButton>
      ),
      cell: ({ row }) => {
        return (
          <FactCell
            plot={row.original}
            factType={R1FactType.r1_soil_moisture_latest_analysis_per_ha}
            unitType={UnitEnum['m^3/ha']}
            icon={WaterIcon}
          />
        );
      },
    },
    {
      id: 'precipitationChange',
      accessorKey: 'precipitationChange',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.ncaDetail.details.water.labels.precipitationChange')}
        </ToggleSortButton>
      ),
      cell: ({ row }) => {
        return (
          <FactTrendCell
            plot={row.original}
            factType={R1FactType.r1_precipitation_trend_change}
            unitType={UnitEnum['%']}
          />
        );
      },
    },
  ];

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.water.labels.water')}>
      <Stack spacing={8}>
        <WaterCard />
        <div>
          <PlotListTitle
            title={t('shared.ncaDetail.details.water.labels.waterDataPerPlot')}
            action={!isSmallScreen ? <WaterExplainer /> : null}
          />
          <PlotListDesktop customColumns={customPlotListColumns} />
        </div>
      </Stack>
    </LandMonitoringPageWrapper>
  );
};
