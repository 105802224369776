import * as RadixPopover from '@radix-ui/react-popover';
import React, { FC, forwardRef, HTMLAttributes } from 'react';

// eslint-disable-next-line import/no-restricted-paths
import { DataAttributes } from '@/types';

import { Stack } from '../Stack/Stack';
import { cn } from '../utils';

export type PopoverProps = RadixPopover.PopoverProps;

export const Popover = RadixPopover.Root;
Popover.displayName = 'Popover';

export const PopoverAnchor = RadixPopover.Anchor;
Popover.displayName = 'PopoverAnchor';

export type PopoverTriggerProps = RadixPopover.PopoverTriggerProps & DataAttributes;

export const PopoverTrigger = forwardRef<HTMLButtonElement, PopoverTriggerProps>(
  ({ children, className, ...delegated }, ref) => {
    return (
      <RadixPopover.Trigger ref={ref} className={cn('cursor-pointer', className)} {...delegated}>
        <span>{children}</span>
      </RadixPopover.Trigger>
    );
  },
);
PopoverTrigger.displayName = 'PopoverTrigger';

export type PopoverContentProps = HTMLAttributes<HTMLDivElement> & {
  arrow?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  size?: 'small' | 'large';
};

/**
 * Use the `arrow`, `position` and `size` props to control the apppearance of the PopoverContent.
 *
 * You can pass custom children as the popover content or use the pre-defined `PopoverContentTitle` and `PopoverContentBody` components.
 */
export const PopoverContent: FC<PopoverContentProps> = ({
  arrow = true,
  position,
  size = 'large',
  className,
  children,
  ...delegated
}) => {
  const contentStyles = cn(
    'bg-white-100 elevation-1 inline-flex items-center max-w-xs focus:outline-none',
    {
      'p-4 rounded-2xl': size === 'large',
      'p-2 rounded-lg': size === 'small',
    },
    className,
  );

  const content =
    typeof children === 'string' ? (
      <span
        className={cn('typography-body1 text-text-primary', {
          'typography-caption': size === 'small',
        })}
      >
        {children}
      </span>
    ) : (
      <Stack spacing={2} className='w-full'>
        {children}
      </Stack>
    );

  return (
    <RadixPopover.Portal>
      <RadixPopover.Content side={position} sideOffset={3} className={contentStyles} {...delegated}>
        {arrow && (
          <RadixPopover.Arrow
            className='fill-current text-white-100'
            width={size === 'small' ? 12 : 24}
            height={size === 'small' ? 6 : 12}
          />
        )}
        {content}
      </RadixPopover.Content>
    </RadixPopover.Portal>
  );
};
PopoverContent.displayName = 'PopoverContent';

export const PopoverContentTitle = React.forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...delegated }, ref) => {
    const styles = cn('text-text-primary typography-h4', className);

    return <span ref={ref} {...delegated} className={styles} />;
  },
);
PopoverContentTitle.displayName = 'PopoverContentTitle';

export const PopoverContentBody = React.forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...delegated }, ref) => {
    const styles = cn('text-text-secondary typography-body1 whitespace-pre-wrap', className);

    return <span ref={ref} {...delegated} className={styles} />;
  },
);
PopoverContentBody.displayName = 'PopoverContentBody';
