// NOTE: This Dialog is mocked for all tests. See jest.config.js
import './comply-cube-dialog.css';

import { toast } from '@landler/tw-component-library';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@/hooks/useLanguage';
import { Logger } from '@/lib/logs/logger';

import { COMPLY_CUBE_BRANDING } from './configs/branding';
import { ComplyCubeCompleteData, WindowComplyCube } from './configs/types';
import { useComplyCubeStages } from './hooks/useComplyCubeStages';

const hasComplyCubeProperty = (window: Window): window is Window & { ComplyCube: WindowComplyCube } => {
  return Object.prototype.hasOwnProperty.call(window, 'ComplyCube');
};

type OpenComplyCubeDialogProps = {
  token: string;
  open: true;
};

type ClosedComplyCubeDialogProps = {
  token: string | undefined;
  open: false;
};

export type ComplyCubeDialogProps = {
  onClose: () => void;
  onComplete: (data: ComplyCubeCompleteData) => void;
} & (OpenComplyCubeDialogProps | ClosedComplyCubeDialogProps);

export const ComplyCubeDialog: React.FC<ComplyCubeDialogProps> = ({ open, onClose, token, onComplete }) => {
  const mountedComplyCubeRef = useRef<ReturnType<WindowComplyCube['mount']> | null>();
  const { t } = useTranslation();

  const language = useLanguage();

  // NOTE: This is only moved to a hook to make this component easier to read.
  const complyCubeStages = useComplyCubeStages();

  const mountComplyCube = (definedToken: string) => {
    if (!hasComplyCubeProperty(window)) {
      throw new Error('ComplyCube object not found on window');
    }

    const mountedComplyCube = window.ComplyCube.mount({
      token: definedToken,
      onComplete: (data) => {
        onComplete(data);
      },
      onExit: () => {
        onClose();
      },
      onError: ({ message }) => {
        Logger.error(`ComplyCube error: ${message}`);
        toast({
          title: t('global.ui.toast.errorToastFallbackTitle'),
          description: t('global.ui.toast.errorToastFallbackDescription'),
          type: 'error',
        });
      },
      onModalClose: () => {
        onClose();
      },
      stages: complyCubeStages,
      branding: COMPLY_CUBE_BRANDING,
      language: language.currentLanguage.code,
    });

    mountedComplyCubeRef.current = mountedComplyCube;
  };

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function handleOpenChange() {
      if (open) {
        // NOTE: The comply cube is mounted every time the dialog is opened to handle language changes.
        mountComplyCube(token);
      } else {
        if (!mountedComplyCubeRef.current) {
          return;
        }
        mountedComplyCubeRef.current.unmount();
        mountedComplyCubeRef.current = null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  return <div id='complycube-mount' />;
};
