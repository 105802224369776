import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { patchRequest } from './requests/patch';
import { RequestData } from './types';

export const useApiPatchRequest = <PathVariables, QueryParams, BodyData, SuccessResponse, ErrorResponse = Error>(
  path: string,
  options?: Omit<
    UseMutationOptions<SuccessResponse, ErrorResponse, RequestData<PathVariables, QueryParams, BodyData>>,
    'mutationFn'
  >,
) => {
  const controller = new AbortController();
  const { signal } = controller;

  const mutation = useMutation<SuccessResponse, ErrorResponse, RequestData<PathVariables, QueryParams, BodyData>>({
    mutationFn: (params) =>
      patchRequest<SuccessResponse>(path, params?.pathVariables, params?.queryParams, params?.bodyData, signal),
    ...options,
  });

  const abort = () => {
    controller.abort();
    mutation.reset();
  };

  return {
    abort,
    ...mutation,
  };
};
