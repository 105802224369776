export type FormError = {
  messages: string[];
  field: string;
};

export class RestApiServerError extends Error {
  constructor(public errors: string[], public formErrors: FormError[], public statusCode?: number) {
    super('The server responded with a failure');
  }
}

export const enum RestApiServerErrorStatusCode {
  OK = 200,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}
