import { useApiPatchRequest } from '../hooks/useApiPatchRequest';
import { useApiPostRequest } from '../hooks/useApiPostRequest';
import { buildUseGetQuery } from '../utils';
import {
  GET_USER_ME_PATH,
  GetUserMeErrorDto,
  GetUserMeRequestPathVariables,
  GetUserMeRequestQueryParams,
  GetUserMeSuccessDto,
  PATCH_USER_ME_CHANGEPASSWORD_PATH,
  PATCH_USER_ME_PATH,
  PatchUserMeChangepasswordErrorDto,
  PatchUserMeChangepasswordRequestBodyData,
  PatchUserMeChangepasswordRequestPathVariables,
  PatchUserMeChangepasswordRequestQueryParams,
  PatchUserMeChangepasswordSuccessDto,
  PatchUserMeErrorDto,
  PatchUserMeRequestBodyData,
  PatchUserMeRequestPathVariables,
  PatchUserMeRequestQueryParams,
  PatchUserMeSuccessDto,
  POST_USER_ACTIVATE_ACCOUNT_PATH,
  POST_USER_BY_ID_MODIFY_USER_AND_ROLE_REQUEST_PATH,
  POST_USER_BY_ID_SOFT_DELETE_PATH,
  POST_USER_COMPLETE_ACCOUNT_DATA_PATH,
  POST_USER_COMPLETE_ACCOUNT_PATH,
  POST_USER_COMPLYCUBE_KYC_CHECK_PATH,
  POST_USER_COMPLYCUBE_KYC_TOKEN_PATH,
  POST_USER_CREATE_AND_INVITE_USER_PATH,
  POST_USER_LAND_STEWARD_COMPLETE_ACCOUNT_PATH,
  POST_USER_LAND_STEWARDCOMPLETE_ACCOUNT_DATA_PATH,
  POST_USER_ME_CHANGE_MAIL_REQUEST_PATH,
  POST_USER_ME_CONFIRM_CHANGE_MAIL_REQUEST_PATH,
  POST_USER_ME_REQUEST_ACTIVATION_MAIL_PATH,
  POST_USER_RESET_PASSWORD_PATH,
  POST_USER_RESET_PASSWORD_REQUEST_PATH,
  POST_USER_SIGN_UP_PATH,
  PostUserActivateAccountErrorDto,
  PostUserActivateAccountRequestBodyData,
  PostUserActivateAccountRequestPathVariables,
  PostUserActivateAccountRequestQueryParams,
  PostUserActivateAccountSuccessDto,
  PostUserByIdModifyUserAndRoleErrorDto,
  PostUserByIdModifyUserAndRoleRequestPathVariables,
  PostUserByIdModifyUserAndRoleRequestRequestBodyData,
  PostUserByIdModifyUserAndRoleRequestRequestQueryParams,
  PostUserByIdModifyUserAndRoleSuccessDto,
  PostUserByIdSoftDeleteErrorDto,
  PostUserByIdSoftDeleteRequestBodyData,
  PostUserByIdSoftDeleteRequestPathVariables,
  PostUserByIdSoftDeleteRequestQueryParams,
  PostUserByIdSoftDeleteSuccessDto,
  PostUserCompleteAccountDataErrorDto,
  PostUserCompleteAccountDataRequestBodyData,
  PostUserCompleteAccountDataRequestPathVariables,
  PostUserCompleteAccountDataRequestQueryParams,
  PostUserCompleteAccountDataSuccessDto,
  PostUserCompleteAccountErrorDto,
  PostUserCompleteAccountRequestBodyData,
  PostUserCompleteAccountRequestPathVariables,
  PostUserCompleteAccountRequestQueryParams,
  PostUserCompleteAccountSuccessDto,
  PostUserComplycubeKycCheckErrorDto,
  PostUserComplycubeKycCheckRequestBodyData,
  PostUserComplycubeKycCheckRequestPathVariables,
  PostUserComplycubeKycCheckRequestQueryParams,
  PostUserComplycubeKycCheckSuccessDto,
  PostUserComplycubeKycTokenErrorDto,
  PostUserComplycubeKycTokenRequestBodyData,
  PostUserComplycubeKycTokenRequestPathVariables,
  PostUserComplycubeKycTokenRequestQueryParams,
  PostUserComplycubeKycTokenSuccessDto,
  PostUserCreateAndInviteUserErrorDto,
  PostUserCreateAndInviteUserRequestBodyData,
  PostUserCreateAndInviteUserRequestPathVariables,
  PostUserCreateAndInviteUserRequestQueryParams,
  PostUserCreateAndInviteUserSuccessDto,
  PostUserLandStewardCompleteAccountDataErrorDto,
  PostUserLandStewardCompleteAccountDataRequestBodyData,
  PostUserLandStewardCompleteAccountDataRequestPathVariables,
  PostUserLandStewardCompleteAccountDataRequestQueryParams,
  PostUserLandStewardCompleteAccountDataSuccessDto,
  PostUserLandStewardCompleteAccountErrorDto,
  PostUserLandStewardCompleteAccountRequestBodyData,
  PostUserLandStewardCompleteAccountRequestPathVariables,
  PostUserLandStewardCompleteAccountRequestQueryParams,
  PostUserLandStewardCompleteAccountSuccessDto,
  PostUserMeChangeMailRequestErrorDto,
  PostUserMeChangeMailRequestRequestBodyData,
  PostUserMeChangeMailRequestRequestPathVariables,
  PostUserMeChangeMailRequestRequestQueryParams,
  PostUserMeChangeMailRequestSuccessDto,
  PostUserMeConfirmChangeMailRequestErrorDto,
  PostUserMeConfirmChangeMailRequestRequestBodyData,
  PostUserMeConfirmChangeMailRequestRequestPathVariables,
  PostUserMeConfirmChangeMailRequestRequestQueryParams,
  PostUserMeConfirmChangeMailRequestSuccessDto,
  PostUserMeRequestActivationMailErrorDto,
  PostUserMeRequestActivationMailRequestBodyData,
  PostUserMeRequestActivationMailRequestPathVariables,
  PostUserMeRequestActivationMailRequestQueryParams,
  PostUserMeRequestActivationMailSuccessDto,
  PostUserResetPasswordErrorDto,
  PostUserResetPasswordRequestBodyData,
  PostUserResetPasswordRequestErrorDto,
  PostUserResetPasswordRequestPathVariables,
  PostUserResetPasswordRequestQueryParams,
  PostUserResetPasswordRequestRequestBodyData,
  PostUserResetPasswordRequestRequestPathVariables,
  PostUserResetPasswordRequestRequestQueryParams,
  PostUserResetPasswordRequestSuccessDto,
  PostUserResetPasswordSuccessDto,
  PostUserSignUpErrorDto,
  PostUserSignUpRequestBodyData,
  PostUserSignUpRequestPathVariables,
  PostUserSignUpRequestQueryParams,
  PostUserSignUpSuccessDto,
} from './types/user';

export const useGetUserMe = buildUseGetQuery<
  GetUserMeRequestPathVariables,
  GetUserMeRequestQueryParams,
  GetUserMeSuccessDto,
  GetUserMeErrorDto
>(['user', 'me'], GET_USER_ME_PATH);

export const usePatchUserMe = () => {
  return useApiPatchRequest<
    PatchUserMeRequestPathVariables,
    PatchUserMeRequestQueryParams,
    PatchUserMeRequestBodyData,
    PatchUserMeSuccessDto,
    PatchUserMeErrorDto
  >(PATCH_USER_ME_PATH);
};

export const usePatchUserMeChangepassword = () => {
  return useApiPatchRequest<
    PatchUserMeChangepasswordRequestPathVariables,
    PatchUserMeChangepasswordRequestQueryParams,
    PatchUserMeChangepasswordRequestBodyData,
    PatchUserMeChangepasswordSuccessDto,
    PatchUserMeChangepasswordErrorDto
  >(PATCH_USER_ME_CHANGEPASSWORD_PATH);
};

export const usePostUserMeChangeMailRequest = () => {
  return useApiPostRequest<
    PostUserMeChangeMailRequestRequestPathVariables,
    PostUserMeChangeMailRequestRequestQueryParams,
    PostUserMeChangeMailRequestRequestBodyData,
    PostUserMeChangeMailRequestSuccessDto,
    PostUserMeChangeMailRequestErrorDto
  >(POST_USER_ME_CHANGE_MAIL_REQUEST_PATH);
};

export const usePostUserMeConfirmChangeMailRequest = () => {
  return useApiPostRequest<
    PostUserMeConfirmChangeMailRequestRequestPathVariables,
    PostUserMeConfirmChangeMailRequestRequestQueryParams,
    PostUserMeConfirmChangeMailRequestRequestBodyData,
    PostUserMeConfirmChangeMailRequestSuccessDto,
    PostUserMeConfirmChangeMailRequestErrorDto
  >(POST_USER_ME_CONFIRM_CHANGE_MAIL_REQUEST_PATH);
};

export const usePostUserByIdModifyUserAndRole = () => {
  return useApiPostRequest<
    PostUserByIdModifyUserAndRoleRequestPathVariables,
    PostUserByIdModifyUserAndRoleRequestRequestQueryParams,
    PostUserByIdModifyUserAndRoleRequestRequestBodyData,
    PostUserByIdModifyUserAndRoleSuccessDto,
    PostUserByIdModifyUserAndRoleErrorDto
  >(POST_USER_BY_ID_MODIFY_USER_AND_ROLE_REQUEST_PATH);
};

export const usePostUserMeRequestActivationMail = () => {
  return useApiPostRequest<
    PostUserMeRequestActivationMailRequestPathVariables,
    PostUserMeRequestActivationMailRequestQueryParams,
    PostUserMeRequestActivationMailRequestBodyData,
    PostUserMeRequestActivationMailSuccessDto,
    PostUserMeRequestActivationMailErrorDto
  >(POST_USER_ME_REQUEST_ACTIVATION_MAIL_PATH);
};

export const usePostUserByIdSoftDelete = () => {
  return useApiPostRequest<
    PostUserByIdSoftDeleteRequestPathVariables,
    PostUserByIdSoftDeleteRequestQueryParams,
    PostUserByIdSoftDeleteRequestBodyData,
    PostUserByIdSoftDeleteSuccessDto,
    PostUserByIdSoftDeleteErrorDto
  >(POST_USER_BY_ID_SOFT_DELETE_PATH);
};

export const usePostUserActivateAccount = () => {
  return useApiPostRequest<
    PostUserActivateAccountRequestPathVariables,
    PostUserActivateAccountRequestQueryParams,
    PostUserActivateAccountRequestBodyData,
    PostUserActivateAccountSuccessDto,
    PostUserActivateAccountErrorDto
  >(POST_USER_ACTIVATE_ACCOUNT_PATH);
};

export const usePostUserResetPasswordRequest = () => {
  return useApiPostRequest<
    PostUserResetPasswordRequestRequestPathVariables,
    PostUserResetPasswordRequestRequestQueryParams,
    PostUserResetPasswordRequestRequestBodyData,
    PostUserResetPasswordRequestSuccessDto,
    PostUserResetPasswordRequestErrorDto
  >(POST_USER_RESET_PASSWORD_REQUEST_PATH);
};

export const usePostUserResetPassword = () => {
  return useApiPostRequest<
    PostUserResetPasswordRequestPathVariables,
    PostUserResetPasswordRequestQueryParams,
    PostUserResetPasswordRequestBodyData,
    PostUserResetPasswordSuccessDto,
    PostUserResetPasswordErrorDto
  >(POST_USER_RESET_PASSWORD_PATH);
};

export const usePostUserSignUp = () => {
  return useApiPostRequest<
    PostUserSignUpRequestPathVariables,
    PostUserSignUpRequestQueryParams,
    PostUserSignUpRequestBodyData,
    PostUserSignUpSuccessDto,
    PostUserSignUpErrorDto
  >(POST_USER_SIGN_UP_PATH);
};

export const usePostUserCompleteAccountData = () => {
  return useApiPostRequest<
    PostUserCompleteAccountDataRequestPathVariables,
    PostUserCompleteAccountDataRequestQueryParams,
    PostUserCompleteAccountDataRequestBodyData,
    PostUserCompleteAccountDataSuccessDto,
    PostUserCompleteAccountDataErrorDto
  >(POST_USER_COMPLETE_ACCOUNT_DATA_PATH);
};

export const usePostUserCompleteAccount = () => {
  return useApiPostRequest<
    PostUserCompleteAccountRequestPathVariables,
    PostUserCompleteAccountRequestQueryParams,
    PostUserCompleteAccountRequestBodyData,
    PostUserCompleteAccountSuccessDto,
    PostUserCompleteAccountErrorDto
  >(POST_USER_COMPLETE_ACCOUNT_PATH);
};

export const usePostUserCreateAndInviteUser = () => {
  return useApiPostRequest<
    PostUserCreateAndInviteUserRequestPathVariables,
    PostUserCreateAndInviteUserRequestQueryParams,
    PostUserCreateAndInviteUserRequestBodyData,
    PostUserCreateAndInviteUserSuccessDto,
    PostUserCreateAndInviteUserErrorDto
  >(POST_USER_CREATE_AND_INVITE_USER_PATH);
};

export const usePostUserLandStewardCompleteAccountData = () => {
  return useApiPostRequest<
    PostUserLandStewardCompleteAccountDataRequestPathVariables,
    PostUserLandStewardCompleteAccountDataRequestQueryParams,
    PostUserLandStewardCompleteAccountDataRequestBodyData,
    PostUserLandStewardCompleteAccountDataSuccessDto,
    PostUserLandStewardCompleteAccountDataErrorDto
  >(POST_USER_LAND_STEWARDCOMPLETE_ACCOUNT_DATA_PATH);
};

export const usePostUserLandStewardCompleteAccount = () => {
  return useApiPostRequest<
    PostUserLandStewardCompleteAccountRequestPathVariables,
    PostUserLandStewardCompleteAccountRequestQueryParams,
    PostUserLandStewardCompleteAccountRequestBodyData,
    PostUserLandStewardCompleteAccountSuccessDto,
    PostUserLandStewardCompleteAccountErrorDto
  >(POST_USER_LAND_STEWARD_COMPLETE_ACCOUNT_PATH);
};

export const usePostUserComplycubeKycToken = () => {
  return useApiPostRequest<
    PostUserComplycubeKycTokenRequestPathVariables,
    PostUserComplycubeKycTokenRequestQueryParams,
    PostUserComplycubeKycTokenRequestBodyData,
    PostUserComplycubeKycTokenSuccessDto,
    PostUserComplycubeKycTokenErrorDto
  >(POST_USER_COMPLYCUBE_KYC_TOKEN_PATH);
};

export const usePostUserComplycubeKycCheck = () => {
  return useApiPostRequest<
    PostUserComplycubeKycCheckRequestPathVariables,
    PostUserComplycubeKycCheckRequestQueryParams,
    PostUserComplycubeKycCheckRequestBodyData,
    PostUserComplycubeKycCheckSuccessDto,
    PostUserComplycubeKycCheckErrorDto
  >(POST_USER_COMPLYCUBE_KYC_CHECK_PATH);
};
