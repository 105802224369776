/* eslint-disable camelcase */
import { Navigate, Outlet, Route } from 'react-router-dom';

import { AccountEditEmailPage } from '@/pages/shared/account/pages/edit-email/EditEmail.page';
import { AccountEditNamePage } from '@/pages/shared/account/pages/edit-name/EditName.page';
import { AccountEditPasswordPage } from '@/pages/shared/account/pages/edit-password/EditPassword.page';
import { AccountPage } from '@/pages/shared/account/pages/index/Account.page';
import { AccountLegalPage } from '@/pages/shared/account/pages/legal/AccountLegal.page';
import { AccountTeamPage } from '@/pages/shared/account/pages/team/AccountTeam.page';
import { AccountTeamEditMemberPage } from '@/pages/shared/account/pages/team/pages/edit-member/AccountTeamEditMember.page';
import { AccountTeamInvitePage } from '@/pages/shared/account/pages/team/pages/invite/AccountTeamInvite.page';
import { ComplianceFrameworksPage } from '@/pages/shared/compliance-frameworks/ComplianceFrameworks.page';
import { PlotsPage } from '@/pages/shared/plots/Plots.page';
import { BiodiversityDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/biodiversity/BiodiversityDetail.page';
import { CarbonDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/carbon/CarbonDetail.page';
import { DeforestationDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/deforestation/DeforestationDetail.page';
import { EcologicalIntegrityPage } from '@/pages/shared/projects/pages/v1_2/project/pages/ecological-integrity/ecological-integrity.page';
import { FloraAndFaunaPage } from '@/pages/shared/projects/pages/v1_2/project/pages/flora-and-fauna/flora-and-fauna.page';
import { HabitatIntactnessPage } from '@/pages/shared/projects/pages/v1_2/project/pages/habitat-intactness/habitat-intactness.page';
import { InteractiveMap } from '@/pages/shared/projects/pages/v1_2/project/pages/interactive-map/interactive-map.page';
import { WaterDetailPage } from '@/pages/shared/projects/pages/v1_2/project/pages/water/WaterDetail.page';
import { ProjectPage as V1_2ProjectPage } from '@/pages/shared/projects/pages/v1_2/project/Project.page';
import { ProjectsPage } from '@/pages/shared/projects/Projects.page';
import { RequireAdminRole } from '@/routing/components/protections/require-admin-role/RequireAdminRole';
import { SkipLoggedInUser } from '@/routing/components/protections/skip-logged-in-user/SkipLoggedInUser';
import { paths } from '@/routing/routing';

import { PlotPage } from '../../shared/projects/pages/project/pages/plot/Plot.page';
import { ProjectPage } from '../../shared/projects/pages/project/Project.page';
import { ProjectPageLayout } from '../../shared/projects/pages/project/ProjectLayout.page';
import { PlotPage as V1_2_PlotPage } from '../../shared/projects/pages/v1_2/project/pages/plot/Plot.page';
import { LandstewardAccountPageWrapper } from '../pages/account/Account.page-wrapper';
import { LandStewardCompleteAccountPage } from '../pages/complete-account/complete-account.page';
import { LandstewardInvestmentsPage } from '../pages/investments/Investments.page';
import { LandstewardEditPlotPage } from '../pages/plot/pages/edit/edit-plot.page';
import { LandstewardNewPlotPage } from '../pages/plot/pages/new/new-plot.page';
import { LandstewardPortfolioPage } from '../pages/portfolio/Portfolio.page';
import { ProjectsNewPage } from '../pages/projects/pages/new-project/NewProject.page';
import { LandstewardComplianceFrameworksPageWrapper } from '../pages/projects/pages/project/pages/compliance-frameworks/ComplianceFrameworks.page-wrapper';
import { LandStewardSignUpPage } from '../pages/sign-up/sign-up.page';
import { LandStewardSignedInRoute } from './components/protections/land-steward-signed-in-route/LandStewardSignedInRoute';
import { RequireAccessiblePlot } from './components/protections/require-accessible-plot/RequireAccessiblePlot';
import { RequireProjectEditable } from './components/protections/require-project-editable/RequireProjectEditable';

export const LandStewardPageRoutes = (
  <Route path='landsteward'>
    <Route element={<LandStewardSignedInRoute />}>
      <Route path={paths.landSteward.account} element={<LandstewardAccountPageWrapper />}>
        <Route index element={<AccountPage />} />
        <Route path={paths.landSteward.accountEditEmail} element={<AccountEditEmailPage />} />
        <Route path={paths.landSteward.accountEditName} element={<AccountEditNamePage />} />
        <Route path={paths.landSteward.accountEditPassword} element={<AccountEditPasswordPage />} />
        <Route path={paths.landSteward.accountLegal} element={<AccountLegalPage />} />
        <Route path={paths.landSteward.accountTeam}>
          <Route index element={<AccountTeamPage />} />
          <Route element={<RequireAdminRole />}>
            <Route path={paths.landSteward.accountTeamEditMember} element={<AccountTeamEditMemberPage />} />
            <Route path={paths.landSteward.accountTeamInvite} element={<AccountTeamInvitePage />} />
          </Route>
        </Route>
      </Route>

      <Route path={paths.landSteward.portfolio} element={<LandstewardPortfolioPage />} />

      <Route path={paths.landSteward.investments} element={<LandstewardInvestmentsPage />} />

      <Route index element={<Navigate to={paths.landSteward.projects} replace />} />
      <Route path={paths.landSteward.projects}>
        <Route path={paths.landSteward.newProject} element={<ProjectsNewPage />} />
        <Route index element={<ProjectsPage />} />
        <Route
          path={paths.landSteward.projectsComplianceFrameworks}
          element={<LandstewardComplianceFrameworksPageWrapper />}
        >
          <Route index element={<ComplianceFrameworksPage />} />
        </Route>
        <Route path={paths.landSteward.ecologicalIntegrity} element={<EcologicalIntegrityPage />} />
        <Route path={paths.landSteward.habitatIntactness} element={<HabitatIntactnessPage />} />
        <Route path={paths.landSteward.floraAndFauna} element={<FloraAndFaunaPage />} />
        <Route path={`${paths.landSteward.projectDetails}/*`} element={<V1_2ProjectPage />} />
        <Route path={paths.landSteward.interactiveMap} element={<InteractiveMap />} />
        <Route path={paths.landSteward.projectDetailsBiodiversity} element={<BiodiversityDetailPage />} />
        <Route path={paths.landSteward.projectDetailsCarbon} element={<CarbonDetailPage />} />
        <Route path={paths.landSteward.projectDetailsWater} element={<WaterDetailPage />} />
        <Route path={paths.landSteward.projectDetailsDeforestation} element={<DeforestationDetailPage />} />

        <Route element={<RequireAccessiblePlot />}>
          <Route path={paths.landSteward.plot} element={<V1_2_PlotPage />} />
        </Route>

        <Route element={<ProjectPageLayout />}>
          <Route path={paths.landSteward.legacy_projectDetails} element={<ProjectPage />} />
          <Route path={paths.landSteward.legacy_plots} element={<PlotsPage />} />
        </Route>
        <Route element={<RequireAccessiblePlot />}>
          <Route path={paths.landSteward.legacy_plot} element={<PlotPage />} />
        </Route>
        <Route element={<RequireProjectEditable />}>
          <Route path={`${paths.landSteward.editPlot}/*`} element={<LandstewardEditPlotPage />} />
          <Route path={`${paths.landSteward.newPlot}/*`} element={<LandstewardNewPlotPage />} />
        </Route>
      </Route>
    </Route>

    <Route
      element={
        <SkipLoggedInUser>
          <Outlet />
        </SkipLoggedInUser>
      }
    >
      <Route path={paths.landSteward.completeAccount} element={<LandStewardCompleteAccountPage />} />
      <Route path={paths.landSteward.signUp} element={<LandStewardSignUpPage />} />
    </Route>
  </Route>
);
