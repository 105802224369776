import bgMapSrc from '@assets/images/bg-map.jpg';
import { Stack } from '@landler/tw-component-library';
import { FC, PropsWithChildren } from 'react';

import { Footer, SignedInNavbar } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { HackHubSpotChatButton } from '@/layout/hack-hubspot-chat-button';
import { useMenuItems } from '@/pages/landsteward/layouts/hooks/useMenuItems';

export const NewProjectLayout: FC<PropsWithChildren> = ({ children }) => {
  const isLargeScreen = useScreenSize() === 'large';
  const menuItems = useMenuItems();

  return (
    <>
      <HackHubSpotChatButton />
      <Stack
        className='bg-map-texture h-full min-h-screen justify-normal bg-cover md:justify-between'
        style={{ backgroundImage: `url(${bgMapSrc})` }}
      >
        <SignedInNavbar items={menuItems} />
        {children}
        {isLargeScreen && <Footer variant='white' />}
      </Stack>
    </>
  );
};
