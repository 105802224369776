import { InfoPopover, Stack } from '@landler/tw-component-library';
import { ReactNode, useMemo } from 'react';

import { useScreenSize } from '@/hooks/useScreenSize';
import { isReactNode } from '@/utils/isReactNode';

export type ChartHeadingProps = {
  heading: ReactNode;
  popover?:
    | ReactNode
    | {
        title: ReactNode;
        body: ReactNode;
      };
};

export const ChartHeading = (props: ChartHeadingProps) => {
  const isSmallScreen = useScreenSize() === 'small';

  const popover = useMemo(() => {
    if (!props.popover) return null;

    if (isReactNode(props.popover)) return props.popover;

    return <InfoPopover {...props.popover} />;
  }, [props.popover]);

  return (
    <Stack direction='row' spacing={2} className='self-start'>
      <span className='typography-body1'>
        {/* TODO: MVP-3284 Update copy for historicalGraphHeadline */}
        {props.heading}
        {!isSmallScreen && (
          <div className='relative inline-block'>
            <div className='absolute -top-[13px] left-2'>{popover}</div>
          </div>
        )}
      </span>
    </Stack>
  );
};
