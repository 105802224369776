/* eslint-disable security/detect-object-injection */
import { captureMessage } from '@sentry/react';

import { LoggingStrategy, UniqueLogEntries } from './LoggingStrategy';
import { getLogEntryMessage } from './utils';

// it probably makes sense to caputure the Error using captureException instead of only capturing the error message
export class SentryLogger extends LoggingStrategy {
  uniqueInfos: UniqueLogEntries = {};

  uniqueWarns: UniqueLogEntries = {};

  uniqueErrors: UniqueLogEntries = {};

  info(error: string | Error): void {
    const message = getLogEntryMessage(error);
    captureMessage(message, 'info');
  }

  public infoOnce(error: string | Error): void {
    const message = getLogEntryMessage(error);

    if (this.uniqueInfos[message]) {
      return;
    }

    this.uniqueInfos[message] = true;

    captureMessage(message, 'info');
  }

  warn(error: string | Error): void {
    const message = getLogEntryMessage(error);
    captureMessage(message, 'warning');
  }

  public warnOnce(error: string | Error): void {
    const message = getLogEntryMessage(error);

    if (this.uniqueWarns[message]) {
      return;
    }

    this.uniqueWarns[message] = true;

    captureMessage(message, 'warning');
  }

  error(error: string | Error): void {
    const message = getLogEntryMessage(error);
    captureMessage(message, 'error');
  }

  public errorOnce(error: string | Error): void {
    const message = getLogEntryMessage(error);

    if (this.uniqueErrors[message]) {
      return;
    }

    this.uniqueErrors[message] = true;

    captureMessage(message, 'error');
  }
}
