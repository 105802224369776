import { Box, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Footer } from '@/components';
import { SignedInNavbar } from '@/components/SignedInNavbar';
import { MenuItemProps } from '@/components/SignedInNavbar/Menu';
import { useScreenSize } from '@/hooks/useScreenSize';

import { SignedInPageLayoutContentContainer } from './components';

export interface SignedInPageProps {
  items: MenuItemProps[];
  withCustomContentContainer?: boolean;
  'data-testid'?: string;
}

export const SignedInPageLayout: React.FC<PropsWithChildren<SignedInPageProps>> = ({
  children,
  items,
  withCustomContentContainer = false,
  ...props
}) => {
  const isLargeScreen = useScreenSize() === 'large';

  return (
    <Stack height='100vh' {...props}>
      <SignedInNavbar items={items} />
      <Box
        component='main'
        data-testid='page-container'
        sx={{
          flexGrow: 1,
          width: '100%',
        }}
      >
        {withCustomContentContainer ? (
          children
        ) : (
          <SignedInPageLayoutContentContainer>{children}</SignedInPageLayoutContentContainer>
        )}
      </Box>

      {isLargeScreen && <Footer />}
    </Stack>
  );
};
