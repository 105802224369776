import bgMapSrc from '@assets/images/bg-investments.jpg';

import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';

import { HelpWidget, HowToWidget } from '../widgets';
import { InvestmentHero } from './hero/Hero';

export const WithoutContracts = () => (
  <>
    <div
      className='bg-cover full-bleed-x'
      style={{ backgroundImage: `url(${bgMapSrc})` }}
      data-cy='investments-hero-bg'
    >
      <SignedInPageLayoutContentContainer>
        <InvestmentHero />
      </SignedInPageLayoutContentContainer>
    </div>
    <div className='full-bleed-x'>
      <SignedInPageLayoutContentContainer>
        <div className='flex flex-col gap-8 lg:flex-row'>
          <HowToWidget />
          <HelpWidget />
        </div>
      </SignedInPageLayoutContentContainer>
    </div>
  </>
);
