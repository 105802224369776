import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';

import { HelpWidget, ScheduleMeetingWidget } from '../widgets';
import { List } from './list/List';
import { Overview } from './overview/Overview';

export const WithContracts = () => {
  return (
    <>
      <Overview />
      <div className='full-bleed-x'>
        <SignedInPageLayoutContentContainer>
          <div className='flex flex-col gap-8'>
            <List />
            <div className='flex flex-col gap-8 lg:flex-row'>
              <ScheduleMeetingWidget />
              <HelpWidget />
            </div>
          </div>
        </SignedInPageLayoutContentContainer>
      </div>
    </>
  );
};
