import { useSuspenseQuery } from '@tanstack/react-query';

import { Observation } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectKey } from './useProjectKey';

export const getObservationById = async (projectKey: string) => {
  const module = await import(`../mockData/${projectKey}/observation.json`);

  return module.default as Observation[];
};

export const useObservation = (id?: string) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey(id);

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'observation'],
    queryFn: async () => {
      const observation = await getObservationById(projectKey);

      return observation.map((o, index) => ({ ...o, id: o.key || index + 1 }));
    },
  });
};
