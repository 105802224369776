import { SidebarPageLayout } from '@landler/component-library';
import { Outlet } from 'react-router-dom';

import { useScreenSize } from '@/hooks/useScreenSize';

import { AccountSidebar } from '../../../shared/account/components';
import { BuyerPageLayout } from '../../components';

export const BuyerAccountPageWrapper = () => {
  const isLargeScreen = useScreenSize() === 'large';

  return (
    <BuyerPageLayout>
      <SidebarPageLayout sidebar={isLargeScreen && <AccountSidebar />}>
        <Outlet />
      </SidebarPageLayout>
    </BuyerPageLayout>
  );
};
