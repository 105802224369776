import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '../utils';

export type TooltipProviderProps = TooltipPrimitive.TooltipProviderProps;
export const TooltipProvider = TooltipPrimitive.Provider;

export type TooltipTriggerProps = TooltipPrimitive.TooltipTriggerProps;
export const TooltipTrigger = TooltipPrimitive.Trigger;

export type TooltipPortalProps = TooltipPrimitive.PortalProps;
export const TooltipPortal = TooltipPrimitive.Portal;

export const Tooltip = ({ children, delayDuration = 300, ...delegated }: TooltipPrimitive.TooltipProps) => (
  <TooltipProvider>
    <TooltipPrimitive.Root delayDuration={delayDuration} {...delegated}>
      {children}
    </TooltipPrimitive.Root>
  </TooltipProvider>
);

export const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, children, ...delegated }, ref) => (
  <TooltipPortal>
    <TooltipPrimitive.Content
      ref={ref}
      className={cn('typography-caption max-w-xs rounded-lg bg-white-100 p-2 text-text-primary elevation-1', className)}
      {...delegated}
      data-cy='tooltip'
    >
      {children}
    </TooltipPrimitive.Content>
  </TooltipPortal>
));
TooltipContent.displayName = 'TooltipContent';

export type TooltipArrowProps = TooltipPrimitive.TooltipArrowProps;
export const TooltipArrow = ({ className, ...delegated }: TooltipPrimitive.TooltipArrowProps) => (
  <TooltipPrimitive.Arrow className={cn('fill-white-100', className)} {...delegated} />
);
TooltipArrow.displayName = 'TooltipArrow';
