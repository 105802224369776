import { Text } from '@landler/component-library';
import { Divider, ListItemButton, ListItemText, Stack, StackProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCookiebot } from '@/hooks/useCookiebot';
import { useLegalUrls } from '@/hooks/useLegalUrls';

export const DrawerFooter: React.FC<StackProps> = (props) => {
  const { t } = useTranslation();
  const { imprintUrl, privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();

  const { renew: renewCookiebotPreferences } = useCookiebot();

  return (
    <Stack component='footer' role='contentinfo' data-testid='drawer-footer' {...props}>
      <Divider />
      <ListItemButton component='a' href={imprintUrl} target='_blank'>
        <ListItemText>{t('global.footer.nav.imprint')}</ListItemText>
      </ListItemButton>
      <ListItemButton component='a' href={termsAndConditionsUrl} target='_blank'>
        <ListItemText>{t('global.footer.nav.termsAndConditions')}</ListItemText>
      </ListItemButton>
      <ListItemButton component='a' href={privacyPolicyUrl} target='_blank'>
        <ListItemText>{t('global.footer.nav.privacy')}</ListItemText>
      </ListItemButton>
      <ListItemButton onClick={renewCookiebotPreferences}>
        <ListItemText>{t('global.footer.nav.updateCookiePreferences')}</ListItemText>
      </ListItemButton>
      <Divider />
      <Text variant='body2' color='text.secondary' sx={{ p: 2, mb: 2 }}>
        &copy; Landler {new Date().getFullYear()}
      </Text>
    </Stack>
  );
};
