import { Button } from '@landler/component-library';
import { Stack } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePostUserResetPasswordRequest } from '@/api/rest/resources/user';
import { ControlledTextInput } from '@/components/Form/ControlledTextInput';
import { OnboardingPageHeading, OnboardingPageHelperLink, OnboardingPagePaper } from '@/layout/OnboardingPage';
import { paths } from '@/routing';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';
import { validateEmail } from '@/utils/validators';

interface Inputs {
  email: string;
}

const defaultValues: Inputs = {
  email: '',
};

// NOTE: The form field names must match the api param names to make useRestApiServerErrorHandling work
const formFields: (keyof Inputs)[] = ['email'];

export const ResetPasswordForm: React.FC<{ onSubmit: () => unknown }> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm<Inputs>({
    defaultValues,
  });
  const { mutate, isPending } = usePostUserResetPasswordRequest();
  const handleServerError = useRestApiServerErrorHandling(setError, formFields);

  const handleResetPassword: SubmitHandler<Inputs> = async (data) => {
    mutate(
      { bodyData: { email: data.email } },
      {
        onSuccess: () => onSubmit(),
        onError: handleServerError,
      },
    );
  };

  return (
    <>
      <OnboardingPageHeading dataCy='password-reset' headlineText={t('passwordReset.title')} />
      <OnboardingPagePaper>
        <Stack component='form' spacing={3} onSubmit={handleSubmit(handleResetPassword)}>
          <ControlledTextInput
            id='email'
            name='email'
            type='email'
            control={control}
            label={t('passwordReset.email.label')}
            placeholder={t('passwordReset.email.placeholder')}
            rules={{
              required: t('passwordReset.errors.email.required'),
              validate: (value) => validateEmail(value) || t('passwordReset.errors.email.invalid'),
            }}
            data-cy='email-input'
          />
          <Button type='submit' size='large' isLoading={isPending} sx={{ alignSelf: 'end' }} data-cy='submit-button'>
            {t('passwordReset.submit')}
          </Button>
        </Stack>
      </OnboardingPagePaper>
      <OnboardingPageHelperLink i18nKey='passwordReset.back' to={paths.login} data-cy='back' />
    </>
  );
};
