import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { RiLineChartLine, RiMoneyEuroCircleLine, RiRoadMapLine } from 'tw-component-library';

import { SignedInPageLayout } from '@/layout/signed-in-page';
import { paths } from '@/routing';

type BuyerPageLayoutProps = {
  withCustomContentContainer?: boolean;
};

export const BuyerPageLayout: React.FC<PropsWithChildren<BuyerPageLayoutProps>> = ({ children, ...delegated }) => {
  const { t } = useTranslation();

  return (
    <SignedInPageLayout
      data-testid='buyer-page-layout'
      items={[
        {
          active: false,
          icon: <RiRoadMapLine className='h-6 w-6' />,
          name: 'projects',
          'data-cy': 'projects-link',
          text: t('navbar.projects'),
          to: paths.buyer.projects,
        },
        {
          active: false,
          icon: <RiMoneyEuroCircleLine className='h-6 w-6' />,
          name: 'investments',
          'data-cy': 'investments-link',
          text: t('navbar.investments'),
          to: paths.buyer.investments,
        },
        {
          active: false,
          icon: <RiLineChartLine className='h-6 w-6' />,
          name: 'portfolio',
          'data-cy': 'portfolio-link',
          text: t('navbar.portfolio'),
          to: paths.buyer.portfolio,
        },
      ]}
      {...delegated}
    >
      {children}
    </SignedInPageLayout>
  );
};
