import { useEffect, useState } from 'react';

const calcAvailableHeight = () => {
  const impersonatingBannerElement = document.getElementById('impersonating-banner');

  return window.innerHeight - (impersonatingBannerElement?.clientHeight ?? 0);
};

export const useCalcAvailableViewportHeight = () => {
  const [availableHeight, setAvailableHeight] = useState(calcAvailableHeight());

  const updateAvailableHeight = () => {
    setAvailableHeight(calcAvailableHeight());
  };

  // we are using a custom page layout to be able to fill the screen size with the navbar + map (without ever needing to scroll).
  // unfortunately neither 100% or 100vh works for the height of the stack on consistenly across all mobile devices.
  // this is caused by the presence or absence of the browsers toolbar, see https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser.
  // this workaround ensures that we always stretch the stack to 100% of the available height (even after resizing or changing the device's orientation).
  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function addResizeListeners() {
    window.addEventListener('resize', updateAvailableHeight);
    window.addEventListener('orientationchange', updateAvailableHeight);

    return () => {
      window.removeEventListener('resize', updateAvailableHeight);
      window.removeEventListener('orientationchange', updateAvailableHeight);
    };
  }, []);

  return {
    availableHeight,
  };
};
