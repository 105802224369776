import { Collapse, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';

import { Icon } from '../Icon';
import { Heading } from '../Typography';

interface SidebarProps {
  /** Title of Sidebar */
  title: string;
}

export const Sidebar: React.FC<PropsWithChildren<SidebarProps>> = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Stack
      maxWidth='xs'
      sx={{ maxWidth: { xs: 665, md: 375 }, mx: { xs: 'auto', md: 0 }, width: { xs: '100%', md: 'auto' } }}
    >
      <Stack
        direction='row'
        alignItems='center'
        mb={4.5}
        onClick={toggleOpen}
        sx={{ cursor: `${isMobileView ? 'pointer' : undefined}` }}
      >
        <Heading variant='h4' data-cy='sidebar-title'>
          {title}
        </Heading>
        {isMobileView && (
          <Icon
            sx={{ ml: 1, transform: open ? 'rotate(180deg)' : undefined }}
            icon='triangle-down'
            height='10'
            width='10'
          />
        )}
      </Stack>
      <Collapse in={open || !isMobileView}>{children}</Collapse>
    </Stack>
  );
};
