import { Button, ButtonLink, RiArrowGoBackLine, RiLoopLeftLine } from '@landler/tw-component-library';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { FullScreenError } from '@/components';
import { paths } from '@/routing';

export const withErrorBoundary = (Wrapped: () => JSX.Element) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const ErrorBoundary = () => {
    const { reset } = useQueryErrorResetBoundary();

    return (
      <ReactErrorBoundary FallbackComponent={NewPlotErrorFallback} onReset={reset}>
        <Wrapped />
      </ReactErrorBoundary>
    );
  };

  return ErrorBoundary;
};

const NewPlotErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();

  return (
    <FullScreenError
      title={t('global.ui.errorBoundary.genericErrorTitleAvoidUsingThis')}
      body={<span className='text-start'>{t('global.ui.errorBoundary.genericErrorDescriptionAvoidUsingThis')}</span>}
      actions={
        <>
          <ButtonLink
            to={paths.landSteward.projects}
            className='flex items-center gap-2'
            variant='outline'
            leftAdornment={<RiArrowGoBackLine />}
          >
            {t('global.ui.errorBoundary.goToProjectsButtonLabel')}
          </ButtonLink>
          <Button onClick={resetErrorBoundary} className='flex items-center gap-2' leftAdornment={<RiLoopLeftLine />}>
            {t('global.ui.buttons.retry')}
          </Button>
        </>
      }
    />
  );
};
