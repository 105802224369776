import { useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const WaterExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='water-explainer' data-cy='water-explainer'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle data-cy='water-info-heading'>
          {t('shared.ncaDetail.details.water.explainers.howDoMeasureThis.headline')}
        </ExplainerTitle>
        <ExplainerBody data-cy='waterHoldingCapacityBody'>
          <ExplainerCopy
            title={t('shared.ncaDetail.details.water.explainers.howDoMeasureThis.waterHoldingTitle')}
            body={t('shared.ncaDetail.details.water.explainers.howDoMeasureThis.waterHoldingBody')}
          />
          <ExplainerCopy
            title={t('shared.ncaDetail.details.water.explainers.howDoMeasureThis.soilMoistureTitle')}
            body={t('shared.ncaDetail.details.water.explainers.howDoMeasureThis.soilMoistureBody')}
          />
        </ExplainerBody>
      </ExplainerContent>
    </Explainer>
  );
};
