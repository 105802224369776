import { useQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_USERS_PATH,
  GetUsersRequestPathVariables,
  GetUsersSuccessDto,
  User,
} from '@/api/rest/resources/types/user';
import { useUser } from '@/lib/auth';

export const useUsers = () => {
  const user = useUser();

  const organizationId = user.membership.organization.id;

  const pathVariables: GetUsersRequestPathVariables = {
    organization: organizationId,
  };

  const queryFn = async () => {
    return (await getRequest<GetUsersSuccessDto>(GET_USERS_PATH, pathVariables)).results;
  };

  return useQuery<User[], Error>({
    queryKey: ['user', pathVariables],
    queryFn,
    staleTime: 1000 * 60 * 5,
    retry: false,
    throwOnError: true,
  });
};
