import { useLocation } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useUser } from '@/lib/auth';
import { paths } from '@/routing';

export const useComplianceFrameworksBackPath = () => {
  const { state } = useLocation();
  const user = useUser();

  const userType = user.membership.type;

  if (state?.previousPath) {
    return state.previousPath;
  }

  return userType === MembershipWithOrganizationTypeEnum.land_steward
    ? paths.landSteward.projects
    : paths.buyer.projects;
};
