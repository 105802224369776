import { Link } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/routing';

export const PasswordResetHelperText = React.forwardRef<HTMLDivElement, { helperText?: ReactNode }>(
  ({ helperText }, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
      <span ref={ref}>
        {helperText && <span>{helperText}. </span>}
        <span data-cy='forgot-password-text'>{`${t('login.forgotPassword')} `}</span>
        <Link
          href={paths.resetPassword}
          onClick={(e) => {
            e.preventDefault();
            navigate(paths.resetPassword);
          }}
          color='inherit'
          data-cy='forgot-password-link'
        >
          {t('login.forgotPasswordLink')}
        </Link>
      </span>
    );
  },
);
PasswordResetHelperText.displayName = 'PasswordResetHelperText';
