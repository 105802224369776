import { useParams } from 'react-router-dom';

export const usePlotId = () => {
  const { plotId } = useParams<{ plotId: string }>();

  if (!plotId) {
    throw new Error('Missing URL param: plotId');
  }

  return plotId;
};
