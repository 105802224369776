import { centerOfMass, feature as turfFeature, featureCollection } from '@turf/turf';
import { Feature, Point } from 'geojson';
import { GeoJSONSource, LngLatBoundsLike, LngLatLike, MapLayerMouseEvent } from 'mapbox-gl';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Layer, LayerProps, MapRef, Source } from 'react-map-gl';

import { PlotType } from '@/api/rest/resources/types/plot';
import { Map } from '@/components';
import { useBoundingBox } from '@/components/MapOverview/hooks/useBoundingBox';
import { MAP_OVERVIEW_PADDING_MOBILE } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { getGeometriesForPlots } from '@/utils/bounds';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';
import { getPNGIconForLandType } from '@/utils/getPNGIconForLandType';
import { isTestEnv } from '@/utils/isTestEnv';
import { getColorOfPlotType } from '@/utils/plot';

const CURRENT_PLOT_POLYGON_SOURCE_ID = 'current-plot-polygon';
const OTHER_PLOT_POLYGONS_SOURCE_ID = 'other-plot-polygons';
const OTHER_PLOT_MARKERS_SOURCE_ID = 'other-plot-markers';
const CURRENT_PLOT_MARKER_SOURCE_ID = 'current-plot-marker';

export const PlotMap = () => {
  const isLargeScreen = useScreenSize() === 'large';

  /**
   * The Map's onLoad callback is not triggered in the test environment, and so
   * the map remains hidden. We set the default value of isMapReady to true in
   * the test environment.
   */
  const [isMapReady, setIsMapReady] = useState(isTestEnv);

  const plots = usePlotsForProject().data.results;

  const currentPlotId = usePlotId();

  const currentPlot = plots.find((p) => p.id === currentPlotId);
  const otherPlots = plots.filter((p) => p.id !== currentPlotId);

  if (!currentPlot) {
    throw new UnexpectedMissingDataError({ dataLabel: 'currentPlot' });
  }

  const mapRef = useRef<MapRef | null>(null);

  const geometries = useMemo(() => getGeometriesForPlots([currentPlot]), [currentPlot]);
  const { bounds } = useBoundingBox({
    geometries,
    padding: MAP_OVERVIEW_PADDING_MOBILE,
  });

  const mapRefCallback = useCallback((ref: MapRef | null) => {
    if (ref !== null) {
      mapRef.current = ref;
      const map = ref;

      /**
       * Once the map is loaded, we load the icon PNGs so
       * that they can be referenced by the `iconLayer` layer.
       */
      Object.values(PlotType).forEach((plotType: PlotType) => {
        const loadImage = () => {
          if (!map.hasImage?.(`${plotType}-icon`)) {
            map.loadImage?.(getPNGIconForLandType(plotType), (error, image) => {
              if (error || image === undefined) throw error;
              map.addImage(`${plotType}-icon`, image, { sdf: true });
            });
          }
        };

        loadImage();
      });
    }
  }, []);

  /**
   * Explode the cluster and zoom in when a cluster is clicked.
   */
  const handleClusterMarkerClick = (feature: Feature<Point>, clusterId: number) => {
    if (!mapRef.current) return;

    const mapboxSource = mapRef.current.getSource(OTHER_PLOT_MARKERS_SOURCE_ID) as GeoJSONSource;

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;
      if (!mapRef.current) return;

      mapRef.current.easeTo({
        center: feature.geometry.coordinates as LngLatLike,
        zoom,
        duration: 500,
      });
    });
  };

  const handleMapClick = (event: MapLayerMouseEvent) => {
    const feature = event.features?.[0];

    if (!feature) return;

    const clusterId = feature.properties?.cluster_id;

    if (clusterId) {
      handleClusterMarkerClick(feature as Feature<Point>, clusterId);
    }
  };

  /**
   * Show pointer when hovering over a cluster marker.
   */
  const handleMapMouseMove = (event: MapLayerMouseEvent) => {
    const map = mapRef.current;

    if (!map) return;

    const feature = event.features?.[0];

    if (!feature) {
      map.getCanvas().style.cursor = 'initial';
    } else {
      map.getCanvas().style.cursor = 'pointer';
    }
  };

  /**
   * We have separate collections for the points (plotMarkers) and polygons
   * (plotPolygons). We supply them to separate sources with IDs
   * POLYGONS_SOURCE_ID and MARKERS_SOURCE_ID.
   *
   * The `plotMarkers` are clustered using Mapbox's clustering feature, so that
   * the markers don't look crowded when there are several markers close
   * together.
   */
  const otherPlotMarkers = featureCollection(
    otherPlots.map((plot, index) => ({
      ...centerOfMass(plot.polygon, {
        properties: {
          plot,
          name: plot.name,
          landType: plot.type,
          icon: `${plot.type}-icon`,
        },
      }),
      /**
       * We use index instead of plot.id because Mapbox doesn't allow
       * alpha-numeric feature IDs. Also, 0 is not a valid feature ID.
       * https://github.com/mapbox/mapbox-gl-js/issues/7986#issuecomment-469381664
       */
      id: index + 1,
    })),
  );
  const otherPlotPolygons = featureCollection(
    otherPlots.map((plot, index) =>
      turfFeature(
        plot.polygon,
        { plot, color: getColorOfPlotType(plot.type) },
        /**
         * We use index instead of plot.id because Mapbox doesn't allow
         * alpha-numeric feature IDs. Also, 0 is not a valid feature ID.
         * https://github.com/mapbox/mapbox-gl-js/issues/7986#issuecomment-469381664
         */
        { id: index + 1 },
      ),
    ),
  );

  const currentPlotMarker = featureCollection([
    {
      ...centerOfMass(currentPlot.polygon, {
        properties: {
          plot: currentPlot,
          name: currentPlot.name,
          landType: currentPlot.type,
          icon: `${currentPlot.type}-icon`,
        },
      }),
    },
  ]);

  const currentPlotPolygon = featureCollection([
    turfFeature(currentPlot.polygon, {
      plot: currentPlot,
      color: getColorOfPlotType(currentPlot.type),
    }),
  ]);

  return (
    <div className='h-[187px] w-full overflow-hidden rounded-3xl bg-primary-50 sm:h-[494px] md:h-full'>
      <Map
        ref={mapRefCallback}
        cooperativeGestures
        initialViewState={{
          bounds: bounds as LngLatBoundsLike,
          fitBoundsOptions: { padding: MAP_OVERVIEW_PADDING_MOBILE },
        }}
        onLoad={() => setIsMapReady(true)}
        onClick={handleMapClick}
        onMouseMove={handleMapMouseMove}
        interactiveLayerIds={[clusterLayer.id as string, clusterCountLayer.id as string]}
        style={{ opacity: isMapReady ? 1 : 0, transition: 'opacity 0.2s' }}
        data-testid='plot-map'
      >
        <Source id={OTHER_PLOT_POLYGONS_SOURCE_ID} type='geojson' data={otherPlotPolygons}>
          <Layer {...otherPlotPolygonsOutlineLayer} />
          <Layer {...otherPlotPolygonsFillLayer} />
        </Source>
        <Source id={OTHER_PLOT_MARKERS_SOURCE_ID} type='geojson' data={otherPlotMarkers} cluster={true}>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...otherPlotPointsLayer} />
          <Layer {...otherPlotIconsLayer} />
        </Source>

        <Source id={CURRENT_PLOT_POLYGON_SOURCE_ID} type='geojson' data={currentPlotPolygon}>
          <Layer {...currentPlotPolygonOutlineLayer} />
          <Layer {...currentPlotPolygonFillLayer} />
        </Source>
        <Source id={CURRENT_PLOT_MARKER_SOURCE_ID} type='geojson' data={currentPlotMarker}>
          <Layer {...currentPlotPointLayer} />
          <Layer {...currentPlotIconLayer} />
        </Source>
        {/* Bottom decoration */}
        {isLargeScreen && (
          <div className='pointer-events-none absolute bottom-0 h-32 w-full bg-gradient-to-t from-neutral-black-60 to-transparent' />
        )}
      </Map>
    </div>
  );
};

const otherPlotPolygonsOutlineLayer: LayerProps = {
  id: 'other-plot-polygons-outline',
  type: 'line',
  paint: {
    'line-width': 3,
    'line-opacity': 0.25,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const otherPlotPolygonsFillLayer: LayerProps = {
  id: 'other-plot-polygons-fill',
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.25,
  },
};

const clusterLayer: LayerProps = {
  id: 'cluster',
  type: 'circle',

  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#ffffff',
    'circle-stroke-width': 15,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-opacity': 0.4,
    'circle-radius': 20,
    'circle-opacity': 0.25,
  },
};

const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
  },
  paint: {
    'text-opacity': 1,
    'text-color': '#ffffff',
    'text-halo-color': '#505050',
    'text-halo-width': 0.8,
  },
};

const otherPlotPointsLayer: LayerProps = {
  id: 'other-plot-points',
  type: 'circle',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#ffffff',
    'circle-stroke-width': 10,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-opacity': 0.4,
    'circle-radius': 15,
    'circle-opacity': 0.25,
  },
};

const otherPlotIconsLayer: LayerProps = {
  id: 'other-plot-icons',
  type: 'symbol',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': 0.5,
    'text-field': ['get', 'name'],
    'text-offset': [0, 3],
    'text-anchor': 'bottom',
    'text-size': 12,
  },
  paint: {
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 2,
    'icon-opacity': 0.25,
    'text-opacity': 0.25,
  },
};

const currentPlotPolygonOutlineLayer: LayerProps = {
  id: 'current-plot-polygon-outline',
  type: 'line',
  paint: {
    'line-width': 3,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const currentPlotPolygonFillLayer: LayerProps = {
  id: 'current-plot-polygon-fill',
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.6,
  },
};

const currentPlotPointLayer: LayerProps = {
  id: 'current-plot-point',
  type: 'circle',
  paint: {
    'circle-color': '#ffffff',
    'circle-stroke-width': 10,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-opacity': 0.4,
    'circle-radius': 15,
  },
};

const currentPlotIconLayer: LayerProps = {
  id: 'current-plot-icon',
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': 0.5,
    'text-field': ['get', 'name'],
    'text-offset': [0, 3],
    'text-anchor': 'bottom',
    'text-size': 12,
  },
  paint: {
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 2,
  },
};
