import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PLOT_AGGREGATE_PATH,
  GET_PLOT_AGGREGATE_PATH,
  GetBuyerPlotAggregateErrorDto,
  GetBuyerPlotAggregateSuccessDto,
  GetPlotAggregateErrorDto,
  GetPlotAggregateSuccessDto,
} from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectId } from './useProjectId';

type SuccessDto = GetPlotAggregateSuccessDto | GetBuyerPlotAggregateSuccessDto;
type ErrorDto = GetPlotAggregateErrorDto | GetBuyerPlotAggregateErrorDto;

export const usePlotAggregate = () => {
  const projectId = useProjectId();
  const membershipType = useMembershipType();

  const queryParams = { project: projectId };
  const queryFn = () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_PLOT_AGGREGATE_PATH
        : GET_BUYER_PLOT_AGGREGATE_PATH,
      undefined,
      queryParams,
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'plot', 'aggregate', queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
