export enum FrameworksEnum {
  eudr = 'eudr',
  sai = 'sai',
  sbti = 'sbti',
  gcp = 'gcp',
}

/**
 * POST /api/v1/compliance/send-data-request-to-customer-support/
 */
export const POST_COMPLIANCE_SEND_DATA_REQUEST_TO_CUSTOMER_SUPPORT_PATH =
  'compliance/send-data-request-to-customer-support';
export type PostComplianceSendDataRequestToCustomerRequestPathVariables = undefined;
export type PostComplianceSendDataRequestToCustomerRequestQueryParams = undefined;
export type PostComplianceSendDataRequestToCustomerRequestBodyData = {
  project_id: string;
  email: string;
  frameworks: FrameworksEnum[];
};
export type PostComplianceSendDataRequestToCustomerSuccessDto = {
  success: boolean;
};
export type PostComplianceSendDataRequestToCustomerErrorDto = Error;
