import { cn, Stack, StackProps } from '@landler/tw-component-library';
import { forwardRef, HTMLAttributes, SVGProps } from 'react';

export const ChartTooltip = forwardRef<HTMLDivElement, StackProps>(({ className, ...delegated }, ref) => {
  return (
    <Stack
      ref={ref}
      className={cn('rounded-md bg-graph-tooltip px-2 py-1', className)}
      direction='col'
      spacing={1.5}
      {...delegated}
    />
  );
});
ChartTooltip.displayName = 'ChartTooltip';

export const ChartTooltipTextPrimary = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...delegated }, ref) => {
    return <p ref={ref} className={cn('typography-body2Semibold text-primary-100', className)} {...delegated} />;
  },
);
ChartTooltipTextPrimary.displayName = 'ChartTooltipTextPrimary';

export const ChartTooltipTextSecondary = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...delegated }, ref) => {
    return <p ref={ref} className={cn('typography-body2 text-primary-100', className)} {...delegated} />;
  },
);
ChartTooltipTextSecondary.displayName = 'ChartTooltipTextSecondary';

export const ChartTooltipDot = (props: SVGProps<SVGElement>) => {
  const { cx, cy, onMouseEnter, onMouseLeave } = props;

  /** For extrapolated/intrapolated points (null points in the raw data),
   *  either cy or cx may be null depending on which axis the graph is plotted over.
   *  If these points are not handled/rendered, they shoot out of the graph boundaries (since cy/cx is null)
   *  Hence, we filter/skip rendering the active dots for these dynamically generated values
   */
  if (!cx || !cy) return <></>;

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={6}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className='fill-none stroke-white-100 stroke-[3px]'
      />
      <circle cx={cx} cy={cy} r={4} className='fill-primary-100' />
    </g>
  );
};
