import React from 'react';
import { Trans } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

interface OnboardingPageHelperLinkProps extends LinkProps {
  text?: string;
  i18nKey: string;
  to: string;
  target?: React.HTMLAttributeAnchorTarget;
  'data-cy'?: string;
}

export const OnboardingPageHelperLink: React.FC<OnboardingPageHelperLinkProps> = ({
  i18nKey,
  to,
  'data-cy': dataCy,
  ...delegated
}) => {
  return (
    <div
      className='typography-body2 mt-5 text-center [text-wrap:balance]'
      data-cy={dataCy ? `${dataCy}-text` : undefined}
    >
      <Trans
        // @ts-ignore couldn't be bothered
        i18nKey={i18nKey}
        components={{
          Link: <Link to={to} className='underline' data-cy={dataCy ? `${dataCy}-link` : undefined} {...delegated} />,
          br: <br />,
        }}
      />
    </div>
  );
};
