import React from 'react';

export const isReactNode = (input: unknown): input is React.ReactNode => {
  return (
    typeof input === 'string' ||
    typeof input === 'number' ||
    input === null ||
    React.isValidElement(input) ||
    (Array.isArray(input) && input.every(isReactNode))
  );
};
