import bgPortfolioSrc from '@assets/images/bg-portfolio.jpg';

import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';

import { LandStewardPageLayout } from '../../layouts/LandStewardPage.layout';
import { About, Help, Hero } from './components';

export const LandstewardPortfolioPage = () => {
  return (
    <LandStewardPageLayout withCustomContentContainer>
      <div
        className='bg-cover full-bleed-x'
        style={{ backgroundImage: `url(${bgPortfolioSrc})` }}
        data-cy='portfolio-hero-bg'
      >
        <SignedInPageLayoutContentContainer>
          <Hero />
        </SignedInPageLayoutContentContainer>
      </div>
      <SignedInPageLayoutContentContainer>
        <div className='flex flex-col gap-8 lg:flex-row'>
          <About />
          <Help />
        </div>
      </SignedInPageLayoutContentContainer>
    </LandStewardPageLayout>
  );
};
