import 'mapbox-gl/dist/mapbox-gl.css';

import React, { ComponentProps } from 'react';
import { Map as MapGlMap, MapRef } from 'react-map-gl';

import { DEFAULT_MAP_STYLE, MAPBOX_TOKEN } from '@/config/constants';
import { fontFamily } from '@/theme/fontFamily';

export const Map = React.forwardRef<MapRef, ComponentProps<typeof MapGlMap>>(({ style, ...delegated }, ref) => (
  <MapGlMap
    ref={ref}
    mapStyle={DEFAULT_MAP_STYLE}
    mapboxAccessToken={MAPBOX_TOKEN}
    dragRotate={false}
    touchPitch={false}
    cursor='default'
    style={{ fontFamily: fontFamily.sans, ...style }}
    logoPosition='bottom-right'
    attributionControl={false}
    {...delegated}
  />
));
Map.displayName = 'Map';

export * from './hooks/useJustifyMap';
