import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { paths } from '@/routing';

import { useMembershipType } from './useMembershipType';

export const usePathSet = (inputMembershipType?: MembershipWithOrganizationTypeEnum) => {
  const membershipTypeFallback = useMembershipType();
  const membershipType = inputMembershipType ?? membershipTypeFallback;

  return membershipType === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;
};
