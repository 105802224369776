import React from 'react';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';

import { formatUnit } from './formatting';

type Dimension = 'hectares' | 'squareKilometers' | 'squareMeters';

export class Area {
  public value: number;

  public dimension: Dimension;

  #valueInSquareMeters: Area['value'];

  constructor({ value, dimension }: { value: Area['value']; dimension: Area['dimension'] }) {
    this.value = value;
    this.dimension = dimension;

    this.#valueInSquareMeters = this.#getValueInSquareMeters({ value, dimension });
  }

  #getValueInSquareMeters({ value, dimension }: { value: Area['value']; dimension: Area['dimension'] }) {
    if (dimension === 'squareKilometers') {
      return value * 1_000_000;
    }

    if (dimension === 'hectares') {
      return value * 10_000;
    }

    return value;
  }

  public toSquareMeters() {
    return new Area({ value: this.#valueInSquareMeters, dimension: 'squareMeters' });
  }

  public toSquareKilometers() {
    return new Area({
      value: this.#valueInSquareMeters / 1_000_000,
      dimension: 'squareKilometers',
    });
  }

  public toHectares() {
    return new Area({
      value: this.#valueInSquareMeters / 10_000,
      dimension: 'hectares',
    });
  }

  public printValue() {
    return getDisplayNumber(this.value);
  }

  public printUnit() {
    return formatUnit(
      (() => {
        const exhaustivenessCheck = (dimension: never) => {
          throw Error(`Unhandled dimension: ${dimension}`);
        };

        switch (this.dimension) {
          case 'hectares':
            return UnitEnum.ha;
          case 'squareKilometers':
            return UnitEnum['km^2'];
          case 'squareMeters':
            return UnitEnum['m^2'];

          default:
            return exhaustivenessCheck(this.dimension);
        }
      })(),
    );
  }

  public printDisplay() {
    const valueDisplay = this.printValue();
    const unitDisplay = this.printUnit();

    return `${valueDisplay} ${unitDisplay}`;
  }

  public renderDisplay(props?: React.ComponentProps<'span'>) {
    const valueDisplay = this.printValue();
    const unitDisplay = this.printUnit();

    return (
      <span {...props}>
        {valueDisplay}&nbsp;{unitDisplay}
      </span>
    );
  }
}

/**
 * @deprecated Use the `printDisplay()` method instead.
 */
export const useAreaDisplay = (area: Area) => {
  return area.printDisplay();
};
