import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECT_BY_ID_PATH,
  GET_PROJECT_BY_ID_PATH,
  GetBuyerProjectByIdErrorDto,
  GetBuyerProjectByIdSuccessDto,
  GetProjectByIdErrorDto,
  GetProjectByIdSuccessDto,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

type SuccessDto = GetProjectByIdSuccessDto | GetBuyerProjectByIdSuccessDto;
type ErrorDto = GetProjectByIdErrorDto | GetBuyerProjectByIdErrorDto;

export const useProjectDetailById = (projectIdArg?: string) => {
  const { projectId: paramProjectId } = useParams();
  const projectId = projectIdArg ?? paramProjectId;
  if (!projectId) {
    throw new UnexpectedMissingDataError('projectId');
  }

  const membershipType = useMembershipType();

  const queryFn = async () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_PROJECT_BY_ID_PATH
        : GET_BUYER_PROJECT_BY_ID_PATH,
      { id: projectId },
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'project', projectId],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
