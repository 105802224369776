export type ContractList = {
  project: string;
  start_date: string;
  end_date: string;
  claim_duration_days?: number;
  carbon_storage_claims?: number;
  water_holding_capacity_claims?: number;
  total_investment?: string;
};

/** GET /api/v1/landsteward/contract */
export const GET_LANDSTEWARD_CONTRACT_PATH = '/landsteward/contract';
export type GetLandstewardContractPathVariables = undefined;
export type GetLandstewardContractQueryParams = {
  id?: string;
  limit?: number;
  offset?: number;
  project?: string;
  sort?: 'id' | '-id' | 'created_at' | '-created_at' | 'start_date' | '-start_date' | 'end_date' | '-end_date';
};
export type GetLandstewardContractSuccessDto = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ContractList[];
};
export type GetLandstewardContractErrorDto = Error;

/** GET /api/v1/buyer/contract */
export const GET_BUYER_CONTRACT_PATH = '/buyer/contract';
export type GetBuyerContractPathVariables = undefined;
export type GetBuyerContractQueryParams = {
  id?: string;
  limit?: number;
  offset?: number;
  project?: string;
  sort?: 'id' | '-id' | 'created_at' | '-created_at' | 'start_date' | '-start_date' | 'end_date' | '-end_date';
};
export type GetBuyerContractSuccessDto = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ContractList[];
};
export type GetBuyerContractErrorDto = Error;
