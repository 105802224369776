import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { useLegalUrls } from '@/hooks/useLegalUrls';

import { ActionButton, InformationBox, InformationBoxEntry } from '../../../components';

export const DesktopView = () => {
  const { t } = useTranslation();

  const { privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();

  return (
    <Stack spacing={3} className='mx-auto' data-testid='account-legal-page-desktop-view'>
      <InformationBox
        data-cy='legal-title'
        title={t('shared.account.titles.legalDocuments')}
        entries={[
          <InformationBoxEntry
            key='tc'
            label=''
            value={t('shared.account.labels.termsAndConditions')}
            dataCy='terms'
            ActionButton={
              <ActionButton
                label={t('shared.account.buttons.view')}
                isExternalLink
                link={termsAndConditionsUrl}
                dataCy='tc-page-button'
              />
            }
          />,
          <InformationBoxEntry
            key='pp'
            label=''
            value={t('shared.account.labels.privacyPolicy')}
            dataCy='privacy'
            ActionButton={
              <ActionButton
                label={t('shared.account.buttons.view')}
                isExternalLink
                link={privacyPolicyUrl}
                dataCy='pp-page-button'
              />
            }
          />,
        ]}
      />
    </Stack>
  );
};
