import { AppBar, Box, Toolbar, ToolbarProps as MLToolbarProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { LandlerLogo } from '@/components/LandlerLogo/LandlerLogo';
import { paths } from '@/routing';

type NavbarProps = {
  ToolbarProps?: MLToolbarProps;
};

export const Navbar: React.FC<PropsWithChildren<NavbarProps>> = ({ children, ToolbarProps }) => {
  return (
    <AppBar
      className='!z-appbar'
      position='sticky'
      sx={{
        backgroundColor: 'common.white',
      }}
      elevation={1}
    >
      <Toolbar disableGutters sx={{ px: 3 }} {...ToolbarProps}>
        <Link reloadDocument to={paths.root} data-cy='logo-link'>
          <LandlerLogo className='w-[120px]' data-testid='navbar-landler-logo' />
        </Link>
        <Box display='flex' flexGrow={1} ml={4} alignItems='center'>
          {children}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
