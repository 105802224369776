import { useSuspenseQuery } from '@tanstack/react-query';

import { Tileset } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectKey } from './useProjectKey';

type TilesetsByLayer = {
  [layer: string]: {
    [year: string]: Tileset;
  };
};

const getTilesetsByProjectKey = async (projectKey: string) => {
  const module = await import(`../mockData/${projectKey}/tilesets.json`);

  return module.default as TilesetsByLayer;
};

export const useTilesetsByLayer = () => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', 'tilesets', projectKey],
    queryFn: () => getTilesetsByProjectKey(projectKey),
  });
};
