import { Route, Routes } from 'react-router-dom';

import { Form } from '@/components';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { NotFoundPage } from '@/pages/not-found';

import { useCreatePlotForm } from '../../hooks/usePlotForm';
import { withErrorBoundary } from './components/ErrorBoundary';
import { useDefaultPlotName } from './hooks/useDefaultPlotName';
import { DrawBoundaryPage } from './pages/draw-boundary.page';
import { PickPolygonEntryMethodPage } from './pages/pick-polygon-entry-method.page';
import { QuestionnairePage } from './pages/questionnaire.page';
import { ReviewUploadPage } from './pages/review-upload.page';
import { SuccessPage } from './pages/success.page';
import { UploadPolygonPage } from './pages/upload-polygon.page';

export const LandstewardNewPlotPage = withErrorBoundary(() => {
  const project = useProject().data;
  const { defaultPlotName } = useDefaultPlotName();
  const form = useCreatePlotForm({ plot: { name: defaultPlotName, type: project.landtypes_allowed[0] } });

  return (
    <Form {...form}>
      <Routes>
        <Route index element={<PickPolygonEntryMethodPage />} />

        <Route path='draw-boundary' element={<DrawBoundaryPage />} />
        <Route path='questionnaire' element={<QuestionnairePage />} />

        <Route path='upload' element={<UploadPolygonPage />} />
        <Route path='review' element={<ReviewUploadPage />} />
        <Route path='success' element={<SuccessPage />} />

        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Form>
  );
});
