import { Button, RiErrorWarningLine, Stack } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';

import { ErrorActivateAccountPageState } from '../../hooks/useActivateAccount';
import { ActivateAccountPageContent } from '../page-content/PageContent';

interface ActivateAccountFailedProps {
  pageState: ErrorActivateAccountPageState;
}

export const ActivateAccountFailed: React.FC<ActivateAccountFailedProps> = ({ pageState }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isSignedIn } = useAuth();

  const getLocalizedErrorMessage = () => {
    switch (pageState) {
      case ErrorActivateAccountPageState.INVALID:
      case ErrorActivateAccountPageState.NO_TOKEN_SUPPLIED:
        return t('activateAccount.errors.invalid');
      case ErrorActivateAccountPageState.EXPIRED:
        return t('activateAccount.errors.expired.info');
      case ErrorActivateAccountPageState.ALREADY_ACTIVATED:
        return t('activateAccount.errors.alreadyActivated');
      case ErrorActivateAccountPageState.UNKNOWN_ERROR:
      default:
        return t('activateAccount.errors.unknown');
    }
  };

  const errorMessage = getLocalizedErrorMessage();

  if (pageState === ErrorActivateAccountPageState.EXPIRED) {
    return <LinkExpired />;
  }

  return (
    <ActivateAccountPageContent headlineText={t('activateAccount.title')}>
      <Stack direction='row' spacing={1.5} className='items-center'>
        <RiErrorWarningLine size={22} className='text-error' />
        <span className='typography-body2 text-text-primary'>{errorMessage}</span>
      </Stack>
      <Button
        size='medium'
        className='mt-12 self-end'
        onClick={(e) => {
          e.preventDefault();
          navigate(isSignedIn ? paths.landSteward.projects : paths.login);
        }}
      >
        {t(isSignedIn ? 'activateAccount.backToProjects' : 'activateAccount.backToLogin')}
      </Button>
    </ActivateAccountPageContent>
  );
};

export const LinkExpired = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  return (
    <div className='w-full max-w-screen-sm'>
      <ActivateAccountPageContent headlineText=''>
        <Stack direction='col' spacing={8}>
          <h1 className='typography-display3 text-center text-info'>{t('activateAccount.errors.expired.title')}</h1>
          <span className='text-primary typography-body1 whitespace-pre-wrap'>
            {t('activateAccount.errors.expired.info')}
          </span>
        </Stack>
        <Button
          size='medium'
          className='mt-12 self-end'
          onClick={(e) => {
            e.preventDefault();
            navigate(paths.landSteward.account);
          }}
        >
          {t(
            isSignedIn
              ? 'activateAccount.errors.expired.continueToSettings'
              : 'activateAccount.errors.expired.continueToLogin',
          )}
        </Button>
      </ActivateAccountPageContent>
    </div>
  );
};
