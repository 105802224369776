import { cn, InfoPopover, InfoPopoverProps, Stack } from '@landler/tw-component-library';
import React, { ComponentProps } from 'react';

type BinaryLayer = {
  label: string;
  color: string;
};

type BinaryLegendProps = {
  title: string;
  layers: BinaryLayer[];
  infoPopoverProps?: InfoPopoverProps;
  onAttributeHover?: (layer: BinaryLayer) => void;
};

export const BinaryLegend = ({ title, layers, infoPopoverProps }: BinaryLegendProps) => {
  return (
    <Stack className='rounded-2xl bg-white-100 py-4' spacing={3}>
      <Stack direction='row' spacing={2} className='px-4'>
        <span className='typography-body2Semibold text-text-secondary'>{title}</span>
        {infoPopoverProps && <InfoPopover position='top' {...infoPopoverProps} />}
      </Stack>

      <Stack direction='row' className='flex-wrap px-2'>
        {layers.map((layer, index) => (
          <Stack key={index} direction='row' centerMain spacing={2} className={cn('rounded-md py-1 pl-2 pr-[10px]')}>
            <div className='h-2 w-2 rounded-full' style={{ backgroundColor: layer.color }} />
            <span className='typography-caption text-text-secondary'>{layer.label}</span>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

type IndexLegendProps = {
  title: string;
  infoPopoverProps?: InfoPopoverProps;
  range: [{ value: number; color: string }, { value: number; color: string }];
};

export const IndexLegend = ({ title, infoPopoverProps, range: [min, max] }: IndexLegendProps) => {
  const rangeDisplay = (() => {
    const formatter = (num: number) => {
      if (max.value <= 10) {
        return Intl.NumberFormat(window.navigator.language, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(num);
      }

      return Intl.NumberFormat(window.navigator.language, {
        maximumFractionDigits: 0,
      }).format(num);
    };

    const step = (max.value - min.value) / 5;

    const range = [min.value];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 5; i++) {
      range.push(min.value + i * step);
    }

    range.push(max.value);

    return range.map(formatter);
  })();

  return (
    <Stack className='rounded-2xl bg-white-100 py-4' spacing={3}>
      <Stack direction='row' spacing={2} className='px-4'>
        <span className='typography-body2Semibold text-text-secondary'>{title}</span>
        {infoPopoverProps && <InfoPopover position='top' {...infoPopoverProps} />}
      </Stack>

      <Stack spacing={2} className='px-4'>
        <div
          style={
            {
              '--gradient-start': min.color,
              '--gradient-end': max.color,
            } as React.CSSProperties
          }
          className='h-3 w-[213px] bg-gradient-to-r from-[var(--gradient-start)] to-[var(--gradient-end)]'
        />
        <Stack direction='row' className='justify-between'>
          {rangeDisplay.map((num) => (
            <span key={num} className='typography-caption text-text-secondary'>
              {num}
            </span>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const Legend = ({ className, ...delegated }: ComponentProps<'div'>) => {
  return <div className={cn('absolute bottom-6 left-6', className)} {...delegated} />;
};
