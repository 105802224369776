import i18next from 'i18next';

import { LANDING_PAGE_URL } from '@/config/constants';
import { ApplicationLanguage, isApplicationLanguage } from '@/types';

export function useTlgLink(path?: string) {
  let { language } = i18next;

  if (!isApplicationLanguage(language)) {
    language = ApplicationLanguage.EN;
  }

  if (path) {
    return `${LANDING_PAGE_URL}${path}/${language}`;
  }

  return LANDING_PAGE_URL;
}
