import { Link, LinkProps } from 'react-router-dom';

import { Button, ButtonProps } from '../Button/Button';

export type ButtonLinkProps = Omit<ButtonProps, 'asChild'> & LinkProps;

export const ButtonLink = ({ disabled, onClick, children, ...delegated }: ButtonLinkProps) => {
  return (
    <Button asChild disabled={disabled} {...delegated}>
      {/* @ts-ignore */}
      <Link
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          } else {
            onClick?.(e);
          }
        }}
      >
        {children}
      </Link>
    </Button>
  );
};
