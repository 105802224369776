import { ThemeOptions } from '@mui/material/styles';

import { breakpoints } from '../breakpoints';

export const dialogOverrides: ThemeOptions['components'] = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        margin: '16px',
        padding: '24px',
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          padding: '40px 56px',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '0',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '0',
      },
      spacing: {
        '& > :not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
};
