import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button/Button';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
} from '../Dialog/Dialog';

export type ConfirmationDialogProps = DialogProps & {
  header: string | React.ReactNode;
  body?: string | React.ReactNode;
  CancelAction?: string | React.ReactNode;
  PrimaryAction: string | React.ReactNode;
};

export const ConfirmationDialog = ({
  header,
  body,
  CancelAction: InputCancelAction,
  PrimaryAction,
  ...delegated
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  const CancelAction = InputCancelAction ?? t('global.ui.buttons.cancel');

  return (
    <Dialog {...delegated}>
      <DialogContent>
        <DialogHeader className='typography-h2 w-full text-center md:typography-h3 sm:text-center'>
          {header}
        </DialogHeader>
        <DialogBody className='mb-8 text-center'>{body}</DialogBody>
        <DialogFooter className='flex-wrap justify-end'>
          {typeof CancelAction === 'string' ? (
            <DialogClose asChild>
              <Button variant='text'>{CancelAction}</Button>
            </DialogClose>
          ) : (
            CancelAction
          )}
          {typeof PrimaryAction === 'string' ? <Button variant='contained'>{PrimaryAction}</Button> : PrimaryAction}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
