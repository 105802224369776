import * as RadixAvatar from '@radix-ui/react-avatar';
import { FC, HTMLAttributes } from 'react';

import { cn } from '../utils';

export type AvatarProps = HTMLAttributes<HTMLSpanElement> & {
  image?: string;
  name: string;
  delayMs?: number;
};

export const Avatar: FC<AvatarProps> = ({ image, name, delayMs = 600, className, ...delegated }) => {
  const styles = cn(
    'inline-flex h-10 w-10 select-none items-center justify-center overflow-hidden rounded-full align-middle',
    className,
  );

  return (
    <RadixAvatar.Root className={styles} {...delegated}>
      {image && <RadixAvatar.Image className='h-full w-full bg-neutral-black-4 object-cover' src={image} alt={name} />}
      <RadixAvatar.Fallback
        className='typography-body1 flex h-full w-full items-center justify-center bg-primary-100 text-white-100'
        delayMs={image ? delayMs : undefined}
      >
        {getInitials(name)}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
};

const getInitials = (name: string) => {
  const names = name.split(' ');

  if (!names[0]) {
    return '';
  }

  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    const lastName = names[names.length - 1];

    if (!lastName) {
      return initials;
    }

    initials += lastName.substring(0, 1).toUpperCase();
  }

  return initials;
};
