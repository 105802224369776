import React from 'react';

import { RequireAuth } from '../protections/require-auth/RequireAuth';
import { SkipLoggedInUser } from '../protections/skip-logged-in-user/SkipLoggedInUser';

/**
 * The user gets redirected to the initial page based on the signed in status and the user's role.
 */
export const RedirectToInitialPage: React.FC = () => (
  <SkipLoggedInUser>
    <RequireAuth />
  </SkipLoggedInUser>
);
