import { cva } from 'class-variance-authority';

export const containedGrayIndicatorVariants = cva('bg-neutral-black-4 px-2 rounded-md', {
  variants: {
    color: {
      error: ['text-error'],
      success: ['text-success-dark'],
    },
    size: {
      medium: ['py-2'],
      small: ['py-1'],
    },
  },
  defaultVariants: {
    color: 'success',
    size: 'medium',
  },
});
