import { Outlet } from 'react-router-dom';

import { BuyerPageLayout } from '@/pages/buyer/components';

export const BuyerComplianceFrameworksPageWrapper = () => {
  return (
    <BuyerPageLayout withCustomContentContainer>
      <Outlet />
    </BuyerPageLayout>
  );
};
