import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@landler/tw-component-library';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { User } from '@/api/rest/resources/types/user';

type RemoveDialogContentProps = {
  member: User;
  submit: (member: User) => void;
  isSubmitting: boolean;
};

export const RemoveDialogContent: FC<RemoveDialogContentProps> = ({ member, submit, isSubmitting }) => {
  const { t } = useTranslation();

  const memberName = `${member.firstname} ${member.lastname}`;

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className='text-error'>{t('shared.account.team.removeDialog.title')}</DialogTitle>
        <DialogDescription className='whitespace-pre-line'>
          <Trans
            i18nKey='shared.account.team.removeDialog.description'
            values={{ name: `${memberName}` }}
            components={{
              strong: <span className='typography-body1Semibold' />,
            }}
          />
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant='text'>{t('global.ui.buttons.cancel')}</Button>
        </DialogClose>
        <Button color='error' loading={isSubmitting} onClick={() => submit(member)}>
          {t('shared.account.team.removeDialog.submitButton')}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
