import { ButtonLink } from '@landler/tw-component-library';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { paths } from '@/routing';

import { LanguageSelector } from './language-selector/LanguageSelector';

export const OnboardingNavbarDesktopContent: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Stack
      direction='row'
      spacing={1}
      display='flex'
      flexGrow={1}
      justifyContent='end'
      data-testid='desktop-navbar-content'
    >
      <LanguageSelector />
      {pathname !== paths.login && (
        <ButtonLink to={paths.login} variant='outline' data-cy='login-button'>
          {t('navbar.login')}
        </ButtonLink>
      )}
    </Stack>
  );
};
