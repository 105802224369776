import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LandlerLogo } from '@/components/LandlerLogo/LandlerLogo';
import { useScreenSize } from '@/hooks/useScreenSize';
import { paths } from '@/routing';

import { OnboardingNavbarDesktopContent, OnboardingNavBarMobileContent } from './components';

export const OnboardingNavbar: React.FC = () => {
  const isLargeScreen = useScreenSize() === 'large';
  const { pathname } = useLocation();

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // do not redirect to /home if we are already on /login
    // otherwise we would add another entry to the browser history and create an infinite loop that breaks the browsers back button
    if (pathname === paths.login) {
      e.preventDefault();
    }
  };

  return (
    <AppBar className='z-appbar' position='static' sx={{ backgroundColor: 'common.white' }} elevation={1}>
      <Toolbar disableGutters sx={{ px: { xs: 3, sm: 6 } }}>
        <Link
          to={paths.root}
          onClick={handleLogoClick}
          data-testid='logo-link'
          data-cy='logo-link'
          style={{ display: 'flex' }}
        >
          <LandlerLogo className='w-[120px]' />
        </Link>
        {isLargeScreen ? <OnboardingNavbarDesktopContent /> : <OnboardingNavBarMobileContent />}
      </Toolbar>
    </AppBar>
  );
};
