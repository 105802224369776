// extending recharts api to set the axis limits to: eg: min=80% of the data min value, max=120% of the data max value
export const minMaxAxisDomain =
  (minMultiplier: number, maxMultiplier: number) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  ([dataMin, dataMax]: [number, number], allowDataOverflow?: boolean): [number, number] =>
    [Math.floor(dataMin * minMultiplier), Math.ceil(dataMax * maxMultiplier)];

export const oneYearInMilliseconds = 1 * 365 * 24 * 60 * 60 * 1000;

export const getYearDomainForNumOfYearsFromToday =
  (yearsBefore = 0, yearsAfter = 0) =>
  ([dateMin, dateMax]: [number, number]): [number, number] =>
    [
      Math.min(dateMin, new Date().getFullYear() - yearsBefore),
      Math.max(dateMax, new Date().getFullYear() + yearsAfter),
    ] satisfies [number, number];

/**  Science spec
 * For historic graphs -
 * - 1 tick per year, evenly spaced
 * - min = min(2018 OR min from the timeseries)
 * - max = max(currentYear OR max from the timeseries)
 * - mm/yyyy
 */
export const getXAxisDomainForHistoricGraph = (minDate: number, maxDate: number) => {
  const minYear = new Date(minDate).getFullYear();
  const maxYear = new Date(maxDate).getFullYear();

  const yearRange = getYearDomainForNumOfYearsFromToday(0, 0)([Math.min(2018, minYear), maxYear]);
  const startYear = yearRange[0];
  const endYear = yearRange[1];

  const ticksRange = Array(endYear - startYear + 1).keys();
  return Array.from(ticksRange).map((x) => new Date(`01-01-${startYear + x}`).getTime());
};

/**  Science spec
 * For potential graphs -
 * - 1 tick per year, evenly spaced
 * - min = min(currentYear OR min from the timeseries)
 * - max = max(currentYear + 10 OR max from the timeseries)
 * - yyyy format
 */
export const getXAxisDomainForPotentialGraph = (minYear: number, maxYear: number) => {
  const yearRange = getYearDomainForNumOfYearsFromToday(0, 10)([minYear, maxYear]);
  const startYear = yearRange[0];
  const endYear = yearRange[1];

  const ticksRange = Array(endYear - startYear + 1).keys();
  return Array.from(ticksRange).map((x) => x + startYear);
};
