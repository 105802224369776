import { cva } from 'class-variance-authority';

export const textButtonVariants = cva(
  [
    'rounded-full bg-transparent',
    'data-[disabled=true]:text-text-disabled',
    'data-[disabled=true]:cursor-not-allowed',
    'data-[disabled=true]:hover:bg-transparent',
  ],
  {
    variants: {
      size: {
        large: ['px-5 py-3 typography-h4 leading-[23.4px]'],
        medium: ['px-5 py-3 typography-button1 tracking-[0.2px]'],
        small: ['px-4 py-2 typography-button2 tracking-[0.3px]'],
      },
      color: {
        error: ['text-error hover:text-error-dark hover:bg-[#BA1B1B14]'],
        primary: ['text-primary-100 hover:bg-primary-12'],
        white: ['text-white-100'],
      },
    },
    defaultVariants: {
      size: 'medium',
      color: 'primary',
    },
  },
);
