import classNames from 'classnames';
import tailwindConfig from 'tailwind.config';
import { extendTailwindMerge } from 'tailwind-merge';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

export const customTwMerge = extendTailwindMerge({
  classGroups: {
    elevation: [{ elevation: ['0', '1', '2', '3', '4', '5'] }],
    typography: [
      {
        typography: [
          'h1',
          'h2',
          'h3',
          'h4',
          'body1',
          'body1Semibold',
          'body2',
          'body2Semibold',
          'link1',
          'link2',
          'overline',
          'caption',
          'display1',
          'display2',
          'display3',
          'display4',
          'display5',
          'button1',
          'button2',
          'inputLabel',
        ],
      },
    ],
  },
});

export const cn = (...args: classNames.ArgumentArray) => customTwMerge(classNames(...args));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const theme = resolveConfig(tailwindConfig).theme!;
