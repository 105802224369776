import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { ScheduleMeetingWidget } from '../../widgets';

export const InvestmentHero = () => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={8}
      className='max-w-5xl rounded-3xl bg-white-100 p-6 lg:p-10'
      data-testid='investment-hero'
      data-cy='investments-hero'
    >
      <Stack spacing={4}>
        <span className='typography-display2 text-primary-100' data-cy='investments-hero-title'>
          {t('buyer.investments.hero.title')}
        </span>
        <span className='typography-h4 whitespace-pre-wrap text-text-secondary' data-cy='investments-hero-subtitle'>
          {t('buyer.investments.hero.subtitle')}
        </span>
      </Stack>
      <ScheduleMeetingWidget className='bg-neutral-black-4' />
    </Stack>
  );
};
