import { TextField, TextFieldProps } from '@mui/material';
import React, { ReactElement } from 'react';

import { cn } from '../utils';

export type TextInputProps = TextFieldProps & {
  EndIcon?: ReactElement;
  StartIcon?: ReactElement;
};

export const TextInput = React.forwardRef<React.ElementRef<typeof TextField>, TextInputProps>(
  ({ className, InputProps, InputLabelProps, disabled, EndIcon, StartIcon, ...props }, ref) => {
    return (
      <TextField
        ref={ref}
        className={cn(disabled && '!text-text-disabled', className)}
        InputLabelProps={{
          ...InputLabelProps,
          className: cn(InputLabelProps?.className, disabled && '!text-text-disabled'),
        }}
        {...props}
        InputProps={{
          ...InputProps,
          disabled,
          startAdornment: StartIcon && <div className='mr-2'>{StartIcon}</div>,
          endAdornment: EndIcon && <div className='pl-2'>{EndIcon}</div>,
        }}
      />
    );
  },
);

TextInput.displayName = 'TextInput';
