import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

import { theme as defaultTheme } from './theme';

interface ThemeProviderProps extends React.PropsWithChildren {
  theme?: typeof defaultTheme;
}

export const ThemeProvider = ({ children, theme = defaultTheme }: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
