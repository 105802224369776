import { cva } from 'class-variance-authority';

export const outlineButtonVariants = cva(
  [
    'rounded-full border bg-transparent',
    'data-[disabled=true]:border-disabled-button-bg',
    'data-[disabled=true]:text-text-disabled',
    'data-[disabled=true]:cursor-not-allowed',
    'data-[disabled=true]:hover:bg-transparent',
    'data-[disabled=true]:hover:border-disabled-button-bg',
  ],
  {
    variants: {
      size: {
        // compensating for the 1px border
        large: ['px-[1.1875rem] py-[.6875rem] typography-h4 leading-[23.4px]'],
        medium: ['px-[1.1875rem] py-[.6875rem] typography-button1 tracking-[0.2px]'],
        small: ['px-[.9375rem] py-[.4375rem] typography-button2 tracking-[0.3px]'],
      },
      color: {
        error: ['text-error border-error hover:text-error-dark hover:bg-[#BA1B1B14]'],
        primary: ['text-primary-100 border-primary-100 hover:bg-primary-12 hover:border-primary-hover'],
        white: ['text-white-100 border-white-100'],
      },
    },
    defaultVariants: {
      size: 'medium',
      color: 'primary',
    },
  },
);
