import { useEffect, useState } from 'react';

import { RestApiServerError } from '@/api/rest/resources/errors/RestApiServerError';
import { usePostUserMeConfirmChangeMailRequest } from '@/api/rest/resources/user';
import { useUserContext } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { delay, IDelay } from '@/utils/delay';

export enum ConfirmMailChangeState {
  NO_TOKEN_PROVIDED = 'NO_TOKEN_PROVIDED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  EXPIRED_LINK = 'EXPIRED_LINK',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

const EXPIRED_LINK_STATUS_CODE = 403;
const ALREADY_VERIFIED_STATUS_CODE = 409;

export const useConfirmMailChange = (token: string | null): { state: ConfirmMailChangeState } => {
  const { mutate } = usePostUserMeConfirmChangeMailRequest();
  const { updateUserPartially } = useUserContext();

  const initalState = !token ? ConfirmMailChangeState.NO_TOKEN_PROVIDED : ConfirmMailChangeState.LOADING;

  const [confirmMailChangeState, setConfirmMailChangeState] = useState(initalState);

  const handleUnexpectedError = (error: Error) => {
    Logger.error(error);
    setConfirmMailChangeState(ConfirmMailChangeState.UNEXPECTED_ERROR);
  };

  const handleServerError = (error: Error) => {
    if (!(error instanceof RestApiServerError)) {
      handleUnexpectedError(error);
      return;
    }

    if (error.statusCode === EXPIRED_LINK_STATUS_CODE) {
      setConfirmMailChangeState(ConfirmMailChangeState.EXPIRED_LINK);
    } else if (error.statusCode === ALREADY_VERIFIED_STATUS_CODE) {
      setConfirmMailChangeState(ConfirmMailChangeState.ALREADY_VERIFIED);
    } else {
      handleUnexpectedError(error);
    }
  };

  const fireConfirmMailChangeRequest = (firedToken: string) => {
    mutate(
      { bodyData: { token: firedToken } },
      {
        onSuccess: (data) => {
          setConfirmMailChangeState(ConfirmMailChangeState.SUCCESS);
          updateUserPartially(data);
        },
        onError: handleServerError,
      },
    );
  };

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function confirmMailChangeOnMount() {
      let timer: IDelay | undefined;
      if (token) {
        // NOTE: Delaying the request has two purposes.
        // NOTE: 1. The loading spinner is displayed a little longer which makes the user experience better.
        // NOTE: 2. React strict mode mounts/unmounts each component twice. Without delaying the post request it would get fired twice.
        timer = delay(() => fireConfirmMailChangeRequest(token));
      } else {
        setConfirmMailChangeState(ConfirmMailChangeState.NO_TOKEN_PROVIDED);
      }

      return () => {
        if (timer) {
          timer.cancel();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  );

  return { state: confirmMailChangeState };
};
