import { useParams } from 'react-router-dom';

import { PlotReportFactElement, PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';

import { useDashboardByProjectId } from './useDashboardByProjectId';

const hasLabel = (filterLabel: PlotReportFactLabel, labels: PlotReportFactLabel[]) => {
  return labels.some((label) => {
    return Object.keys(filterLabel).every(
      (key) => label[key as keyof PlotReportFactLabel] === filterLabel[key as keyof PlotReportFactLabel],
    );
  });
};

const hasLabels = (filterLabels: PlotReportFactLabel[], labels: PlotReportFactLabel[]) => {
  return filterLabels.every((filterLabel) => hasLabel(filterLabel, labels));
};

/**
 * @param projectId Falls back to the projectId in the URL if no value is provided.
 */
export const useNCData = <T = unknown>(
  type: R1FactType,
  projectIdArg?: string,
  filterLabels?: PlotReportFactLabel[],
) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const dashboardData = useDashboardByProjectId(projectId).data;

  // TODO: MVP-3284 refactor into useDashboard like plot-report
  const getFact = (factType: R1FactType, labels?: PlotReportFactLabel[]) => {
    return dashboardData.facts.find(
      (f) => f.name === factType && (labels && f.labels ? hasLabels(labels, f.labels) : true),
    ) as PlotReportFactElement<T | null> | undefined;
  };

  if (!Object.keys(R1FactType).includes(type)) {
    throw Error(`Unrecognised type received: ${type}`);
  }

  return getFact(type, filterLabels);
};
