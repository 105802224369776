import { FC, HTMLAttributes } from 'react';
import { cn } from 'tw-component-library/utils';

export const SectionCard: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...delegated }) => (
  <div className={cn('rounded-[10px] bg-white-100 px-6 py-6 md:py-8', className)} {...delegated}>
    {children}
  </div>
);

export const SectionCardHeading: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...delegated }) => (
  <div
    className={cn('typography-overline mb-4 border-b border-divider pb-2 text-text-primary', className)}
    {...delegated}
  >
    {children}
  </div>
);
