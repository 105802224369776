/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosHeaders } from 'axios';

import i18n from '@/config/i18n';
import { tokenStorage } from '@/config/tokenStorage';

import { getRestApiBaseUrlWithVersion } from './getRestApiBaseUrlWithVersion';
import { BadRequestReponseData, RestApiBadRequestServerError } from './resources/errors/RestApiBadRequestError';
import { GeneralErrorResponseData, RestApiGeneralError } from './resources/errors/RestApiGeneralError';

const ACCEPTED_HEADER = 'application/json';

const isBadRequestResponse = (
  response: BadRequestReponseData | GeneralErrorResponseData,
  status?: number,
): response is BadRequestReponseData => {
  return status === 400;
};

export const restApi = axios.create({
  baseURL: getRestApiBaseUrlWithVersion(),
});

restApi.interceptors.request.use((config) => {
  const authToken = tokenStorage.getItem('authToken');

  // ensure URL ends with trailing slash
  config.url += config.url?.endsWith('/') ? '' : '/';

  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }
  config.headers['Accept-Language'] = i18n.language;
  config.headers.Accept = ACCEPTED_HEADER;

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

restApi.interceptors.response.use(
  (response) => {
    // status codes 2xx response
    return response;
  },
  // NOTE: The error reponse has always this interface we could probably validate the error response which
  // NOTE: logs an error if the response has another format
  (error: AxiosError<GeneralErrorResponseData | BadRequestReponseData>) => {
    // if "response" is defined, it is definitely a server error and not a local error such as "connection error"
    if (error.response === undefined) {
      return Promise.reject(error);
    }

    const { data } = error.response;

    if (isBadRequestResponse(data, error.response.status)) {
      return Promise.reject(
        new RestApiBadRequestServerError({
          data,
        }),
      );
    }

    return Promise.reject(new RestApiGeneralError({ data, status: error.response.status }));
  },
);
