import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { GET_USER_ME_PATH, GetUserMeErrorDto, GetUserMeSuccessDto } from '@/api/rest/resources/types/user';

export const useUserMe = () => {
  const queryFn = async () => {
    return getRequest<GetUserMeSuccessDto>(GET_USER_ME_PATH);
  };

  return useSuspenseQuery<GetUserMeSuccessDto, GetUserMeErrorDto>({
    queryKey: ['user', 'me'],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
