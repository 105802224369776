import { UseMutationOptions } from '@tanstack/react-query';

import { RequestData } from '../hooks/types';
import { useApiDeleteRequest } from '../hooks/useApiDeleteRequest';
import { useApiPatchRequest } from '../hooks/useApiPatchRequest';
import { useApiPostRequest } from '../hooks/useApiPostRequest';
import { useApiUploadRequest } from '../hooks/useApiUploadRequest';
import { buildUseGetQuery } from '../utils';
import {
  DELETE_PLOT_PATH,
  DeletePlotErrorDto,
  DeletePlotRequestPathVariables,
  DeletePlotRequestQueryParams,
  DeletePlotSuccessDto,
  GET_BUYER_PLOT_BY_ID_PATH,
  GET_BUYER_PLOTS_PATH,
  GET_DRAFT_PLOT_PATH,
  GET_PLOT_BY_ID_PATH,
  GET_PLOT_PATH,
  GET_PLOT_QUESTIONNAIRE_PATH,
  GetBuyerPlotByIdErrorDto,
  GetBuyerPlotByIdRequestPathVariables,
  GetBuyerPlotByIdRequestQueryParams,
  GetBuyerPlotByIdSuccessDto,
  GetBuyerPlotsErrorDto,
  GetBuyerPlotsRequestPathVariables,
  GetBuyerPlotsRequestQueryParams,
  GetBuyerPlotsSuccessDto,
  GetDraftPlotErrorDto,
  GetDraftPlotRequestPathVariables,
  GetDraftPlotRequestQueryParams,
  GetDraftPlotSuccessDto,
  GetPlotByIdErrorDto,
  GetPlotByIdRequestPathVariables,
  GetPlotByIdRequestQueryParams,
  GetPlotByIdSuccessDto,
  GetPlotErrorDto,
  GetPlotQuestionnaireErrorDto,
  GetPlotQuestionnaireRequestPathVariables,
  GetPlotQuestionnaireRequestQueryParams,
  GetPlotQuestionnaireSuccessDto,
  GetPlotRequestPathVariables,
  GetPlotRequestQueryParams,
  GetPlotSuccessDto,
  PATCH_PLOT_PATH,
  PATCH_PLOT_QUESTIONNAIRE_PATH,
  PatchPlotErrorDto,
  PatchPlotQuestionnaireErrorDto,
  PatchPlotQuestionnaireRequestBodyData,
  PatchPlotQuestionnaireRequestPathVariables,
  PatchPlotQuestionnaireRequestQueryParams,
  PatchPlotQuestionnaireSuccessDto,
  PatchPlotRequestBodyData,
  PatchPlotRequestPathVariables,
  PatchPlotRequestQueryParams,
  PatchPlotSuccessDto,
  POST_DRAFT_PLOT_COMMIT_PATH,
  POST_DRAFT_PLOT_PATH,
  POST_PLOT_PATH,
  POST_PLOT_VALIDATE_POLYGON_PATH,
  PostDraftPlotCommitErrorDto,
  PostDraftPlotCommitRequestBodyData,
  PostDraftPlotCommitRequestPathVariables,
  PostDraftPlotCommitRequestQueryParams,
  PostDraftPlotCommitSuccessDto,
  PostDraftPlotErrorDto,
  PostDraftPlotRequestBodyData,
  PostDraftPlotRequestPathVariables,
  PostDraftPlotRequestQueryParams,
  PostDraftPlotSuccessDto,
  PostPlotErrorDto,
  PostPlotRequestBodyData,
  PostPlotRequestPathVariables,
  PostPlotRequestQueryParams,
  PostPlotSuccessDto,
  PostPlotValidatePolygonErrorDto,
  PostPlotValidatePolygonRequestBodyData,
  PostPlotValidatePolygonRequestPathVariables,
  PostPlotValidatePolygonRequestQueryParams,
  PostPlotValidatePolygonSuccessDto,
} from './types/plot';

export const useGetDraftPlot = buildUseGetQuery<
  GetDraftPlotRequestPathVariables,
  GetDraftPlotRequestQueryParams,
  GetDraftPlotSuccessDto,
  GetDraftPlotErrorDto
>((params) => ['land_steward', 'draftplot', params], GET_DRAFT_PLOT_PATH);

export const usePostDraftPlot = () => {
  return useApiUploadRequest<
    PostDraftPlotRequestPathVariables,
    PostDraftPlotRequestQueryParams,
    PostDraftPlotRequestBodyData,
    PostDraftPlotSuccessDto,
    PostDraftPlotErrorDto
  >(POST_DRAFT_PLOT_PATH);
};

export const usePostDraftPlotCommit = () => {
  return useApiPostRequest<
    PostDraftPlotCommitRequestPathVariables,
    PostDraftPlotCommitRequestQueryParams,
    PostDraftPlotCommitRequestBodyData,
    PostDraftPlotCommitSuccessDto,
    PostDraftPlotCommitErrorDto
  >(POST_DRAFT_PLOT_COMMIT_PATH);
};

export const useGetPlots = buildUseGetQuery<
  GetPlotRequestPathVariables,
  GetPlotRequestQueryParams,
  GetPlotSuccessDto,
  GetPlotErrorDto
>((params) => ['land_steward', 'plot', params], GET_PLOT_PATH);

export const useGetPlotById = buildUseGetQuery<
  GetPlotByIdRequestPathVariables,
  GetPlotByIdRequestQueryParams,
  GetPlotByIdSuccessDto,
  GetPlotByIdErrorDto
>((params) => ['land_steward', 'plot', params?.pathVariables.id, params], GET_PLOT_BY_ID_PATH);

export const usePostPlot = () => {
  return useApiPostRequest<
    PostPlotRequestPathVariables,
    PostPlotRequestQueryParams,
    PostPlotRequestBodyData,
    PostPlotSuccessDto,
    PostPlotErrorDto
  >(POST_PLOT_PATH);
};

export const usePatchPlot = (
  options?: Omit<
    UseMutationOptions<
      PatchPlotSuccessDto,
      PatchPlotErrorDto,
      RequestData<PatchPlotRequestPathVariables, PatchPlotRequestQueryParams, PatchPlotRequestBodyData>
    >,
    'mutationFn'
  >,
) => {
  return useApiPatchRequest<
    PatchPlotRequestPathVariables,
    PatchPlotRequestQueryParams,
    PatchPlotRequestBodyData,
    PatchPlotSuccessDto,
    PatchPlotErrorDto
  >(PATCH_PLOT_PATH, options);
};

export const useDeletePlot = () => {
  return useApiDeleteRequest<
    DeletePlotRequestPathVariables,
    DeletePlotRequestQueryParams,
    DeletePlotSuccessDto,
    DeletePlotErrorDto
  >(DELETE_PLOT_PATH);
};

export const useGetPlotQuestionnaire = buildUseGetQuery<
  GetPlotQuestionnaireRequestPathVariables,
  GetPlotQuestionnaireRequestQueryParams,
  GetPlotQuestionnaireSuccessDto,
  GetPlotQuestionnaireErrorDto
>(
  (params) => ['land_steward', 'plot', params?.pathVariables.id, 'get_questionnaire', params],
  GET_PLOT_QUESTIONNAIRE_PATH,
);

export const usePatchPlotQuestionnaire = (
  options?: Omit<
    UseMutationOptions<
      PatchPlotQuestionnaireSuccessDto,
      PatchPlotQuestionnaireErrorDto,
      RequestData<
        PatchPlotQuestionnaireRequestPathVariables,
        PatchPlotQuestionnaireRequestQueryParams,
        PatchPlotQuestionnaireRequestBodyData
      >
    >,
    'mutationFn'
  >,
) => {
  return useApiPatchRequest<
    PatchPlotQuestionnaireRequestPathVariables,
    PatchPlotQuestionnaireRequestQueryParams,
    PatchPlotQuestionnaireRequestBodyData,
    PatchPlotQuestionnaireSuccessDto,
    PatchPlotQuestionnaireErrorDto
  >(PATCH_PLOT_QUESTIONNAIRE_PATH, options);
};

export const usePostPlotValidatePolygon = () => {
  return useApiPostRequest<
    PostPlotValidatePolygonRequestPathVariables,
    PostPlotValidatePolygonRequestQueryParams,
    PostPlotValidatePolygonRequestBodyData,
    PostPlotValidatePolygonSuccessDto,
    PostPlotValidatePolygonErrorDto
  >(POST_PLOT_VALIDATE_POLYGON_PATH);
};

export const useGetBuyerPlots = buildUseGetQuery<
  GetBuyerPlotsRequestPathVariables,
  GetBuyerPlotsRequestQueryParams,
  GetBuyerPlotsSuccessDto,
  GetBuyerPlotsErrorDto
>((params) => ['buyer', 'plot', params], GET_BUYER_PLOTS_PATH);

export const useGetBuyerPlotById = buildUseGetQuery<
  GetBuyerPlotByIdRequestPathVariables,
  GetBuyerPlotByIdRequestQueryParams,
  GetBuyerPlotByIdSuccessDto,
  GetBuyerPlotByIdErrorDto
>((params) => ['buyer', 'plot', params?.pathVariables.id, params], GET_BUYER_PLOT_BY_ID_PATH);
