import { Button, Text } from '@landler/component-library';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { OnboardingPageHeading, OnboardingPagePaper } from '@/layout/OnboardingPage';
import { paths } from '@/routing';

export const ResetPasswordSubmitted: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <OnboardingPageHeading dataCy='link-sent' headlineText={t('passwordReset.success.title')} />
      <OnboardingPagePaper>
        <Stack spacing={3}>
          <Text variant='body1' data-cy='link-sent-subtitle'>
            {t('passwordReset.success.subtitle')}
          </Text>
          <Button
            href={paths.login}
            size='large'
            sx={{ alignSelf: 'end' }}
            onClick={(e) => {
              e.preventDefault();
              navigate(paths.login);
            }}
            data-cy='link-sent-back-button'
          >
            {t('passwordReset.success.backButton')}
          </Button>
        </Stack>
      </OnboardingPagePaper>
    </>
  );
};
