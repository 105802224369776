import {
  BottomSheet,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetTitleProps,
  BottomSheetTrigger,
  BottomSheetTriggerProps,
  Button,
  cn,
  Stack,
  StackProps,
} from '@landler/tw-component-library';
import { FC } from 'react';

export const Explainer = BottomSheet;

export const ExplainerTrigger: FC<BottomSheetTriggerProps & { children: string }> = ({ children, ...delegated }) => {
  return (
    <BottomSheetTrigger asChild {...delegated}>
      <Button variant='outline'>{children}</Button>
    </BottomSheetTrigger>
  );
};

export const ExplainerContent = BottomSheetContent;

export const ExplainerTitle: FC<BottomSheetTitleProps> = ({ children, ...delegated }) => {
  return (
    <BottomSheetHeader>
      <BottomSheetTitle {...delegated}>{children}</BottomSheetTitle>
    </BottomSheetHeader>
  );
};

export const ExplainerBody: FC<StackProps> = ({ children, className, ...delegated }) => {
  return (
    <Stack
      direction='row'
      spacing={10}
      className={cn('grid grid-flow-row auto-rows-auto pb-20 pt-10 md:auto-cols-auto md:grid-flow-col', className)}
      {...delegated}
    >
      {children}
    </Stack>
  );
};

export const ExplainerCopy: FC<{ title: string; body: string }> = ({ title, body }) => {
  return (
    <Stack>
      <h3 className='typography-h3 mb-3 md:mb-6'>{title}</h3>
      {body.split('\n').map((string, index) => (
        <p key={index} className='mb-3 text-text-secondary last:mb-0'>
          {string}
        </p>
      ))}
    </Stack>
  );
};
