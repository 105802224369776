export const HackHubSpotChatButton = () => {
  // The Hubspot button overlaps the bottom bar.
  // This a very ugly hack to fix it.
  // We should remove this when we have a better solution.
  return (
    <style>
      {`  
        #hubspot-messages-iframe-container {
          bottom: 80px !important;
        }
      `}
    </style>
  );
};
