import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { BiodiversityCard } from './nc-cards/biodiversity/Card';
import { CarbonCard } from './nc-cards/carbon/Card';
import { WaterCard } from './nc-cards/water/Card';

export const NCBreakdown = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={10} data-testid='nc-breakdown'>
      <span className='typography-overline'>{t('shared.ncaDetail.details.labels.naturalCapitalBreakdown')}</span>
      <CarbonCard />
      <WaterCard />
      <BiodiversityCard />
    </Stack>
  );
};
