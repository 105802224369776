import { Logger } from '@/lib/logs/logger';

export const replacePathVariables = (originalPath: string, pathVariables: unknown): string => {
  let path = originalPath;

  if (pathVariables) {
    Object.entries(pathVariables as Record<string, unknown>).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        path = path.replaceAll(`{${key}}`, `${value}`);
      } else {
        Logger.errorOnce(`API path variable "${key}" of path "${originalPath}" has an invalid type: ${typeof value}`);
      }
    });
  }

  const hasPathVariables = /{.+}/.test(path);
  if (hasPathVariables) {
    Logger.errorOnce(`API path "${originalPath}" has unresolved variables: ${path}`);
  }

  return path;
};
