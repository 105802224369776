import { usePostComplianceSendDataRequestToCustomerSupport } from '@/api/rest/resources/compliance';
import {
  FrameworksEnum,
  PostComplianceSendDataRequestToCustomerRequestBodyData,
} from '@/api/rest/resources/types/compliance';

type FrameworkValue = {
  value: FrameworksEnum;
  label: string;
  checked: boolean;
};

export type RequestCompliancaDataInputsFrameworks = [FrameworkValue, FrameworkValue, FrameworkValue, FrameworkValue];

export type RequestCompliancaDataInputs = {
  project_id: string;
  email: string;
  frameworks: RequestCompliancaDataInputsFrameworks;
};

export const useRequestComplianceData = () => {
  const postComplianceSendDataRequestToCustomerSupportMutation = usePostComplianceSendDataRequestToCustomerSupport();

  const submit = async (inputs: RequestCompliancaDataInputs) => {
    const bodyData: PostComplianceSendDataRequestToCustomerRequestBodyData = {
      project_id: inputs.project_id,
      email: inputs.email,
      frameworks: inputs.frameworks.filter((framework) => framework.checked).map((framework) => framework.value),
    };

    await postComplianceSendDataRequestToCustomerSupportMutation.mutateAsync({
      bodyData,
    });
  };

  const isSubmitting = postComplianceSendDataRequestToCustomerSupportMutation.isPending;

  return { submit, isSubmitting };
};
