import { toast } from '@landler/tw-component-library';
import { useCallback } from 'react';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RestApiServerError } from '@/api/rest/resources/errors/RestApiServerError';
import { Logger } from '@/lib/logs/logger';

export const isRestApiServerError = (e: unknown): e is RestApiServerError => {
  return e instanceof RestApiServerError;
};

type FormErrors = Record<string, string[]>;

export function useRestApiServerErrorHandling<TFieldValues extends FieldValues = FieldValues>(
  setFormError: UseFormSetError<TFieldValues>,
  formFields?: (keyof TFieldValues)[],
) {
  const { t } = useTranslation();

  const isFormField = useCallback(
    (fieldName: string): fieldName is Path<TFieldValues> => {
      if (!formFields) {
        return false;
      }
      return formFields.includes(fieldName);
    },
    [formFields],
  );

  const addErrorsToFormField = useCallback(
    (fieldName: Path<TFieldValues>, messages: (string | Record<string, string[]>)[]) => {
      if (typeof messages[0] === 'string') {
        const message = messages.join(', ');
        setFormError(fieldName, { type: 'custom', message });
      } else {
        messages.forEach((messageItem, index) => {
          Object.entries(messageItem).forEach(([key, value]) => {
            const message = value.join(', ');
            setFormError(`${fieldName}.${index}.${key}` as Path<TFieldValues>, { type: 'custom', message });
          });
        });
      }
    },
    [setFormError],
  );

  return useCallback(
    (e: RestApiServerError | unknown): { serverErrors: string[]; formErrors: FormErrors; statusCode?: number } => {
      if (!isRestApiServerError(e)) {
        throw e;
      }

      const genericErrors = e.errors;
      const formErrors: FormErrors = {};

      e.formErrors.forEach((error) => {
        if (isFormField(error.field)) {
          addErrorsToFormField(error.field, error.messages);

          formErrors[error.field] = error.messages;
        } else {
          genericErrors.push(...error.messages);
        }
      });

      if (genericErrors[0]) {
        if (genericErrors[0] !== 'UNKNOWN_ERROR') {
          toast({
            title: genericErrors[0],
            type: 'error',
            autoClose: 10_000,
          });
        } else {
          Logger.warnOnce("Didn't receive error message, rendering generic error toast.");

          toast({
            title: t('global.ui.toast.errorToastFallbackTitle'),
            description: t('global.ui.toast.errorToastFallbackDescription'),
            type: 'error',
            autoClose: 10_000,
          });
        }
      }

      return { serverErrors: genericErrors, formErrors, statusCode: e.statusCode };
    },
    [isFormField, addErrorsToFormField, t],
  );
}
