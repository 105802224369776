import { Stack } from '@landler/tw-component-library';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../card/Card';

export const About = () => {
  const { t } = useTranslation();

  const items = [
    t('landSteward.portfolio.about.step1'),
    t('landSteward.portfolio.about.step2'),
    t('landSteward.portfolio.about.step3'),
  ];

  return (
    <Card label={t('landSteward.portfolio.about.label')} data-testid='portfolio-about' data-cy='portfolio-about'>
      <Stack spacing={6}>
        {items.map((item, index) => (
          <ListItem key={index} number={index + 1} text={item} />
        ))}
      </Stack>
    </Card>
  );
};

type ListItemProps = {
  number: number;
  text: string;
};

const ListItem: FC<ListItemProps> = ({ number, text }) => (
  <Stack direction='row' spacing={4}>
    <span className='typography-body2Semibold flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-primary-100 text-primary-100'>
      {number}
    </span>
    <span
      className='typography-body1 whitespace-pre-line text-text-secondary'
      data-cy={`portfolio-about-item-${number}`}
    >
      {text}
    </span>
  </Stack>
);
