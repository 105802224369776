import _pick from 'lodash/pick';

import { PlotInputs } from './types';

export const buildQuestionnaireBody = (inputs: PlotInputs) => {
  return _pick(inputs, grasslandFields);
};

export const grasslandFields = [
  'grassland_biodiversity_livestock_units',
  'grassland_converted_to_permanent_in_last_20y',
  'grassland_grass_type',
  'grassland_num_of_mechanical_events_per_year',
  'grassland_organic_fertilizer_type',
  'grassland_regularly_exposed_to_backwater',
  'grassland_subject_to_water_shortages',
  'grassland_converted_to_permanent_in_last_20y',
];
