import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useState } from 'react';

import { uploadRequest } from './requests/upload';
import { RequestData } from './types';

export const useApiUploadRequest = <PathVariables, QueryParams, BodyData, SuccessResponse, ErrorResponse = Error>(
  path: string,
  options?: Omit<
    UseMutationOptions<SuccessResponse, ErrorResponse, RequestData<PathVariables, QueryParams, BodyData>>,
    'mutationFn'
  >,
) => {
  const [progress, setProgress] = useState(0);

  const controller = new AbortController();
  const { signal } = controller;

  const mutation = useMutation<SuccessResponse, ErrorResponse, RequestData<PathVariables, QueryParams, BodyData>>({
    mutationFn: (params) =>
      uploadRequest<SuccessResponse>(
        path,
        params?.pathVariables,
        params?.queryParams,
        params?.bodyData,
        signal,
        setProgress,
      ),
    ...options,
  });

  const abort = () => {
    controller.abort();
    mutation.reset();
    setProgress(0);
  };

  return {
    abort,
    progress,
    ...mutation,
  };
};
