import {
  ButtonLink,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';

export const SuccessDialog: React.FC<DialogProps> = (props) => {
  const { t } = useTranslation();
  const { accountPath } = useAccountPageRoutes();

  return (
    <Dialog {...props}>
      <DialogContent data-testid='password-change-success-dialog' data-cy='password-change-success-dialog'>
        <DialogHeader>
          <DialogTitle data-cy='password-change-success-dialog-title'>
            {t('shared.account.editPassword.successDialog.headline')}
          </DialogTitle>
          <DialogDescription data-cy='password-change-success-dialog-text1'>
            {t('shared.account.editPassword.successDialog.body.paragraph1')}
          </DialogDescription>
          <DialogDescription data-cy='password-change-success-dialog-text2'>
            {t('shared.account.editPassword.successDialog.body.paragraph2')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='justify-end'>
          <ButtonLink to={accountPath} data-cy='dialog-submit-button'>
            {t('shared.account.editPassword.successDialog.button')}
          </ButtonLink>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
