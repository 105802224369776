import * as Dialog from '@radix-ui/react-dialog';
import React, { FC, HTMLAttributes } from 'react';

import { RiCloseLine } from '../Icon/Icon';
import { Stack } from '../Stack/Stack';
import { cn } from '../utils';

export type BottomSheetProps = Dialog.DialogProps;

export const BottomSheet = Dialog.Root;

export type BottomSheetTriggerProps = Dialog.DialogTriggerProps;

export const BottomSheetTrigger = Dialog.Trigger;

export type BottomSheetHeaderProps = HTMLAttributes<HTMLDivElement>;

export const BottomSheetHeader: FC<BottomSheetHeaderProps> = ({ className, children, ...delegated }) => {
  return (
    <Stack
      direction='row'
      centerMain
      className={cn(
        'sticky top-0 justify-between gap-4 border-b border-b-divider bg-white-100 p-10 pb-8 pr-6 full-bleed-x',
        className,
      )}
      {...delegated}
    >
      {children}
      <div className='ml-auto'>
        <BottomSheetCloseButton />
      </div>
    </Stack>
  );
};

export type BottomSheetContentProps = React.ComponentPropsWithoutRef<typeof Dialog.Content>;

export const BottomSheetContent = React.forwardRef<React.ElementRef<typeof Dialog.Content>, BottomSheetContentProps>(
  ({ className, children, ...props }, ref) => (
    <BottomSheetPortal>
      <BottomSheetOverlay />
      <Dialog.Content
        ref={ref}
        className={cn(
          'fixed bottom-0 max-h-screen w-full overflow-y-auto rounded-tl-2xl rounded-tr-2xl bg-white-100',
          'grid grid-cols-[1fr_min(var(--bp-lg),_calc(100%_-_80px))_1fr] gap-x-10 [&>*]:col-start-2',
          'animate-in data-[state=open]:fade-in data-[state=open]:slide-in-from-bottom-10',
          className,
        )}
        {...props}
      >
        {children}
      </Dialog.Content>
    </BottomSheetPortal>
  ),
);
BottomSheetContent.displayName = 'BottomSheetContent';

export type BottomSheetTitleProps = React.ComponentPropsWithoutRef<typeof Dialog.Title>;

export const BottomSheetTitle = React.forwardRef<React.ElementRef<typeof Dialog.Title>, BottomSheetTitleProps>(
  ({ className, ...props }, ref) => <Dialog.Title ref={ref} className={cn('typography-h3', className)} {...props} />,
);
BottomSheetTitle.displayName = Dialog.Title.displayName;

const BottomSheetPortal: FC<Dialog.DialogPortalProps> = ({ className, children, ...props }) => (
  <Dialog.Portal className={cn(className)} {...props}>
    <div className='fixed inset-0 z-dialog flex items-start justify-center sm:items-center'>{children}</div>
  </Dialog.Portal>
);

export type BottomSheetCloseButtonProps = React.ComponentPropsWithoutRef<typeof Dialog.Close>;

export const BottomSheetCloseButton = React.forwardRef<
  React.ElementRef<typeof Dialog.Close>,
  BottomSheetCloseButtonProps
>(({ className, ...delegated }, ref) => {
  return (
    <Dialog.Close
      ref={ref}
      {...delegated}
      className={cn('aspect-square rounded-full bg-neutral-black-12 p-2 center', className)}
    >
      <RiCloseLine className='h-6 w-6' data-cy='close-btn' />
      <span className='sr-only'>Close</span>
    </Dialog.Close>
  );
});
BottomSheetCloseButton.displayName = 'BottomSheetCloseButton';

const BottomSheetOverlay = React.forwardRef<
  React.ElementRef<typeof Dialog.Overlay>,
  React.ComponentPropsWithoutRef<typeof Dialog.Overlay>
>(({ className, ...props }, ref) => (
  <Dialog.Overlay
    ref={ref}
    className={cn('fixed inset-0 bg-neutral-black-30 transition-all duration-100', 'animate-in fade-in', className)}
    {...props}
  />
));
BottomSheetOverlay.displayName = Dialog.Overlay.displayName;
