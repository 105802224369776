import { tokenRefresher } from '@/lib/auth/TokenRefresher';

import { restApi } from '../../client';
import { replacePathVariables } from './utils/replacePathVariables';

export const getRequest = async <TResponse, PathVariables = unknown, QueryParams = unknown>(
  path: string,
  pathVariables?: PathVariables,
  queryParams?: QueryParams,
  signal?: AbortSignal,
): Promise<TResponse> => {
  const pathWithVariables = replacePathVariables(path, pathVariables);

  await tokenRefresher.checkToken();

  return new Promise((resolve, reject) => {
    restApi
      .get(pathWithVariables, { params: queryParams, signal })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
