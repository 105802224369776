import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';

import { NCCardAnalysisType } from '../NCCard/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import { ChartTooltip, ChartTooltipDot } from './components/Tooltip';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';
import { getXAxisDomainForPotentialGraph, minMaxAxisDomain } from './utils';

const chartColors = { waterHoldingCapacity: '#85DAFF' };

export type WaterHoldingCapacityChartData = {
  date: string;
  name: 'water_holding_capacity_potential';
  value: number;
  unit: 'm^3/m^3';
};

type WaterHoldingCapacityChartProps = DefaultChartProps & {
  /** The chart data */
  data: WaterHoldingCapacityChartData[];
  analysisType: NCCardAnalysisType;
};

export const WaterHoldingCapacityChart: FC<HTMLAttributes<HTMLDivElement> & WaterHoldingCapacityChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const chartData = data.map((d) => ({ label: new Date(d.date).getFullYear(), value: d.value }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicks = getXAxisDomainForPotentialGraph(firstTick.label, lastTick.label);

  const currentYearValue = firstTick.value;

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='waterHoldingCapacity' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.waterHoldingCapacity} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.waterHoldingCapacity} stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='value'
            stroke='none'
            fill='url(#waterHoldingCapacity)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <XAxis
            dataKey='label'
            axisLine={false}
            tickLine={false}
            tick={<ChartXTick />}
            padding={{ right: 25 }}
            type='number'
            domain={['dataMin', 'dataMax']}
            ticks={xTicks}
          />
          <YAxis
            dataKey='value'
            axisLine={false}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={([dataMin, dataMax]) =>
              /**  Science spec
            Set Y axis limits to (97% of currentDayValue, 103% of currentDayValue)
            Fallback to (97% of dataMin, 103% of dataMax) if current year value is lesser/greater than min/max
            */
              minMaxAxisDomain(0.97, 1.03)([Math.min(dataMin, currentYearValue), Math.max(currentYearValue, dataMax)])
            }
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={65}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 57, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={(content) => ChartTooltip(content, undefined, unit)}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: chartColors.waterHoldingCapacity,
    },
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='water-holding-capacity-chart-legend' />;
};
