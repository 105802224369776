import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_LANDSTEWARD_CONTRACT_PATH,
  GetLandstewardContractErrorDto,
  GetLandstewardContractSuccessDto,
} from '@/api/rest/resources/types/contract';

export const useLandstewardContract = () => {
  const queryFn = async () => {
    return getRequest<GetLandstewardContractSuccessDto>(GET_LANDSTEWARD_CONTRACT_PATH);
  };

  return useSuspenseQuery<GetLandstewardContractSuccessDto, GetLandstewardContractErrorDto>({
    queryKey: ['landsteward', 'contract'],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
