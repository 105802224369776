import { Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCFactCapsule,
  NCFactCapsuleProps,
} from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useFact } from '@/hooks/useFact';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { formatFactKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

export const CarbonStatsTile = () => {
  return (
    <Stack className='gap-8 p-6 md:justify-center md:gap-4 md:p-8 md:pr-2' data-testid='carbon-stats-tile'>
      <BelowGroundCarbonStorage />
    </Stack>
  );
};

const BelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBg1YearUpliftPotential = formatFactKilogramsToTonnes(
    useNCData<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_1year_uplift_potential_total_change
        : R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_change,
    ),
  );
  const carbonBg1YearTrend = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_1year_uplift_potential_total_pct_change
      : R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_pct_change,
  );

  const carbonBg10YearUpliftPotential = formatFactKilogramsToTonnes(
    useNCData<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_10year_uplift_potential_total_change
        : R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_change,
    ),
  );
  const carbonBg10YearTrend = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_10year_uplift_potential_total_pct_change
      : R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_pct_change,
  );

  const equivalentExplainer = useEquivalentsExplainer();

  const popover1Year: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.carbon.explainers.1YearPotential.title'),
        body: `${t('shared.ncaDetail.details.carbon.explainers.1YearPotential.body')}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-1-year-uplift-potential-popover',
        },
      }
    : undefined;

  const popover10Year: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.carbon.explainers.10YearPotential.title'),
        body: `${t('shared.ncaDetail.details.carbon.explainers.10YearPotential.body')}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-10-year-uplift-potential-popover',
        },
      }
    : undefined;

  const latestAnalysisDate = printLatestDate([
    carbonBg1YearUpliftPotential?.measured_at,
    carbonBg10YearUpliftPotential?.measured_at,
  ]);

  let carbonBg1YearTrendDisplay = `${useDisplayNumber(carbonBg1YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;
  carbonBg1YearTrendDisplay = carbonBg1YearTrend?.value != null ? carbonBg1YearTrendDisplay : '-';

  let carbonBg10YearTrendDisplay = `${useDisplayNumber(carbonBg10YearTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;
  carbonBg10YearTrendDisplay = carbonBg10YearTrend?.value != null ? carbonBg10YearTrendDisplay : '-';

  return (
    <Stack className='gap-8 lg:mt-4 lg:justify-center lg:gap-6'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.upliftPotentialForCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestAnalysis')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.1YearPotential')}
          fact={carbonBg1YearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBg1YearUpliftPotential),
            variant: getNCChangeIconVariant(carbonBg1YearUpliftPotential),
          }}
          infoPopoverProps={popover1Year}
          trend={
            typeof carbonBg1YearTrend?.value === 'number' && (
              <CapsuleTrend variant={carbonBg1YearTrend.value < 0 ? 'negative' : 'positive'}>
                {carbonBg1YearTrendDisplay}
              </CapsuleTrend>
            )
          }
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.10YearPotential')}
          fact={carbonBg10YearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBg10YearUpliftPotential),
            variant: getNCChangeIconVariant(carbonBg10YearUpliftPotential),
          }}
          infoPopoverProps={popover10Year}
          trend={
            typeof carbonBg10YearTrend?.value === 'number' && (
              <CapsuleTrend variant={carbonBg10YearTrend.value < 0 ? 'negative' : 'positive'}>
                {carbonBg10YearTrendDisplay}
              </CapsuleTrend>
            )
          }
        />
      </Stack>
      {typeof carbonBg10YearTrend?.value === 'number' && (
        <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
          <span className='typography-h4'>
            {t('shared.ncaDetail.details.carbon.explainers.carbonUpliftPotential.title')}
          </span>
          <Trans
            i18nKey='shared.ncaDetail.details.carbon.explainers.carbonUpliftPotential.body'
            values={{
              trendPercent: carbonBg10YearTrendDisplay,
            }}
            components={{
              p: <p className='typography-body1 text-text-secondary' />,
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

// TODO: MVP-3284 Verify the explainer copy
const useEquivalentsExplainer = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonStorageBg = formatFactKilogramsToTonnes(
    useNCData(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );
  const carbonStorageBgCo2Equivalent = formatFactKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_storage_bg_co2_equivalent_total
        : R1FactType.r1_carbon_storage_bg_co2_equivalent_per_ha,
    ),
  );

  const { display: carbonStorageBgDisplay, value: carbonStorageBgValue } = useFact(carbonStorageBg);

  const { display: carbonStorageBgCo2EquivalentDisplay, value: carbonStorageBgCo2EquivalentValue } =
    useFact(carbonStorageBgCo2Equivalent);

  if (!carbonStorageBgValue || !carbonStorageBgCo2EquivalentValue) return undefined;

  return t('shared.ncaDetail.details.carbon.explainers.equivalents', {
    cValue: carbonStorageBgDisplay,
    co2Value: carbonStorageBgCo2EquivalentDisplay,
  });
};
