import { useTranslation } from 'react-i18next';

export const useComplyCubeStages = () => {
  const { t } = useTranslation();
  return [
    {
      name: 'intro',
      options: {
        heading: t('global.kyc.complyCube.stages.intro.heading'),
        message: [
          t('global.kyc.complyCube.stages.intro.message.line1'),
          t('global.kyc.complyCube.stages.intro.message.line2'),
          t('global.kyc.complyCube.stages.intro.message.line3'),
        ],
        startButtonText: t('global.kyc.complyCube.stages.intro.startButtonText'),
      },
    },
    'userConsentCapture',
    {
      name: 'documentCapture',
      options: {
        crossDeviceOnly: true,
        documentTypes: {
          passport: true,
          driving_license: false,
          national_identity_card: true,
          residence_permit: true,
        },
      },
    },
    {
      name: 'faceCapture',
      options: {
        mode: 'photo',
      },
    },
    {
      name: 'poaCapture',
      options: {
        documentTypes: {
          bank_statement: true,
          utility_bill: false,
        },
      },
    },
    {
      name: 'completion',
      options: {
        heading: t('global.kyc.complyCube.stages.completion.heading'),
        message: [t('global.kyc.complyCube.stages.completion.message')],
      },
    },
  ];
};
