import backgroundDesktop from '@assets/images/trim.svg';
import backgroundMobile from '@assets/images/trim-mobile.svg';
import { Stack } from '@landler/tw-component-library';
import React from 'react';

import { useScreenSize } from '@/hooks/useScreenSize';

import { Footer } from '../Footer/Footer';
import { Navbar } from '../Navbar';

export type FullScreenErrorProps = {
  title: React.ReactNode;
  body: React.ReactNode;
  actions: React.ReactNode;
};

export const FullScreenError = ({ title, body, actions }: FullScreenErrorProps) => {
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <div
      className='flex h-screen w-screen flex-col items-center justify-between bg-bg-light-grey bg-cover'
      style={{ backgroundImage: `url("${isSmallScreen ? backgroundMobile : backgroundDesktop}")` }}
      data-cy='error-page'
    >
      <Navbar />
      <Stack className='mx-2 flex max-w-2xl items-center rounded-3xl bg-white-100 px-12 py-10'>
        <h1 className='typography-display3 text-center text-primary-100'>{title}</h1>
        <div className='typography-body1 mt-4 px-1 text-center text-text-primary'>{body}</div>
        <Stack className='mt-12 flex items-center gap-4'>{actions}</Stack>
      </Stack>
      <Footer />
    </div>
  );
};
