import { PasswordInput, Text } from '@landler/component-library';
import { Button, Stack } from '@landler/tw-component-library';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ControlledCheckbox, ControlledTextInput } from '@/components/Form';
import { useLegalUrls } from '@/hooks/useLegalUrls';
import { validatePassword } from '@/utils/validators';

import { BuyerCompleteAccountInputs, PredefinedData } from '../../hooks/useBuyerCompleteAccountPage';

const INPUT_REQUIRED_KEY = 'global.ui.form.input.required';

type CompleteAccountFormProps = {
  predefinedData: PredefinedData;
  handleCompleteAccount: (data: BuyerCompleteAccountInputs) => void;
  isSubmitting: boolean;
};

export const BuyerCompleteAccountForm: React.FC<CompleteAccountFormProps> = ({
  predefinedData,
  handleCompleteAccount,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();
  const { control, handleSubmit } = useForm<BuyerCompleteAccountInputs>();
  return (
    <div className='px-4'>
      <h1 className='typography-h1 text-primary-100'>{t('buyer.completeAccount.title')}</h1>
      <p className='typography-body-1 mt-4'>{t('buyer.completeAccount.subline')}</p>
      <div className='mt-8 w-full rounded-2xl bg-white-100 p-6 pt-10 sm:p-12'>
        <form onSubmit={handleSubmit(handleCompleteAccount)}>
          <p className='mb-10 text-text-primary'>{t('buyer.completeAccount.info', { email: predefinedData.email })}</p>
          <Stack direction='col' spacing={6}>
            <Stack className='sm:flex-row' spacing={6}>
              <ControlledTextInput
                id='firstname'
                name='firstname'
                label={t('buyer.completeAccount.form.firstName.label')}
                control={control}
                defaultValue={predefinedData.firstname}
                rules={{ required: t(INPUT_REQUIRED_KEY) }}
              />
              <ControlledTextInput
                id='lastname'
                name='lastname'
                label={t('buyer.completeAccount.form.lastName.label')}
                control={control}
                defaultValue={predefinedData.lastname}
                rules={{ required: t(INPUT_REQUIRED_KEY) }}
              />
            </Stack>
            <ControlledTextInput
              id='password'
              name='password'
              label={t('buyer.completeAccount.form.password.label')}
              type='password'
              control={control}
              rules={{
                required: t(INPUT_REQUIRED_KEY),
                validate: (password) => validatePassword(password) || t('global.ui.form.input.passwordMinLength'),
              }}
              component={PasswordInput}
            />
          </Stack>
          <Stack spacing={3} className='mb-8 pt-6'>
            <ControlledCheckbox
              name='termsAndConditions'
              id='termsAndConditions'
              control={control}
              data-testid='checkbox-termsAndConditions'
              rules={{
                required: t(INPUT_REQUIRED_KEY),
              }}
            >
              <Trans
                i18nKey='buyer.completeAccount.form.termsAndConditions.label'
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  tc: <a className='underline' href={termsAndConditionsUrl} target='_blank' rel='noreferrer' />,
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  pp: <a className='underline' href={privacyPolicyUrl} target='_blank' rel='noreferrer' />,
                  br: (
                    <>
                      <span>*</span>
                      <br />
                      <br />
                    </>
                  ),
                }}
              />
            </ControlledCheckbox>
            <Text variant='body2' alignSelf='start' color='text.secondary' data-cy='required-text'>
              {t('global.ui.form.requiredHint')}
            </Text>
          </Stack>
          <div className='flex flex-row-reverse'>
            <Button className='w-full sm:w-auto' type='submit' disabled={isSubmitting} loading={isSubmitting}>
              {t('buyer.completeAccount.form.submit')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
