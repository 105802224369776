import React, { PropsWithChildren } from 'react';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OnboardingPage } from '@/layout/OnboardingPage';
import { useAuth, useUser } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';

import { BuyerPageLayout } from '../buyer/components';
import { LandStewardPageLayout } from '../landsteward/layouts/LandStewardPage.layout';
import { ActivateAccountFailed, ActivateAccountLoading, ActivateAccountSuccess } from './components';
import { BaseActivateAccountPageState, useActivateAccount } from './hooks/useActivateAccount';

export const ActivateAccountPage: React.FC = () => {
  const { isSignedIn } = useAuth();

  if (isSignedIn) {
    return (
      <RoleSpecificPageLayout>
        <PageContent />
      </RoleSpecificPageLayout>
    );
  }

  return (
    <OnboardingPage>
      <PageContent />
    </OnboardingPage>
  );
};

const RoleSpecificPageLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useUser();

  const userType = user.membership.type;

  if (userType === MembershipWithOrganizationTypeEnum.land_steward) {
    return <LandStewardPageLayout>{children}</LandStewardPageLayout>;
  }

  if (userType === MembershipWithOrganizationTypeEnum.buyer) {
    return <BuyerPageLayout>{children}</BuyerPageLayout>;
  }

  Logger.errorOnce(`Unknown membership type "${user.membership.type}"`);
  return <OnboardingPage>{children}</OnboardingPage>;
};

const PageContent: React.FC = () => {
  const { pageState } = useActivateAccount();

  const Content = () => {
    switch (pageState) {
      case BaseActivateAccountPageState.LOADING:
        return <ActivateAccountLoading />;
      case BaseActivateAccountPageState.SUCCESS:
        return <ActivateAccountSuccess />;
      default:
        return <ActivateAccountFailed pageState={pageState} />;
    }
  };

  return (
    <div className='m-auto max-w-[526px]'>
      <Content />
    </div>
  );
};
