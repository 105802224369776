import { FactLike } from '@/api/rest/resources/conservation';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { isValidFactValue, prettifyDisplayedValue } from '@/hooks/useFact';
import { formatUnit } from '@/utils/formatting';

type UseFactDisplayOptions = {
  signed?: boolean;
};

type UseFactDisplayResult = {
  display: string;
  value?: string | number;
  unit?: string;
};

export const useFactDisplay = (fact?: FactLike, options?: UseFactDisplayOptions): UseFactDisplayResult => {
  if (!fact || !isValidFactValue(fact.value) || !fact.unit) {
    return {
      display: 'N/A',
    };
  }

  const { value, unit } = fact;

  const formattedValue =
    typeof value === 'number'
      ? getDisplayNumber(value, window.navigator.language, { signDisplay: options?.signed ? 'exceptZero' : 'never' })
      : value;
  const formattedUnit = formatUnit(unit);

  return {
    display: prettifyDisplayedValue(formattedValue, formattedUnit),
    value,
    unit: unit === UnitEnum.none ? undefined : formattedUnit,
  };
};
