import { useQueryClient } from '@tanstack/react-query';

import { RoleEnum } from '@/api/rest/resources/types/membership';
import { usePostUserCreateAndInviteUser } from '@/api/rest/resources/user';
import { useUser } from '@/lib/auth';

export type InviteInputs = {
  user: {
    firstname: string;
    lastname: string;
    email: string;
  };
  role: RoleEnum;
};

export const useSendInvite = () => {
  const user = useUser();

  const queryClient = useQueryClient();
  const postUserCreateAndInviteUserMutation = usePostUserCreateAndInviteUser();

  const submit = async (inputs: InviteInputs) => {
    await postUserCreateAndInviteUserMutation.mutateAsync({
      bodyData: {
        ...inputs,
        org_id: user.membership.organization.id,
      },
    });

    queryClient.resetQueries({ queryKey: ['user'] });
  };

  const isSubmitting = postUserCreateAndInviteUserMutation.isPending;

  return { submit, isSubmitting };
};
