import { useApiPostRequest } from '../hooks/useApiPostRequest';
import {
  POST_AUTHENTICATION_OTP_PATH,
  POST_AUTHENTICATION_SIGN_IN_PATH,
  POST_AUTHENTICATION_VALIDATE_ONETIME_TOKEN_PATH,
  PostAuthenticationOtpErrorDto,
  PostAuthenticationOtpRequestBodyData,
  PostAuthenticationOtpRequestPathVariables,
  PostAuthenticationOtpRequestQueryParams,
  PostAuthenticationOtpSuccessDto,
  PostAuthenticationSignInErrorDto,
  PostAuthenticationSignInRequestBodyData,
  PostAuthenticationSignInRequestPathVariables,
  PostAuthenticationSignInRequestQueryParams,
  PostAuthenticationSignInSuccessDto,
  PostAuthenticationValidateOnetimeTokenErrorDto,
  PostAuthenticationValidateOnetimeTokenRequestBodyData,
  PostAuthenticationValidateOnetimeTokenRequestPathVariables,
  PostAuthenticationValidateOnetimeTokenRequestQueryParams,
  PostAuthenticationValidateOnetimeTokenSuccessDto,
} from './types/authentication';

export const usePostAuthenticationOtp = () => {
  return useApiPostRequest<
    PostAuthenticationOtpRequestPathVariables,
    PostAuthenticationOtpRequestQueryParams,
    PostAuthenticationOtpRequestBodyData,
    PostAuthenticationOtpSuccessDto,
    PostAuthenticationOtpErrorDto
  >(POST_AUTHENTICATION_OTP_PATH);
};

export const usePostAuthenticationSignIn = () => {
  return useApiPostRequest<
    PostAuthenticationSignInRequestPathVariables,
    PostAuthenticationSignInRequestQueryParams,
    PostAuthenticationSignInRequestBodyData,
    PostAuthenticationSignInSuccessDto,
    PostAuthenticationSignInErrorDto
  >(POST_AUTHENTICATION_SIGN_IN_PATH);
};

export const usePostAuthenticationValidateOnetimeToken = () => {
  return useApiPostRequest<
    PostAuthenticationValidateOnetimeTokenRequestPathVariables,
    PostAuthenticationValidateOnetimeTokenRequestQueryParams,
    PostAuthenticationValidateOnetimeTokenRequestBodyData,
    PostAuthenticationValidateOnetimeTokenSuccessDto,
    PostAuthenticationValidateOnetimeTokenErrorDto
  >(POST_AUTHENTICATION_VALIDATE_ONETIME_TOKEN_PATH);
};
