import merge from 'lodash/merge';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import { RestApiServerError } from '@/api/rest/resources/errors/RestApiServerError';
import { User as ApiUser } from '@/api/rest/resources/types/user';
import { useGetUserMe, usePatchUserMe } from '@/api/rest/resources/user';
import { useAuth } from '@/lib/auth/AuthenticationContext';

import { Logger } from '../logs/logger';
import { UserContextProvider } from './UserContextProvider';

export const CustomUserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { refetch: fetchUserData } = useGetUserMe(undefined, { enabled: false });
  const { mutate: mutateUser } = usePatchUserMe();
  const { authenticatedUser, setUser, isSignedIn, signOut, isBooted } = useAuth();
  const user = useMemo(() => authenticatedUser.user, [authenticatedUser]);

  const updateUserPartially = (updatedUserPart: Partial<ApiUser>) => {
    if (!user) {
      // This error can not be tested because the user is always defined when using the useUserContext hook
      throw new Error('The user must be provided when updating the data');
    }

    setUser(merge(user, updatedUserPart));
  };

  const reloadUserData = () => {
    fetchUserData()
      .then((response) => {
        // the request failed (e.g. because of a 401 error)
        if (response.isError) {
          throw new Error(
            response.error instanceof RestApiServerError ? response.error.errors[0] : response.error.message,
          );
        }

        const fetchedUserData = response.data;
        if (!fetchedUserData && !user) {
          throw new Error('A user should never be signed in without loaded user data.');
        }
        if (!fetchedUserData) {
          return;
        }
        if (user) {
          updateUserPartially(fetchedUserData);
        } else {
          setUser(fetchedUserData);
        }
      })
      .catch((e: Error) => {
        signOut();
        if (e.message !== 'Network Error')
          Logger.error(
            `Something unexpected went wrong with reloading the user data in the UserContextProvider: ${e.message}`,
          );
      });
  };

  const changeLanguage = async (language: string): Promise<void> => {
    if (!user?.id) {
      throw new Error('The user id must be provided when changing the user language in the UserContextProvider');
    }
    mutateUser(
      {
        bodyData: { language },
      },
      {
        onSuccess: (userData) => updateUserPartially(userData),
      },
    );
  };

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function reloadUserDataOnPageReload() {
      if (isSignedIn) {
        reloadUserData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBooted],
  );

  return <UserContextProvider value={{ user, changeLanguage, updateUserPartially }}>{children}</UserContextProvider>;
};
