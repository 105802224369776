import { restApi } from '@/api/rest/client';
import { tokenStorage } from '@/config/tokenStorage';

import { TokenStorageKeys } from './types';

const TOKEN_REFRESH_PATH = '/token/refresh/';

const isObjectWithExpProperty = (obj: unknown): obj is { exp: number } => {
  return typeof obj === 'object' && obj !== null && 'exp' in obj;
};

export class TokenRefresher {
  private static isRefreshing = false;

  private refresherQueue: Array<() => void> = [];

  private tokenSubscribers = new Map<
    string,
    { onRefresh: (token: string) => Promise<void>; onInvalidate: () => Promise<void> }
  >();

  private static instance: TokenRefresher;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    /* disabled "no-useless-constructor" because the constructor must be private to create an singleton */
  }

  static getInstance(): TokenRefresher {
    if (!TokenRefresher.instance) {
      TokenRefresher.instance = new TokenRefresher();
    }

    return TokenRefresher.instance;
  }

  public subscribeTokenChanges(
    identifier: string,
    {
      onRefresh,
      onInvalidate,
    }: {
      onRefresh: (token: string) => Promise<void>;
      onInvalidate: () => Promise<void>;
    },
  ): () => void {
    this.tokenSubscribers.set(identifier, { onRefresh, onInvalidate });

    return () => this.unsubscribeTokenChanges(identifier);
  }

  public async checkToken(): Promise<void> {
    if (!this.shouldTokenBeRefreshed()) {
      return Promise.resolve();
    }

    if (TokenRefresher.isRefreshing) {
      return this.addToRefresherQueue();
    }

    TokenRefresher.isRefreshing = true;

    await this.refreshAccessToken();
    this.resolveAndClearQueue();

    TokenRefresher.isRefreshing = false;
    return Promise.resolve();
  }

  private unsubscribeTokenChanges(identifier: string) {
    this.tokenSubscribers.delete(identifier);
  }

  private shouldTokenBeRefreshed(): boolean {
    return !!this.getRefreshToken() && this.isTokenExpiredOrInvalid();
  }

  private getRefreshToken(): string | undefined {
    return tokenStorage.getItem(TokenStorageKeys.REFRESH_TOKEN);
  }

  private async addToRefresherQueue(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.refresherQueue.push(resolve);
    });
  }

  private async refreshAccessToken(): Promise<void> {
    return restApi
      .post(TOKEN_REFRESH_PATH, { access: this.getAccessToken(), refresh: this.getRefreshToken() })
      .then(async (response) => {
        tokenStorage.setItem(TokenStorageKeys.AUTH_TOKEN, response.data.access);

        const promises = Array.from(this.tokenSubscribers.values()).map(async (value) =>
          value.onRefresh(response.data.access),
        );

        await Promise.all(promises);
      })
      .catch(async () => {
        const promises = Array.from(this.tokenSubscribers.values()).map(async (value) => value.onInvalidate());

        await Promise.all(promises);
      });
  }

  private isTokenExpiredOrInvalid(): boolean {
    const token = this.getAccessToken();
    if (!token) return true;

    const encodedPayload = token.split('.')[1];

    if (!encodedPayload) return true;

    const decodedPayload: unknown = JSON.parse(window.atob(encodedPayload));

    if (!isObjectWithExpProperty(decodedPayload)) {
      return true;
    }

    return this.isExpired(new Date(decodedPayload.exp * 1000));
  }

  private isExpired(expirationDate: Date): boolean {
    const differenceInSeconds = (expirationDate.getTime() - new Date().getTime()) / 1000;
    const halfMinuteInSeconds = 30;

    // NOTE: The token is considered expired if it expires in less than 30 seconds
    return differenceInSeconds < halfMinuteInSeconds;
  }

  private getAccessToken(): string | undefined {
    return tokenStorage.getItem(TokenStorageKeys.AUTH_TOKEN);
  }

  private resolveAndClearQueue(): void {
    this.refresherQueue.forEach((subscriber) => {
      subscriber();
    });
    this.refresherQueue = [];
  }
}

export const tokenRefresher = TokenRefresher.getInstance();
