import { IconButton } from '@mui/material';
import React, { ComponentType, useState } from 'react';

import { Icon } from '../Icon';
import { TextInput, TextInputProps } from './TextInput';

export type PasswordInputProps = Omit<TextInputProps, 'EndAdornment' | 'type'>;

const RawPasswordInput: React.ForwardRefRenderFunction<HTMLDivElement | null, PasswordInputProps> = (
  { ...props },
  ref,
) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      ref={ref}
      {...props}
      type={showPassword ? 'text' : 'password'}
      EndAdornment={
        <IconButton onClick={() => setShowPassword((prev) => !prev)} edge='end' data-testid='password-input-icon'>
          <Icon icon={showPassword ? 'visibility-off' : 'visibility-on'} />
        </IconButton>
      }
    />
  );
};

export const PasswordInput = React.forwardRef(RawPasswordInput) as ComponentType<PasswordInputProps>;
