import { cn, Shimmer, Stack } from '@landler/tw-component-library';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type OverflowStackProps = {
  /**
   * Element is not part of 'children' prop
   * Acts as an footer/last element
   */
  lastChild?: React.ReactNode;
  /**
   * Spacing between the children nodes if any
   * Used in calculating the final width/height the children will occupy
   */
  padding?: number;
  /**
   * Width of container
   * Defaults to parent container/auto width if not specified
   */
  containerWidth?: 'auto' | number;
};

export const OverflowStack: FC<PropsWithChildren<OverflowStackProps>> = ({
  lastChild,
  padding = 8,
  containerWidth = 'auto',
  children,
}) => {
  const [isReady, setIsReady] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const boundingBoxWidth =
    containerWidth === 'auto' ? containerRef.current?.getBoundingClientRect().width : containerWidth;

  const lastChildWrapperRef = useRef<HTMLDivElement>(null);
  const lastChildWidth = lastChildWrapperRef.current?.getBoundingClientRect().width;

  const availableWidth = (boundingBoxWidth ?? 0) - (lastChildWidth ?? 0);

  const childrenRef = useRef<HTMLDivElement[]>([]);
  const { maxCount } = childrenRef.current.reduce(
    (acc, curr) => {
      if (curr) {
        /* futureWidth equals (currentWidth + padding between elements if any + next element width) */
        const newWidth = acc.width + padding + curr.getBoundingClientRect().width;
        const newMaxCount = newWidth > availableWidth ? acc.maxCount : acc.maxCount + 1;

        return { maxCount: newMaxCount, width: newWidth };
      }
      return acc;
    },
    {
      maxCount: 0,
      /* taking into consideration that the first element would not have a precending padding */
      width: -padding,
    },
  );

  /**
   * We do this so that the parent container has a chance
   * to render first before measuring its dimensions.
   */
  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function waitForSlowContainerToAdjust() {
    const timeout = setTimeout(() => {
      setIsReady(true);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {createPortal(
        <>
          {(children as React.ReactNode[])?.map((child, index) => (
            <div
              key={index}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ref={(node) => childrenRef.current?.push(node!)}
              className={cn('invisible w-fit whitespace-nowrap bg-warning-light')}
            >
              {child}
            </div>
          ))}
        </>,
        document.body,
      )}
      <Stack style={{ gap: `${padding}px` }} direction='row' className={cn('overflow-hidden')} ref={containerRef}>
        {!isReady && <Shimmer className='h-8 w-full rounded-full' data-testid='loading-widget' />}
        {isReady && (children as React.ReactNode[])?.slice(0, maxCount)}
        {isReady && (children as React.ReactNode[])?.length > maxCount && lastChild && (
          <div ref={lastChildWrapperRef}>{lastChild}</div>
        )}
      </Stack>
    </>
  );
};
