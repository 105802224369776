import { useTranslation } from 'react-i18next';

import { NCAReportBalanceType, NCAReportBalanceValueType } from '@/api/rest/resources/types/ncaBalance';
import { NCCardSectionHeader } from '@/components/NCCardV1_2/NCCard';
import { useNCABalance, useNCAReportBalance } from '@/pages/shared/hooks/useNcaReportByProjectId';

import { NCABalanceTile } from './NCABalanceTile';

export const BiodiversityBalance = () => {
  const { t } = useTranslation();

  const biodiversityBalance = useNCAReportBalance(NCAReportBalanceType.BIODIVERSITY);

  const openingValue = useNCABalance(biodiversityBalance, NCAReportBalanceValueType.opening_value);
  const latestValue = useNCABalance(biodiversityBalance, NCAReportBalanceValueType.latest_value);
  const latestValueTrend = useNCABalance(biodiversityBalance, NCAReportBalanceValueType.one_year_change_perc);
  const oneYearChange = useNCABalance(biodiversityBalance, NCAReportBalanceValueType.one_year_change_value);

  return (
    <div data-testid='biodiversity-stats-tile' className='flex w-full flex-col gap-6'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.biodiversity.labels.biodiversityZoneData')}
        popover={{
          title: t('shared.ncaDetail.details.biodiversity.explainers.biodiversityZone.title'),
          body: t('shared.ncaDetail.details.biodiversity.explainers.biodiversityZone.body'),
        }}
      />
      <NCABalanceTile
        openingValue={openingValue}
        latestValue={latestValue}
        latestValueTrend={latestValueTrend}
        oneYearChange={oneYearChange}
      />
    </div>
  );
};
