import { FC } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { usePlot } from '@/pages/landsteward/pages/plot/pages/edit/hooks';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

export const RequireAccessiblePlot: FC = () => {
  const { projectId } = useParams();
  const { plotId } = useParams();

  if (!plotId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'plotId' });
  }

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  const plot = usePlot().data;

  if (projectId !== plot.project) {
    throw new Error('Invalid Project Id');
  }

  if (plot.status === PlotStatusEnum.invalid) {
    return <Navigate to={buildPath(paths.landSteward.plots, { pathParams: { projectId: plot.project } })} replace />;
  }

  return <Outlet />;
};
