import i18next from 'i18next';
import { useEffect, useState } from 'react';

import { fallbackLanguage } from '@/config/i18n';
import { Logger } from '@/lib/logs/logger';
import { ApplicationLanguage, isApplicationLanguage } from '@/types';

export const languages = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
} as const;

type CurrentLanguage = {
  code: ApplicationLanguage;
  nativeTranslation: string;
};

const getCurrentLanguage = (): CurrentLanguage => {
  return isApplicationLanguage(i18next.language)
    ? { nativeTranslation: languages[i18next.language], code: i18next.language }
    : // eslint-disable-next-line security/detect-object-injection
      { nativeTranslation: languages[fallbackLanguage], code: fallbackLanguage };
};

type UseLanguageProps = {
  changeUserLanguage?: (language: ApplicationLanguage) => Promise<void>;
};

export const useLanguage = ({ changeUserLanguage }: UseLanguageProps = {}) => {
  const [currentLanguage, setCurrentLanguage] = useState<CurrentLanguage>(getCurrentLanguage());

  const changeLanguage = (langKey: string) => {
    if (!isApplicationLanguage(langKey)) {
      Logger.error(`Language ${langKey} is not supported`);
      return;
    }

    if (i18next.language === langKey) {
      return;
    }

    i18next.changeLanguage(langKey);

    if (!changeUserLanguage) {
      return;
    }

    changeUserLanguage(langKey).then(() => {
      /* nothing happens here */
    });
  };

  const handleI18NextLanguageChanged = () => {
    setCurrentLanguage(getCurrentLanguage());
  };

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function addAndRemoveI18NextListeners() {
    i18next.on('languageChanged', handleI18NextLanguageChanged);

    return () => {
      i18next.off('languageChanged', handleI18NextLanguageChanged);
    };
  }, []);

  return {
    currentLanguage,
    changeLanguage,
  };
};
