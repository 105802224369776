import React, { ComponentProps } from 'react';

export const ChartLabel = (props: ComponentProps<'text'> & { title: string; value: React.ReactNode }) => {
  const { x, y, title, stroke } = props;
  return (
    <text x={x} y={y} dx={-2} dy={-8} fill={stroke} className='typography-caption' textAnchor='end'>
      {title}
    </text>
  );
};
