import { Stack } from '@landler/tw-component-library';
import React from 'react';

interface OnboardingPageHeadingProps {
  headlineText: string;
  sublineText?: string;
  dataCy?: string;
}

export const OnboardingPageHeading: React.FC<OnboardingPageHeadingProps> = ({ headlineText, sublineText, dataCy }) => {
  return (
    <Stack spacing={4} className='mb-10'>
      <h3 className='typography-display2 text-center text-primary-100' data-cy={dataCy ? `${dataCy}-title` : undefined}>
        {headlineText}
      </h3>
      {sublineText && (
        <span
          className='typography-body1 text-center [text-wrap:balance]'
          data-testid='onboarding-page-subline'
          data-cy={dataCy ? `${dataCy}-subtitle` : undefined}
        >
          {sublineText}
        </span>
      )}
    </Stack>
  );
};
