import bgMapSrc from '@assets/images/bg-map.jpg';
import {
  Button,
  ButtonLink,
  ButtonLinkProps,
  cn,
  RiAddFill,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@landler/tw-component-library';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

export const NoPlotsHero: FC = () => {
  const { t } = useTranslation();

  const projectDetail = useProjectDetailById().data;

  const hasWritePermission = getProjectPermissions(projectDetail).includes('write');

  return (
    <Stack
      spacing={8}
      className='mx-auto min-h-[calc(100vh-64px)] w-full items-center justify-center bg-primary-30 bg-cover px-10 py-10 text-center bg-blend-color-burn lg:min-h-0 lg:justify-normal lg:py-48'
      style={{ backgroundImage: `url(${bgMapSrc})` }}
      data-testid='no-plots-hero'
    >
      <Stack spacing={2} className='max-w-[936px]'>
        <span className='typography-display2 text-white-100' data-cy='new-project-detail'>
          {t('shared.projects.noPlotsHero.headline', { projectName: projectDetail.name })}
        </span>
        <span className='typography-display3 text-white-100' data-cy='new-project-subline'>
          {t('shared.projects.noPlotsHero.subline')}
        </span>
      </Stack>
      <AddPlotsButton
        data-cy='add-a-plot-btn'
        to={buildPath(paths.landSteward.newPlot, { pathParams: { projectId: projectDetail.id } })}
        disabled={!hasWritePermission}
        disabledHint={t('global.tooltips.addPlotsDisabledLockedProject')}
        color='white'
        leftAdornment={<RiAddFill />}
        className={cn(
          'flex items-center gap-2 bg-white-100',
          hasWritePermission ? 'text-primary-100' : 'text-text-disabled',
          'data-[disabled=true]:bg-white-100 data-[disabled=true]:hover:bg-white-100',
        )}
      >
        {t('shared.projects.noPlotsHero.button.label')}
      </AddPlotsButton>
    </Stack>
  );
};

type AddPlotsButtonProps = ButtonLinkProps & {
  disabledHint: string;
};

const AddPlotsButton: FC<AddPlotsButtonProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <ButtonLink type='button' to={to} {...delegated}>
        {children}
      </ButtonLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button {...delegated}>{children}</Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
