import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PLOTS_PATH,
  GET_PLOT_PATH,
  GetBuyerPlotsErrorDto,
  GetBuyerPlotsSuccessDto,
  GetPlotErrorDto,
  GetPlotSuccessDto,
} from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

type SuccessDto = GetPlotSuccessDto | GetBuyerPlotsSuccessDto;
type ErrorDto = GetPlotErrorDto | GetBuyerPlotsErrorDto;

export const getPlotsForProject = ({
  membershipType,
  projectId,
}: {
  membershipType: MembershipWithOrganizationTypeEnum;
  projectId: string;
}) => {
  return getRequest<SuccessDto>(
    membershipType === MembershipWithOrganizationTypeEnum.land_steward ? GET_PLOT_PATH : GET_BUYER_PLOTS_PATH,
    undefined,
    { project: projectId },
  );
};

export const usePlotsForProject = (projectIdArg?: string) => {
  const membershipType = useMembershipType();

  const pathParams = useParams();
  const projectId = projectIdArg ?? pathParams.projectId;

  if (!projectId) {
    throw new UnexpectedMissingDataError('projectId');
  }

  const queryFn = () => {
    return getPlotsForProject({ membershipType, projectId });
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'plot', projectId, 'plotsForProject'],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
