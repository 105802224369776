// TODO(MVP-1615): move icons to tw-component-library
import { Icon } from '@landler/component-library';
import React, { useState } from 'react';

import { IconButton } from '../IconButton/IconButton';
import { TextInput, TextInputProps } from './TextInput';

export type PasswordInputProps = Omit<TextInputProps, 'EndIcon' | 'type'>;

export const PasswordInput = React.forwardRef<React.ElementRef<typeof TextInput>, PasswordInputProps>(
  ({ ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <TextInput
        ref={ref}
        {...props}
        type={showPassword ? 'text' : 'password'}
        EndIcon={
          <IconButton
            onClick={() => setShowPassword((prev) => !prev)}
            data-testid='password-input-icon'
            data-cy='password-toggle-button'
          >
            <Icon icon={showPassword ? 'visibility-off' : 'visibility-on'} />
          </IconButton>
        }
      />
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
