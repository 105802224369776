import { Button, Text } from '@landler/component-library';
import { RiErrorWarningLine } from '@landler/tw-component-library';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { OnboardingPageHelperLink } from '@/layout/OnboardingPage';
import { paths } from '@/routing';

import { NewPasswordPageContent } from '../page-content/PageContent';

interface NewPasswordTokenInvalidProps {
  error: string;
}

export const NewPasswordTokenInvalid: React.FC<NewPasswordTokenInvalidProps> = ({ error }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <NewPasswordPageContent headlineText={t('passwordResetTokenInvalid.title')}>
        <Stack direction='row' spacing={1.5} alignItems='center'>
          <RiErrorWarningLine size={22} className='text-error' />
          <Text variant='body2' color='error.dark'>
            {error}
          </Text>
        </Stack>
        <Button
          href={paths.resetPassword}
          size='large'
          onClick={(e) => {
            e.preventDefault();
            navigate(paths.resetPassword);
          }}
          sx={{ alignSelf: 'end' }}
        >
          {t('passwordResetTokenInvalid.retry')}
        </Button>
      </NewPasswordPageContent>
      <OnboardingPageHelperLink i18nKey='passwordResetTokenInvalid.back' to={paths.login} />
    </>
  );
};
