import { ThemeOptions } from '@mui/material/styles';

export const buttonOverrides: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: { variant: 'contained' },
    styleOverrides: {
      root: { borderRadius: 50, textTransform: 'none' },
    },
    variants: [
      {
        props: { size: 'small' },
        style: { fontSize: 13, lineHeight: '100%', padding: '4px 10px' },
      },
      {
        props: { size: 'medium' },
        style: { fontSize: 14, lineHeight: '100%', padding: '8px 16px' },
      },
      {
        props: { size: 'large' },
        style: { fontSize: 15, lineHeight: '100%', padding: '12px 22px' },
      },
    ],
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        '.MuiButton-root': {
          transition: 'none', // avoids a weird behaviour when toggling
        },
      },
    },
  },
};
