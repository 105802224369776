import { PlotReportFactElement } from '@/api/rest/resources/types/fact';

import { resolveThemeColor } from './resolveThemeColor';

export const getTrendIconColor = (fact: PlotReportFactElement | undefined) => {
  if (!fact) {
    return null;
  }
  if ((fact.value as number) === 0) return null;
  if ((fact.value as number) > 0) return resolveThemeColor('success');
  return resolveThemeColor('error');
};

export const getTrendTextColor = (fact: PlotReportFactElement | undefined) => {
  if (!fact) {
    return null;
  }
  if ((fact.value as number) === 0) return resolveThemeColor('text-primary');
  if ((fact.value as number) > 0) return resolveThemeColor('success-dark');
  return resolveThemeColor('error-dark');
};
