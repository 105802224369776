import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  toast,
} from '@landler/tw-component-library';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletePlot } from '@/api/rest/resources/plot';
import { Plot } from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';

export type DeletePlotProps = {
  plot: Plot | null;
  onClose: () => void;
  onSuccess?: () => void;
};

export const DeletePlotWithConfirmation = ({ plot, onClose, onSuccess }: DeletePlotProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const membershipType = useMembershipType();

  const [deletingPlot, setDeletingPlot] = useState(false);

  const deletePlotMutation = useDeletePlot();

  const deletePlot = async () => {
    if (!plot) return;

    try {
      setDeletingPlot(true);
      await deletePlotMutation.mutateAsync({ pathVariables: { id: plot.id } });

      toast({
        type: 'success',
        title: t('landSteward.projects.plots.deletePlot.toasts.success'),
      });

      if (onSuccess) {
        onSuccess();
      }

      await queryClient.invalidateQueries({ queryKey: [membershipType, 'plot'] });
      await queryClient.invalidateQueries({ queryKey: [membershipType, 'plots'] });
      await queryClient.invalidateQueries({ queryKey: [membershipType, 'project'] });
      await queryClient.invalidateQueries({ queryKey: [membershipType, 'projects'] });
      await queryClient.invalidateQueries({ queryKey: [membershipType, 'dashboard'] });

      onClose();
    } catch (error) {
      toast({
        type: 'error',
        title: t('landSteward.projects.plots.deletePlot.toasts.error'),
      });
    } finally {
      setDeletingPlot(false);
    }
  };

  return (
    <Dialog open={!!plot} onOpenChange={onClose}>
      <DialogContent data-testid='delete-plot-with-confirmation' data-cy='delete-plot-confirmation-dialog'>
        <DialogHeader
          className='typography-h2 w-full text-error md:typography-h3 sm:text-center'
          data-cy='delete-plot-header'
        >
          {t('landSteward.projects.plots.deletePlot.dialog.title')}
        </DialogHeader>
        <DialogBody className='mb-8' data-cy='delete-plot-body'>
          {t('landSteward.projects.plots.deletePlot.dialog.message', { plotName: plot?.name })}
        </DialogBody>
        <DialogFooter className='justify-end'>
          <DialogClose asChild>
            <Button variant='text' data-cy='cancel-btn'>
              {t('global.ui.buttons.cancel')}
            </Button>
          </DialogClose>
          <Button
            onClick={() => deletePlot()}
            loading={deletingPlot}
            className='bg-error hover:bg-error-dark'
            data-cy='submit-delete-button'
          >
            {t('landSteward.projects.plots.deletePlot.dialog.submitButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
