import { forwardRef, HTMLAttributes } from 'react';

import { cn } from '../utils';

export type ShimmerProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Defaults to true.
   */
  animate?: boolean;
};

export const Shimmer = forwardRef<HTMLDivElement, ShimmerProps>(
  ({ className, animate = true, children, ...delegated }, ref) => {
    return (
      <div
        ref={ref}
        role={animate ? 'progressbar' : undefined}
        className={cn('inline-block rounded-md bg-transparent', className, animate && shimmerStyle)}
        {...delegated}
      >
        {!animate && children}
      </div>
    );
  },
);
Shimmer.displayName = 'Shimmer';

export const shimmerStyle = 'animate-pulse bg-neutral-black-10';
export const shimmerStyleSvg = 'animate-pulse fill-neutral-black-10';
