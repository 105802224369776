import { RiPieChart2Line, Stack } from '@landler/tw-component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';
import { usePlot } from '@/pages/shared/hooks/usePlot';

export const NoPlotData = () => {
  const { t } = useTranslation();

  const plot = usePlot().data;
  const plotStatus = plot.status;

  const label = useMemo(() => {
    const exhaustivenessCheck = (v: never) => {
      Logger.error(`Invalid plot status received: ${v}`);
      return t('global.analysis.noData');
    };

    const getLabelFromStatus = (status: PlotStatusEnum) => {
      switch (status) {
        case PlotStatusEnum.invalid:
        case PlotStatusEnum.new_plot:
          return t('global.analysis.noAnalysis');
        case PlotStatusEnum.scheduled_for_analysis:
        case PlotStatusEnum.calculating:
          return t('global.analysis.calculationInProgress');
        case PlotStatusEnum.draft:
        case PlotStatusEnum.ready_to_analyse:
        case PlotStatusEnum.analysed:
          return t('global.analysis.noData');
        default:
          return exhaustivenessCheck(status);
      }
    };

    /**
     * For the first analysis of a plot that has had no previous analyses, we need to show "No analysis yet" since there is no data
     * A special check needs to be added for this outside of checking for the plot status
     * - We use the last_analyzed_at property of a plot, which is null if a plot has had no previous analysis
     * - We also check if the plot status matches any of the states that appear before an analysis is triggered
     *   eg: last_analysed_at is still null when a plot is scheduled_for_analysis,
     *       but in that case we would want to show "Calc in progress" instead, to indicate that an analysis is in progress
     */
    const noPreviousAnalysis = plot.last_analyzed_at === null;
    const noOngoingAnalysis = [
      PlotStatusEnum.invalid,
      PlotStatusEnum.new_plot,
      PlotStatusEnum.draft,
      PlotStatusEnum.ready_to_analyse,
    ].includes(plotStatus);
    const isFirstAnalysisPending = noPreviousAnalysis && noOngoingAnalysis;

    return isFirstAnalysisPending ? t('global.analysis.noAnalysis') : getLabelFromStatus(plotStatus);
  }, [plotStatus, plot.last_analyzed_at, t]);

  return (
    <Stack data-testid='no-plot-data' center spacing={4} className='min-h-[160px] items-center justify-center'>
      <span className='flex h-12 w-12 items-center justify-center rounded-full bg-neutral-black-8'>
        <RiPieChart2Line size={20} className='text-text-secondary' />
      </span>
      <span className='typography-h3 text-text-secondary'>{label}</span>
    </Stack>
  );
};
