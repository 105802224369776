import { cn, RiLock2Fill } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectBuyerDetail, ProjectDetail, ProjectStatus } from '@/api/rest/resources/types/project';
import { Logger } from '@/lib/logs/logger';

const badgeBgColor = (projectStatus: ProjectStatus) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No color defined for plot status "${status}"`);
    return 'transparent';
  };

  switch (projectStatus) {
    case ProjectStatus.initial:
    case ProjectStatus.in_preparation:
    case ProjectStatus.ready_to_analyse:
    case ProjectStatus.scheduled_for_analysis:
    case ProjectStatus.analysed:
    case ProjectStatus.locked:
    case ProjectStatus.activated:
      return 'bg-info-light';
    case ProjectStatus.invested:
      return 'bg-success-light';
    default:
      return exhaustivenessCheck(projectStatus);
  }
};

export const ProjectStatusBadge: FC<
  HTMLAttributes<HTMLSpanElement> & { project: ProjectDetail | ProjectBuyerDetail }
> = ({ project, className, ...delegated }) => {
  const { t } = useTranslation();
  const projectStatus = project.status;

  return (
    <span
      className={cn(
        'typography-overline flex max-w-fit items-center gap-2 rounded-full px-4 py-2.5',
        badgeBgColor(projectStatus),
        className,
      )}
      {...delegated}
    >
      {project.is_locked && <RiLock2Fill data-testid='lock-icon' size={12} />}
      {t(`global.projectStatus.${projectStatus}`)}
    </span>
  );
};

export const ProjectStatusDemoBadge: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => {
  const { t } = useTranslation();

  return (
    <span
      className={cn(
        'typography-overline flex max-w-fit items-center gap-2 rounded-full bg-warning px-4 py-2.5',
        className,
      )}
      {...delegated}
    >
      {t(`global.projectStatus.demo`)}
    </span>
  );
};
