import { AxiosProgressEvent } from 'axios';

import { tokenRefresher } from '@/lib/auth/TokenRefresher';

import { restApi } from '../../client';
import { buildFormData } from './utils/buildFormData';
import { replacePathVariables } from './utils/replacePathVariables';

export const uploadRequest = async <TResponse>(
  path: string,
  pathVariables?: unknown,
  queryParams?: unknown,
  bodyData?: unknown,
  signal?: AbortSignal,
  updateProgress?: (progress: number) => void,
): Promise<TResponse> => {
  await tokenRefresher.checkToken();

  const pathWithVariables = replacePathVariables(path, pathVariables);
  const bodyDataAsFormData = buildFormData(path, bodyData);

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total === undefined) {
      return;
    }

    const progressPercent = (100 / progressEvent.total) * progressEvent.loaded;
    if (updateProgress) {
      updateProgress(progressPercent);
    }
  };

  return new Promise((resolve, reject) => {
    restApi
      .post(pathWithVariables, bodyDataAsFormData, {
        params: queryParams,
        signal,
        onUploadProgress: typeof updateProgress === 'function' ? onUploadProgress : undefined,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
