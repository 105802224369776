import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Sentry } from '@/lib/logs';

import * as GoogleAnalytics from './GoogleAnalytics';

window.addEventListener(
  'CookiebotOnAccept',
  () => {
    if (window.Cookiebot?.consent.statistics) {
      GoogleAnalytics.init();
      Sentry.init();
    }

    if (window.Cookiebot?.consent.marketing) {
      const hubSpotScript = document.createElement('script');
      hubSpotScript.type = 'text/javascript';
      hubSpotScript.id = 'hs-script-loader';
      hubSpotScript.async = true;
      hubSpotScript.defer = true;
      hubSpotScript.src = 'https://js-eu1.hs-scripts.com/25771685.js';

      document.body.appendChild(hubSpotScript);
    }
  },
  false,
);

export const useNavigationTracking = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    GoogleAnalytics.pageview(pathname);
  }, [pathname]);
};
