import logoUrl from '@assets/images/logo.svg';

import { colorPalette } from '@/theme/colorPalette';

const DEFAULT_BUTTON_COLOUR = colorPalette['primary-100'];
const DEFAULT_BUTTON_TEXT_COLOUR = colorPalette['white-100'];
const DEFAULT_TEXT_COLOUR = colorPalette['text-primary'];
const DEFAULT_HEADER_COLOUR = colorPalette['primary-100'];

export const COMPLY_CUBE_BRANDING = {
  appearance: {
    bodyTextColor: DEFAULT_TEXT_COLOUR,
    cameraButtonActiveColor: DEFAULT_TEXT_COLOUR,
    documentSelectorActiveBorderColor: DEFAULT_BUTTON_COLOUR,
    documentSelectorHoverBorderColor: DEFAULT_BUTTON_COLOUR,
    documentSelectorTextColor: DEFAULT_TEXT_COLOUR,
    headingTextColor: DEFAULT_HEADER_COLOUR,
    infoPopupColor: null,
    infoPopupTextColor: DEFAULT_TEXT_COLOUR,
    primaryButtonBorderColor: DEFAULT_BUTTON_COLOUR,
    primaryButtonColor: DEFAULT_BUTTON_COLOUR,
    primaryButtonHoverColor: DEFAULT_BUTTON_COLOUR,
    primaryButtonTextColor: DEFAULT_BUTTON_TEXT_COLOUR,
    secondaryButtonBorderColor: DEFAULT_BUTTON_COLOUR,
    secondaryButtonColor: DEFAULT_BUTTON_COLOUR,
    secondaryButtonHoverColor: DEFAULT_BUTTON_COLOUR,
    secondaryButtonTextColor: DEFAULT_BUTTON_TEXT_COLOUR,
    subheadingTextColor: DEFAULT_HEADER_COLOUR,
  },
  logo: {
    darkLogoUrl: `${window.location.origin}${logoUrl}`,
  },
  textBrand: 'Landler',
};
