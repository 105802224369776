import { useSyncExternalStore } from 'react';

import { breakpoints } from '@/theme/breakpoints';

export type ScreenSize = 'small' | 'medium' | 'large';

/**
 * - small  :   viewport <= sm
 * - medium :   sm < viewport <= md
 * - large  :   viewport > md
 *
 * @example
 * ```ts
 * const isSmallScreen = useScreenSize() === 'small'
 * ```
 */
export const useScreenSize = () => {
  const subscribe: Parameters<typeof useSyncExternalStore>[0] = (onStoreChange) => {
    window.addEventListener('resize', onStoreChange);

    return () => {
      window.removeEventListener('resize', onStoreChange);
    };
  };

  const getSnapshot = (): ScreenSize => {
    const viewportWidth = window.innerWidth;

    if (viewportWidth <= breakpoints.sm) {
      return 'small';
    }

    if (viewportWidth > breakpoints.sm && viewportWidth <= breakpoints.md) {
      return 'medium';
    }

    return 'large';
  };

  return useSyncExternalStore<ScreenSize>(subscribe, getSnapshot);
};
