import { useQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_USER_BY_ID_PATH,
  GetUserByIdRequestPathVariables,
  GetUserByIdSuccessDto,
  User,
} from '@/api/rest/resources/types/user';

export const useGetUser = (id: string) => {
  const pathVariables: GetUserByIdRequestPathVariables = {
    id,
  };

  const queryFn = async () => {
    return getRequest<GetUserByIdSuccessDto>(GET_USER_BY_ID_PATH, pathVariables);
  };

  return useQuery<User, Error>({
    queryKey: ['user', pathVariables],
    queryFn,
    staleTime: 1000 * 60 * 5,
    retry: false,
    throwOnError: true,
  });
};
