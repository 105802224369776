import { useTranslation } from 'react-i18next';

import { BiodiversityExplainer } from '@/components/NCAExplainersV1_2/BiodiversityExplainer';
import { NCCard, NCCard2SectionLayout, NCCardComplianceFooter, NCCardHeader } from '@/components/NCCardV1_2/NCCard';
import { COMPLIANCE_LOGOS_BIODIVERSITY } from '@/config/constants';

import { BiodiversityCardGraphTile } from './GraphTile';
import { BiodiversityCardStatsTile } from './StatsTile';

export const BiodiversityCard = () => {
  const { t } = useTranslation();

  return (
    <NCCard explainer={<BiodiversityExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.details.biodiversity.labels.biodiversityZone')} />
      <NCCard2SectionLayout>
        <BiodiversityCardGraphTile />
        <BiodiversityCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_BIODIVERSITY} />
    </NCCard>
  );
};
