import React, { HTMLAttributes } from 'react';

import { Container } from '../Container/Container';
import { cn } from '../utils';

export type AppBarProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * @default 'top'
   */
  placement?: 'top' | 'bottom';
};

export const AppBar = React.forwardRef<HTMLDivElement, AppBarProps>(
  ({ className, placement = 'top', ...delegated }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'sticky z-appbar flex w-full bg-white-100 elevation-1',
          {
            'top-0': placement === 'top',
            'bottom-0': placement === 'bottom',
          },
          className,
        )}
        {...delegated}
      />
    );
  },
);
AppBar.displayName = 'AppBar';

export type AppBarContentProps = HTMLAttributes<HTMLDivElement>;

export const AppBarContent = React.forwardRef<HTMLDivElement, AppBarContentProps>(
  ({ className, children, ...delegated }, ref) => {
    return (
      <Container {...delegated} ref={ref}>
        <div className={cn('flex items-center justify-between py-3', className)}>{children}</div>
      </Container>
    );
  },
);
AppBarContent.displayName = 'AppBarContent';
