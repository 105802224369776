export enum ApplicationLanguage {
  'DE' = 'de',
  'EN' = 'en',
  'ES' = 'es',
  'FR' = 'fr',
}

export function isApplicationLanguage(value: unknown): value is ApplicationLanguage {
  return Object.values(ApplicationLanguage).includes(value as ApplicationLanguage);
}

export type ReactQueryStatus = 'loading' | 'success' | 'error';

type DataAttributeKey = `data-${string}`;

/**
 * Typescript doesn't extend JSX attributes such as `data-*` when creating
 * custom types for component props. Use this utility type to add support for
 * data attributes without having to explicitly add types for `data-testid` etc.
 *
 * {@link https://github.com/microsoft/TypeScript/issues/28960#issuecomment-903519759 Reference discussion}
 *
 * @example
 * ```ts
 * type CustomButtonProps = DataAttributes & HTMLAttributes<HTMLButtonElement>;
 *
 * const buttonProps: CustomButtonProps = {
 *  'data-testid': 'some-test-id' // this works
 * }
 * ```
 */
export type DataAttributes = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [dataAttribute: DataAttributeKey]: any;
};
