import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { feature } from '@turf/helpers';
import { Feature, Polygon } from 'geojson';
import type { EventData, MapMouseEvent } from 'mapbox-gl';
import { MutableRefObject, useEffect, useId, useState } from 'react';
import { useControl, useMap } from 'react-map-gl';

import { PlotType } from '@/api/rest/resources/types/plot';
import { useDrawHint } from '@/hooks/useDrawHint';
import { getColorOfPlotType } from '@/utils/plot';
import { drawStyles } from '@/utils/plot/drawStyles';

type UseDrawControlOptions = {
  plotType: PlotType;
  initialPolygon?: Polygon;
  onFeatureUpdate?: (newFeature: Feature | null) => void;
};

export const useDrawControl = ({ onFeatureUpdate, initialPolygon, plotType }: UseDrawControlOptions) => {
  const [currentFeature, setCurrentFeature] = useState<Feature | null>(null);

  const updateCurrentFeature = (newFeature: Feature | null) => {
    setCurrentFeature(newFeature);
    onFeatureUpdate?.(newFeature);
  };

  const drawControl = useControl(({ map }) => {
    map.on('draw.create', ({ features }) => {
      updateCurrentFeature(features[0]);
    });
    map.on('draw.update', ({ features }) => {
      updateCurrentFeature(features[0]);
    });

    return new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: 'simple_select',
      styles: drawStyles({ fillColor: getColorOfPlotType(plotType) }),
    });
  });

  const initialFeatureId = useId();

  const { current: map } = useMap();

  useEffect(() => {
    const addInitialFeature = () => {
      if (!initialPolygon) {
        drawControl.changeMode('draw_polygon');
        return;
      }

      const initialFeature = feature(initialPolygon);
      initialFeature.id = initialFeatureId;
      drawControl.set({ type: 'FeatureCollection', features: [initialFeature] });

      drawControl.changeMode('simple_select', { featureIds: [initialFeature.id] });
      setCurrentFeature(initialFeature);
    };

    map?.on('load', addInitialFeature);
    return () => {
      map?.off('load', addInitialFeature);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCurrentPolygon = () => {
    const { features } = drawControl.getAll();

    features.forEach((f) => {
      if (typeof f.id === 'string') {
        drawControl.delete(f.id);
      }
    });

    drawControl.trash();

    updateCurrentFeature(null);
    drawControl.changeMode('draw_polygon');
  };

  const drawNewPolygon = () => {
    drawControl.changeMode('draw_polygon');
  };

  const { renderDrawHint } = useCursorDrawHint(drawControl, currentFeature);

  return { deleteCurrentPolygon, drawNewPolygon, currentFeature, renderDrawHint };
};

const useCursorDrawHint = (drawControl: MapboxDraw, currentFeature: Feature | null) => {
  const { current: map } = useMap();

  const { drawHintTranslation, tooltipRef, handleMouseEvents } = useDrawHint(drawControl);

  useEffect(() => {
    const handler = (e: MapMouseEvent & EventData) => {
      const mapInstance = map?.getMap();
      if (mapInstance) handleMouseEvents(e, mapInstance);
    };

    map?.on('mousemove', handler);
    map?.on('mouseup', handler);

    return () => {
      map?.off('mousemove', handler);
      map?.off('mouseup', handler);
    };
  }, [handleMouseEvents, map, currentFeature]);

  const renderDrawHint = () => {
    return (
      <div
        ref={tooltipRef as MutableRefObject<HTMLDivElement>}
        className='whitespace-nowrap rounded-full bg-primary-100 px-2 py-1 text-white-100'
      >
        {drawHintTranslation}
      </div>
    );
  };

  return { renderDrawHint };
};
