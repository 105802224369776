import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';
import { resolveThemeColor } from '@/utils/resolveThemeColor';

export const getColorOfPlotStatus = (plotStatus: PlotStatusEnum) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No color defined for plot status "${status}"`);
    return resolveThemeColor('transparent');
  };
  switch (plotStatus) {
    case PlotStatusEnum.invalid:
      return resolveThemeColor('error');
    case PlotStatusEnum.new_plot:
      return resolveThemeColor('new-plot');
    case PlotStatusEnum.draft:
      return resolveThemeColor('active-54');
    case PlotStatusEnum.ready_to_analyse:
      return resolveThemeColor('warning');
    case PlotStatusEnum.analysed:
      return resolveThemeColor('success');
    case PlotStatusEnum.calculating:
    case PlotStatusEnum.scheduled_for_analysis:
      return resolveThemeColor('warning-light');
    default:
      return exhaustivenessCheck(plotStatus);
  }
};

export const getColorOfPlotStatusNotice = (plotStatus: PlotStatusEnum) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No color defined for plot status notice "${status}"`);
    return resolveThemeColor('transparent');
  };

  switch (plotStatus) {
    case PlotStatusEnum.invalid:
      return resolveThemeColor('error-plot-background');
    case PlotStatusEnum.new_plot:
      return resolveThemeColor('new-plot-light');
    case PlotStatusEnum.draft:
      return resolveThemeColor('neutral-black-4');
    case PlotStatusEnum.analysed:
    case PlotStatusEnum.calculating:
    case PlotStatusEnum.ready_to_analyse:
    case PlotStatusEnum.scheduled_for_analysis:
      return resolveThemeColor('transparent');
    default:
      return exhaustivenessCheck(plotStatus);
  }
};

export const getTextColorOfPlotStatusNotice = (plotStatus: PlotStatusEnum) => {
  const exhaustivenessCheck = (status: never) => {
    Logger.error(`No text color defined for plot status notice "${status}"`);
    return resolveThemeColor('black-100');
  };

  switch (plotStatus) {
    case PlotStatusEnum.invalid:
      return resolveThemeColor('error-dark');
    case PlotStatusEnum.new_plot:
      return resolveThemeColor('new-plot-dark');
    case PlotStatusEnum.draft:
      return resolveThemeColor('plot-status-draft-notice-text');
    case PlotStatusEnum.analysed:
    case PlotStatusEnum.calculating:
    case PlotStatusEnum.ready_to_analyse:
    case PlotStatusEnum.scheduled_for_analysis:
      return resolveThemeColor('black-100');
    default:
      return exhaustivenessCheck(plotStatus);
  }
};

export const getColorOfPill = (isOutdatedValue: boolean) => {
  return isOutdatedValue ? resolveThemeColor('neutral-black-12') : resolveThemeColor('neutral-black-4');
};

export const getTextColorOfPill = (status: PlotStatusEnum) => {
  return status === PlotStatusEnum.analysed ? resolveThemeColor('black-100') : resolveThemeColor('secondary');
};
