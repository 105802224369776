import { useState } from 'react';
import { VerticalCoordinatesGenerator } from 'recharts-overriden/types/cartesian/CartesianGrid';

import { SpeciesGroupId } from '@/api/rest/resources/conservation';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';
import { range } from '@/utils/range';

import {
  CartesianGrid,
  Chart,
  ChartConfig,
  Line,
  LineChart,
  useTimeseriesXAxisProps,
} from './conservation/components/Chart';
import { useFact } from './conservation/hooks/useFact';
import { useProject } from './conservation/hooks/useProject';

const chartColours = [
  '#CA623C',
  '#769DC7',
  '#728A42',
  '#5CB689',
  '#D2A034',
  '#6FADC8',
  '#955454',
  '#B18B65',
  '#8D5495',
  '#549569',
];

export const IndicatorSpeciesBreakdownChart = () => {
  const { speciesGroups } = useProject().data;

  const chartConfig = Object.entries(speciesGroups).reduce(
    (acc, [group, label], index) => ({
      ...acc,
      [group]: {
        color: chartColours[index as number],
        label,
      },
    }),
    {},
  ) satisfies ChartConfig;

  type AttributeName = keyof typeof chartConfig;

  const currentYear = useFact<number>('r1_conservation_current_year').value;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;
  const speciesGroupPresenceTimeseries = useFact<Record<SpeciesGroupId, Record<`${number}`, number>>>(
    'r1_conservation_species_group_presence_ts',
  ).value;

  const speciesGroupIds = Object.keys(speciesGroups) as SpeciesGroupId[];

  const chartData = range(baselineYear, currentYear).map((year) => {
    const groupWiseData = speciesGroupIds.reduce((acc, curr) => {
      const value = speciesGroupPresenceTimeseries[curr as SpeciesGroupId]?.[`${year}`];

      const percentage = value ? value * 100 : null;

      return {
        ...acc,
        [curr]: percentage,
      };
    }, {});

    return {
      date: new Date(`${year}`),
      ...groupWiseData,
    } as { date: Date; [key: SpeciesGroupId]: number };
  });

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  const [highlightedAttribute, setHighlightedAttribute] = useState<AttributeName | null>(null);

  return (
    <Chart.Container config={chartConfig} data={chartData} height={500}>
      <LineChart data={chartData}>
        <Chart.XAxis {...xAxisProps} axisLine={false} />
        <Chart.YAxis domain={[0, 100]} tickFormatter={(v) => `${v}${formatUnit(UnitEnum['%'])}`} axisLine={false} />
        <CartesianGrid
          horizontal={false}
          strokeDasharray='2 4'
          stroke='#000000'
          strokeOpacity={0.4}
          syncWithTicks
          verticalCoordinatesGenerator={verticalCoordinatesGenerator(xAxisProps.ticks)}
        />
        <Chart.Tooltip
          cursor={{ strokeWidth: 2 }}
          content={(props) => <Chart.TooltipContent {...props} labelFormatter={printMonthYear} formatter={() => ''} />}
        />
        {Object.keys(chartConfig).map((dataKey) => {
          const color = (() => {
            const defaultColor = `var(--color-${dataKey})`;

            if (!highlightedAttribute) {
              return defaultColor;
            }

            if (dataKey !== highlightedAttribute) {
              return '#EEEEEE';
            }

            return defaultColor;
          })();

          return (
            <Line
              key={dataKey}
              dataKey={dataKey}
              stroke={color}
              dot={{
                fill: color,
                strokeWidth: 0,
                className: 'transition-[fill]',
              }}
              activeDot={{
                fill: color,
                r: 5.5,
                className: 'transition-[fill]',
              }}
              isAnimationActive={false}
              className='[&>path]:transition-[stroke]'
            />
          );
        })}
        <Chart.Legend
          content={(props) => (
            <Chart.LegendContent
              {...props}
              onAttributeHover={(dataKey) => setHighlightedAttribute(dataKey as AttributeName)}
            />
          )}
        />
      </LineChart>
    </Chart.Container>
  );
};

const verticalCoordinatesGenerator = (ticks: unknown[]) => (props: Parameters<VerticalCoordinatesGenerator>[0]) => {
  const ticksCount = ticks.length;

  const gridSpacing = (props.offset.width as number) / (ticksCount - 1);

  const offset = props.xAxis.x as number;

  return new Array(ticksCount).fill(null).map((_, index) => gridSpacing * index + offset);
};
