import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { CarbonExplainer } from '@/components/NCAExplainersV1_2/CarbonExplainer';
import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
} from '@/components/NCCardV1_2/NCCard';
import { COMPLIANCE_LOGOS_CARBON } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';

import { CarbonCardGraphTile } from './GraphTile';
import { CarbonCardStatsTile } from './StatsTile';

export const CarbonCard = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <NCCard explainer={<CarbonExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.details.carbon.labels.carbonStorageBG')}>
        {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
      </NCCardHeader>
      <NCCard2SectionLayout>
        <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
          {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
          <CarbonCardGraphTile />
        </Stack>
        <CarbonCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CARBON} />
    </NCCard>
  );
};
