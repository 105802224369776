import React, { createContext, useContext } from 'react';

import { cn } from '../utils';

type AlertType = 'default' | 'error' | 'warning';

export type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * @default 'default'
   */
  type?: AlertType;
};

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, type = 'default', ...delegated }, ref) => {
    return (
      <AlertContext.Provider value={{ type }}>
        <div
          ref={ref}
          {...delegated}
          className={cn(
            {
              'bg-info-light text-info-dark': type === 'default',
              'bg-error-light text-error-dark': type === 'error',
              'bg-warning-light text-warning-dark': type === 'warning',
            },
            'rounded-lg p-4',
            'grid grid-cols-[min-content_auto_max-content] grid-rows-[auto_auto] items-center gap-x-4 gap-y-0.5',
            className,
          )}
        />
      </AlertContext.Provider>
    );
  },
);
Alert.displayName = 'Alert';

export const AlertTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, style, ...delegated }, ref) => {
    return (
      // eslint-disable-next-line jsx-a11y/heading-has-content
      <h5
        ref={ref}
        {...delegated}
        className={cn('typography-body1Semibold', className)}
        style={{ gridColumn: '2 / 3', gridRow: '1 / 2', ...style }}
      />
    );
  },
);
AlertTitle.displayName = 'AlertTitle';

export const AlertBody = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, style, ...delegated }, ref) => {
    return (
      <p
        ref={ref}
        {...delegated}
        className={cn('typography-body2 whitespace-pre-line', className)}
        style={{ gridColumn: '2 / 3', gridRow: '2 / 3', ...style }}
      />
    );
  },
);
AlertBody.displayName = 'AlertBody';

export const AlertIcon = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...delegated }, ref) => {
    const { type } = useContext(AlertContext);

    return (
      <div
        ref={ref}
        {...delegated}
        className={cn(
          {
            'text-error': type === 'error',
            'text-warning': type === 'warning',
          },
          'typography-body2 col-span-1 col-start-1 row-span-2 row-start-1',
          className,
        )}
      />
    );
  },
);
AlertIcon.displayName = 'AlertIcon';

export type AlertActionProps = React.HTMLAttributes<HTMLDivElement>;

export const AlertAction = React.forwardRef<HTMLDivElement, AlertActionProps>(({ className, ...delegated }, ref) => {
  return <div ref={ref} className={cn('col-span-1 col-start-3 row-span-2 row-start-1', className)} {...delegated} />;
});
AlertAction.displayName = 'AlertAction';

interface AlertContextValue {
  type?: AlertType;
}

const AlertContext = createContext<AlertContextValue>({});
