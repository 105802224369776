import { createContext, useContext } from 'react';

import { User as ApiUser, User } from '@/api/rest/resources/types/user';

export interface UserContextInterface {
  user: User | null;
  changeLanguage: (language: string) => Promise<void>;
  updateUserPartially: (user: Partial<ApiUser>) => void;
}

export interface UseUserContextInterface extends UserContextInterface {
  user: User;
}

export const UserContext = createContext<UserContextInterface | null>(null);

export const UserContextProvider = UserContext.Provider;

// TODO: this is a hotfix and should not be exposed to other components (MVP-1158)
export const useUpdateUserPartially = () => {
  return useContext(UserContext)?.updateUserPartially;
};

export const useUserContext = (): UseUserContextInterface => {
  const userContextData = useContext(UserContext);
  if (!userContextData) {
    throw new Error(`The component that calls useUser() needs to be a deep child of <UserContextProvider>`);
  }
  if (!userContextData.user) {
    throw new Error(
      `The user should never be null if using useUserContext. This should be protected by the AuthenticationGuard`,
    );
  }

  // this type assertion is necessary because typescript does not infers that user can not be null
  return userContextData as UseUserContextInterface;
};

export const useUser = () => {
  const { user } = useUserContext();

  return user;
};
