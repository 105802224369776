import {
  BiodiversityIcon,
  cn,
  Co2Icon,
  Pill,
  RiArrowRightSLine,
  RiCircleFill,
  Shimmer,
  ShimmerProps,
  Stack,
  StackProps,
  toast,
  WaterIcon,
} from '@landler/tw-component-library';
import { useQuery } from '@tanstack/react-query';
import { FC, HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FactsByFactType, GetDashboardPlotReportErrorDto } from '@/api/rest/resources/types/dashboard';
import { PlotReportFactElement, R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { Plot, PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { MaybeLink, PlotTypeIcon } from '@/components';
import { MapPopup, MapPopupContent, MapPopupProps } from '@/components/MapOverview/components/MapPopup';
import { PlotThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { UNANALYZABLE_PLOT_STATUS_TYPES } from '@/config/constants';
import { useFact } from '@/hooks/useFact';
import { useMembershipType } from '@/hooks/useMembershipType';
import { getPlotReportsForProject } from '@/pages/shared/hooks/usePlotReportsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { PlotNotice } from '@/pages/shared/plots/components/Cells';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatFactKilogramsToTonnes } from '@/utils/formatting';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';
import {
  getColorOfPill,
  getColorOfPlotStatus,
  getTextColorOfPill,
} from '@/utils/plot/get-color-of-plot-status/getColorOfPlotStatus';

export type ProjectMapPopupProps = MapPopupProps;

export const ProjectMapPopup = ({ plot, onClose }: ProjectMapPopupProps) => {
  const { t } = useTranslation();

  const membershipType = useMembershipType();
  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');

  const { display: areaDisplay } = useFact({
    value: squareMetersToHectares(plot.area),
    unit: UnitEnum.ha,
  } as PlotReportFactElement);

  const destinationPath = useMemo(() => {
    if (membershipType === MembershipWithOrganizationTypeEnum.buyer) {
      if (plot.status === PlotStatusEnum.invalid) return null;

      return buildPath(paths.buyer.plot, {
        pathParams: { projectId: projectDetail.id, plotId: plot.id },
      });
    }

    if ([PlotStatusEnum.invalid].includes(plot.status) && !isProjectEditable) {
      return null;
    }

    if ([PlotStatusEnum.invalid].includes(plot.status)) {
      return buildPath(paths.landSteward.editPlot, {
        pathParams: { projectId: projectDetail.id, plotId: plot.id },
      });
    }
    return buildPath(paths.landSteward.plot, {
      pathParams: { projectId: projectDetail.id, plotId: plot.id },
    });
  }, [plot, projectDetail.id, membershipType, isProjectEditable]);

  const onRowClick = () => {
    if (plot.status === PlotStatusEnum.invalid && !isProjectEditable) {
      toast({
        toastId: 'project-is-locked-redirect-toast',
        title: t('landSteward.plot.lockedToasts.redirectAddEditLink'),
        type: 'error',
        autoClose: 10_000,
      });
    }
  };

  return (
    <MapPopup plot={plot} onClose={onClose}>
      <MapPopupContent>
        <div className='p-2'>
          <MaybeLink to={destinationPath}>
            <Stack
              onClick={onRowClick}
              direction='row'
              centerMain
              className='rounded-[10px] p-2 full-bleed-x hover:bg-neutral-hover'
            >
              <Stack direction='row' centerMain spacing={4} className='flex-1'>
                <PlotThumbnail plot={plot} className='h-[72px] w-[72px] rounded-md' />
                <InfoBox className='min-w-0 max-w-[250px] items-start gap-2'>
                  <InfoText className='max-w-full truncate'>{plot.name}</InfoText>
                  {plot.status && (
                    <Stack direction='row' spacing={2} className='typography-body2 items-center justify-center'>
                      <RiCircleFill size={12} color={getColorOfPlotStatus(plot.status)} />
                      <span>{t(`global.plotStatus.${plot.status}`)}</span>
                    </Stack>
                  )}
                </InfoBox>
              </Stack>
              <RiArrowRightSLine size={24} className='shrink-0' />
            </Stack>
          </MaybeLink>
        </div>
        <Stack direction='row' spacing={10} className='px-4'>
          <InfoBox className='flex-shrink-0'>
            <InfoTitle>{t('global.plot.mapPopup.labels.type')}</InfoTitle>
            <PlotTypeIcon type={plot.type} />
          </InfoBox>
          <InfoBox className='flex-shrink-0'>
            <InfoTitle>{t('global.plot.mapPopup.labels.area')}</InfoTitle>
            <InfoText>{areaDisplay}</InfoText>
          </InfoBox>
          <InfoBox>
            <InfoTitle>{t('global.plot.mapPopup.labels.location')}</InfoTitle>
            <InfoText>{projectDetail.location_description || t('global.plot.mapPopup.unknownLocation')}</InfoText>
          </InfoBox>
        </Stack>

        <Stack spacing={6} className='px-4 pb-4'>
          {UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status) && <PlotNotice {...plot} />}
          {![PlotStatusEnum.invalid, PlotStatusEnum.new_plot].includes(plot.status) && (
            <>
              <BiodiversityZone {...plot} />
              <CarbonStorage {...plot} />
              <WaterHoldingCapacity {...plot} />
            </>
          )}
        </Stack>
      </MapPopupContent>
    </MapPopup>
  );
};

const BiodiversityZone = (plot: Plot) => {
  const { t } = useTranslation();

  const { data: plotReport, isPending } = usePlotReport(plot.id);
  const biodiversityZone = plotReport?.[R1FactType.r1_biodiversity_zone_percent];

  let { display } = useFact(biodiversityZone as PlotReportFactElement<number>);
  display = display === 'N/A' ? '--' : display;
  const isOutdatedValue = display !== '--' && plot.status !== PlotStatusEnum.analysed;
  return (
    <InfoBox>
      <InfoTitle>{t('global.analysis.biodiversityZone')}</InfoTitle>
      <PendingPill animate={isPending}>
        <Pill
          size='small'
          style={{
            backgroundColor: getColorOfPill(isOutdatedValue),
            color: getTextColorOfPill(plot.status),
          }}
          leftAdornment={<BiodiversityIcon size={12} />}
        >
          {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
          {biodiversityZone ? display : t('global.misc.comingSoon')}
        </Pill>
      </PendingPill>
    </InfoBox>
  );
};

const CarbonStorage = (plot: Plot) => {
  const { t } = useTranslation();

  const { data: plotReport, isPending } = usePlotReport(plot.id);
  const carbonStorage = plotReport?.[R1FactType.r1_carbon_storage_bg_per_ha] as
    | PlotReportFactElement<number>
    | undefined;

  let { display } = useFact(formatFactKilogramsToTonnes(carbonStorage));
  display = display === 'N/A' ? '--' : display;
  const isOutdatedValue = display !== '--' && plot.status !== PlotStatusEnum.analysed;
  return (
    <InfoBox>
      <InfoTitle>{t('global.analysis.carbonStorageBg')}</InfoTitle>
      <PendingPill animate={isPending}>
        <Pill
          size='small'
          style={{
            backgroundColor: getColorOfPill(isOutdatedValue),
            color: getTextColorOfPill(plot.status),
          }}
          leftAdornment={<Co2Icon size={12} />}
        >
          {carbonStorage ? display : t('global.misc.comingSoon')}
        </Pill>
      </PendingPill>
    </InfoBox>
  );
};

const WaterHoldingCapacity = (plot: Plot) => {
  const { t } = useTranslation();

  const { data: plotReport, isPending } = usePlotReport(plot.id);
  const waterHoldingCapacity = plotReport?.[R1FactType.r1_water_holding_capacity_per_ha];

  let { display } = useFact(waterHoldingCapacity as PlotReportFactElement<number>);
  display = display === 'N/A' ? '--' : display;
  const isOutdatedValue = display !== '--' && plot.status !== PlotStatusEnum.analysed;
  return (
    <InfoBox>
      <InfoTitle>{t('global.analysis.waterHoldingCapacity')}</InfoTitle>
      <PendingPill animate={isPending}>
        <Pill
          size='small'
          style={{
            backgroundColor: getColorOfPill(isOutdatedValue),
            color: getTextColorOfPill(plot.status),
          }}
          leftAdornment={<WaterIcon size={12} />}
        >
          {waterHoldingCapacity ? display : t('global.misc.comingSoon')}
        </Pill>
      </PendingPill>
    </InfoBox>
  );
};

type InfoBoxProps = StackProps;

const InfoBox: FC<InfoBoxProps> = ({ className, ...delegated }) => (
  <Stack className={cn('min-w-0', className)} {...delegated} />
);

const InfoTitle: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span
    className={cn('typography-overline block truncate pb-2 text-left text-text-disabled', className)}
    {...delegated}
  />
);

const InfoText: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h4 block truncate whitespace-nowrap text-left', className)} {...delegated} />
);

const PendingPill = ({ className, ...delegated }: ShimmerProps) => (
  <Shimmer className={cn('h-8 w-[100px] rounded-l-full rounded-r-full', className)} {...delegated} />
);

/**
 * We create a separate hook here instead of using `usePlotReportForPlot` since
 * we'd like to use a regular query (useQuery) instead of a suspense query
 * (useSuspenseQuery).
 */
const usePlotReport = (plotId: string) => {
  const projectDetail = useProjectDetailById().data;
  const membershipType = useMembershipType();

  return useQuery<FactsByFactType | undefined, GetDashboardPlotReportErrorDto>({
    queryKey: [membershipType, 'dashboard', 'plot-reports', plotId],
    // eslint-disable-next-line security/detect-object-injection
    queryFn: async () => (await getPlotReportsForProject({ projectId: projectDetail.id, membershipType }))[plotId],
    staleTime: 1000 * 60 * 5,
  });
};
