import { ButtonLink } from '@landler/tw-component-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FullScreenError } from '@/components';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { Logger } from '@/lib/logs/logger';
import { paths } from '@/routing';

type NotFoundPageProps = {
  disableLogging?: boolean;
};

export const NotFoundPage: React.FC<NotFoundPageProps> = ({ disableLogging }) => {
  const { t } = useTranslation();
  const contactLink = useContactFormLink();
  const { pathname, search } = useLocation();

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function logWarning() {
      if (!disableLogging) {
        Logger.warnOnce(`Invalid route: ${pathname + search}`);
      }
    },
    [pathname, search, disableLogging],
  );

  return (
    <FullScreenError
      title={t('notFound.heading')}
      body={t('notFound.description')}
      actions={
        <>
          <ButtonLink target='_blank' to={contactLink} variant='outline'>
            {t('notFound.labels.contactSupportButton')}
          </ButtonLink>
          <ButtonLink reloadDocument to={paths.root}>
            {t('notFound.labels.backToHomeButton')}
          </ButtonLink>
        </>
      }
    />
  );
};
