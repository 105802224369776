import { cva } from 'class-variance-authority';

export const containedIndicatorVariants = cva('px-2 rounded-md text-white-100', {
  variants: {
    color: {
      error: ['bg-error'],
      success: ['bg-success'],
    },
    size: {
      medium: ['py-2'],
      small: ['py-1'],
    },
  },
  defaultVariants: {
    color: 'success',
    size: 'medium',
  },
});
