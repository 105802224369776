import { useParams } from 'react-router-dom';

export const useProjectId = () => {
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId) {
    throw new Error('Missing URL param: projectId');
  }

  return projectId;
};
