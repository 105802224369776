import { FC, HTMLProps } from 'react';

import { cn } from '../utils';

export type DividerProps = HTMLProps<HTMLHRElement> & {
  direction?: 'horizontal' | 'vertical';
};

/**
 * Before using this, consider using the `divide-x` or `divide-y` Tailwind utilities.
 * @link https://tailwindcss.com/docs/divide-width#basic-usage
 */
export const Divider: FC<DividerProps> = ({ className, direction, ...delegated }) => {
  const dir = direction ?? 'horizontal';

  if (dir === 'vertical') return <div className={cn('self-stretch border-l text-divider', className)} {...delegated} />;

  return <hr className={cn('text-divider', className)} {...delegated} />;
};
