import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';

type CardProps = HTMLAttributes<HTMLDivElement> & {
  label: string;
};

export const Card: FC<CardProps> = ({ label, children, className, ...delegated }) => {
  const styles = cn('flex flex-col rounded-3xl bg-white-100 p-6 lg:p-10 gap-10', className);

  return (
    <div className={styles} {...delegated}>
      <span className='typography-overline' data-cy='card-label'>
        {label}
      </span>
      {children}
    </div>
  );
};
