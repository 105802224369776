import { Button, Container, Divider, PlotIcon, RiMapPin2Fill, Shimmer, Stack } from '@landler/tw-component-library';
import { centroid } from '@turf/turf';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TextInput } from 'tw-component-library/TextInput/TextInput';

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components';
import { PlotThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useFormatCoordinates } from '@/hooks/useFormatCoordinates';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { usePlotForm } from '@/pages/landsteward/pages/plot/hooks/usePlotForm';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';
import { squareMetersToHectares } from '@/utils/plot';

export type PlotOverviewProps = {
  editPlotPath: string;
};

export const PlotOverview = ({ editPlotPath }: PlotOverviewProps) => {
  const isLargeScreen = useScreenSize() === 'large';

  return (
    <Container className='bg-white-100 py-6' data-testid='plot-overview'>
      {isLargeScreen ? (
        <LargeScreenView editPlotPath={editPlotPath} />
      ) : (
        <SmallScreenView editPlotPath={editPlotPath} />
      )}
    </Container>
  );
};

const LargeScreenView = ({ editPlotPath }: PlotOverviewProps) => {
  const { t } = useTranslation();

  const projectQuery = useProject();
  const projectData = projectQuery.data;

  const form = usePlotForm();

  const [plotType, polygon] = form.watch(['plotType', 'polygon']);

  if (!polygon || !plotType) {
    throw new UnexpectedMissingDataError({ dataLabels: { polygon, plotType } });
  }

  const formattedArea = useFormattedArea();
  const formattedCoordinates = useFormattedCoordinates();
  const locationName = projectData?.location_description;

  return (
    <Stack direction='row' className='items-start justify-between' data-testid='plot-overview-desktop'>
      <Stack direction='row'>
        <div className='mt-3'>
          <PlotThumbnail plot={{ polygon, type: plotType }} alt='plot map' className='h-32 w-32 rounded-lg' />
        </div>
        <Stack direction='row' spacing={6} className='justify-between divide-x divide-divider py-4'>
          <InfoStack title={t('landSteward.plot.plotSummary')}>
            <FormField
              control={form.control}
              name='name'
              rules={{ required: true }}
              render={({ field: { value, ...field } }) => (
                <FormItem className='mt-4 flex flex-col gap-2'>
                  {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                  <FormLabel>{t('landSteward.plot.plotName')}</FormLabel>
                  <FormControl>
                    <TextInput
                      placeholder={t('landSteward.plot.plotName')}
                      className='w-[250px]'
                      value={value ?? ''}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className='mt-0'>{t('global.ui.form.input.required')}</FormMessage>
                </FormItem>
              )}
            />
          </InfoStack>
          <InfoStack title={t('landSteward.plot.size')}>
            <span className='typography-h2'>{formattedArea} ha</span>
          </InfoStack>
          <InfoStack title={t('landSteward.plot.location')}>
            <Stack direction='row' spacing={2}>
              <RiMapPin2Fill size={24} className='flex-shrink-0 text-primary-100' />
              <div>
                <Shimmer className='mb-1 block min-h-[20px]' animate={!locationName}>
                  <span>{locationName}</span>
                </Shimmer>
                <span className='text-text-secondary'>{formattedCoordinates}</span>
              </div>
            </Stack>
          </InfoStack>
        </Stack>
      </Stack>

      <div className='flex-shrink-0 py-4'>
        <Button variant='outline' leftAdornment={<PlotIcon />} className='bg-white-100' asChild>
          <Link to={editPlotPath}>{t('landSteward.plot.labels.editBoundaries')}</Link>
        </Button>
      </div>
    </Stack>
  );
};

const SmallScreenView = ({ editPlotPath }: PlotOverviewProps) => {
  const { t } = useTranslation();

  const projectQuery = useProject();
  const projectData = projectQuery.data;

  const form = usePlotForm();

  const [plotType, polygon] = form.watch(['plotType', 'polygon']);

  if (!polygon || !plotType) {
    throw new UnexpectedMissingDataError({ dataLabels: { polygon, plotType } });
  }

  const formattedArea = useFormattedArea();
  const formattedCoordinates = useFormattedCoordinates();
  const locationName = projectData?.location_description;

  return (
    <Stack spacing={5} data-testid='plot-overview-mobile'>
      <Stack direction='row' spacing={6}>
        <div className='mt-3'>
          <PlotThumbnail plot={{ polygon, type: plotType }} alt='plot map' className='h-32 w-32 rounded-lg' />
        </div>
        <Stack className='flex-1 justify-between py-4'>
          <span className='typography-overline text-text-secondary'>{t('landSteward.plot.plotSummary')}</span>
          <FormField
            control={form.control}
            name='name'
            rules={{ required: true }}
            render={({ field: { value, ...field } }) => (
              <FormItem className='mt-4 flex flex-col gap-2'>
                <FormLabel>{t('landSteward.plot.plotName')}</FormLabel>
                <FormControl>
                  <TextInput
                    placeholder={t('landSteward.plot.plotName')}
                    className='w-full'
                    value={value ?? ''}
                    {...field}
                  />
                </FormControl>
                <FormMessage className='mt-0'>{t('global.ui.form.input.required')}</FormMessage>
              </FormItem>
            )}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction='row' className='items-center justify-between'>
        <span className='typography-overline text-text-secondary'>{t('landSteward.plot.size')}</span>
        <span className='typography-h2'>{formattedArea} ha</span>
      </Stack>
      <Divider />
      <Stack direction='row' className='items-center justify-between'>
        <span className='typography-overline text-text-secondary'>{t('landSteward.plot.location')}</span>
        <Stack className='text-right'>
          <Shimmer className='h-5 min-w-[100px]' animate={!locationName}>
            <span className='mb-1 block'>{locationName}</span>
          </Shimmer>
          <span className='truncate text-text-secondary'>{formattedCoordinates}</span>
        </Stack>
      </Stack>
      <Divider />
      <Button variant='outline' leftAdornment={<PlotIcon />} className='self-start bg-white-100' asChild>
        <Link to={editPlotPath}>{t('landSteward.plot.labels.editBoundaries')}</Link>
      </Button>
    </Stack>
  );
};

const InfoStack = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <Stack className='justify-between pl-6'>
      <span className='typography-overline'>{title}</span>
      {children}
    </Stack>
  );
};

const useFormattedArea = () => {
  const form = usePlotForm();

  const areaSquareMeters = form.watch('areaSquareMeters');

  if (!areaSquareMeters) {
    throw new UnexpectedMissingDataError({ dataLabels: { areaSquareMeters } });
  }

  return useDisplayNumber(squareMetersToHectares(areaSquareMeters));
};

const useFormattedCoordinates = () => {
  const form = usePlotForm();

  const polygon = form.watch('polygon');

  if (!polygon) {
    throw new UnexpectedMissingDataError({ dataLabels: { polygon } });
  }

  const lngLat = centroid(polygon).geometry.coordinates;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useFormatCoordinates([lngLat[1]!, lngLat[0]!]);
};
