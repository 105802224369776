import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PLOT_BY_ID_PATH,
  GET_PLOT_BY_ID_PATH,
  GetBuyerPlotByIdErrorDto,
  GetBuyerPlotByIdSuccessDto,
  GetPlotByIdErrorDto,
  GetPlotByIdSuccessDto,
} from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';

import { usePlotId } from './usePlotId';

type SuccessDto = GetPlotByIdSuccessDto | GetBuyerPlotByIdSuccessDto;
type ErrorDto = GetPlotByIdErrorDto | GetBuyerPlotByIdErrorDto;

export const usePlot = (plotIdArg?: string) => {
  const routePlotId = usePlotId();
  const plotId = plotIdArg ?? routePlotId;

  const membershipType = useMembershipType();

  const pathParams = { id: plotId };

  const queryFn = async () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_PLOT_BY_ID_PATH
        : GET_BUYER_PLOT_BY_ID_PATH,
      pathParams,
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'plot', plotId],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
