import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { ProjectStatus, ProjectType } from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';
import { usePathSet } from '@/hooks/usePathSet';
import { useScreenSize } from '@/hooks/useScreenSize';
import { ProjectListEnhanced } from '@/pages/shared/hooks/useProjectsList';
import { buildPath } from '@/utils/buildPath';

import { ProjectCardDesktop } from './components/ProjectCardDesktop';
import { ProjectCardMobile } from './components/ProjectCardMobile';
import { ProjectCardTablet } from './components/ProjectCardTablet';
import { ProjectCardProps } from './types';

export const printSignedPercentage = (percentage: number) => (percentage >= 0 ? `+${percentage}%` : `${percentage}%`);

export const ProjectCard = ({ project }: { project: ProjectListEnhanced }) => {
  const navigate = useNavigate();
  const membershipType = useMembershipType();
  const pathSet = usePathSet();

  const goToProjectDetails = () => {
    if (project.type === ProjectType.conservation) {
      return navigate(buildPath(pathSet.projectDetails, { pathParams: { projectId: project.id } }));
    }

    return navigate(buildPath(pathSet.projectDetails, { pathParams: { projectId: project.id } }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') goToProjectDetails();
  };

  const shouldRedirectToProjectDetails =
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? true
      : project.organization.type === OrganizationTypeEnum.DEMO || project.status !== ProjectStatus.initial;

  if (shouldRedirectToProjectDetails) {
    return (
      /**
       * We're using a div with an onClick listener instead of a <Link> because
       * we'd like to prevent navigation when an interactive element inside the
       * card is clicked.
       */
      <div
        role='link'
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={goToProjectDetails}
        className='cursor-pointer'
        data-testid={`project-card-${project.id}`}
        data-cy={`project-card-${project.id}`}
      >
        <ResponsiveCard project={project} />
      </div>
    );
  }

  return (
    <div data-testid={`project-card-${project.id}`}>
      <ResponsiveCard project={project} />
    </div>
  );
};

const ResponsiveCard: React.FC<ProjectCardProps> = (delegated) => {
  const isTablet = useScreenSize() === 'medium';
  const isMobile = useScreenSize() === 'small';

  if (isMobile) {
    return <ProjectCardMobile {...delegated} />;
  }

  if (isTablet) {
    return <ProjectCardTablet {...delegated} />;
  }

  return <ProjectCardDesktop {...delegated} />;
};
