export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 1000,
  lg: 1440,
  xl: 1920,

  // eslint-disable-next-line security/detect-object-injection
  down: (size: Breakpoint) => `(max-width: ${breakpoints[size]}px)`,

  // eslint-disable-next-line security/detect-object-injection
  up: (size: Breakpoint) => `(min-width: ${breakpoints[size]}px)`,
} as const;

export const setBreakpointVariables = () => {
  const css = `
    :root {
      --bp-xs: ${breakpoints.xs}px;
      --bp-sm: ${breakpoints.sm}px;
      --bp-md: ${breakpoints.md}px;
      --bp-lg: ${breakpoints.lg}px;
      --bp-xl: ${breakpoints.xl}px;
    }
  `;

  const styleElement = document.createElement('style');
  styleElement.appendChild(document.createTextNode(css));
  document.head.appendChild(styleElement);
};
