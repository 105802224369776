import { Slot, Slottable } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import _merge from 'lodash/merge';
import React, { ButtonHTMLAttributes } from 'react';
import { IconContext } from 'react-icons';

import { cn } from '../utils';
import { containedButtonVariants } from './containedButton';
import { outlineButtonVariants } from './outlineButton';
import { textButtonVariants } from './textButton';

type TextButtonProps = VariantProps<typeof textButtonVariants>;
type ContainedButtonProps = VariantProps<typeof containedButtonVariants>;
type OutlineButtonProps = VariantProps<typeof outlineButtonVariants>;

interface ButtonVariants extends TextButtonProps, ContainedButtonProps, OutlineButtonProps {
  /**
   * Which button variant to render. Default: 'contained'.
   */
  variant?: 'contained' | 'outline' | 'text';
}

const buttonStyles = (variants: ButtonVariants = {}) => {
  const defaultVariants: ButtonVariants = { variant: 'contained', size: 'medium' };
  const $variants = _merge(defaultVariants, variants);

  const backgroundStyles = cn(
    'relative flex gap-2 items-center justify-center text-center flex-shrink-0',
    {
      text: textButtonVariants($variants),
      contained: containedButtonVariants($variants),
      outline: outlineButtonVariants($variants),
    }[$variants.variant ?? 'contained'],
  );

  return cn(backgroundStyles, 'transition-colors');
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonVariants & {
    /**
     * If true, the component will render as the child element and forward all
     * props to it.
     */
    asChild?: boolean;
    /**
     * When true, disables the button and shows a loading indicator on the button.
     */
    loading?: boolean;
    /**
     * Add an element to the left side of the button child.
     */
    leftAdornment?: React.ReactNode;
    /**
     * Add an element to the right side of the button child.
     */
    rightAdornment?: React.ReactNode;
  };

/**
 * Use the `variant`, `size` and `disabled` props to control the apppearance of the Button.
 *
 * Use the `asChild` prop to style a different HTML element as a Button.
 * @example
 * ```jsx
 * <Button asChild>
 *    <a href='/account'>Account</a>
 * </Button>
 * ```
 *
 * Output:
 * ```jsx
 * <a href='/account'>Account</a> // with button styling
 * ```
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      color,
      disabled: disabledProp,
      'aria-disabled': ariaDisabledProp,
      loading: isLoading,
      asChild,
      type: typeProp,
      children,
      leftAdornment,
      rightAdornment,
      ...delegated
    },
    ref,
  ) => {
    const disabled = disabledProp || isLoading;
    const ariaDisabled = ariaDisabledProp ?? disabled;

    const styles = cn(buttonStyles({ variant, size, color }), className);

    const iconSize = size === 'small' ? '20' : '24';

    const Comp = asChild ? Slot : 'button';

    const type = typeProp ?? (asChild ? undefined : 'button');

    /**
     * `Slottable` seems to be an undocumented component. It allows us to use
     * `Slot` with multiple children, while forwarding props to a specific child.
     * Reference: https://github.com/radix-ui/primitives/issues/1825
     */
    return (
      <IconContext.Provider value={{ size: iconSize }}>
        <Comp
          ref={ref}
          {...delegated}
          disabled={disabled}
          // We add a `data-disabled` attribute to be able to style elements
          // that do not have a `disabled` attribute
          data-disabled={disabled}
          aria-disabled={ariaDisabled}
          className={styles}
          type={type}
          data-isloading={isLoading}
        >
          {leftAdornment}
          <Slottable>{children}</Slottable>
          {rightAdornment}
          {isLoading && (
            <div className='absolute inset-0 flex items-center justify-center rounded-full bg-neutral-white-50 animate-in fade-in'>
              <ButtonSpinner />
            </div>
          )}
        </Comp>
      </IconContext.Provider>
    );
  },
);
Button.displayName = 'Button';

const ButtonSpinner = () => (
  <svg
    className='h-5 w-5 animate-spin text-primary-100'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
  >
    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
    <path
      className='opacity-75'
      fill='currentColor'
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    ></path>
  </svg>
);
