import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { languages, useLanguage } from '@/hooks/useLanguage';
import { useUserContext } from '@/lib/auth';

export const SelectLanguage = () => {
  const { t } = useTranslation();
  const { changeLanguage: changeAppLanguage, currentLanguage: currentAppLanguage } = useLanguage();
  const { changeLanguage: changeUserLanguage } = useUserContext();

  const changeLanguage = (lang: string) => {
    changeUserLanguage(lang);
    changeAppLanguage(lang);
  };

  return (
    <Box sx={{ maxWidth: { md: 300 }, mt: 1.5, width: '100%' }} data-testid='select-language'>
      <FormControl fullWidth>
        <InputLabel data-cy='account-language-select-label'>{t('shared.account.labels.language')}</InputLabel>
        <Select
          label={t('shared.account.labels.language')}
          value={currentAppLanguage.nativeTranslation}
          data-cy='account-language-select-value'
        >
          {Object.entries(languages).map(([key, label]) => (
            <MenuItem
              key={key}
              value={label}
              data-cy={`account-language-selector-item-${key}`}
              onClick={() => changeLanguage(key)}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
