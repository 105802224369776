import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECT_BY_ID_PATH,
  GET_PROJECT_BY_ID_PATH,
  GetBuyerProjectByIdSuccessDto,
  GetProjectByIdSuccessDto,
  ProjectBuyerList,
  ProjectList,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjects } from '@/pages/shared/hooks/useProjects';

export type ProjectsList = ProjectList[] | ProjectBuyerList[];

export type ProjectListEnhancedLS = GetProjectByIdSuccessDto & ProjectList;
export type ProjectListEnhancedBuyer = GetBuyerProjectByIdSuccessDto & ProjectBuyerList;

export type ProjectListEnhanced = ProjectListEnhancedLS | ProjectListEnhancedBuyer;

export type ProjectListEnhancedWithCentroid = ProjectListEnhanced & { centroid: { lat: number; lon: number } };

const getProjectsList = async ({
  projects,
  membershipType,
}: {
  projects: ProjectsList;
  membershipType: MembershipWithOrganizationTypeEnum;
}) => {
  // TODO: MVP-2617 clean up after project APIs are updated
  return Promise.all(
    projects.map(async (project) => {
      const response = await getRequest<ProjectListEnhanced>(
        membershipType === MembershipWithOrganizationTypeEnum.land_steward
          ? GET_PROJECT_BY_ID_PATH
          : GET_BUYER_PROJECT_BY_ID_PATH,
        { id: project.id },
      );

      return { ...response, ...project } as ProjectListEnhanced;
    }),
  );
};

export const useProjectsList = () => {
  const membershipType = useMembershipType();
  const projects = useProjects().data.results;

  return useSuspenseQuery<ProjectListEnhanced[]>({
    queryKey: [membershipType, 'project', projects],
    queryFn: () => getProjectsList({ projects, membershipType }),
    staleTime: 1000 * 60 * 5,
  });
};
