import { Slot } from '@radix-ui/react-slot';
import { createContext, HTMLAttributes, MouseEventHandler, useContext, useState } from 'react';

import { cn } from '../utils';

export interface ToggleButtonGroupProps<T> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: T;
  onChange?: (newValue: T) => void;
  size?: 'default' | 'small';
}

export const ToggleButtonGroup = <T,>({
  value,
  onChange,
  size,
  className,
  ...delegated
}: ToggleButtonGroupProps<T>) => {
  const [selection, setSelectionState] = useState(value);

  const setSelection: typeof onChange = (newValue) => {
    if (value) {
      onChange?.(newValue);
    } else {
      setSelectionState(newValue);
    }
  };

  return (
    <ToggleButtonGroupContext.Provider value={{ selection: value ?? selection, setSelection, size }}>
      <div className={cn('inline-block flex-shrink-0', className)} {...delegated} />
    </ToggleButtonGroupContext.Provider>
  );
};

export interface ToggleButtonProps<T> extends HTMLAttributes<HTMLButtonElement> {
  value: T;
  asChild?: boolean;
}

export const ToggleButton = <T,>({ className, value, onClick, asChild, ...delegated }: ToggleButtonProps<T>) => {
  const { selection, setSelection, size = 'default' } = useContext(ToggleButtonGroupContext);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (...args) => {
    onClick?.(...args);
    setSelection(value);
  };

  const Component = asChild ? Slot : 'button';

  return (
    <Component
      onClick={handleClick}
      className={cn(
        'inline-block border-b border-r border-t border-primary-100 text-primary-100',
        'first:rounded-l-full first:border-l',
        'last:rounded-r-full',
        {
          'bg-primary-100 text-white-100': selection && value && selection === value,
        },
        {
          'typography-button1 px-5 py-3': size === 'default',
        },
        {
          'typography-button2 px-4 py-2': size === 'small',
        },
        className,
      )}
      {...delegated}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ToggleButtonGroupContextValue<T = any> {
  selection: T | null;
  setSelection: (s: T) => void;
  size?: 'default' | 'small';
}

const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextValue>({
  selection: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelection: () => {},
});
