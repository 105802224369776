import {
  BottomSheet,
  BottomSheetCloseButton,
  BottomSheetContent,
  BottomSheetTitle,
  BottomSheetTrigger,
  cn,
  IconButton,
  Pagination,
  RiArrowRightSLine,
  RiCheckLine,
  RiCircleFill,
  RiEqualizerLine,
  RiErrorWarningFill,
  Stack,
  toast,
} from '@landler/tw-component-library';
import { DialogClose } from '@radix-ui/react-dialog';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { MaybeLink } from '@/components';
import { PlotThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { getColorOfPlotStatus } from '@/utils/plot/get-color-of-plot-status/getColorOfPlotStatus';

import { useProjectDetailById } from '../../hooks/useProjectDetailById';
import { PAGE_SIZE } from '../constants';
import { useDestinationPath } from '../hooks/useDestinationPath';
import { usePaginatedPlotsForProject } from '../hooks/usePaginatedPlotsForProject';

export type SortOrder = 'name' | 'size' | 'type';

const SortOrderContext = createContext<{
  sortOrder: SortOrder | null;
  setSortOrder: (sortOrder: SortOrder | null) => void;
}>({ sortOrder: null, setSortOrder: () => undefined });

export const PlotListMobile = () => {
  const { t } = useTranslation();

  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');

  const { getDestinationPath } = useDestinationPath();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const setCurrentPage = (page: number) => setSearchParams({ page: `${page}` }, { preventScrollReset: true });

  const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);

  const { count: totalPlotCount, results: plots } = usePaginatedPlotsForProject(
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    '-errors,has_complete_questionnaire,name',
  ).data;

  const plotsSorted = useMemo(() => {
    if (sortOrder === 'name')
      return plots.sort((plotA, plotB) => {
        const nameA = plotA.name.toUpperCase();
        const nameB = plotB.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

    if (sortOrder === 'size') return plots.sort((plotA, plotB) => plotB.area - plotA.area);

    if (sortOrder === 'type')
      return plots.sort((plotA, plotB) => {
        const typeA = plotA.type.toUpperCase();
        const typeB = plotB.type.toUpperCase();

        if (typeA < typeB) {
          return -1;
        }
        if (typeA > typeB) {
          return 1;
        }
        return 0;
      });

    return plots;
  }, [sortOrder, plots]);

  return (
    <Stack spacing={4} data-testid='plot-list-mobile'>
      <Stack direction='row' centerMain className='justify-between'>
        <span>
          {t('shared.plots.pagination.titleMobile', {
            count: totalPlotCount,
          })}
        </span>
        <BottomSheet>
          <BottomSheetTrigger asChild>
            <IconButton className='text-text-secondary' data-testid='sort-order-picker-trigger'>
              <RiEqualizerLine size={20} />
            </IconButton>
          </BottomSheetTrigger>

          <BottomSheetContent>
            <Stack direction='row' className='justify-end pt-8'>
              <BottomSheetCloseButton />
            </Stack>
            <Stack spacing={5}>
              <BottomSheetTitle className='typography-overline'>
                {t('shared.plots.sortOrderPicker.title')}
              </BottomSheetTitle>

              <Stack className='pb-8'>
                <SortOrderContext.Provider value={{ sortOrder, setSortOrder }}>
                  <RadioButton name='name'>{t('shared.plots.sortOrderPicker.name')}</RadioButton>
                  <RadioButton name='size'>{t('shared.plots.sortOrderPicker.size')}</RadioButton>
                  <RadioButton name='type'>{t('shared.plots.sortOrderPicker.type')}</RadioButton>
                </SortOrderContext.Provider>
              </Stack>
            </Stack>
          </BottomSheetContent>
        </BottomSheet>
      </Stack>
      <Stack className='divide-y divide-neutral-black-4 overflow-clip rounded-xl'>
        {plotsSorted.map((plot) => {
          const destinationPath = getDestinationPath(plot);
          const onRowClick = () => {
            if (plot.status === PlotStatusEnum.invalid && !isProjectEditable) {
              toast({
                toastId: 'project-is-locked-redirect-toast',
                title: t('landSteward.plot.lockedToasts.redirectAddEditLink'),
                type: 'error',
                autoClose: 10_000,
                style: { width: '100vw' },
                draggable: false,
              });
            }
          };
          return (
            <MaybeLink key={plot.id} to={destinationPath}>
              <Stack
                data-testid='plot-row'
                onClick={onRowClick}
                direction='row'
                spacing={4}
                centerMain
                className='bg-white-100 p-4 pr-0'
              >
                <div className='self-start pt-1'>
                  {'status' in plot && plot.status === PlotStatusEnum.invalid ? (
                    <span className='flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-bg-light-grey'>
                      <RiErrorWarningFill size={20} className='text-error' data-testid='alert-icon' />
                    </span>
                  ) : (
                    <PlotThumbnail plot={plot} className='h-12 w-12 shrink-0 rounded-lg' />
                  )}
                </div>
                <Stack spacing={2} className='flex-1'>
                  <span>{plot.name}</span>
                  {'status' in plot && (
                    <Stack direction='row' spacing={2} className='typography-body2 items-center'>
                      <RiCircleFill size={12} color={getColorOfPlotStatus(plot.status)} />
                      <span>{t(`global.plotStatus.${plot.status}`)}</span>
                    </Stack>
                  )}
                </Stack>
                {destinationPath && <RiArrowRightSLine size={24} className='mr-2 text-text-disabled' />}
              </Stack>
            </MaybeLink>
          );
        })}
      </Stack>

      <Stack className='flex items-center'>
        <Pagination
          className='typography-overline text-text-secondary'
          currentPage={currentPage}
          totalCount={totalPlotCount}
          pageSize={PAGE_SIZE}
          onPageChange={(nextPage) => {
            setCurrentPage(nextPage);
          }}
          data-testid='pagination'
        />
      </Stack>
    </Stack>
  );
};

const RadioButton = ({ name, children }: { name: SortOrder; children: React.ReactNode }) => {
  const { sortOrder, setSortOrder } = useContext(SortOrderContext);
  const isSelected = sortOrder === name;

  const handleButtonClick = () => {
    setSortOrder(name);
  };

  return (
    <DialogClose asChild>
      <button
        onClick={handleButtonClick}
        className={cn('typography-body1 flex items-center justify-start py-1', isSelected && 'text-secondary-100')}
      >
        <div className='h-6 w-10'>{isSelected && <RiCheckLine size={24} />}</div>
        {children}
      </button>
    </DialogClose>
  );
};
