import { cn, Pill, RiAlertFill, RiLockFill, Stack } from '@landler/tw-component-library';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { MembershipStatus, RoleEnum } from '@/api/rest/resources/types/membership';
import { User } from '@/api/rest/resources/types/user';
import { useUser } from '@/lib/auth';

export type TeamMemberItemProps = HTMLAttributes<HTMLDivElement> & {
  member: User;
  actions: ReactNode;
};

export const TeamMemberItem: FC<TeamMemberItemProps> = ({ member, actions, className, ...delegated }) => {
  const user = useUser();

  const isCurrentUser = user.id === member.id;
  const isAdmin = member.membership.role === RoleEnum.admin;
  const hasAccepted = member.membership.status === MembershipStatus.accepted;
  const showActions = user.membership.role === RoleEnum.admin && !isCurrentUser;

  return (
    <Stack spacing={2} direction='row' className={cn('items-center justify-between', className)} {...delegated}>
      <div className='flex flex-grow flex-col gap-1 md:flex-row md:items-center md:justify-between md:gap-2'>
        <Stack>
          <span className='typography-body1Semibold text-text-primary'>
            {member.firstname} {member.lastname}
          </span>
          {hasAccepted ? (
            <span className='typography-body1 text-text-secondary'>{member.email}</span>
          ) : (
            <PendingInvitation />
          )}
        </Stack>
        {isAdmin && <AdminPill />}
      </div>
      {showActions && actions}
    </Stack>
  );
};

const PendingInvitation: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction='row' className='items-center'>
      <RiAlertFill size={16} className='fill-warning' />
      <span className='typography-inputLabel text-warning-dark'>{t('shared.account.team.labels.invitePending')}</span>
    </Stack>
  );
};

const AdminPill: FC = () => {
  const { t } = useTranslation();

  return (
    <Pill leftAdornment={<RiLockFill size={12} />} className='bg-info-light'>
      <span className='typography-overline text-black-100'>{t('shared.account.team.labels.admin')}</span>
    </Pill>
  );
};
