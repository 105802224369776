import { Loader } from '@landler/tw-component-library';
import React from 'react';

import { isReactNode } from '@/utils/isReactNode';

export type SuspenseBoundaryProps = React.SuspenseProps;

export const SuspenseBoundary = React.Suspense;

export const withSuspenseBoundary = <T extends object>(
  Component: React.ComponentType<T>,
  fallback?: React.ReactNode,
) => {
  return function Wrapped(props?: T) {
    return (
      <SuspenseBoundary fallback={isReactNode(fallback) ? fallback : <Loader />}>
        <Component {...(props as T)} />
      </SuspenseBoundary>
    );
  };
};
