import { useApiPostRequest } from '../hooks/useApiPostRequest';
import {
  POST_TOKEN_SIGN_OUT_PATH,
  PostTokenSignOutErrorDto,
  PostTokenSignOutRequestBodyData,
  PostTokenSignOutRequestPathVariables,
  PostTokenSignOutRequestQueryParams,
  PostTokenSignOutSuccessDto,
} from './types/token';

export const usePostTokenSignOut = () => {
  return useApiPostRequest<
    PostTokenSignOutRequestPathVariables,
    PostTokenSignOutRequestQueryParams,
    PostTokenSignOutRequestBodyData,
    PostTokenSignOutSuccessDto,
    PostTokenSignOutErrorDto
  >(POST_TOKEN_SIGN_OUT_PATH);
};
