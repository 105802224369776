import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { RequestData } from './hooks/types';
import { useApiGetRequest } from './hooks/useApiGetRequest';

export function buildUseGetQuery<RequestPathVariables, RequestQueryParams, SuccessDto, ErrorDto>(
  maybeQueryKey:
    | QueryKey
    | ((
        p?: RequestData<RequestPathVariables, RequestQueryParams, undefined>,
        o?: Omit<UseQueryOptions<SuccessDto, ErrorDto>, 'queryKey' | 'queryFn'>,
      ) => QueryKey),
  path: string,
) {
  return (
    params?: RequestData<RequestPathVariables, RequestQueryParams, undefined>,
    options?: Omit<UseQueryOptions<SuccessDto, ErrorDto>, 'queryKey' | 'queryFn'>,
  ) => {
    const queryKey = typeof maybeQueryKey === 'function' ? maybeQueryKey(params, options) : maybeQueryKey;

    return useApiGetRequest<RequestPathVariables, RequestQueryParams, SuccessDto, ErrorDto>(
      queryKey,
      path,
      params,
      options,
    );
  };
}
