import { LoggingStrategy, UniqueLogEntries } from './LoggingStrategy';

export class NoopLogger extends LoggingStrategy {
  uniqueInfos: UniqueLogEntries = {};

  uniqueWarns: UniqueLogEntries = {};

  uniqueErrors: UniqueLogEntries = {};

  info(): void {
    return undefined;
  }

  infoOnce(): void {
    return undefined;
  }

  warn(): void {
    return undefined;
  }

  warnOnce(): void {
    return undefined;
  }

  error(): void {
    return undefined;
  }

  errorOnce(): void {
    return undefined;
  }
}
