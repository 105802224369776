import { usePostMembershipByIdSendInvitationMail } from '@/api/rest/resources/membership';

export const useReinviteTeamMember = () => {
  const postMembershipByIdSendInvitationMailMutation = usePostMembershipByIdSendInvitationMail();

  const submit = async (id: string) => {
    await postMembershipByIdSendInvitationMailMutation.mutateAsync({
      pathVariables: { id },
    });
  };

  const isSubmitting = postMembershipByIdSendInvitationMailMutation.isPending;

  return { submit, isSubmitting };
};
