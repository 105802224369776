import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCCardSectionHeader,
  NCFactCapsule,
  useNCCardContext,
} from '@/components';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

export const WaterUpliftStatsTile = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();
  const plotId = usePlotId();

  const { getFact } = usePlotReportForPlot({ plotId });
  const waterHoldingCapacity1yearUpliftPotential = getFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_1year_uplift_potential_total
      : R1FactType.r1_water_holding_capacity_1year_uplift_potential_per_ha,
  );

  const waterHoldingCapacity10yearUpliftPotential = getFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_total
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha,
  );

  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='water-uplift-stats-tile'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.upliftPotentialForWaterHoldingCapacity')}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.upliftPotentialForWaterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.upliftPotentialForWaterHoldingCapacity.body'),
        }}
      />
      <div className='flex flex-col gap-8 lg:flex-row lg:justify-between lg:gap-0'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.1YearPotential')}
          fact={waterHoldingCapacity1yearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacity1yearUpliftPotential),
            variant: getNCChangeIconVariant(waterHoldingCapacity1yearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.1YearPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.1YearPotential.body'),
          }}
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.10YearPotential')}
          fact={waterHoldingCapacity10yearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacity10yearUpliftPotential),
            variant: getNCChangeIconVariant(waterHoldingCapacity10yearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.10YearPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.10YearPotential.body'),
          }}
        />
      </div>
    </Stack>
  );
};
