import { RiPieChartLine, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

export const CalculationInProgress = () => {
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={4} className='min-h-[160px] items-center justify-center'>
      <div className='flex h-12 w-12 items-center justify-center rounded-full bg-neutral-black-8'>
        <RiPieChartLine size={20} className='text-text-secondary' />
      </div>
      <span className='typography-h3 text-text-secondary'>{t('global.analysis.calculationInProgress')}</span>
    </Stack>
  );
};
