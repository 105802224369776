import { RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { Capsule, CapsuleContent, CapsuleIcon, CapsuleLabel } from '@/components';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCardV1_2/NCCard';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';
import { range } from '@/utils/range';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from './conservation/components/Chart';
import { useFact } from './conservation/hooks/useFact';
import { useLatestAnalysisDatestamp } from './conservation/hooks/useLatestAnalysisDatestamp';

export const DegradationDriversCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard>
      <NCCardHeader title={t('shared.ncaDetail.habitatIntactness.degradationDrivers.title')} />
      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const currentYear = useFact<number>('r1_conservation_current_year').value;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;
  const habitatIntactnessTimeseries = useFact<Record<string, number>>('r1_conservation_threats_i_ts').value;

  const years = range(baselineYear, currentYear);

  const chartData = years.map((year) => ({
    intact: (habitatIntactnessTimeseries[`${year}`] ?? 0) * 100 || null,
    threats: 100,
    date: new Date(`${year}`),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');
  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.habitatIntactness.degradationDrivers.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          intact: {
            label: t('shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.attributeLabel.intact'),
            color: '#8A9C5A',
          },
          threats: {
            label: t('shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.attributeLabel.threats'),
            color: '#DFC29F',
            tooltip: false,
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='intactFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#ABC072' stopOpacity={1} />
              <stop offset='100%' stopColor='#FBFFF7' stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            strokeWidth={0}
            fill='var(--color-threats)'
            fillOpacity={1}
            activeDot={false}
            dataKey='threats'
            connectNulls
          />
          <Area
            isAnimationActive={false}
            strokeWidth={2}
            stroke='var(--color-intact)'
            fill='url(#intactFill)'
            fillOpacity={1}
            activeDot={{ fill: 'var(--color-intact)' }}
            dataKey='intact'
            connectNulls
          />
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis
            padding={{ top: 0 }}
            domain={[0, 100]}
            tickFormatter={(v) => `${v}${formatUnit(UnitEnum['%'])}`}
          />

          <Chart.Tooltip
            content={(props) => (
              <Chart.TooltipContent
                {...props}
                labelFormatter={printMonthYear}
                formatter={(v) => `${getDisplayNumber(v as number)}${formatUnit(UnitEnum['%'])}`}
              />
            )}
          />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const degradationDriversCountTotal = useFact<number>('r1_conservation_drivers_total');
  const degradationDriversCountDetected = useFact<number>('r1_conservation_drivers_detected');

  const latestAnalysisDatestamp = useLatestAnalysisDatestamp();

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.habitatIntactness.degradationDrivers.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.habitatIntactness.degradationDrivers.explainers.degradationDrivers.title'),
            body: t('shared.ncaDetail.habitatIntactness.degradationDrivers.explainers.degradationDrivers.body'),
          }}
          supplementaryInfo={latestAnalysisDatestamp}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <Capsule
            label={
              <CapsuleLabel
                infoPopoverProps={{
                  body: t('shared.ncaDetail.habitatIntactness.degradationDrivers.explainers.remoteSensedThreats.body'),
                }}
              >
                {t('shared.ncaDetail.habitatIntactness.degradationDrivers.remoteSensedThreats')}
              </CapsuleLabel>
            }
            content={
              <CapsuleContent>
                {t('shared.ncaDetail.habitatIntactness.degradationDrivers.remoteSensedThreatsDisplay', {
                  detectedCount: degradationDriversCountDetected.value,
                  totalCount: degradationDriversCountTotal.value,
                })}
              </CapsuleContent>
            }
            thumbnail={<CapsuleIcon icon={<RiCompass4Line />} variant='default' />}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Trans
            i18nKey='shared.ncaDetail.habitatIntactness.degradationDrivers.explanation'
            values={{
              detectedCount: degradationDriversCountDetected.value,
              totalCount: degradationDriversCountTotal.value,
            }}
          />
        }
      />
    </Stack>
  );
};
