import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export interface PaperProps extends MuiPaperProps {
  type?: 'white' | 'sand';
  radius?: number;
}

const StyledPaper = styled(MuiPaper)<PaperProps>(({ theme, type, radius }) => ({
  borderRadius: radius || '18px',
  padding: '20px',
  backgroundColor: type === 'sand' ? theme.palette.background.sand : theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    padding: '32px',
    borderRadius: radius || '24px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '48px',
    borderRadius: radius || '32px',
  },
}));

export const Paper = (props: PaperProps) => <StyledPaper {...props} />;
