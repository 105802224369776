import jamieImgSrc from '@assets/images/team-jamie.jpg';
import mariaImgSrc from '@assets/images/team-maria.jpg';
import sophieImgSrc from '@assets/images/team-sophie.jpg';
import { AvatarProps, ButtonLink, cn, RiCalendarEventLine } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarGroup } from '@/components';
import { LANDSTEWARD_CALENDLY_LINK } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';

import { Card } from '../card/Card';

export const ScheduleMeetingWidget: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';

  const avatars: AvatarProps[] = [
    {
      image: sophieImgSrc,
      name: 'Sophie van Berchem',
    },
    {
      image: mariaImgSrc,
      name: 'Maria Koppatz',
    },
    {
      image: jamieImgSrc,
      name: 'Jamie Batho',
    },
  ];

  const styles = cn('items-start', className);

  return (
    <Card
      label={t('landSteward.investments.scheduleMeeting.title')}
      data-testid='investment-schedule-meeting'
      data-cy='investments-meeting'
      className={styles}
      {...delegated}
    >
      <div className='flex flex-col gap-4 lg:flex-row'>
        <AvatarGroup items={avatars} data-cy='investments-meeting-avatars' />
        <span className='typography-body1 max-w-[456px] text-text-secondary' data-cy='investments-meeting-subtitle'>
          {t('landSteward.investments.scheduleMeeting.subtitle')}
        </span>
      </div>
      <ButtonLink
        to={LANDSTEWARD_CALENDLY_LINK}
        target='_blank'
        rel='noreferrer'
        data-cy='investments-meeting-link'
        leftAdornment={<RiCalendarEventLine className='h-5 w-5' />}
      >
        {t(
          isLargeScreen
            ? 'landSteward.investments.scheduleMeeting.buttonLabel'
            : 'landSteward.investments.scheduleMeeting.buttonLabelShort',
        )}
      </ButtonLink>
    </Card>
  );
};
