import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { cn, Divider, Stack } from 'tw-component-library';

export const Card: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={cn('grid grid-cols-[24px_1fr_24px] overflow-hidden [&>*]:col-start-2', 'rounded-2xl bg-white-100')}>
      {children}
    </div>
  );
};

export const CardHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...delegated }) => {
  return (
    <>
      <div className={cn('py-6', className)} {...delegated}>
        {children}
      </div>
      <Divider className='full-bleed-x' />
    </>
  );
};

export const CardContent: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...delegated }) => {
  return (
    <div className={cn('py-6', className)} {...delegated}>
      {children}
    </div>
  );
};

export const CardFooter: FC<{ overline: string; description: string; icon: React.ReactNode; cta: React.ReactNode }> = ({
  overline,
  description,
  icon,
  cta,
}) => {
  return (
    <Stack
      className='items-center justify-between gap-5 bg-neutral-black-8 px-6 py-8 full-bleed-x sm:flex-row'
      data-test-id='card-footer'
      data-cy='cta-container'
    >
      <Stack direction='row' className='items-center gap-4'>
        <div className='items-center justify-center rounded-full bg-neutral-black-30 p-3 text-white-100'>{icon}</div>
        <div className='flex flex-col gap-2'>
          <span className='typography-overline text-text-secondary' data-cy='cta-title'>
            {overline}
          </span>
          <span className='typography-h3 text-text-primary' data-cy='cta-text'>
            {description}
          </span>
        </div>
      </Stack>
      {cta}
    </Stack>
  );
};
