import { useTranslation } from 'react-i18next';

export const useDefaultPlotName = () => {
  const { t } = useTranslation();
  const defaultPlotNameCounter = sessionStorage.getItem('defaultPlotNameCounter');
  const defaultPlotName = t('landSteward.plot.new.questionnairePage.defaultPlotName', {
    num: defaultPlotNameCounter ?? 1,
  });

  const setDefaultPlotNameCounter = (countOrCallback: number | ((count: number) => number)) => {
    const count =
      typeof countOrCallback === 'number'
        ? countOrCallback
        : countOrCallback(parseInt(defaultPlotNameCounter ?? '1', 10));

    sessionStorage.setItem('defaultPlotNameCounter', `${count}`);
  };

  return { defaultPlotName, setDefaultPlotNameCounter };
};
