import { Button, ButtonLink } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FullScreenError } from '@/components';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { paths } from '@/routing';

import { ErrorPageState } from '../../hooks/useBuyerCompleteAccountPage';

interface BuyerCompleteAccountErrorProps {
  pageState: keyof typeof ErrorPageState;
}

export const BuyerCompleteAccountError: React.FC<BuyerCompleteAccountErrorProps> = ({ pageState }) => {
  const { t } = useTranslation();
  const contactLink = useContactFormLink();

  if (pageState === ErrorPageState.EXPIRED) {
    return <LinkExpired />;
  }

  return (
    <div>
      <FullScreenError
        title={t('buyer.completeAccount.error.title')}
        body={t('buyer.completeAccount.error.message')}
        actions={
          <ButtonLink variant='outline' target='_blank' to={contactLink}>
            {t('notFound.labels.contactSupportButton')}
          </ButtonLink>
        }
      />
    </div>
  );
};

const LinkExpired = () => {
  const { t } = useTranslation();
  const contactLink = useContactFormLink();
  const navigate = useNavigate();
  return (
    <div>
      <FullScreenError
        title={
          <span className='typography-display3 mb-8 text-center text-info'>
            {t('buyer.completeAccount.error.expired.title')}
          </span>
        }
        body={
          <span className='text-primary typography-body1 whitespace-pre-wrap'>
            {t('buyer.completeAccount.error.expired.info')}
          </span>
        }
        actions={
          <>
            <ButtonLink variant='outline' to={contactLink} target='_blank' className='mr-0 md:mr-auto'>
              {t('buyer.completeAccount.error.expired.contactSupport')}
            </ButtonLink>
            <Button
              size='medium'
              onClick={(e) => {
                e.preventDefault();
                navigate(paths.login);
              }}
            >
              {t('buyer.completeAccount.error.expired.continueToLogin')}
            </Button>
          </>
        }
      />
    </div>
  );
};
