import { FC, useMemo } from 'react';

import { Button } from '../Button/Button';
import { RiArrowLeftSLine, RiArrowRightSLine } from '../Icon/Icon';
import { Stack, StackProps } from '../Stack/Stack';
import { cn } from '../utils';

const DOTS = '...';

const pageRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from(Array(length).keys()).map((x) => x + start);
};

type PaginationProps = StackProps & {
  onPageChange?: (pageNumber: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
};

export const Pagination: FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  ...delegated
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If less than 2 times in pagination range, nothing to render
  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange?.(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange?.(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Stack
      direction='row'
      {...delegated}
      className={cn('items-center gap-2', 'typography-body2 p-1 text-text-primary', className)}
    >
      <Button
        className='p-2'
        variant='text'
        disabled={currentPage === 1}
        onClick={onPrevious}
        data-testid='previous-page'
      >
        <RiArrowLeftSLine size={24} className='shrink-0' />
      </Button>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <span key={index}>...</span>;
        }
        return (
          <Button
            key={index}
            variant='text'
            className={cn('aspect-square w-1', pageNumber === currentPage ? 'bg-neutral-black-8' : '')}
            onClick={() => onPageChange?.(pageNumber as number)}
          >
            {pageNumber}
          </Button>
        );
      })}
      <Button
        className='p-2'
        variant='text'
        disabled={currentPage === lastPage}
        onClick={onNext}
        data-testid='next-page'
      >
        <RiArrowRightSLine size={24} className='shrink-0' />
      </Button>
    </Stack>
  );
};

const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: {
  totalCount: number;
  pageSize: number;
  siblingCount: number;
  currentPage: number;
}) => {
  return useMemo(() => {
    const numOfPages = Math.ceil(totalCount / pageSize);
    // default value = 5 = siblingCount + firstPage + lastPage + currentPage + 2 dots
    const numOfDisplayedPages = siblingCount + 5;

    // show all page numbers if pages fit into display
    if (numOfDisplayedPages >= numOfPages) {
      return pageRange(1, numOfPages);
    }

    // left and right sibling index should be within range 1 and numOfPages
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, numOfPages);
    // do not show dots when nearing the end, or starting at the beginning
    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < numOfPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = numOfPages;

    // If no left dots to be shown
    if (!showLeftDots && showRightDots) {
      // pages to show = (start + end + current page) + (left and right siblings of current page);
      const leftItemCount = 3 + siblingCount;
      const leftRange = pageRange(1, leftItemCount);
      return [...leftRange, DOTS, numOfPages];
    }

    // If no right dots to shown
    if (showLeftDots && !showRightDots) {
      // pages to show = (start + end + current page) + (left and right siblings of current page);
      const rightItemCount = 3 + siblingCount;
      const rightRange = pageRange(numOfPages - rightItemCount + 1, numOfPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    // If left and right dots both are shown
    // pages to show = (start + end + current page) + (left and right siblings of current page);
    const middleRange = pageRange(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }, [totalCount, pageSize, siblingCount, currentPage]);
};
