import {
  ButtonLink,
  Dialog,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownSeparator,
  DropdownTrigger,
  IconButton,
  RiAccountCircleFill,
  RiDeleteBinFill,
  RiFileEditFill,
  RiMore2Fill,
  RiUserAddLine,
  Shimmer,
  Stack,
  toast,
  toastifyToast,
} from '@landler/tw-component-library';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MembershipStatus, RoleEnum } from '@/api/rest/resources/types/membership';
import { User } from '@/api/rest/resources/types/user';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useUser } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';

import { Heading, SectionCard, SectionCardHeading } from '../../components';
import { withErrorBoundary } from './components/ErrorBoundary';
import { ReinviteDialogContent } from './components/ReinviteDialogContent';
import { RemoveDialogContent } from './components/RemoveDialogContent';
import { TeamMemberItem } from './components/TeamMemberItem';
import { useReinviteTeamMember } from './hooks/useReinviteTeamMember';
import { useRemoveTeamMember } from './hooks/useRemoveTeamMember';
import { useUsers } from './hooks/useUsers';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AccountTeamPage = withErrorBoundary(() => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';
  const { accountTeamInvitePath } = useAccountPageRoutes();

  const user = useUser();

  const members = useUsers().data;

  const isAdmin = user.membership.role === RoleEnum.admin;

  const [selectedMember, setSelectedMember] = useState<User>();
  const [isReinviteDialogOpen, setIsReinviteDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const { submit: submitReinviteMutation } = useReinviteTeamMember();
  const { submit: submitRemoveMutation, isSubmitting: isSubmittingRemoveMutation } = useRemoveTeamMember();

  const removeMember = (member: User) => {
    setSelectedMember(member);
    setIsRemoveDialogOpen(true);
  };

  const reinviteMember = async (member: User) => {
    try {
      setSelectedMember(member);

      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      await submitReinviteMutation(member.membership.id);

      setIsReinviteDialogOpen(true);
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(`Failed to reinvite team member with message "${error.message}"`);
      }

      toast({
        title: t('shared.account.team.toasts.reinviteMember.errorTitle'),
        description: t('shared.account.team.toasts.reinviteMember.errorDescription'),
        type: 'error',
        autoClose: 10_000,
      });

      setSelectedMember(undefined);
    }
  };

  const submitRemoveMember = async (member: User) => {
    try {
      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      await submitRemoveMutation(member.id);

      toast({
        title: t('shared.account.team.toasts.removeMember.successTitle'),
        type: 'success',
      });
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(`Failed to delete team member with message "${error.message}"`);
      }

      toast({
        title: t('shared.account.team.toasts.removeMember.errorTitle'),
        description: t('shared.account.team.toasts.removeMember.errorDescription'),
        type: 'error',
        autoClose: 10_000,
      });
    } finally {
      setIsRemoveDialogOpen(false);
      setSelectedMember(undefined);
    }
  };

  return (
    <>
      <Heading>{t('shared.account.titles.team')}</Heading>
      <div className='flex flex-col gap-6 md:gap-9'>
        <SectionCard>
          <SectionCardHeading>{t('shared.account.team.labels.teamMembers')}</SectionCardHeading>
          <Stack spacing={6}>
            {members ? (
              members.map((member) => (
                <TeamMemberItem
                  key={member.id}
                  member={member}
                  actions={
                    <MemberItemDropdown member={member} reinviteMember={reinviteMember} removeMember={removeMember} />
                  }
                />
              ))
            ) : (
              <ShimmerList />
            )}
          </Stack>
        </SectionCard>
        {isAdmin && (
          <ButtonLink
            to={accountTeamInvitePath}
            variant='outline'
            size={isLargeScreen ? 'medium' : 'small'}
            leftAdornment={<RiUserAddLine size={16} />}
            className='self-end'
          >
            {t('shared.account.team.buttons.invite')}
          </ButtonLink>
        )}
      </div>
      {selectedMember && (
        <>
          <Dialog
            open={isReinviteDialogOpen}
            onOpenChange={(isOpen) => {
              setIsReinviteDialogOpen(isOpen);
              if (!isOpen) {
                setSelectedMember(undefined);
              }
            }}
          >
            <ReinviteDialogContent member={selectedMember} />
          </Dialog>
          <Dialog
            open={isRemoveDialogOpen}
            onOpenChange={(isOpen) => {
              setIsRemoveDialogOpen(isOpen);
              if (!isOpen) {
                setSelectedMember(undefined);
              }
            }}
          >
            <RemoveDialogContent
              member={selectedMember}
              submit={submitRemoveMember}
              isSubmitting={isSubmittingRemoveMutation}
            />
          </Dialog>
        </>
      )}
    </>
  );
});

const ShimmerList: FC = () => {
  return (
    <Stack spacing={6} data-testid='team-members-list-loading'>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-24' />
        <Shimmer className='h-5 w-44' />
      </Stack>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-40' />
        <Shimmer className='h-5 w-60' />
      </Stack>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-32' />
        <Shimmer className='h-5 w-52' />
      </Stack>
    </Stack>
  );
};

type MemberItemDropdownProps = {
  member: User;
  reinviteMember: (member: User) => void;
  removeMember: (member: User) => void;
};

const MemberItemDropdown: FC<MemberItemDropdownProps> = ({ member, reinviteMember, removeMember }) => {
  const { t } = useTranslation();
  const { accountTeamEditMemberPath } = useAccountPageRoutes();

  return (
    <Dropdown>
      <DropdownTrigger asChild data-testid={`member-dropdown-trigger-${member.id}`}>
        <IconButton className='border text-primary-100'>
          <RiMore2Fill size={24} />
        </IconButton>
      </DropdownTrigger>
      <DropdownContent align='end'>
        <DropdownItemLink
          to={accountTeamEditMemberPath.replace(':memberId', member.id)}
          leftAdornment={<RiFileEditFill className='fill-text-primary' />}
        >
          {t('shared.account.team.memberDropdown.edit')}
        </DropdownItemLink>
        <DropdownSeparator />
        {member.membership.status === MembershipStatus.invited && (
          <>
            <DropdownItem
              leftAdornment={<RiAccountCircleFill className='fill-text-primary' />}
              onSelect={() => reinviteMember(member)}
            >
              {t('shared.account.team.memberDropdown.reinvite')}
            </DropdownItem>
            <DropdownSeparator />
          </>
        )}
        <DropdownItem
          leftAdornment={<RiDeleteBinFill className='fill-text-primary' />}
          onSelect={() => removeMember(member)}
        >
          {t('shared.account.team.memberDropdown.remove')}
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
