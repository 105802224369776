import { useQueryClient } from '@tanstack/react-query';

import { usePostDraftPlotCommit } from '@/api/rest/resources/plot';

import { DraftPlotInputs } from '../../../types';

export const useCommitDraftPlot = () => {
  const queryClient = useQueryClient();
  const postDraftPlotCommitMutation = usePostDraftPlotCommit();

  const submit = async (inputs: DraftPlotInputs) => {
    const { draftPlots } = inputs;

    await postDraftPlotCommitMutation.mutateAsync({ bodyData: { draft_plots: draftPlots ?? [] } });
    queryClient.resetQueries({ queryKey: ['land_steward'] });
  };

  const isSubmitting = postDraftPlotCommitMutation.isPending;

  return { submit, isSubmitting };
};
