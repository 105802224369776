import { toast } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { Logger } from '@/lib/logs/logger';

export const useCookiebot = () => {
  const { t } = useTranslation();

  const renew = () => {
    try {
      // If Cookiebot is unavailable, we'd like to handle it in the same catch block
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.Cookiebot!.renew();
    } catch (error) {
      toast({
        title: t('global.footer.errorToast.title'),
        description: t('global.footer.errorToast.description'),
        type: 'error',
      });

      Logger.warn('Failed to invoke Cookiebot renew banner.');
      Logger.warn(error as Error);
    }
  };

  return { renew };
};
