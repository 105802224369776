import React from 'react';

import { cn } from '..';

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({ className, ...delegated }, ref) => {
  return (
    <input
      ref={ref}
      className={cn(
        'flex h-10 w-full rounded border border-outlined-border-23p px-4 py-[1.6rem] placeholder:text-text-disabled',
        'focus:border-black-100',
        'disabled:cursor-not-allowed disabled:border-text-disabled',
        'data-[invalid=true]:border-error data-[invalid=true]:text-error data-[invalid=true]:outline-error',
        className,
      )}
      {...delegated}
    />
  );
});
TextInput.displayName = 'TextInput';
