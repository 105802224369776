import {
  Alert,
  AlertBody,
  AlertIcon,
  AlertTitle,
  AppBar,
  AppBarContent,
  Button,
  ConfirmationDialog,
  Container,
  RiAlertLine,
  RiCheckboxMultipleLine,
  Stack,
  toast,
} from '@landler/tw-component-library';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { ErrorsEnum, PlotType } from '@/api/rest/resources/types/plot';
import { PLOT_MAX_AREA_IN_M2, PLOT_MIN_AREA_IN_M2 } from '@/config/constants';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';
import { squareMetersToHectares } from '@/utils/plot';

import { usePlotId } from '../../../../../../../shared/hooks/usePlotId';
import { GrasslandQuestionnaire } from '../../../../components/GrasslandQuestionnaire';
import { PlotOverview } from '../../../../components/PlotOverview';
import { PlotUsage } from '../../../../components/PlotUsage';
import { usePlotForm } from '../../../../hooks/usePlotForm';
import { TitleBar } from '../../components/TitleBar';
import { useEditPlot, usePlot } from '../../hooks';

export const EditPlotQuestionnairePage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = usePlotForm();

  const plotId = usePlotId();

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  const plot = usePlot().data;

  const { submit: submitPlot, isSubmitting: isSubmittingPlot } = useEditPlot();

  const showErrorToast = (error: unknown) => {
    let description = t('landSteward.plot.submitFailedErrorDescription');
    if (error instanceof RestApiBadRequestServerError && error.errors[0]) {
      [description] = error.errors;
    }
    if (error instanceof RestApiBadRequestServerError && error.formErrors[0]) {
      description = error.formErrors[0].messages[0] ?? '';
    }

    toast({
      title: t('landSteward.plot.updateFailedErrorTitle'),
      description,
      type: 'error',
      autoClose: 10_000,
    });
  };

  const [showSaveDraftPlotConfirmation, setShowSaveDraftPlotConfirmation] = useState(false);
  const [showSavePlotConfirmation, setShowSavePlotConfirmation] = useState(false);

  const submit = (options?: { draft: boolean }) => async () => {
    const inputs = form.getValues();

    try {
      await submitPlot(inputs, options);

      navigate(buildPath(paths.landSteward.editPlotSuccess, { pathParams: { projectId, plotId } }), {
        state: { successPageVariant: options?.draft ? 'draft' : 'default' },
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const [plotType] = form.watch(['plotType']);

  if (!plotType) {
    throw new UnexpectedMissingDataError({ dataLabel: 'plotType' });
  }

  const isBoundaryModified = form.getFieldState('polygon').isDirty;

  return (
    <Stack className='min-h-screen'>
      <TitleBar title={t('landSteward.plot.edit.editPlot')} />
      <form onSubmit={form.handleSubmit(() => setShowSavePlotConfirmation(true))} className='flex flex-1 flex-col'>
        <fieldset disabled={isSubmittingPlot} className='flex-1'>
          <Container contentWidth='md' className='mb-12 gap-6'>
            <div className='full-bleed-x'>
              <PlotOverview
                editPlotPath={buildPath(paths.landSteward.editPlotBoundary, { pathParams: { plotId, projectId } })}
              />
            </div>
            {isBoundaryModified && (
              <Container contentWidth='md'>
                <Alert className='mb-6'>
                  <AlertIcon>
                    <RiCheckboxMultipleLine size={24} />
                  </AlertIcon>
                  <AlertTitle>{t('landSteward.plot.edit.plotBoundaryModifiedNotice.title')}</AlertTitle>
                  <AlertBody>{t('landSteward.plot.edit.plotBoundaryModifiedNotice.description')}</AlertBody>
                </Alert>
              </Container>
            )}
            {!isBoundaryModified &&
              plot.errors.map((plotError) => <PlotErrorNotice key={plotError} error={plotError} />)}
            <PlotUsage />
            {renderQuestionnaireForPlotType(plotType)}
          </Container>
        </fieldset>
        <AppBar placement='bottom' className='border-t border-t-divider elevation-0'>
          <AppBarContent className='justify-end'>
            <Stack direction='row' spacing={4} centerMain>
              <Button variant='text' onClick={() => setShowSaveDraftPlotConfirmation(true)}>
                {t('landSteward.plot.edit.labels.savePlotAsDraft')}
              </Button>
              <Button type='submit'>{t('landSteward.plot.edit.labels.confirmPlotChanges')}</Button>
            </Stack>
          </AppBarContent>
        </AppBar>
      </form>

      <ConfirmationDialog
        open={showSaveDraftPlotConfirmation}
        onOpenChange={setShowSaveDraftPlotConfirmation}
        header={t('landSteward.plot.edit.confirmationDialog.saveDraft.header')}
        body={t('landSteward.plot.edit.confirmationDialog.saveDraft.body')}
        PrimaryAction={
          <Button variant='contained' loading={isSubmittingPlot} onClick={submit({ draft: true })}>
            {t('landSteward.plot.edit.labels.saveAsDraft')}
          </Button>
        }
        CancelAction={t('landSteward.plot.edit.labels.close')}
      />

      <ConfirmationDialog
        open={showSavePlotConfirmation}
        onOpenChange={setShowSavePlotConfirmation}
        header={t('landSteward.plot.edit.confirmationDialog.savePlot.header')}
        body={t('landSteward.plot.edit.confirmationDialog.savePlot.body')}
        PrimaryAction={
          <Button variant='contained' loading={isSubmittingPlot} onClick={submit()}>
            {t('landSteward.plot.edit.labels.confirmChanges')}
          </Button>
        }
        CancelAction={t('landSteward.plot.edit.labels.close')}
      />
    </Stack>
  );
};

const renderQuestionnaireForPlotType = (plotType: PlotType) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (plotType) {
    case PlotType.GRASSLAND:
      return <GrasslandQuestionnaire />;

    default:
      return null;
  }
};

const PlotErrorNotice: FC<{ error: ErrorsEnum }> = ({ error }) => {
  const { t } = useTranslation();

  const formattedMinArea = useDisplayNumber(squareMetersToHectares(parseInt(PLOT_MIN_AREA_IN_M2, 10)));
  const formattedMaxArea = useDisplayNumber(squareMetersToHectares(parseInt(PLOT_MAX_AREA_IN_M2, 10)));

  const notice = useMemo(() => {
    const exhaustivenessCheck = (type: never) => {
      throw Error(`Unrecognised type received: ${type}`);
    };

    switch (error) {
      case ErrorsEnum.invalid_shape_index:
        return {
          title: t('landSteward.plot.edit.plotErrorNotices.invalidShapeIndex.title'),
          description: t('landSteward.plot.edit.plotErrorNotices.invalidShapeIndex.description'),
        };
      case ErrorsEnum.self_intersecting:
        return {
          title: t('landSteward.plot.edit.plotErrorNotices.selfIntersecting.title'),
          description: t('landSteward.plot.edit.plotErrorNotices.selfIntersecting.description'),
        };
      case ErrorsEnum.too_small:
        return {
          title: t('landSteward.plot.edit.plotErrorNotices.tooSmall.title'),
          description: t('landSteward.plot.edit.plotErrorNotices.tooSmall.description', {
            minSizeHa: formattedMinArea,
          }),
        };
      case ErrorsEnum.too_large:
        return {
          title: t('landSteward.plot.edit.plotErrorNotices.tooLarge.title'),
          description: t('landSteward.plot.edit.plotErrorNotices.tooLarge.description', {
            maxSizeHa: formattedMaxArea,
          }),
        };
      case ErrorsEnum.contains_holes:
        return {
          title: t('landSteward.plot.edit.plotErrorNotices.containsHoles.title'),
          description: t('landSteward.plot.edit.plotErrorNotices.containsHoles.description', {
            maxSizeHa: formattedMaxArea,
          }),
        };
      default:
        return exhaustivenessCheck(error);
    }
  }, [error, formattedMaxArea, formattedMinArea, t]);

  return (
    <Alert type='warning' data-testid='plot-error-notice'>
      <AlertIcon>
        <RiAlertLine size={24} />
      </AlertIcon>
      <AlertTitle>{notice.title}</AlertTitle>
      <AlertBody>{notice.description}</AlertBody>
    </Alert>
  );
};
