import { InfoPopover, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import {
  CalculationInProgress,
  NoChartData,
  useNCCardContext,
  WaterHoldingCapacityChart,
  WaterHoldingCapacityChartData,
} from '@/components';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

export const WaterUpliftGraphTile = () => {
  const { t } = useTranslation();

  const plot = usePlot().data;
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const waterHoldingCapacity10yearUpliftPotential = getFact<WaterHoldingCapacityChartData[]>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph,
  );

  const chartData = waterHoldingCapacity10yearUpliftPotential?.value;
  const noChartData = !chartData?.length;

  if (noChartData) {
    if ([PlotStatusEnum.calculating, PlotStatusEnum.scheduled_for_analysis].includes(plot.status)) {
      return <CalculationInProgress />;
    }

    if (plot.status === PlotStatusEnum.analysed) {
      return <NoChartData />;
    }

    return <NoChartData variant='analysisPending' />;
  }

  return (
    <Stack
      spacing={2.5}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='water-uplift-graph-tile'
    >
      <Stack direction='row' spacing={2} className='items-center'>
        <span className='typography-body1'>{t('shared.ncaDetail.details.water.labels.upliftGraphHeadline')}</span>
        <InfoPopover
          title={t('shared.ncaDetail.details.water.explainers.upliftPotential.title')}
          body={t('shared.ncaDetail.details.water.explainers.upliftPotential.body')}
        />
      </Stack>
      <WaterHoldingCapacityChart
        analysisType={analysisType}
        data={chartData}
        showTooltip={true}
        className='relative'
        data-testid='water-holding-capacity-chart'
      />
    </Stack>
  );
};
