export const zIndex = {
  buyer: {
    projects: {
      map: {
        marker: 1,
      },
    },
  },
  drawer: 2000,
  landSteward: {
    addPlot: {
      map: {
        alert: 1500,
        drawControl: 1400,
        popUp: 2,
      },
    },
  },
};
