import noop from 'lodash/noop';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';

export const enum SignInWithTokenState {
  SUCCESS = 'SUCCESS',
  TOKEN_ERROR = 'TOKEN_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const enum SignInWithTokenErrorState {
  NO_TOKEN_PROVIDED = 'NO_TOKEN_PROVIDED',
  TOKEN_INVALID = 'TOKEN_INVALID',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

const formFields: never[] = [];

interface SignInWithTokenStates {
  signInState: SignInWithTokenState;
  signInError?: SignInWithTokenErrorState;
}

export const useSignInWithToken = (token: string | null): SignInWithTokenStates => {
  const [signInStates, setSignInStates] = useState<SignInWithTokenStates>({
    signInState: SignInWithTokenState.IN_PROGRESS,
  });
  const handleServerError = useRestApiServerErrorHandling(noop, formFields);

  const { signInWithToken } = useAuth();

  // react runs each useEffect twice in strict mode to
  // identify unsafe lifecycles, legacy API usage, and a number of other features.
  // This behaviour causes problems in this component because signInWithToken would be triggered
  // twice but the provided token is only valid once. React recommends to use a top-level variable
  // to ensure that the logic is only executed once per app load.
  let didInit = false;

  const signIn = useCallback(
    (signInToken: string) => {
      signInWithToken({ token: signInToken })
        .then(() => {
          setSignInStates({ signInState: SignInWithTokenState.SUCCESS });
        })
        .catch((e) => {
          const { serverErrors, statusCode } = handleServerError(e);
          if (statusCode === 400) {
            setSignInStates({
              signInState: SignInWithTokenState.TOKEN_ERROR,
              signInError: SignInWithTokenErrorState.TOKEN_INVALID,
            });
          } else {
            if (serverErrors[0]) {
              Logger.error(serverErrors[0]);
            }
            setSignInStates({
              signInState: SignInWithTokenState.TOKEN_ERROR,
              signInError: SignInWithTokenErrorState.UNEXPECTED_ERROR,
            });
          }
        });
    },
    [handleServerError, signInWithToken],
  );

  useEffect(() => {
    if (didInit) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    didInit = true;

    if (typeof token !== 'string') {
      setSignInStates({
        signInState: SignInWithTokenState.TOKEN_ERROR,
        signInError: SignInWithTokenErrorState.NO_TOKEN_PROVIDED,
      });
      return;
    }

    signIn(token);
  }, [token, signIn]);

  return signInStates;
};
