import { toast } from '@landler/tw-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePostUserComplycubeKycCheck, usePostUserComplycubeKycToken } from '@/api/rest/resources/user';
import { useUserContext } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';

import { ComplyCubeDialog, ComplyCubeDialogProps } from './comply-cube/comply-cube-dialog';
import { ComplyCubeCompleteData } from './comply-cube/configs/types';
import { KycIntroDialog } from './intro/intro-dialog';

export type KycDialogProps = {
  onChange: (newValue: boolean) => void;
  open: boolean;
};

// NOTE: This component pretends to be a single dialog, but it's actually two dialogs.
// NOTE: The first dialog is a custom intro dialog that we use to explain the KYC process.
// NOTE: The second dialog is the ComplyCube dialog which is the actual KYC process.
// NOTE: The ComplyCube dialog is included via a code snippet that is provided by ComplyCube.
// NOTE: We basically just mount the ComplyCube dialog when the user clicks the "Start" button
// NOTE: and we are adding some custom configs and styles.
export const KycDialog: React.FC<KycDialogProps> = ({ onChange, open }) => {
  const [complyCubeToken, setComplyCubeToken] = useState<string | undefined>();
  const { mutateAsync: tokenMutateAsync } = usePostUserComplycubeKycToken();
  const { mutateAsync: checkMutateAsync } = usePostUserComplycubeKycCheck();
  const { t } = useTranslation();
  const { updateUserPartially } = useUserContext();

  const handleUnexpectedError = (error: Error) => {
    Logger.error(error);

    toast({
      title: t('global.ui.toast.errorToastFallbackTitle'),
      description: t('global.ui.toast.errorToastFallbackDescription'),
      type: 'error',
    });
  };

  const openComplyCube = async () => {
    return tokenMutateAsync()
      .then((data) => {
        setComplyCubeToken(data.token);
      })
      .catch(handleUnexpectedError);
  };

  const onCloseComplyCube = () => {
    setComplyCubeToken(undefined);
    onChange(false);
  };

  // NOTE: This is not tested because the ComplyCubeDialog is fully mocked
  const onCompleteComplyCube = (data: ComplyCubeCompleteData) => {
    checkMutateAsync({
      bodyData: {
        document_capture: {
          document_id: data.documentCapture.documentId,
          document_type: data.documentCapture.documentType,
        },
        face_capture: {
          live_photo_id: data.faceCapture.livePhotoId,
        },
        poa_capture: {
          document_id: data.poaCapture.documentId,
          document_type: data.poaCapture.documentType,
        },
      },
    })
      .then((response) => {
        updateUserPartially(response.user);
      })
      .catch(handleUnexpectedError);
  };

  // NOTE: This doesn't seem to be straigt forward but it is the only way typescript accepts the token-open logic
  const complyCubeProps: ComplyCubeDialogProps = complyCubeToken
    ? {
        open: true,
        token: complyCubeToken,
        onComplete: onCompleteComplyCube,
        onClose: onCloseComplyCube,
      }
    : {
        open: false,
        token: undefined,
        onComplete: onCompleteComplyCube,
        onClose: onCloseComplyCube,
      };

  return (
    <>
      <KycIntroDialog open={open && !complyCubeToken} onChange={onChange} onSubmit={openComplyCube} />
      <ComplyCubeDialog {...complyCubeProps} />
    </>
  );
};
