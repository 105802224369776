import React from 'react';
import { Link } from 'react-router-dom';

interface OnboardingNavBarMobileContentListItemButtonProps {
  href: string;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}

export const OnboardingNavBarMobileContentListItemButton: React.FC<
  OnboardingNavBarMobileContentListItemButtonProps
> = ({ href, icon, label, onClick }) => (
  <Link to={href} onClick={onClick}>
    <div className='flex min-h-[64px] flex-row items-center gap-8 p-4 hover:bg-neutral-black-4'>
      <div className='text-neutral-black-60'>{icon}</div>
      <span className='typography-body1'>{label}</span>
    </div>
  </Link>
);
