import { Slot } from '@radix-ui/react-slot';
import { forwardRef, HTMLAttributes } from 'react';

import { cn } from '../utils';

export type IconButtonProps = HTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, className, asChild, ...delegated }, ref) => {
    const styles = cn(
      'text-neutral-black-60 p-2 hover:bg-neutral-black-4 rounded-full transition-all duration-200 flex-shrink-0',
      className,
    );

    const Comp = asChild ? Slot : 'button';

    return (
      <Comp ref={ref} className={styles} style={{ WebkitTapHighlightColor: 'transparent' }} {...delegated}>
        {children}
      </Comp>
    );
  },
);
IconButton.displayName = 'IconButton';
