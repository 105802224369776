import { Stack, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Icon, IconKey } from '../Icon';
import { Text } from '../Typography';

interface SidebarItemProps {
  /* Item is underlined when active=true */
  active: boolean;
  /* Called on click */
  onClick?: () => void;
  /* Key of icon which is on the left side of the text */
  iconKey?: IconKey;
  icon?: React.ReactNode;
  dataCy?: string;
}

export const SidebarItem: React.FC<PropsWithChildren<SidebarItemProps>> = ({
  active,
  onClick,
  children,
  iconKey,
  icon,
  dataCy,
}) => {
  const theme = useTheme();

  return (
    <Stack
      mb={1}
      minWidth={300}
      borderRadius={1}
      px={2}
      py={1.5}
      bgcolor={active ? theme.palette.primary.p08 : undefined}
      direction='row'
      data-cy={dataCy}
      role='menuitem'
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      {iconKey ? <Icon sx={{ mr: 2 }} icon={iconKey} opacity={0.54} /> : icon}
      <Text variant='body1' mx={2}>
        {children}
      </Text>
    </Stack>
  );
};
