import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import React from 'react';

import { allIcons } from '../Icons';

export type IconKey = keyof typeof allIcons;
export interface IconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  /** The icon's unique identifier */
  icon: IconKey;
  /**
   * Viewbox of the SVG Element
   * @default '0 0 24 24'
   */
  viewBox?: string;
  /** Accessibility title */
  title?: string;
  /** Style props of the container box */
  sx?: SxProps<Theme>;
  /** Test ID */
  'data-testid'?: string;
  'data-cy'?: string;
}

export const Icon = ({
  icon,
  className,
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  title,
  sx,
  color,
  ...rest
}: IconProps) => {
  // eslint-disable-next-line security/detect-object-injection
  const SvgIcon = allIcons[icon];

  if (!SvgIcon) {
    throw new Error(`Icon identifier ${icon} is not supported.`);
  }

  return (
    <Box
      component='span'
      display='flex'
      role='img'
      title={title}
      sx={sx}
      data-testid={`${icon}-icon`}
      data-cy={`${icon}-icon`}
      className={classNames(`icon icon--${icon.toString().replace('/', '-')}`, className)}
      color={color || 'inherit'}
    >
      <SvgIcon
        width={width}
        height={height}
        className='overflow-visible'
        preserveAspectRatio='xMinYMid meet'
        viewBox={viewBox}
        {...rest}
      />
    </Box>
  );
};
