// TODO: these types should be generated automatically based on the API's Swagger documentation

import { User } from './user';

/**
 * POST /api/v1/authentication/otp
 */
export const POST_AUTHENTICATION_OTP_PATH = 'authentication/otp';
export type PostAuthenticationOtpRequestPathVariables = undefined;
export type PostAuthenticationOtpRequestQueryParams = undefined;
export type PostAuthenticationOtpRequestBodyData = {
  token: string;
};
export type PostAuthenticationOtpSuccessDto = {
  token: {
    access: string;
    refresh: string;
  };
  user: User;
  impersonated_by?: string | null;
};
export type PostAuthenticationOtpErrorDto = Error;

/**
 * POST /api/v1/authentication/sign_in
 */
export const POST_AUTHENTICATION_SIGN_IN_PATH = 'authentication/sign_in';
export type PostAuthenticationSignInRequestPathVariables = undefined;
export type PostAuthenticationSignInRequestQueryParams = undefined;
export type PostAuthenticationSignInRequestBodyData = {
  email: string;
  password: string;
};
export type PostAuthenticationSignInSuccessDto = {
  token: {
    access: string;
    refresh: string;
  };
  user: User;
};
export type PostAuthenticationSignInErrorDto = Error;

/**
 * POST /api/v1/authentication/validate_onetime_token
 */
export const POST_AUTHENTICATION_VALIDATE_ONETIME_TOKEN_PATH = 'authentication/validate_onetime_token';
export type PostAuthenticationValidateOnetimeTokenRequestPathVariables = undefined;
export type PostAuthenticationValidateOnetimeTokenRequestQueryParams = undefined;
export type PostAuthenticationValidateOnetimeTokenRequestBodyData = {
  token: string;
};
export type PostAuthenticationValidateOnetimeTokenSuccessDto = {
  valid: boolean;
};
export type PostAuthenticationValidateOnetimeTokenErrorDto = Error;
