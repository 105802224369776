import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { useBenchmarkForFact } from '@/pages/shared/hooks/useBenchmarkForFact';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';
import { getStyleForBenchmark } from '@/utils/getStyleForBenchmark';

import { Area, Chart, ComposedChart, getXAxisProps, TooltipProps } from './components/Chart';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { NoChartData } from './NoChartData';
import { extrapolateReferenceLinesChartData, RenderReferenceLine } from './ReferenceLines';
import { ChartProps } from './types';
import {
  getChartDataFromGraphFact,
  getXAxisDomainForHistoricGraph,
  kgToTonneFormatter,
  minMaxAxisDomain,
  sortChartData,
} from './utils';

const chartColors = {
  carbon: { stroke: '#EAC745', fill: '#FFDD5F' },
} as const;

const totalFactNames = {
  permanent_soil_carbon_total: 'cbg',
  permanent_soil_carbon_total_benchmark_low: 'cbg_benchmark_low',
  permanent_soil_carbon_total_benchmark_moderate: 'cbg_benchmark_moderate',
  permanent_soil_carbon_total_benchmark_high: 'cbg_benchmark_high',
};

const perHaFactNames = {
  permanent_soil_carbon_per_ha: 'cbg',
  permanent_soil_carbon_per_ha_benchmark_low: 'cbg_benchmark_low',
  permanent_soil_carbon_per_ha_benchmark_moderate: 'cbg_benchmark_moderate',
  permanent_soil_carbon_per_ha_benchmark_high: 'cbg_benchmark_high',
};

export const CarbonStorageBGHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  analysisType,
  data,
  benchmarks,
  className,
  showTooltip,
  ...delegated
}) => {
  const { t } = useTranslation();
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum.t) : formatUnit(UnitEnum['t/ha']);

  const factNames = analysisType === 'total' ? totalFactNames : perHaFactNames;
  const dateDataMap = getChartDataFromGraphFact(data, factNames, kgToTonneFormatter);
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());

  let chartData = extrapolateReferenceLinesChartData(sortedChartData, 'cbg_benchmark_low');
  chartData = extrapolateReferenceLinesChartData(chartData, 'cbg_benchmark_moderate');
  chartData = extrapolateReferenceLinesChartData(chartData, 'cbg_benchmark_high');

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }
  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);
  const xAxisProps = getXAxisProps(xTicksDomain);

  return (
    <>
      <Chart.Container
        {...delegated}
        data={chartData}
        config={{
          cbg: {
            label: t('global.analysis.belowGroundCarbonStorage'),
            color: chartColors.carbon.stroke,
          },
        }}
      >
        <ComposedChart data={chartData}>
          <defs>
            <linearGradient id='cbg' x1='0' y1='-2.6' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.carbon.fill} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.carbon.fill} stopOpacity={0} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'cbg.confidence_interval',
            type: 'monotone',
          })}
          {RenderReferenceLine({
            dataKey: 'cbg_benchmark_low.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.cbg_benchmark_low) ? t('global.benchmark.low') : null,
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'cbg_benchmark_moderate.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.cbg_benchmark_moderate) ? t('global.benchmark.moderate') : null,
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'cbg_benchmark_high.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.cbg_benchmark_high) ? t('global.benchmark.high') : null,
            chartData,
          })}
          <Area
            type='monotone'
            isAnimationActive={false}
            stroke='var(--color-cbg)'
            strokeWidth={3}
            fill='url(#cbg)'
            fillOpacity={1}
            dataKey='cbg.value'
            connectNulls
            activeDot={ChartTooltipDot}
          />
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={minMaxAxisDomain(0.8, 1.2)}>
            <Chart.Label>{unit}</Chart.Label>
          </Chart.YAxis>
          {showTooltip && (
            <Chart.Tooltip
              content={(props) => <CustomTooltip {...props} unit={unit as UnitEnum} benchmarks={benchmarks} />}
            />
          )}
        </ComposedChart>
      </Chart.Container>
      <CustomLegendBar />
    </>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.belowGroundCarbonStorage'),
      color: chartColors.carbon.fill,
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='mt-8 sm:ml-[28px]' data-testid='carbon-storage-bg-chart-legend' />
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  unit,
  benchmarks,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TooltipProps<ValueType, NameType> & { unit: UnitEnum; benchmarks?: any[] }) => {
  const { t } = useTranslation();

  const cbgValue = payload?.find((data) => data.dataKey === 'cbg.value');
  const cbgDisplay =
    cbgValue?.value != null
      ? `${getDisplayNumber(cbgValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');
  const cbgBenchmarkType = useBenchmarkForFact(benchmarks, cbgValue?.value);

  if (!active) return null;

  const style = cbgBenchmarkType ? getStyleForBenchmark(cbgBenchmarkType) : null;
  return (
    <ChartTooltip>
      {cbgBenchmarkType && (
        <ChartTooltipTextPrimary
          className='typography-overline mt-1 w-fit rounded px-[9px] py-2'
          style={{
            color: style?.text,
            backgroundColor: style?.primary,
          }}
        >
          {t(`global.benchmark.${cbgBenchmarkType}`)}
        </ChartTooltipTextPrimary>
      )}
      <ChartTooltipTextPrimary>{`${t('global.analysis.carbonStorageBg')}: ${cbgDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
