import { ThemeOptions } from '@mui/material/styles';

import { buttonOverrides } from './button';
import { dialogOverrides } from './dialog';
import { otherComponentsOverrides } from './otherComponents';

export const components: ThemeOptions['components'] = {
  ...buttonOverrides,
  ...dialogOverrides,
  ...otherComponentsOverrides,
};
