import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  NCABalance,
  NCAReportBalance,
  NCAReportBalanceType,
  NCAReportBalanceValue,
  NCAReportBalanceValueType,
} from '@/api/rest/resources/types/ncaBalance';
import {
  GET_PROJECT_NCA_REPORT_BY_ID_PATH,
  GetProjectNcaReportByIdErrorDto,
  GetProjectNcaReportByIdSuccessDto,
} from '@/api/rest/resources/types/project';

/**
 * @param projectIdArg Falls back to the projectId in the URL if no value is provided.
 */
export const useNcaReportByProjectId = (projectIdArg?: string) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const queryParams = { id: projectId };

  const queryFn = async () => {
    return getRequest<GetProjectNcaReportByIdSuccessDto>(GET_PROJECT_NCA_REPORT_BY_ID_PATH, queryParams);
  };

  return useSuspenseQuery<GetProjectNcaReportByIdSuccessDto, GetProjectNcaReportByIdErrorDto>({
    queryKey: ['project', queryParams, 'nca-report'],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};

/**
 * @param projectId Falls back to the projectId in the URL if no value is provided.
 */
export const useNCAReportBalance = (type: NCAReportBalanceType, projectIdArg?: string) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const ncaReportData = useNcaReportByProjectId(projectId).data;

  return ncaReportData.balances.find((balance) => balance.name === type) as NCAReportBalance | undefined;
};

export const useNCABalance = <T = unknown>(balance: NCAReportBalance | undefined, type: NCAReportBalanceValueType) => {
  // eslint-disable-next-line security/detect-object-injection
  const balanceValue = balance?.[type] as NCAReportBalanceValue<T | null> | undefined;
  return balanceValue ? ({ ...balanceValue, name: balance?.name, type } as NCABalance<T | null>) : undefined;
};
