import { ComponentRef, forwardRef, PropsWithChildren } from 'react';
import { Link, LinkProps, useMatch } from 'react-router-dom';
import { Stack, StackProps } from 'tw-component-library/Stack/Stack';
import { cn } from 'tw-component-library/utils';

export type TabsListProps = StackProps;

export const TabsList = forwardRef<ComponentRef<typeof Stack>, TabsListProps>(
  ({ className, ...delegated }, forwardedRef) => {
    return <Stack ref={forwardedRef} direction='row' className={cn('overflow-auto', className)} {...delegated} />;
  },
);
TabsList.displayName = 'TabsList';

export type TabTriggerProps = PropsWithChildren<{
  path: LinkProps['to'];
  className?: string;
}>;

export const TabTrigger = forwardRef<ComponentRef<typeof Link>, TabTriggerProps>(
  ({ className, path, ...delegated }, forwardedRef) => {
    const isTabActive = !!useMatch(path.toString());

    const commonStyles = 'typography-button1 block border-b-2 p-4 whitespace-nowrap';

    if (isTabActive)
      return <span className={cn(commonStyles, 'border-b-secondary-100 text-primary-100', className)} {...delegated} />;

    return (
      <Link
        ref={forwardedRef}
        to={path}
        preventScrollReset
        className={cn(commonStyles, 'border-transparent text-text-secondary', className)}
        {...delegated}
      />
    );
  },
);
TabTrigger.displayName = 'TabTrigger';
