import { useApiPatchRequest } from '../hooks/useApiPatchRequest';
import { useApiPostRequest } from '../hooks/useApiPostRequest';
import { buildUseGetQuery } from '../utils';
import {
  GET_BUYER_PROJECT_AGGREGATE_PATH,
  GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH,
  GET_BUYER_PROJECT_BY_ID_PATH,
  GET_BUYER_PROJECTS_PATH,
  GET_PROJECT_BY_ID_DASHBOARD_PATH,
  GET_PROJECT_BY_ID_PATH,
  GET_PROJECT_PATH,
  GetBuyerProjectAggregateErrorDto,
  GetBuyerProjectAggregateRequestPathVariables,
  GetBuyerProjectAggregateRequestQueryParams,
  GetBuyerProjectAggregateSuccessDto,
  GetBuyerProjectByIdDashboardErrorDto,
  GetBuyerProjectByIdDashboardRequestPathVariables,
  GetBuyerProjectByIdDashboardRequestQueryParams,
  GetBuyerProjectByIdDashboardSuccessDto,
  GetBuyerProjectByIdErrorDto,
  GetBuyerProjectByIdRequestPathVariables,
  GetBuyerProjectByIdRequestQueryParams,
  GetBuyerProjectByIdSuccessDto,
  GetBuyerProjectsErrorDto,
  GetBuyerProjectsRequestPathVariables,
  GetBuyerProjectsRequestQueryParams,
  GetBuyerProjectsSuccessDto,
  GetProjectByIdDashboardErrorDto,
  GetProjectByIdDashboardRequestPathVariables,
  GetProjectByIdDashboardRequestQueryParams,
  GetProjectByIdDashboardSuccessDto,
  GetProjectByIdErrorDto,
  GetProjectByIdRequestPathVariables,
  GetProjectByIdRequestQueryParams,
  GetProjectByIdSuccessDto,
  GetProjectErrorDto,
  GetProjectRequestPathVariables,
  GetProjectRequestQueryParams,
  GetProjectSuccessDto,
  PATCH_PROJECT_BY_ID_PATH,
  PatchProjectByIdErrorDto,
  PatchProjectByIdRequestBody,
  PatchProjectByIdRequestPathVariables,
  PatchProjectByIdRequestQueryParams,
  PatchProjectByIdSuccessDto,
  POST_ANALYSE_PROJECT_PATH,
  POST_PROJECT_PATH,
  PostAnalyseProjectErrorDto,
  PostAnalyseProjectRequestBodyData,
  PostAnalyseProjectRequestPathVariables,
  PostAnalyseProjectRequestQueryParams,
  PostAnalyseProjectSuccessDto,
  PostProjectErrorDto,
  PostProjectRequestBodyData,
  PostProjectRequestPathVariables,
  PostProjectRequestQueryParams,
  PostProjectSuccessDto,
} from './types/project';

export const useGetProjects = buildUseGetQuery<
  GetProjectRequestPathVariables,
  GetProjectRequestQueryParams,
  GetProjectSuccessDto,
  GetProjectErrorDto
>((params) => ['land_steward', 'project', params], GET_PROJECT_PATH);

export const useGetProjectById = buildUseGetQuery<
  GetProjectByIdRequestPathVariables,
  GetProjectByIdRequestQueryParams,
  GetProjectByIdSuccessDto,
  GetProjectByIdErrorDto
>((params) => ['land_steward', 'project', params?.pathVariables.id, params], GET_PROJECT_BY_ID_PATH);

export const usePostProject = () => {
  return useApiPostRequest<
    PostProjectRequestPathVariables,
    PostProjectRequestQueryParams,
    PostProjectRequestBodyData,
    PostProjectSuccessDto,
    PostProjectErrorDto
  >(POST_PROJECT_PATH);
};

export const usePatchProjectById = () => {
  return useApiPatchRequest<
    PatchProjectByIdRequestPathVariables,
    PatchProjectByIdRequestQueryParams,
    PatchProjectByIdRequestBody,
    PatchProjectByIdSuccessDto,
    PatchProjectByIdErrorDto
  >(PATCH_PROJECT_BY_ID_PATH);
};

export const usePostAnalyseProject = () => {
  return useApiPostRequest<
    PostAnalyseProjectRequestPathVariables,
    PostAnalyseProjectRequestQueryParams,
    PostAnalyseProjectRequestBodyData,
    PostAnalyseProjectSuccessDto,
    PostAnalyseProjectErrorDto
  >(POST_ANALYSE_PROJECT_PATH);
};

export const useGetProjectByIdDashboard = buildUseGetQuery<
  GetProjectByIdDashboardRequestPathVariables,
  GetProjectByIdDashboardRequestQueryParams,
  GetProjectByIdDashboardSuccessDto,
  GetProjectByIdDashboardErrorDto
>(
  (params) => ['land_steward', 'project', params?.pathVariables.id, 'dashboard', params],
  GET_PROJECT_BY_ID_DASHBOARD_PATH,
);

export const useGetBuyerProjects = buildUseGetQuery<
  GetBuyerProjectsRequestPathVariables,
  GetBuyerProjectsRequestQueryParams,
  GetBuyerProjectsSuccessDto,
  GetBuyerProjectsErrorDto
>((params) => ['buyer', 'project', params], GET_BUYER_PROJECTS_PATH);

export const useGetBuyerProjectById = buildUseGetQuery<
  GetBuyerProjectByIdRequestPathVariables,
  GetBuyerProjectByIdRequestQueryParams,
  GetBuyerProjectByIdSuccessDto,
  GetBuyerProjectByIdErrorDto
>((params) => ['buyer', 'project', params?.pathVariables.id, params], GET_BUYER_PROJECT_BY_ID_PATH);

export const useGetBuyerProjectAggregate = buildUseGetQuery<
  GetBuyerProjectAggregateRequestPathVariables,
  GetBuyerProjectAggregateRequestQueryParams,
  GetBuyerProjectAggregateSuccessDto,
  GetBuyerProjectAggregateErrorDto
>((params) => ['buyer', 'project', 'aggregate', params], GET_BUYER_PROJECT_AGGREGATE_PATH);

export const useGetBuyerProjectByIdDashboard = buildUseGetQuery<
  GetBuyerProjectByIdDashboardRequestPathVariables,
  GetBuyerProjectByIdDashboardRequestQueryParams,
  GetBuyerProjectByIdDashboardSuccessDto,
  GetBuyerProjectByIdDashboardErrorDto
>(
  (params) => ['buyer', 'project', params?.pathVariables.id, 'dashboard', params],
  GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH,
);
