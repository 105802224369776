import { Stack } from '@landler/tw-component-library';
import { alpha } from '@mui/material';
import { centerOfMass } from '@turf/turf';
import React from 'react';
import { Layer, Marker, Source } from 'react-map-gl';

import { Plot } from '@/api/rest/resources/types/plot';
import { getColorOfPlotType } from '@/utils/plot';

import { PlotTypeIcon } from '../PlotTypeIcon/PlotTypeIcon';

type MapPlotLayerProps = {
  plot: Plot;
};

export const MapPlotLayer: React.FC<MapPlotLayerProps> = ({ plot }) => {
  const fillColor = getColorOfPlotType(plot.type);

  return (
    <>
      <Source id={`source-${plot.id}`} type='geojson' data={plot.polygon} />
      <Layer
        id={`feat-${plot.id}`}
        type='fill'
        source={`source-${plot.id}`}
        paint={{
          'fill-outline-color': fillColor,
          'fill-color': alpha(fillColor, 0.4),
        }}
      />
    </>
  );
};

type MapPlotLayerWithMarkerProps = MapPlotLayerProps & {
  withIcon?: boolean;
  withName?: boolean;
};

export const MapPlotLayerWithMarker: React.FC<MapPlotLayerWithMarkerProps> = ({
  plot,
  withIcon = false,
  withName = false,
}) => {
  const centroid = centerOfMass(plot.polygon).geometry.coordinates;

  return (
    <>
      <MapPlotLayer plot={plot} />
      <Marker longitude={centroid[0]} latitude={centroid[1]}>
        <Stack className='items-center text-white-100'>
          {withIcon && <PlotTypeIcon type={plot.type} className='text-white-100' />}
          {withName && <span className='typography-caption text-white-100'>{plot.name}</span>}
        </Stack>
      </Marker>
    </>
  );
};
