import { NCABalance, NCAReportBalanceValueType, SIGNED_NCA_BALANCE_TYPES } from '@/api/rest/resources/types/ncaBalance';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { formatUnit } from '@/utils/formatting';

import { getDisplayNumber } from './useDisplayNumber';

type UseBalanceResult = {
  display: string;
  value?: string | number;
  unit?: string;
};

const prettifyDisplayedValue = (value: string, unit?: string) => {
  if (!unit || unit === '') {
    return value;
  }

  return `${value} ${unit}`;
};

// TODO: MVP-3284 merge with useFact
export const useBalance = (balance?: NCABalance): UseBalanceResult => {
  if (!balance || !isValidBalanceValue(balance.value) || !balance.unit) {
    return {
      display: 'N/A',
    };
  }

  const { type, value, unit } = balance;

  const showSign = SIGNED_NCA_BALANCE_TYPES.includes(type as NCAReportBalanceValueType);
  const formattedValue =
    typeof value === 'number'
      ? getDisplayNumber(value, window.navigator.language, { signDisplay: showSign ? 'exceptZero' : 'never' })
      : value;
  const formattedUnit = formatUnit(unit);

  return {
    display: prettifyDisplayedValue(formattedValue, formattedUnit),
    value,
    unit: unit === UnitEnum.none ? undefined : formattedUnit,
  };
};

const isValidBalanceValue = (value: unknown): value is number | string => {
  return ['string', 'number'].includes(typeof value);
};
