import { cn, RiArrowDownLine, RiArrowUpLine, Stack } from '@landler/tw-component-library';
import { Column } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { DraftPlot, Plot } from '@/api/rest/resources/types/plot';

export const ToggleSortButton = ({
  className,
  column,
  children,
  ...delegated
}: React.HTMLAttributes<HTMLButtonElement> & { column: Column<Plot, unknown> | Column<DraftPlot, unknown> }) => {
  const sortDirection = column.getIsSorted();
  const sortIndicator = useMemo(() => {
    if (sortDirection === 'asc') {
      return <RiArrowUpLine size={16} />;
    }

    if (sortDirection === 'desc') {
      return <RiArrowDownLine size={16} />;
    }

    return null;
  }, [sortDirection]);

  return (
    <Stack direction='row' centerMain>
      <button
        className={cn(
          'inline-flex items-center gap-1.5 rounded-md px-3 py-2 transition-colors hover:bg-bg-light-grey',
          'typography-overline max-w-fit whitespace-nowrap leading-4',
          className,
        )}
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        {...delegated}
      >
        {children}
        {sortIndicator}
      </button>
      {/* Prevents layout shift as the sort indicator icon switches between columns */}
      {!sortIndicator && <div className='ml-1.5 h-4 w-4 shrink-0' />}
    </Stack>
  );
};
