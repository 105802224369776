import { useSuspenseQuery } from '@tanstack/react-query';

import { Dashboard, Fact, FactType, Project } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectKey } from './useProjectKey';

const getFactsByProjectId = async (projectId: Project['id']) => {
  const module = await import(`../mockData/${projectId}/dashboard.json`);

  return (module.default as Dashboard).facts.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: curr,
    };
  }, {} as Record<FactType, Fact>);
};

const useDashboardFactsByType = () => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'dashboard'],
    queryFn: () => getFactsByProjectId(projectKey),
  });
};

export const useFact = <V, F extends FactType = FactType>(factType: F) => {
  const { data: dashboardFacts } = useDashboardFactsByType();

  // eslint-disable-next-line security/detect-object-injection
  return dashboardFacts[factType] as Fact<F, V>;
};
