import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_BUYER_CONTRACT_PATH,
  GetBuyerContractErrorDto,
  GetBuyerContractSuccessDto,
} from '@/api/rest/resources/types/contract';

export const useBuyerContract = () => {
  const queryFn = async () => {
    return getRequest<GetBuyerContractSuccessDto>(GET_BUYER_CONTRACT_PATH);
  };

  return useSuspenseQuery<GetBuyerContractSuccessDto, GetBuyerContractErrorDto>({
    queryKey: ['buyer', 'contract'],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
