import * as LabelPrimitive from '@radix-ui/react-label';
import * as React from 'react';

import { cn } from '../utils';

export type LabelProps = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
  disabled?: boolean;
};

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, disabled, ...props }, ref) => (
    <LabelPrimitive.Root
      ref={ref}
      data-disabled={disabled}
      className={cn(
        'data-[disabled=true]:cursor-not-allowed data-[disabled=true]:text-text-disabled',
        'peer-disabled:cursor-not-allowed peer-disabled:text-text-disabled',
        className,
      )}
      {...props}
    />
  ),
);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
