import { toast } from '@landler/tw-component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePostUserMeRequestActivationMail } from '@/api/rest/resources/user';
import { useUser } from '@/lib/auth';

import { InformationBoxEntryStatus } from './InformationBoxEntryStatus';

export enum EmailVerificationStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export type EmailInformationBoxEntryStatusProps = { status: EmailVerificationStatus };

export const EmailInformationBoxEntryStatus: React.FC<EmailInformationBoxEntryStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  const [isLinkClicked, setIsLinkClicked] = useState(false);
  const orgId = useUser().membership.organization.id;
  const { mutate } = usePostUserMeRequestActivationMail();

  const handleOnClick = () => {
    if (isLinkClicked) {
      return;
    }
    setIsLinkClicked(true);
    mutate(
      { bodyData: { org_id: orgId } },
      {
        onSuccess: () => {
          toast({
            title: t('shared.account.status.notVerified.retrigger.success'),
            type: 'success',
          });
        },
        onError: () => {
          setIsLinkClicked(false);

          toast({
            title: t('shared.account.status.notVerified.retrigger.error'),
            type: 'error',
          });
        },
      },
    );
  };

  if (status === EmailVerificationStatus.VERIFIED) {
    return (
      <InformationBoxEntryStatus
        variant='success'
        label={t('shared.account.status.verified')}
        data-cy='account-page-verfication-status'
      />
    );
  }

  return (
    <InformationBoxEntryStatus
      variant='warning'
      label={t('shared.account.status.notVerified.label')}
      link={
        !isLinkClicked
          ? { label: t('shared.account.status.notVerified.retrigger.label'), onClick: handleOnClick }
          : undefined
      }
      data-cy='account-page-verfication-status'
    />
  );
};
