import { Button, Text } from '@landler/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/routing';

import { NewPasswordPageContent } from '../page-content/PageContent';

export const NewPasswordSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <NewPasswordPageContent dataCy='password-reset-success' headlineText={t('passwordResetSuccess.title')}>
      <Text variant='body1'>{t('passwordResetSuccess.subtitle')}</Text>
      <Button
        href={paths.landSteward.projects}
        size='large'
        onClick={(e) => {
          e.preventDefault();
          navigate(paths.landSteward.projects);
        }}
        sx={{ alignSelf: 'end' }}
      >
        {t('passwordResetSuccess.continue')}
      </Button>
    </NewPasswordPageContent>
  );
};
