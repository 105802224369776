import { useTranslation } from 'react-i18next';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { PlotListDesktop } from '../../components/plot-list/components/PlotListDesktop';
import { PlotListTitle } from '../../components/plot-list/PlotList';

export const DeforestationDetailPage = () => {
  const { t } = useTranslation();

  const plotListFilters = {
    is_deforested: true,
  };

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.deforestation.deforestation')}>
      <PlotListTitle title={t('shared.ncaDetail.details.deforestation.plotsAffectedByDeforestation')} />
      <PlotListDesktop plotFilters={plotListFilters} />
    </LandMonitoringPageWrapper>
  );
};
