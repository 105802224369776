import { Logger } from '@/lib/logs/logger';

export const buildFormData = (path: string, data: unknown): FormData | undefined => {
  if (!data) {
    return undefined;
  }

  const formData = new FormData();

  Object.entries(data as Record<string, unknown>).forEach(([key, value]) => {
    if (typeof value === 'string' || value instanceof Blob) {
      formData.append(key, value);
    } else {
      Logger.errorOnce(`API body form data "${key}" of path "${path}" has an invalid type: ${typeof value}`);
    }
  });

  return formData;
};
