/* eslint-disable security/detect-object-injection */
import { LoggingStrategy, UniqueLogEntries } from './LoggingStrategy';
import { getLogEntryMessage } from './utils';

export class ConsoleLogger implements LoggingStrategy {
  uniqueInfos: UniqueLogEntries = {};

  uniqueWarns: UniqueLogEntries = {};

  uniqueErrors: UniqueLogEntries = {};

  public info(error: string | Error): void {
    // eslint-disable-next-line no-console
    console.info(error);
  }

  public infoOnce(error: string | Error): void {
    const infoId = getLogEntryMessage(error);

    if (this.uniqueInfos[infoId]) {
      return;
    }

    this.uniqueInfos[infoId] = true;

    // eslint-disable-next-line no-console
    console.info(error);
  }

  public warn(error: string | Error): void {
    // eslint-disable-next-line no-console
    console.warn(error);
  }

  public warnOnce(error: string | Error): void {
    const warnId = getLogEntryMessage(error);

    if (this.uniqueWarns[warnId]) {
      return;
    }

    this.uniqueWarns[warnId] = true;

    // eslint-disable-next-line no-console
    console.warn(error);
  }

  public error(error: string | Error): void {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  public errorOnce(error: string | Error): void {
    const errorId = getLogEntryMessage(error);

    if (this.uniqueErrors[errorId]) {
      return;
    }

    this.uniqueErrors[errorId] = true;

    // eslint-disable-next-line no-console
    console.error(error);
  }
}
