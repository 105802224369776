import React from 'react';

import { ContractList } from '@/api/rest/resources/types/contract';

import { LandStewardPageLayout } from '../../layouts/LandStewardPage.layout';
import { WithContracts } from './components/with-contracts/WithContracts';
import { WithoutContracts } from './components/without-contracts/WithoutContracts';
import { useLandstewardContract } from './hooks/useLandstewardContract';

export const LandstewardInvestmentsPage = () => {
  const { data } = useLandstewardContract();

  return (
    <LandStewardPageLayout withCustomContentContainer>
      <Body contracts={data.results} />
    </LandStewardPageLayout>
  );
};

export const Body: React.FC<{ contracts: ContractList[] }> = ({ contracts }) => {
  if (contracts.length === 0) {
    return <WithoutContracts />;
  }

  return <WithContracts />;
};
