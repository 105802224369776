import { Alert as MuiAlert, AlertProps, alpha, styled, Theme } from '@mui/material';
import React from 'react';

const getBackgroundColor = (
  theme: Theme,
  severity: AlertProps['severity'] = 'success',
  variant: AlertProps['variant'] = 'standard',
) => {
  if (variant === 'outlined') {
    return theme.palette.common.white;
  }

  if (variant === 'filled') {
    // eslint-disable-next-line security/detect-object-injection
    return theme.palette[severity].main;
  }

  // eslint-disable-next-line security/detect-object-injection
  return alpha(theme.palette[severity].main, 0.1);
};

const getBorder = (
  theme: Theme,
  severity: AlertProps['severity'] = 'success',
  variant: AlertProps['variant'] = 'standard',
) => {
  if (variant === 'outlined') {
    // eslint-disable-next-line security/detect-object-injection
    return `1px solid ${theme.palette[severity].main}`;
  }

  return 'none';
};

const getIconColor = (
  theme: Theme,
  severity: AlertProps['severity'] = 'success',
  variant: AlertProps['variant'] = 'standard',
) => {
  if (variant === 'filled') {
    if (severity === 'warning') {
      return theme.palette.warning.contrastText;
    }
    return theme.palette.common.white;
  }

  // eslint-disable-next-line security/detect-object-injection
  return theme.palette[severity].main;
};

const getTextAndActionColor = (
  theme: Theme,
  severity: AlertProps['severity'] = 'success',
  variant: AlertProps['variant'] = 'standard',
) => {
  if (variant === 'filled') {
    if (severity === 'warning') {
      return theme.palette.warning.contrastText;
    }
    return theme.palette.common.white;
  }

  // eslint-disable-next-line security/detect-object-injection
  return theme.palette[severity].dark;
};

const StyledAlert = styled(MuiAlert)<AlertProps>(({ severity, variant, theme }) => ({
  alignItems: 'center',
  backgroundColor: getBackgroundColor(theme, severity, variant),
  border: getBorder(theme, severity, variant),
  borderRadius: 8,
  '& .MuiAlert-icon': {
    color: getIconColor(theme, severity, variant),
  },
  '& .MuiAlert-message': {
    color: getTextAndActionColor(theme, severity, variant),
    whiteSpace: 'pre-line',
  },
  '& .MuiAlertTitle-root': {
    color: getTextAndActionColor(theme, severity, variant),
  },
  '& .MuiAlert-action': {
    color: getTextAndActionColor(theme, severity, variant),
    paddingTop: 0,
  },
}));

const AlertComponent: React.ForwardRefRenderFunction<HTMLDivElement, AlertProps> = (props, ref) => (
  <StyledAlert {...props} ref={ref} />
);

export const Alert = React.forwardRef(AlertComponent);
