import { Box, Collapse, Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';

import { Icon, IconKey } from '../Icon';

type MenuExpandableComponentProps = {
  /** Custom component instead of a icon and label pair * */
  header: React.ReactNode;
  /** Defines where dividers are added */
  dividers?: 'top' | 'bottom' | 'both' | 'none';
};

type MenuExpandableItemProps = {
  /** The icon which is shown in front of the label */
  iconKey: IconKey;
  /** The label of the expandable button */
  label: string;
  /** Defines where dividers are added */
  dividers?: 'top' | 'bottom' | 'both' | 'none';
};

export const MenuExpandableItem: React.FC<
  PropsWithChildren<MenuExpandableItemProps | MenuExpandableComponentProps>
> = ({ children, dividers = 'both', ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const hasTopDivider = dividers === 'top' || dividers === 'both';
  const hasBottomDivider = dividers === 'bottom' || dividers === 'both';

  const { header } = props as MenuExpandableComponentProps;
  const { iconKey, label } = props as MenuExpandableItemProps;

  return (
    <Box>
      {hasTopDivider && <Divider />}
      <ListItemButton onClick={toggleOpen} sx={{ height: 64, paddingLeft: header ? 0 : undefined }}>
        {header || (
          <>
            <ListItemIcon>
              <Icon icon={iconKey} />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </>
        )}
        <Icon
          icon='expand-more-filled'
          sx={{
            color: 'action.active',
            transition: 'transform .2s ease-in-out',
            transform: isOpen ? 'rotate(180deg)' : undefined,
          }}
        />
      </ListItemButton>

      {hasBottomDivider && <Divider />}
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        {children}
        {hasBottomDivider && <Divider />}
      </Collapse>
    </Box>
  );
};
