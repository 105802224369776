import { Box, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface SidebarPageLayoutProps {
  /** The sidebar is on the left side of the content in the desktop view and on top of the content in the mobile view */
  sidebar: React.ReactNode;
}

export const SidebarPageLayout: React.FC<PropsWithChildren<SidebarPageLayoutProps>> = ({ children, sidebar }) => {
  return (
    <Stack width='100%' sx={{ flexDirection: { xs: 'column', md: 'row' } }} data-testid='sidebar'>
      {sidebar}
      <Stack flexGrow={2} mx={{ xs: 0, md: 3 }}>
        <Box maxWidth={665} mx='auto' width='100%' mt={{ xs: 0, md: 9 }}>
          {children}
        </Box>
      </Stack>
    </Stack>
  );
};
