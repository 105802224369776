import { Button, ButtonLink } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useContactFormLink } from '@/hooks/useContactFormLink';
import { paths } from '@/routing';

import { FullScreenError } from '..';

export const FallbackError: React.FC = () => {
  const { t } = useTranslation();
  const contactFormUrl = useContactFormLink();

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <FullScreenError
      title={t('fallbackError.title')}
      body={t('fallbackError.body')}
      actions={
        <>
          <ButtonLink target='_blank' to={contactFormUrl} variant='outline'>
            {t('fallbackError.buttons.contact')}
          </ButtonLink>
          <Button variant='outline' asChild>
            <Link reloadDocument to={paths.root}>
              {t('fallbackError.buttons.backToHome')}
            </Link>
          </Button>
          <Button onClick={handleReloadPage}>{t('fallbackError.buttons.tryAgain')}</Button>
        </>
      }
    />
  );
};
