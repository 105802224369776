import {
  ButtonLink,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { ConfirmMailChangeState } from '@/pages/confirm-mail-change/hooks/useConfirmMailChange';

export type AccountEditEmailConfirmMailChangeDialogState = Exclude<
  ConfirmMailChangeState,
  ConfirmMailChangeState.LOADING | ConfirmMailChangeState.NO_TOKEN_PROVIDED
>;

type ConfirmMailChangeDialogProps = {
  state: AccountEditEmailConfirmMailChangeDialogState;
};

export const AccountEditEmailConfirmMailChangeDialog: React.FC<ConfirmMailChangeDialogProps> = ({ state }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountPath, accountEditEmailPath } = useAccountPageRoutes();

  const cancelButtonLabel = t('global.ui.buttons.cancel');

  const onOpenChange = (open: boolean) => {
    if (!open) {
      navigate(accountEditEmailPath);
    }
  };

  const defaultDialogProps: DialogProps = {
    open: true,
    onOpenChange,
  };

  if (state === ConfirmMailChangeState.SUCCESS) {
    return (
      <Dialog {...defaultDialogProps}>
        <Content
          title={t('shared.account.editEmail.confirmChangeMail.success.title')}
          text={t('shared.account.editEmail.confirmChangeMail.success.bodyText')}
          submitButton={{ label: t('shared.account.editEmail.confirmChangeMail.success.button'), href: accountPath }}
        />
      </Dialog>
    );
  }

  if (state === ConfirmMailChangeState.EXPIRED_LINK) {
    return (
      <Dialog {...defaultDialogProps}>
        <Content
          title={t('shared.account.editEmail.confirmChangeMail.expired.title')}
          text={t('shared.account.editEmail.confirmChangeMail.expired.bodyText')}
          cancelButton={{ label: cancelButtonLabel, href: accountEditEmailPath }}
          submitButton={{ label: t('shared.account.editEmail.buttons.submit'), href: accountEditEmailPath }}
        />
      </Dialog>
    );
  }

  if (state === ConfirmMailChangeState.ALREADY_VERIFIED) {
    return (
      <Dialog {...defaultDialogProps}>
        <Content
          title={t('shared.account.editEmail.confirmChangeMail.alreadyVerified.title')}
          text={t('shared.account.editEmail.confirmChangeMail.alreadyVerified.bodyText')}
          cancelButton={{ label: cancelButtonLabel, href: accountEditEmailPath }}
          submitButton={{
            label: t('shared.account.editEmail.confirmChangeMail.alreadyVerified.submit'),
            href: accountPath,
          }}
        />
      </Dialog>
    );
  }

  return (
    <Dialog {...defaultDialogProps}>
      <Content
        title={t('shared.account.editEmail.confirmChangeMail.unexpectedError.title')}
        text={t('shared.account.editEmail.confirmChangeMail.unexpectedError.bodyText')}
        cancelButton={{ label: cancelButtonLabel, href: accountEditEmailPath }}
        submitButton={{
          label: t('shared.account.editEmail.buttons.submit'),
          href: accountEditEmailPath,
        }}
      />
    </Dialog>
  );
};

type ContentButtonProps = {
  label: string;
  href: string;
};

type ContentProps = {
  title: string;
  text: string;
  cancelButton?: ContentButtonProps;
  submitButton?: ContentButtonProps;
};

const Content: React.FC<ContentProps> = ({ title, text, cancelButton, submitButton }) => {
  return (
    <DialogContent data-testid='confirm-mail-change-dialog' data-cy='email-change-success-dialog'>
      <DialogHeader>
        <DialogTitle data-cy='email-change-success-dialog-title'>{title}</DialogTitle>
        <DialogDescription data-cy='email-change-success-dialog-text'>{text}</DialogDescription>
      </DialogHeader>
      <DialogFooter className={cancelButton ? undefined : 'justify-end'}>
        {cancelButton && (
          <ButtonLink to={cancelButton.href} variant='outline' data-cy='dialog-cancel-button'>
            {cancelButton.label}
          </ButtonLink>
        )}
        {submitButton && (
          <ButtonLink to={submitButton.href} data-cy='dialog-submit-button'>
            {submitButton.label}
          </ButtonLink>
        )}
      </DialogFooter>
    </DialogContent>
  );
};
