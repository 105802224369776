import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlertFill, Stack } from 'tw-component-library';

import { useAuth } from '../AuthenticationContext';
import { useUser } from '../UserContextProvider';

export const ImpersonatingBanner: FC = () => {
  const { t } = useTranslation();

  const { authenticatedUser } = useAuth();
  const { email } = useUser();

  return (
    <Stack id='impersonating-banner' direction='row' className='items-center gap-4 bg-error p-4 text-white-100'>
      <RiAlertFill size={24} className='flex-shrink-0' />
      <Stack className='gap-1'>
        <span className='typography-body1 font-medium'>{t('global.impersonating.title')}</span>
        <span className='typography-body2'>
          {t('global.impersonating.text', {
            super_user_account: authenticatedUser.impersonatedBy,
            signed_in_user: email,
          })}
        </span>
      </Stack>
    </Stack>
  );
};
