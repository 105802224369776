import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_DRAFT_PLOT_PATH,
  GetDraftPlotErrorDto,
  GetDraftPlotSuccessDto,
  PlotType,
} from '@/api/rest/resources/types/plot';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

export const useDraftPlot = (landType: PlotType, limit?: number, offset?: number) => {
  const projectId = useProjectId();

  const queryParams = {
    project: projectId,
    land_type: landType,
    limit,
    offset,
  };

  const queryFn = async () => {
    return getRequest<GetDraftPlotSuccessDto>(GET_DRAFT_PLOT_PATH, undefined, queryParams);
  };

  return useSuspenseQuery<GetDraftPlotSuccessDto, GetDraftPlotErrorDto>({
    queryKey: ['land_steward', 'draftplots', queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
