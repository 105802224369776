import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  cn,
  Divider,
  RiArrowRightUpLine,
  RiCompass4Line,
  Stack,
} from '@landler/tw-component-library';
import { centerOfMass } from '@turf/turf';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CellProperties } from '@/api/rest/resources/conservation';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { Area } from '@/utils/area';

import { FactCapsule, FactCapsuleTrend } from '../../../components/conservation/components/FactCapsule';
import { useFact } from '../../../components/conservation/hooks/useFact';
import { useControlsContext } from '../hooks/useControlsForm';

export const InfoPanel = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();
  const cell = controlsForm.watch('cell');

  const areaDisplay = new Area({
    value: cell?.properties.area_cut ?? cell?.properties.area ?? 0,
    dimension: 'squareKilometers',
  }).printDisplay();

  if (!cell) {
    return null;
  }

  const cellCoordinates = centerOfMass(cell).geometry.coordinates.map((c) => c.toFixed(6));
  const cellCoordinatesDisplay = `${cellCoordinates[0]} S, ${cellCoordinates[1]} E`;

  const closePanel = () => {
    controlsForm.setValue('cell', undefined);
  };

  return (
    <Stack className='h-full w-[413px] bg-white-100' data-testid='interactive-map-info-panel'>
      <Stack spacing={2} className='flex-1 overflow-auto pb-8'>
        <Stack spacing={4} className='mx-6 mt-8 rounded-xl bg-neutral-black-4 p-4'>
          <Stack direction='row' centerMain spacing={8}>
            <InfoStack
              label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.cellId')}
              content={cell.properties.name}
            />
            <InfoStack
              label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.unitSize')}
              content={areaDisplay}
            />
          </Stack>
          <InfoStack
            label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.cellCoordinates')}
            content={cellCoordinatesDisplay}
          />
        </Stack>

        <Accordion className='group/accordion flex flex-col px-4' type='single' collapsible>
          <AccordionItem value='about'>
            <AccordionTrigger>
              {t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.ecologicalIntegrity')}
            </AccordionTrigger>
            <AccordionContent>
              <EcologicalIntegrity />
            </AccordionContent>
            <AccordionDivider />
          </AccordionItem>

          <AccordionItem value='species'>
            <AccordionTrigger>
              {t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.habitatIntactness')}
            </AccordionTrigger>
            <AccordionContent>
              <HabitatIntactness />
            </AccordionContent>
            <AccordionDivider />
          </AccordionItem>

          <AccordionItem value='community'>
            <AccordionTrigger>
              {t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.floraAndFauna')}
            </AccordionTrigger>
            <AccordionContent className='pt-4'>
              <FloraAndFauna />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Stack>
      <Stack
        direction='row'
        className={cn('justify-end justify-self-end px-6 py-4', '[box-shadow:_0px_-10px_10px_0px_rgba(0,0,0,0.05)]')}
      >
        <Button variant='outline' onClick={closePanel}>
          {t('global.ui.buttons.close')}
        </Button>
      </Stack>
    </Stack>
  );
};

const InfoStack = ({ label, content }: { label: React.ReactNode; content: React.ReactNode }) => {
  return (
    <Stack spacing={2} className='min-w-0 flex-1'>
      <span
        className='typography-overline truncate text-text-secondary'
        title={typeof label === 'string' ? label : undefined}
      >
        {label}
      </span>
      <span className='typography-h4 truncate' title={typeof content === 'string' ? content : undefined}>
        {content}
      </span>
    </Stack>
  );
};

const AccordionDivider = () => <Divider className='mx-4' />;

const EcologicalIntegrity = () => {
  const { t } = useTranslation();

  const currentYear = useFact('r1_conservation_current_year').value;
  const latestAverage = useCellFact('ecological_integrity');

  return (
    <Stack spacing={7}>
      <FactCapsule
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestAverageValue', {
          year: currentYear,
        })}
        fact={latestAverage}
        capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
      />
    </Stack>
  );
};

const HabitatIntactness = () => {
  const { t } = useTranslation();

  const currentYear = useFact<number>('r1_conservation_current_year').value ?? 2023;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value ?? 2018;

  const latestAverage = useCellFact('threats_i');
  const change = useCellFact('threats_i_change');
  const changePercentage = useCellFact('threats_i_change_percentage');

  return (
    <Stack spacing={7}>
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestAverageValue', {
          year: currentYear,
        })}
        fact={latestAverage}
        capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
      />
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.fiveYearChange', {
          yearCount: currentYear - baselineYear,
        })}
        signed
        trend={<FactCapsuleTrend trend={changePercentage} />}
        fact={change}
        capsuleIconProps={{ icon: <RiArrowRightUpLine />, variant: 'default' }}
      />
    </Stack>
  );
};

const FloraAndFauna = () => {
  const { t } = useTranslation();

  const currentYear = useFact('r1_conservation_current_year').value;
  const latestAverage = useCellFact('indicator_species_score');

  return (
    <Stack spacing={7}>
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestAverageValue', {
          year: currentYear,
        })}
        fact={latestAverage}
        capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
      />
    </Stack>
  );
};

const useCellFact = <T extends keyof CellProperties>(property: T) => {
  const controlsForm = useControlsContext();

  const cellProperties = controlsForm.watch('cell')?.properties;

  const value = cellProperties?.[property as T] as CellProperties[T] | undefined;

  return { name: property, value, unit: UnitEnum.none };
};
