import React, { useState } from 'react';

import { OnboardingPage } from '@/layout/OnboardingPage';

import { ResetPasswordForm, ResetPasswordSubmitted } from './components';

export const ResetPasswordPage: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <OnboardingPage variant='narrow'>
      {isSubmitted ? <ResetPasswordSubmitted /> : <ResetPasswordForm onSubmit={() => setIsSubmitted(true)} />}
    </OnboardingPage>
  );
};
