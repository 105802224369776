import { Paper } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface OnboardingPagePaperProps extends PropsWithChildren {
  'data-testid'?: string;
  'data-cy'?: string;
}

export const OnboardingPagePaper: React.FC<OnboardingPagePaperProps> = ({
  'data-testid': dataTestId,
  'data-cy': dataCy,
  children,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: { xs: 4, md: 6 },
        pb: { xs: 3, md: 6 },
        pt: { xs: 5, md: 6 },
        px: { xs: 3, md: 6 },
      }}
      data-testid={dataTestId}
      data-cy={dataCy}
    >
      {children}
    </Paper>
  );
};
