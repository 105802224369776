import { UnitEnum } from './types/units';

export type Ecoregion = 'atlantic_forests_and_dry_shrublands';

export type Biome = 'subtropical_forest';

type ProjectOverviewItem = {
  body: string;
  images: { name: string; caption: string }[];
};

export type SpeciesGroupId = `g${number}`;

export type Project = {
  id: string;
  name: string;
  location_description: string;
  biomes: Biome[];
  organization: {
    id: string;
    name: string;
  };
  area?: string;
  ecoregion: Ecoregion;
  speciesGroups: Record<SpeciesGroupId, string>;
  overview: {
    about: ProjectOverviewItem;
    species: ProjectOverviewItem;
    community: ProjectOverviewItem;
  };
};

export type CellProperties = {
  name: string;
  area: number;
  area_cut: number;
  cut: boolean;
  parent_id: string;
  threats_ts: string;
  threats: number;
  threats_i: number;
  threats_i_ts: Record<string, number>;
  built_ts: string;
  built: number;
  crop_ts: string;
  crop: number;
  edge_ts: string;
  edge: number;
  mine_ts: string;
  mine: number;
  road_ts: string;
  road: number;
  treeloss_ts: string;
  treeloss: number;
  index: number;
  freshwater_habitat: number;
  [`g{number}`]: number;
  indicator_species_score: number;
  ecological_integrity: number;
  threats_i_change: number;
  threats_i_change_percentage: number;
};

export type Tileset = {
  id: string;
  sourceLayer: string;
};

export type Observation = {
  species: string;
  key: number;
  year: number;
  decimalLatitude: number;
  decimalLongitude: number;
  geometry: string;
  references?: string;
  image_url?: string;
  source: string;
  eventDate?: string;
};

export const allThreats = ['built', 'treeloss', 'crop', 'road', 'mine', 'overgrazing', 'other'] as const;

export type Threat = (typeof allThreats)[number];

export type FactType =
  | 'r1_conservation_current_year'
  | 'r1_conservation_baseline_year'
  | 'r1_conservation_no_nature_units'
  | 'r1_conservation_ecological_integrity'
  | 'r1_conservation_ecological_integrity_lowest'
  | 'r1_conservation_ecological_integrity_highest'
  | 'r1_conservation_ecological_integrity_ts'
  | 'r1_conservation_species_group_presence'
  | 'r1_conservation_species_group_presence_ts'
  | 'r1_conservation_threats_i'
  | 'r1_conservation_threats_i_baseline'
  | 'r1_conservation_threats_i_ts'
  | 'r1_conservation_indicator_species_score'
  | 'r1_conservation_indicator_species_ts'
  | 'r1_conservation_groups_detected'
  | 'r1_conservation_drivers_total'
  | 'r1_conservation_drivers_detected'
  | 'r1_conservation_species_pct_historic_graph'
  | 'r1_conservation_habitat_intactness_historic_index_graph'
  | 'r1_conservation_habitat_intactness_historic_graph'
  | 'r1_conservation_detected_threats_breakdown'
  | 'r1_conservation_threats_i'
  | 'r1_conservation_threats_i_baseline'
  | 'r1_conservation_threats_i_ts'
  | 'r1_conservation_built'
  | 'r1_conservation_built_ts'
  | 'r1_conservation_crop'
  | 'r1_conservation_crop_ts'
  | 'r1_conservation_edge'
  | 'r1_conservation_edge_ts'
  | 'r1_conservation_mine'
  | 'r1_conservation_mine_ts'
  | 'r1_conservation_road'
  | 'r1_conservation_road_ts'
  | 'r1_conservation_treeloss'
  | 'r1_conservation_treeloss_ts';

export type Fact<N extends FactType = FactType, V = unknown> = {
  name: N;
  value: V;
  unit: UnitEnum;
  measured_at?: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FactLike<N = any, V = any> = {
  name: N;
  value: V;
  unit: UnitEnum;
  measured_at?: string | null;
};

export type Dashboard = {
  facts: Fact[];
};
