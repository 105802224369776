import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { ElementRef, forwardRef } from 'react';

// NOTE: ignoring this restriction because the component library will be moved soon into the src/components folder anyways
// eslint-disable-next-line import/no-restricted-paths
import { DataAttributes } from '@/types';

import { RiCheckboxFill } from '../Icon/Icon';
import { cn } from '../utils';

export type CheckboxProps = CheckboxPrimitive.CheckboxProps &
  DataAttributes & {
    onChange?: CheckboxPrimitive.CheckboxProps['onCheckedChange'];
    hasError?: boolean;
  };

export const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  (
    {
      id,
      checked,
      disabled,
      hasError,
      children,
      onChange,
      onCheckedChange,
      'data-cy': dataCy, // TODO: this must be refactored as the data-cy attribute is currently being added to the surrounding div, while the data-testid is being added to the inner button, which is confusing and unintuitive
      ...delegated
    },
    ref,
  ) => {
    return (
      <div className='flex items-start gap-2' data-cy={dataCy}>
        <CheckboxPrimitive.Root
          ref={ref}
          id={id}
          checked={checked}
          disabled={disabled}
          className={cn(
            'flex h-5 w-5 min-w-[20px] appearance-none items-center justify-center overflow-hidden',
            'cursor-pointer rounded-[4px] border border-neutral-black-60 bg-transparent hover:bg-info-light',
            'disabled:cursor-not-allowed disabled:border-disabled-button-bg disabled:hover:bg-transparent',
            {
              'border-none': checked,
              'border-error': hasError,
            },
          )}
          onCheckedChange={onCheckedChange ?? onChange}
          {...delegated}
        >
          <CheckboxPrimitive.Indicator className='group/indicator'>
            <RiCheckboxFill size={26} className='text-success' />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {children}
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';
