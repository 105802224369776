import bgPortfolioSrc from '@assets/images/bg-portfolio.jpg';

import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';

import { BuyerPageLayout } from '../../components';
import { About, Help, Hero } from './components';

export const BuyerPortfolioPage = () => {
  return (
    <BuyerPageLayout withCustomContentContainer>
      <div
        className='bg-cover full-bleed-x'
        style={{ backgroundImage: `url(${bgPortfolioSrc})` }}
        data-cy='portfolio-hero-bg'
      >
        <SignedInPageLayoutContentContainer>
          <Hero />
        </SignedInPageLayoutContentContainer>
      </div>
      <div className='full-bleed-x'>
        <SignedInPageLayoutContentContainer>
          <div className='flex flex-col gap-8 lg:flex-row'>
            <About />
            <Help />
          </div>
        </SignedInPageLayoutContentContainer>
      </div>
    </BuyerPageLayout>
  );
};
