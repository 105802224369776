import { AppBar, AppBarContent, Button, Container, Stack, toast, toastifyToast } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { PlotType } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { GrasslandQuestionnaire } from '../../../components/GrasslandQuestionnaire';
import { PlotOverview } from '../../../components/PlotOverview';
import { PlotUsage } from '../../../components/PlotUsage';
import { usePlotForm } from '../../../hooks/usePlotForm';
import { TitleBar } from '../components/TitleBar';
import { useCreatePlot } from '../hooks/useCreatePlot';

export const QuestionnairePage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = usePlotForm();

  const [polygon, plotType] = form.watch(['polygon', 'plotType']);

  /**
   * Even though they are invoked identically, we have separate submit actions
   * so that we get separate loading states for each. That way, we can show a
   * loading indicator on the appropriate button.
   */
  const { submit: submitPlot, isSubmitting: isSubmittingPlot } = useCreatePlot();
  const { submit: submitDraftPlot, isSubmitting: isSubmittingDraftPlot } = useCreatePlot();

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  const showErrorToast = (error: unknown) => {
    let description = t('landSteward.plot.submitFailedErrorDescription');
    if (error instanceof RestApiBadRequestServerError && error.errors[0]) {
      [description] = error.errors;
    }
    if (error instanceof RestApiBadRequestServerError && error.formErrors[0]) {
      description = error.formErrors[0].messages[0] ?? '';
    }

    Logger.errorOnce(error as Error);

    toast({
      title: t('landSteward.plot.createFailedErrorTitle'),
      description,
      type: 'error',
      autoClose: 10_000,
    });
  };

  const submit = (options?: { draft?: boolean }) => async () => {
    const inputs = form.getValues();

    try {
      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      if (options?.draft) {
        await submitDraftPlot(inputs, options);
      } else {
        await submitPlot(inputs, options);
      }

      navigate(buildPath(paths.landSteward.newPlotSuccess, { pathParams: { projectId } }), {
        state: { successPageVariant: options?.draft ? 'draft' : 'default' },
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  if (!polygon || !plotType) {
    return <Navigate to={buildPath(paths.landSteward.newPlot, { pathParams: { projectId } })} />;
  }

  return (
    <>
      <Stack className='min-h-screen' data-testid='questionnaire-page'>
        <TitleBar currentStep={3} />
        <form onSubmit={form.handleSubmit(submit())} className='flex flex-1 flex-col'>
          <fieldset disabled={isSubmittingPlot || isSubmittingDraftPlot} className='flex-1'>
            <Container contentWidth='md' gutterWidth={{ sm: 4 }} className='mb-12 gap-y-6'>
              <div className='mb-6 full-bleed-x'>
                <PlotOverview
                  editPlotPath={buildPath(paths.landSteward.newPlotDrawBoundary, { pathParams: { projectId } })}
                />
              </div>
              <PlotUsage />
              {renderQuestionnaireForPlotType(plotType)}
            </Container>
          </fieldset>
          <AppBar placement='bottom'>
            <AppBarContent className='justify-between'>
              <Button onClick={() => navigate(-1)} variant='outline'>
                {t('global.ui.buttons.back')}
              </Button>
              <Stack direction='row' spacing={4} centerMain>
                <Button
                  loading={isSubmittingDraftPlot}
                  variant='text'
                  onClick={submit({ draft: true })}
                  disabled={isSubmittingPlot}
                >
                  {t('landSteward.plot.new.questionnairePage.buttons.saveAsDraft')}
                </Button>
                <Button type='submit' loading={isSubmittingPlot} disabled={isSubmittingDraftPlot}>
                  {t('landSteward.plot.new.questionnairePage.buttons.submit')}
                </Button>
              </Stack>
            </AppBarContent>
          </AppBar>
        </form>
      </Stack>
    </>
  );
};

const renderQuestionnaireForPlotType = (plotType: PlotType) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (plotType) {
    case PlotType.GRASSLAND:
      return <GrasslandQuestionnaire />;

    default:
      return null;
  }
};
