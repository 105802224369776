import { Stack } from '@landler/tw-component-library';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../card/Card';

export const HowToWidget = () => {
  const { t } = useTranslation();

  const items = [
    t('buyer.investments.howTo.step1'),
    t('buyer.investments.howTo.step2'),
    t('buyer.investments.howTo.step3'),
  ];

  return (
    <Card label={t('buyer.investments.howTo.label')} data-testid='investment-how-to' data-cy='investments-about'>
      <Stack spacing={6}>
        {items.map((item, index) => (
          <ListItem key={index} number={index + 1} text={item} />
        ))}
      </Stack>
    </Card>
  );
};

type ListItemProps = {
  number: number;
  text: string;
};

const ListItem: FC<ListItemProps> = ({ number, text }) => (
  <Stack direction='row' spacing={4}>
    <span className='typography-body2Semibold flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-primary-100 text-primary-100'>
      {number}
    </span>
    <span className='typography-body1 text-text-secondary' data-cy={`investments-about-item-${number}`}>
      {text}
    </span>
  </Stack>
);
