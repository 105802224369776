import { Position } from 'geojson';

import { Organization } from './organization';

// TODO: (MVP-1132): these types should be generated automatically based on the API's Swagger documentation

// eslint-disable-next-line @typescript-eslint/ban-types
export type LandQuestionnaireAnswers<T = {}> = Partial<T>;

type Point = {
  lat: number;
  lon: number;
};

export type Polygon = {
  type: 'Polygon';
  coordinates: Position[][];
};

export enum PlotType {
  GRASSLAND = 'grassland',
  CROPLAND = 'cropland',
  TREE_COVER = 'tree_cover',
  HERBACEOUS_WETLAND = 'herbaceous_wetland',
  MOSS_AND_LICHENS = 'moss_and_lichens',
  BUILT_UP = 'built_up',
  SHRUBLAND = 'shrubland',
  BARE_AND_SPARSE_VEGETATION = 'bare_and_sparse_vegetation',
  MANGROVES = 'mangroves',
  PERMANENT_WATER_BODIES = 'permanent_water_bodies',
  SNOW_AND_ICE = 'snow_and_ice',
  UNDEFINED = 'undefined',
}

export enum GrasslandGrassType {
  NONE = 'NONE',
  PERENNIAL_GRASS = 'PERENNIAL_GRASS',
  GRASS_CLOVER_MIXTURE = 'GRASS_CLOVER_MIXTURE',
  ALFALFA = 'ALFALFA',
}

export enum GrasslandOrganicFertilizerType {
  NONE = 'NONE',
  SLURRY = 'SLURRY',
  FORCED_AERIATION_COMPOST_OR_MANURE = 'FORCED_AERIATION_COMPOST_OR_MANURE',
  NON_FORCED_AERIATION_COMPOST_OR_MANURE = 'NON_FORCED_AERIATION_COMPOST_OR_MANURE',
}

export enum CropsEnum {
  alfalfa = 'alfalfa',
  apple = 'apple',
  barley = 'barley',
  canola = 'canola',
  clover = 'clover',
  coffee = 'coffee',
  cotton = 'cotton',
  dry_bean = 'dry_bean',
  grass_clover_mix = 'grass_clover_mix',
  hops = 'hops',
  maize_grain = 'maize_grain',
  maize_silage = 'maize_silage',
  millet = 'millet',
  oats = 'oats',
  peanut = 'peanut',
  perennial_grass = 'perennial_grass',
  potato = 'potato',
  rapeseed = 'rapeseed',
  rice = 'rice',
  rye = 'rye',
  sorghum = 'sorghum',
  soyabean = 'soyabean',
  spring_wheat = 'spring_wheat',
  tea = 'tea',
  tomato = 'tomato',
  tree_crop = 'tree_crop',
  vegetable_above_ground = 'vegetable_above_ground',
  sugarcane = 'sugarcane',
  vegetable_root = 'vegetable_root',
  winter_wheat = 'winter_wheat',
  other = 'other',
  other_grain = 'other_grain',
  other_legume = 'other_legume',
  other_n_fixing_forage = 'other_n_fixing_forage',
  other_non_n_fixing_forage = 'other_non_n_fixing_forage',
  other_root_crops = 'other_root_crops',
  other_tuber_crop = 'other_tuber_crop',
  blueberry = 'blueberry',
  raspberry = 'raspberry',
  strawberry = 'strawberry',
}

export enum ErrorsEnum {
  self_intersecting = 'self_intersecting',
  too_small = 'too_small',
  too_large = 'too_large',
  invalid_shape_index = 'invalid_shape_index',
  contains_holes = 'contains_holes',
}

export enum PlotStatusEnum {
  invalid = 'invalid',
  new_plot = 'new_plot',
  draft = 'draft',
  ready_to_analyse = 'ready_to_analyse',
  scheduled_for_analysis = 'scheduled_for_analysis',
  analysed = 'analysed',
  calculating = 'calculating',
}

export type DraftPlot = {
  id: string;
  user_id: string;
  name: string;
  land_type: PlotType;
  polygon: Polygon;
  area: number;
  project_id: string | null;
};

export type Plot = {
  id: string;
  name: string;
  type: PlotType;
  polygon: Polygon;
  area: number;
  project?: string | null;
  organization: Organization;
  crops: CropsEnum[];
  errors: ErrorsEnum[];
  has_complete_questionnaire: boolean;
  is_deforested: boolean;
  status: PlotStatusEnum;
  created_at: string;
  modified_at: string;
  last_analyzed_at: string | null;
};

/**
 * GET /api/v1/draftplot
 */
export const GET_DRAFT_PLOT_PATH = 'draftplot';
export type GetDraftPlotRequestPathVariables = undefined;

export type GetDraftPlotRequestQueryParams =
  | {
      project?: string;
      land_type?: string;
      limit?: number;
      offset?: number;
    }
  | undefined;
export type GetDraftPlotSuccessDto = {
  count: number;
  next: string | null;
  previous: string | null;
  results: DraftPlot[];
};
export type GetDraftPlotErrorDto = Error;

/**
 * POST /api/v1/draftplot
 */
export const POST_DRAFT_PLOT_PATH = 'draftplot';
export type PostDraftPlotRequestPathVariables = undefined;
export type PostDraftPlotRequestQueryParams = undefined;
export type PostDraftPlotRequestBodyData = {
  file: File;
  land_type: PlotType;
  project: string;
};
export type PostDraftPlotSuccessDto = undefined;
export type PostDraftPlotErrorDto = Error;

/**
 * POST /api/v1/draftplot/commit
 */
export const POST_DRAFT_PLOT_COMMIT_PATH = 'draftplot/commit/';
export type PostDraftPlotCommitRequestPathVariables = undefined;
export type PostDraftPlotCommitRequestQueryParams = undefined;
export type PostDraftPlotCommitRequestBodyData = {
  draft_plots: string[];
};
export type PostDraftPlotCommitSuccessDto = undefined;
export type PostDraftPlotCommitErrorDto = Error;

/**
 * GET /api/v1/plot/aggregate
 */
export const GET_PLOT_AGGREGATE_PATH = 'plot/aggregate';
export type GetPlotAggregateRequestPathVariables = undefined;
export type GetPlotAggregateRequestQueryParams =
  | {
      project?: string;
    }
  | undefined;
export type GetPlotAggregateSuccessDto = {
  plots_requiring_attention: number;
};
export type GetPlotAggregateErrorDto = Error;

/**
 * GET /api/v1/buyer/plot/aggregate
 */
export const GET_BUYER_PLOT_AGGREGATE_PATH = 'buyer/plot/aggregate';
export type GetBuyerPlotAggregateRequestPathVariables = undefined;
export type GetBuyerPlotAggregateRequestQueryParams =
  | {
      project?: string;
    }
  | undefined;
export type GetBuyerPlotAggregateSuccessDto = {
  plots_requiring_attention: number;
};
export type GetBuyerPlotAggregateErrorDto = Error;

/**
 * GET /api/v1/plot
 */
export const GET_PLOT_PATH = 'plot';
export type GetPlotRequestPathVariables = undefined;

export type GetPlotRequestQueryParams =
  | {
      project?: string;
      demo?: boolean;
    }
  | undefined;
export type GetPlotSuccessDto = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Plot[];
};
export type GetPlotErrorDto = Error;

/**
 * GET /api/v1/plot/{id}
 */
export const GET_PLOT_BY_ID_PATH = 'plot/{id}';
export type GetPlotByIdRequestPathVariables = { id: string };
export type GetPlotByIdRequestQueryParams = undefined;
export type GetPlotByIdSuccessDto = Plot;
export type GetPlotByIdErrorDto = Error;

/**
 * POST /api/v1/plot
 */
export const POST_PLOT_PATH = 'plot';
export type PostPlotRequestPathVariables = undefined;
export type PostPlotRequestQueryParams = undefined;
export type PostPlotRequestBodyData = {
  name: string;
  type: PlotType;
  polygon: Polygon;
  project: string;
  organization: string;
  crops: CropsEnum[];
  status?: PlotStatusEnum;
};
export type PostPlotSuccessDto = {
  id: string;
  name: string;
  type?: PlotType;
  polygon: Polygon;
  area: number;
  project?: string | null;
  organization: string;
};
export type PostPlotErrorDto = Error;

/**
 * PATCH /api/v1/plot/{id}
 */
export const PATCH_PLOT_PATH = 'plot/{id}';
export type PatchPlotRequestPathVariables = { id: string };
export type PatchPlotRequestQueryParams = undefined;
export type PatchPlotRequestBodyData = Partial<{
  name: string;
  type: PlotType;
  polygon: Polygon;
  project: string;
  crops: CropsEnum[];
  organization: string;
  status?: PlotStatusEnum;
}>;
export type PatchPlotSuccessDto = {
  id: string;
  name: string;
  type?: PlotType;
  polygon: Polygon;
  area: number;
  project?: string | null;
  crops: CropsEnum[];
  organization: string;
};
export type PatchPlotErrorDto = Error;

/**
 * DELETE /api/v1/plot/{id}
 */
export const DELETE_PLOT_PATH = 'plot/{id}';
export type DeletePlotRequestPathVariables = {
  id: string;
};
export type DeletePlotRequestQueryParams = undefined;
export type DeletePlotSuccessDto = {
  /* no body */
};
export type DeletePlotErrorDto = Error;

/**
 * PATCH /api/v1/plot/{id}/get_questionnaire
 */
export const GET_PLOT_QUESTIONNAIRE_PATH = 'plot/{id}/get_questionnaire';
export type GetPlotQuestionnaireRequestPathVariables = { id: string };
export type GetPlotQuestionnaireRequestQueryParams = undefined;
export type GetPlotQuestionnaireRequestBodyData = undefined;
export type GetPlotQuestionnaireSuccessDto = { json: LandQuestionnaireAnswers<unknown> };
export type GetPlotQuestionnaireErrorDto = Error;

/**
 * PATCH /api/v1/plot/{id}/update_questionnaire
 */
export const PATCH_PLOT_QUESTIONNAIRE_PATH = 'plot/{id}/update_questionnaire';
export type PatchPlotQuestionnaireRequestPathVariables = { id: string };
export type PatchPlotQuestionnaireRequestQueryParams = undefined;
export type PatchPlotQuestionnaireRequestBodyData = {
  json: LandQuestionnaireAnswers<unknown>;
};
export type PatchPlotQuestionnaireSuccessDto = {
  /* no body */
};
export type PatchPlotQuestionnaireErrorDto = Error;

/**
 * POST /api/v1/plot/kml_to_polygon
 * POST /api/v1/plot/shp_to_polygon
 */
export const UPLOAD_PLOT_KML_TO_POLYGON_PATH = 'plot/kml_to_polygon';
export const UPLOAD_PLOT_SHP_TO_POLYGON_PATH = 'plot/shp_to_polygon';
export type UploadPlotFileToPolygonRequestPathVariables = undefined;
export type UploadPlotFileToPolygonRequestQueryParams = undefined;
export type UploadPlotFileToPolygonRequestBodyData = { file: File };
export type UploadPlotFileToPolygonSuccessDto = {
  centroid: Point;
  place_name: string;
  polygon: Polygon;
};
export type UploadPlotFileToPolygonErrorDto = { file: string[] };

/**
 * POST /api/v1/plot/validate_polygon
 */
export const POST_PLOT_VALIDATE_POLYGON_PATH = 'plot/validate_polygon';
export type PostPlotValidatePolygonRequestPathVariables = undefined;
export type PostPlotValidatePolygonRequestQueryParams = undefined;
export type PostPlotValidatePolygonRequestBodyData = {
  polygon: Polygon;
};
export type PostPlotValidatePolygonSuccessDto = {
  polygon: Polygon;
  centroid: Point;
  place_name: string;
  country: string;
};
export type PostPlotValidatePolygonErrorDto = Error;

/**
 * GET /api/v1/buyer/plot
 */
export const GET_BUYER_PLOTS_PATH = 'buyer/plot';
export type GetBuyerPlotsRequestPathVariables = undefined;
export type GetBuyerPlotsRequestQueryParams = { project?: string };
export type GetBuyerPlotsSuccessDto = {
  count: number;
  next: null;
  previous: null;
  results: Plot[];
};
export type GetBuyerPlotsErrorDto = Error;

/**
 * GET /api/v1/buyer/plot/{id}
 */
export const GET_BUYER_PLOT_BY_ID_PATH = 'buyer/plot/{id}';
export type GetBuyerPlotByIdRequestPathVariables = { id: string };
export type GetBuyerPlotByIdRequestQueryParams = undefined;
export type GetBuyerPlotByIdSuccessDto = Plot;
export type GetBuyerPlotByIdErrorDto = Error;
